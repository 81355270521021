import { Link, useHistory } from "react-router-dom";
import { faPlus, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormEvent, useState } from "react";
import { Discount } from "./types";
import { useEffect } from "react";
import { fetchDiscounts } from "./service";

export function List() {

    const [searchText, setSearchText] = useState('');
    const [loading, setLoading] = useState(false);
    const [discounts, setDiscounts] = useState<Discount[]>([]);
    const history = useHistory();

    useEffect(() => {
        loadDiscounts();
    }, []);

    function handleSearchSubmit(event: FormEvent) {
        event.preventDefault();
        loadDiscounts();
    }

    function loadDiscounts() {
        const params = {
            search: searchText,
        };
        setLoading(true);
        fetchDiscounts(params).then(({ data }) => {
            setDiscounts(data);
            setLoading(false);
        });
    }

    return (
        <div>
            <div>
                <h1 className="text-lg">Discounts</h1>
            </div>
            <div className="p-2 my-4 bg-white rounded shadow">
                <div className="flex items-center">
                    <form action="" onSubmit={handleSearchSubmit} className="flex">
                        <div className="mr-2">
                            <input type="search" placeholder="Search..." value={searchText} onChange={(e) => { setSearchText(e.target.value) }} className="mb-0 input-control" />
                        </div>
                        <div>
                            <button className="bg-yellow-300 button" disabled={loading}>Search</button>
                        </div>
                    </form>
                    <div className="flex h-auto ml-auto">
                        <button className="mr-2 text-white bg-gray-600 button" type="button" onClick={(event) => { event.preventDefault(); loadDiscounts(); }} disabled={loading}>
                            <FontAwesomeIcon icon={faSyncAlt} className={'mr-1 text-sm ' + (loading ? 'fa-spin' : '')} />
                            {loading ? 'Loading...' : 'Refresh'}
                        </button>
                        <Link to="/discounts/new" className="bg-yellow-300 button">
                            <FontAwesomeIcon icon={faPlus} className="mr-1" />
                            <span>New Discount</span>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="p-2 my-4 bg-white rounded shadow">
                {discounts.length && !loading ? (
                    <table className="table w-full table-striped">
                        <thead>
                            <th className="text-left">Name</th>
                            <th className="text-left">Title</th>
                            <th className="text-left">Group</th>
                        </thead>
                        <tbody>
                            {discounts.map((discount) => (
                                <tr className="cursor-pointer" onClick={() => { history.push('/discounts/' + discount.id + '/edit') }}>
                                    <td>{discount.name}</td>
                                    <td>{discount.title}</td>
                                    <td>{discount.group?.name}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : null}
                {!discounts.length && !loading ? (
                    <p>No discounts found</p>
                ) : null}
                {loading ? (
                    <p>Loading discounts...</p>
                ) : null}
            </div>
        </div>
    );
}

export default List;