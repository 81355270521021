import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createRef } from "react";
import { ChangeEvent, useState } from "react";
import Alert from "../alerts/Alert";
import { getUploadUrl, uploadFile } from './service';
import { MediaFile } from "./types";
import { isFileTypeImage } from "./util";

type OnUploadedFunction = (mediaFile: MediaFile) => any;
export interface UploadProps {
    onUploaded?: OnUploadedFunction,
    imageOnly?: boolean
}

export function Upload(props: UploadProps) {
    const [file, setFile] = useState<File | null>(null);
    const [uploading, setUploading] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const inputRef = createRef<HTMLInputElement>();

    function handleFileChanged(event: ChangeEvent<HTMLInputElement>) {
        if (!event.target.files || !event.target.files.length) {
            setFile(null);
            return;
        }
        const file = event.target.files[0];
        setFile(file);
    }

    function handleUpload() {
        setMessage('');
        setError('');
        if (file === null) {
            return;
        }
        setUploading(true);
        getUploadUrl(file.name).then(({ data }) => {
            const url = data.signedUploadUrl;
            const mimeType = data.mimeType;
            uploadFile(file, url, mimeType).then(() => {
                if(props.onUploaded) {
                    props.onUploaded(data);
                }
                setUploading(false);
                setMessage('File uploaded successfully');
                setFile(null);
                if (inputRef.current) {
                    inputRef.current.value = '';
                }
                window.setTimeout(() => {setMessage('')}, 5000);
            }).catch(handleUploadError);

        }).catch(handleUploadError)
    }

    function handleUploadError(err: any) {
        setUploading(false);
        console.error(err);
        setError('Failed to upload file, try again');
    }

    function isFileAnImage() {
        if (file === null) {
            return false;
        }
        return isFileTypeImage(file['type']);
    }

    const accepts = props.imageOnly ? 'image/*' : '*' ;

    return (
        <div>
            <Alert message={message} type="success" />
            <Alert message={error} type="error" />
            <div className="mb-2">
                <label className="inline-block w-auto text-sm text-white bg-gray-500 cursor-pointer button">
                    <input type="file" onChange={handleFileChanged} multiple={false} className="invisible w-0" ref={inputRef} accept={accepts}/>
                    <FontAwesomeIcon icon={faUpload} className="mr-1" />
                    <span>Choose a File</span>
                </label>
            </div>
            {file && isFileAnImage() ? (
                <div className="w-48 max-w-full p-2 pt-1 mb-2 border-2">
                    <span className="text-xs text-gray">Preview:</span>
                    <img src={URL.createObjectURL(file)} alt="Preview" />
                </div>
            ) : null}
            {uploading ? (
                <button className="bg-yellow-400 button" type="button" disabled={true}>Uploading...</button>
            ) : (
                <button className="bg-yellow-400 button" type="button" onClick={handleUpload} disabled={file == null}>Upload {file ? file.name : ''}</button>
            )}
        </div>
    );
}

export default Upload;