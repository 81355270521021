import Dialog from "../dialog/Dialog";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {useState} from "react";
import Alert from "../alerts/Alert";
import {selectDeleteCard, setDeleteCard} from "./slice";
import {setEditPassenger} from "../passengers/slice";
import {setSuccessNotificationText} from "../notification/slice";
import {deleteCard as apiDeleteCard} from "./service";

export const DeleteDialog = () => {

    const deleteCard = useAppSelector(selectDeleteCard);
    const dispatch = useAppDispatch();
    const [deleting, setDeleting] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')


    if (!deleteCard) {
        return null;
    }

    const cancelFunction = () => {
        setErrorMessage('')
        dispatch(setDeleteCard(null))
    }


    const handleDeleteCard = () => {
        setDeleting(true);

        apiDeleteCard(deleteCard).then(() =>{
            dispatch(setDeleteCard(null))
            dispatch(setEditPassenger(null))
            setSuccessNotificationText('Card deleted successfully')
        }).catch(() => {
            setErrorMessage('Failed to delete Card, please try again');
        }).finally(() => {
            setDeleting(false);
        })
    }

    return (
        <Dialog title="Delete Card" confirmFunction={handleDeleteCard} cancelFunction={cancelFunction} disabled={deleting} confirmText={deleting? 'Deleting...' : 'Delete'}>
            <p> Are you sure you want to delete delete card <strong> {deleteCard.cardNumber}? </strong></p>
            <Alert message={errorMessage} type="error" />
        </Dialog>
    )
}