import { useState } from "react";
import { Route } from "./types";
import { Driver, DriverRoute } from "../drivers/types";
import { useEffect } from "react";
import {fetchAllDrivers} from "../drivers/service";
import moment from 'moment';
import {Permission} from "../policies/helpers";
import { Link } from "react-router-dom";

export interface EditDriversProps {
    route: Route,
    onChange: CallableFunction
    permissionsLevel: number
}

export function EditDrivers(props: EditDriversProps) {
    const [routeDrivers, setRouteDrivers] = useState([...props.route.drivers])
    const [drivers, setDrivers] = useState<Array<Driver>>([]);

    useEffect(() => {
        fetchAllDrivers().then(({ data }) => {
            setDrivers(data);
        });
    }, []);

    function updateDriverRouteChecked(driver: Driver) {
        const newDrivers = [...routeDrivers];
        const index = newDrivers.findIndex(routeDriver => routeDriver.driverId === driver.id);
        if (index === -1) {
            const driverRoute = {
                driverId: driver.id,
                firstName: driver.firstName,
                lastName: driver.lastName,
                expiresAt: '',
            } as DriverRoute;
            newDrivers.push(driverRoute);
        } else {
            newDrivers.splice(index, 1);
        }

        setRouteDrivers(newDrivers);
        props.onChange({ ...props.route, drivers: newDrivers });
    }

    function updateDriverRouteExpiresAt(driver: Driver, expiresAt: string) {
        const newDrivers = [...routeDrivers];
        const driverRouteIndex = newDrivers.findIndex(d => d.driverId === driver.id);
        if (driverRouteIndex < 0) {
            return;
        }
        newDrivers[driverRouteIndex].expiresAt = expiresAt;
        setRouteDrivers(newDrivers);
        props.onChange({ ...props.route, drivers: newDrivers });

    }


    return (
        <div>
            {drivers.map((driver: Driver) => {
                const driverRoute = routeDrivers.find(d => d.driverId === driver.id);
                let expiresAt = '';
                if (driverRoute && driverRoute.expiresAt) {
                    expiresAt = moment.utc(driverRoute.expiresAt).format('YYYY-MM-DDTHH:mm');
                }

                return (
                    <div className="flex items-center p-2 mb-1 border-2 rounded" key={'driver_' + driver.id}>
                        <input disabled={props.permissionsLevel === Permission.Read} readOnly type="checkbox" checked={driverRoute !== undefined} onClick={(e) => { updateDriverRouteChecked(driver) }} className="mr-2" />
                        <span>{driver.firstName} {driver.lastName}</span>
                        <div className="ml-auto">
                            <label className="mr-2 text-sm text-gray-500">Expires at:</label>
                            {driverRoute ? (<input disabled={props.permissionsLevel === Permission.Read} type="datetime-local" value={expiresAt} className="mb-0 input-control" onChange={(e) => { updateDriverRouteExpiresAt(driver, e.target.value) }} />) : (<input type="datetime-local" disabled className="mb-0 input-control" />)}
                        </div>
                    </div>
                )
            })}
            {
                drivers.length ===0 && <span className="block px-1 py-4">There are no drivers available for this route. Please assign driver(s) by going to the <Link to="/drivers" className="underline">Drivers screen</Link></span>
            }
        </div>
    );
}

export default EditDrivers;