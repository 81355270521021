import { createAction } from "@reduxjs/toolkit";
import { LoginActionPayload, LoginServerResponse, RefreshActionPayload } from "./types";

export const loginAction = createAction<LoginActionPayload>('LOGIN');

export const logoutAction = createAction('LOGOUT');

export const refreshAction = createAction<RefreshActionPayload>('REFRESH');

export const refreshSuccessEffect = createAction<LoginServerResponse>('REFRESH_SUCCESSFUL');
