import axios, { AxiosRequestConfig } from 'axios';
import {API_HOST} from './constants';

const apiConfig = {
    baseURL: API_HOST,
} as AxiosRequestConfig;

// An axios instance to make requests to the API server without authentication
export const baseApiAxios = axios.create(apiConfig)

// An axios instance to make requests to the API server with authentication
export const apiAxios = axios.create(apiConfig)

export default apiAxios;