import React, {useEffect, useState} from "react";
import {Message, Messages} from "./types";
import {fetchAppMessages} from "./service";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSyncAlt} from "@fortawesome/free-solid-svg-icons";
import {RouteMapMarker} from "../routes/RouteMap";
import passengerPin from "../../img/yellow-pin.png"
import {PositionMap} from "./PositionMap";
import Pagination from "../pagination/Pagination";
import moment from "moment";

export const Index = () => {
    const [messages, setMessages] = useState<Messages>()
    const [loading, setLoading] = useState(false)
    const [marker, setMarker] = useState<RouteMapMarker>()
    const [search, setSearch] = useState('')
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(1);

    useEffect(() => {
        setLoading(true)
        fetchMessages();
    }, [page])

    const fetchMessages = () => {
        setMessages(undefined)
        fetchAppMessages({search: search, page: page}).then((response) => {
            const data = response.data
            setPages(data.lastPage)
            setMessages(data.items)
        }).finally(() => {
            setLoading(false)
        })
    }
    const showMap = (message: Message) => {
        setMarker({
            name: 'Passenger Location',
            location: new google.maps.LatLng(parseFloat(message.lat), parseFloat(message.long)),
            icon: passengerPin,
            type: 'PASSENGER_LOCATION'
        })
    }

    const formatDateTime = (dateTime: string|null, fallback = '-') => {
        if (!dateTime) {
            return fallback;
        }
    
        return moment(dateTime).format('DD/MM/YYYY HH:mm a');
    }

    return (
        <div>
            <div>
                <h1 className="mb-2 text-lg">Help Requests</h1>
            </div>
            <div className="p-2 my-4 bg-white rounded shadow">
                <div className="flex items-center">
                        <div className="mr-2">
                            <input type="search" placeholder="User" onChange={(event) => {setMessages(undefined); setSearch(event.target.value)}} className="mb-0 input-control" />
                        </div>
                    <div>
                        <button className="bg-yellow-300 button" onClick={fetchMessages} disabled={loading}>Search</button>
                    </div>
                    <div className="flex h-auto ml-auto">
                        <button className="mr-2 text-white bg-gray-600 button" type="button" disabled={loading} onClick={() => fetchMessages()}>
                            <FontAwesomeIcon icon={faSyncAlt} className={'mr-1 text-sm ' + (loading ? 'fa-spin' : '')} />
                            {loading ? 'Loading...' : 'Refresh'}
                        </button>
                    </div>
                </div>
            </div>
            {loading &&<span>...Loading messages</span>}

            {messages && <div className="w-full p-4 bg-white shadow">
                <table className="table w-full table-striped">
                    <thead>
                    <tr>
                        <th>Sent By</th>
                        <th>Route</th>
                        <th className="text-left">Title</th>
                        <th className="text-left">Message</th>
                        <th>Sent At</th>
                        <th>&nbsp;</th>
                    </tr>
                    </thead>
                    <tbody>
                    {messages && messages.length === 0 &&<tr><td colSpan={5}><div className="p-4 m-3 text-center text-gray-400">No messages</div></td></tr>}
                    {messages.map(message => {
                        return (
                            <tr key={message.id}>
                                <td className="text-center">{message.sentBy && message.sentBy.firstName + ' ' + message.sentBy.lastName}</td>
                                <td className="text-center">{message.route ? message.route?.name : 'Unknown'}</td>
                                <td>{message.title}</td>
                                <td className="text-left">{message.body}</td>
                                <td>{formatDateTime(message.sentAt)}</td>
                                <td className="text-right">{message.long && message.lat && <button onClick={() => {showMap(message)}} className="block my-1 mt-4 text-white bg-gray-600 button"> Location </button>}</td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>

                <div className="m-2 text-center">
                <Pagination page={page} onChange={setPage} pages={pages}/>
                </div>
            </div>
            }
            {marker && <PositionMap marker={marker} closeModal={() => setMarker(undefined)} />}
        </div>
    )
}
