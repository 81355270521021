import { MediaFile } from "./types";

export function isMediaFileAnImage(mediaFile: MediaFile) {
    return isFileTypeImage(mediaFile.mimeType);
    
}

export function isFileTypeImage(fileType: string) {
    const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
    return validImageTypes.includes(fileType);
}