import {apiAxios} from '../api/axios';
import { Carer } from './types';

export interface FetchCarersParams {
    search?: string,
    perPage?: number,
}

export function fetchCarers(params?: FetchCarersParams) {
    return apiAxios.get('/carers', {params});
}

export function fetchCarer(id: any)
{
    return apiAxios.get('/carers/'+id);
}

export function saveCarer(carer: Carer)
{
    if (carer.id) {
        return apiAxios.put('/carers/'+carer.id, carer);
    }
    return apiAxios.post('/carers', carer);
}

export function deleteCarer(carer: Carer) {
    return apiAxios.delete('/carers/'+carer.id);
}