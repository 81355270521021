
import axios from 'axios';
import {apiAxios} from '../api/axios';
import { MediaFile } from './types';

export function getUploadUrl(fileName: string) {
    return apiAxios.post('/media/uploadUrl', {
        fileName,
    });
}

export function getViewUrl(fileName: string) {
    return apiAxios.get('/media/viewUrl', {
        params: {
            fileName,
        }
    });
}

export function getMediaFileViewUrl(mediaFile: MediaFile) {
    return apiAxios.get('/media/'+mediaFile.id+'/viewUrl');
}

export function deleteMediaFile(mediaFile: MediaFile) {
    return apiAxios.delete('/media/'+mediaFile.id);
}

export function uploadFile(file: File, url: string, mimeType: string) {
    const gcAxios = axios.create();
    return gcAxios.put(url, file, {headers: {'Content-Type': mimeType}});
}