import { Formik, Form, Field } from "formik";
import React, { useEffect, useState } from "react";
import { CovidTestFormValues, SaveCovidTestParams } from "./types";
import { save } from "./service";
import { getFormikDateFromString } from "../dates/helper";
import Alert from "../alerts/Alert";
import * as Yup from "yup";
import FormError from "../form/FormError";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { setErrorNotificationText, setSuccessNotificationText } from "../notification/slice";
import { useHistory } from "react-router";
import { useAppDispatch } from "../../app/hooks";
import UserAutoComplete from "../users/UserAutoComplete";
import { ParamsWithId } from "../routing/types";
import { fetchUser } from "../users/service";
import { User } from "../users/types";

export const AddTest = () => {

    const [errorMessage, setErrorMessage] = useState('')
    const [apiErrors, setApiErrors] = useState({})
    const [user, setUser] = useState<User>()
    const [loading, setLoading] = useState(false)
    const [saving, setSaving] = useState(false)
    const history = useHistory();
    const dispatch = useAppDispatch();
    const { id } = useParams<ParamsWithId>();

    useEffect(() => {
        if (id) {
            setLoading(true)
            fetchUser(id).then(res => {
                setUser(res.data)
            }).finally(() => {
                setLoading(false)
            })
        }
    }, [])

    const handleSaveCovidTest = (values: CovidTestFormValues) => {
        setSaving(true)
        if (!(values.user)) {
            setErrorMessage('A user must be selected')
            return
        }
        const saveCovidTestParams: SaveCovidTestParams = {
            testId: values.testId,
            date: values.date,
            positive: values.positive,
            user: values.user,
            type: values.type,
        }

        save(saveCovidTestParams).then((res) => {
            dispatch(setSuccessNotificationText('Covid test saved'))
            if (values.user?.id) {
                history.push('/covid/' + values.user?.id)
            } else {
                history.push('/covid')
            }
        }).catch((err) => {
            if (err.response.data) {
                setApiErrors(err.response.data);
            }
            dispatch(setErrorNotificationText('Error saving covid test'))
        }).finally(() => {
            setSaving(false)
        })
    }
    const today = new Date()
    const initialValues: CovidTestFormValues = {
        testId: '',
        date: getFormikDateFromString(today.toISOString()),
        user: user,
        positive: true,
        type: '',
    }

    const validation = Yup.object().shape({
        user: Yup.object().label('Name of Person Tested').required(),
        testId: Yup.string().label('Test ID').required(),
        date: Yup.date().label('Date of Test').required(),
        positive: Yup.boolean().label('Positive').required(),
        type: Yup.string().label('Test Type').required(),
    });

    useEffect(() => {
        setTimeout(() => {
            setErrorMessage('')
        }, 10000)
    }, [errorMessage])

    if (loading) {
        return (<div>Loading ...</div>)
    }
    return (
        <div>
            <div className="mb-4">
                <h1 className="text-lg">Covid Information</h1>
            </div>
            <Formik
                initialValues={initialValues}
                onSubmit={(values) => {
                    handleSaveCovidTest(values)
                }}
                validationSchema={validation}
            >
                {({ setFieldValue, handleChange, isValid, dirty, errors }) => {
                    const allErrors = { ...errors, ...apiErrors }
                    return (
                        <Form autoComplete="off">
                            <div className="w-full p-4 mb-4 bg-white shadow">
                                <div>
                                    <div>
                                        <h3 className="mb-4 text-lg text-gray-400">Register a test</h3>
                                    </div>
                                    <div className="mr-2">
                                        <label htmlFor="testId" className="form-label">Test ID</label>
                                        <Field type="text" name="testId" id="testId" className="input-control"
                                            placeholder="Test ID" />
                                        <FormError name="testId" errors={allErrors} />
                                    </div>
                                    <div>
                                        <label className="form-label">
                                            Date of Test
                                        </label>
                                        <Field className="input-control" type="date" name="date" id="date" />
                                        <FormError name="date" errors={allErrors} />
                                    </div>
                                    <div className="mr-2">
                                        <label htmlFor="type" className="form-label">Test Type</label>
                                        <Field type="text" name="type" id="type" className="input-control"
                                            placeholder="Test Type" />
                                        <FormError name="type" errors={allErrors} />
                                    </div>
                                    <div className="mt-2">
                                        <label className="form-label">
                                            Name of Person Tested
                                        </label>
                                        <UserAutoComplete placeholder="Search by name" value={user ? user.firstName + ' ' + user.lastName : ''} onChange={({ user }) => { setFieldValue('user', user); setUser(user) }} />
                                        <FormError name="user" errors={allErrors} />
                                    </div>
                                    <div className="mt-2">
                                        <label htmlFor="positive" className="form-label">Test Result</label>
                                    </div>
                                    <div className="flex flex-row my-2">
                                        <div className="mr-4">
                                            <label htmlFor="positive" className="mr-1">Positive</label>
                                            <Field type="checkbox" onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                                setFieldValue('negative', false)
                                                handleChange(event)
                                            }} name="positive" id="positive" className="input-control"
                                                placeholder="positive" />
                                            <FormError name="positive" errors={allErrors} />

                                        </div>
                                        <div className="mr-4">
                                            <label htmlFor="negative" className="mr-1">Negative</label>
                                            <Field type="checkbox" onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                                setFieldValue('positive', false)
                                                handleChange(event)
                                            }} name="negative" id="negative" className="input-control"
                                                placeholder="negative" />
                                            <FormError name="negative" errors={allErrors} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {errorMessage ? (<Alert type="error" message={errorMessage} />) : null}
                            <div className="flex p-4 my-4 bg-white shadow">
                                <div className="flex w-full">
                                    <Link to="/covid" className="button">
                                        <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                                        Back to Tests
                                    </Link>
                                    <button className="ml-auto bg-yellow-300 button" type="submit" disabled={(saving || !isValid || !dirty)}>{saving ? 'Saving...' : 'Save'}</button>
                                </div>
                            </div>
                        </Form>
                    );
                }
                }
            </Formik>
        </div>
    )
}