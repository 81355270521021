import React, {useEffect, useRef} from "react";

export interface RouteMapWaypoint {
    location: google.maps.LatLng,
    name?: string,
}

export interface RouteMapMarker {
    location: google.maps.LatLng
    name: string
    icon: any
    type: string
}

export interface RouteMapProps {
    waypoints: RouteMapWaypoint[],
    markers?: RouteMapMarker[]
}

let map: google.maps.Map;
let directionsService: google.maps.DirectionsService;

export function RouteMap(props: RouteMapProps) {
    const mapEl = useRef<HTMLDivElement | null>(null);
    const prevMarkersRef = useRef<google.maps.Marker[]>([]);


    const getStops = (waypoints: RouteMapWaypoint[]) => {
        return waypoints.map(waypoint => {
            return {lat: waypoint.location.lat(), lng:waypoint.location.lng(), name: waypoint.name};
        })
    }

    useEffect(() => {
        directionsService = new google.maps.DirectionsService();

        if (!mapEl.current) {
            return;
        }

        map = new google.maps.Map(mapEl.current)

        const stops = getStops(props.waypoints)
        let lngs: number[] = stops.map(stop => { return stop.lng; });
        let lats: number[] = stops.map(stop => { return stop.lat; });
        map.fitBounds({
            west: Math.min.apply(null, lngs),
            east: Math.max.apply(null, lngs),
            north: Math.min.apply(null, lats),
            south: Math.max.apply(null, lats),
        });

        // Show stops on the map as markers
        for (var i = 0; i < stops.length; i++) {
            new google.maps.Marker({
                position: stops[i],
                map: map,
                title: stops[i].name
            });
        }

        for (var y = 0, parts = [], max = 25 - 1; y < stops.length; y = y + max)
            parts.push(stops.slice(y, y + max + 1));

        // Service callback to process service results
        var service_callback = function(response: google.maps.DirectionsResult | null, status: string) {
            if (status !== 'OK') {
                console.log('Directions request failed due to ' + status);
                return;
            }
            var renderer = new google.maps.DirectionsRenderer();
            renderer.setMap(map);
            renderer.setOptions({ suppressMarkers: true, preserveViewport: true });
            renderer.setDirections(response);
        };

        // Send requests to service to get route (for stops count <= 25 only one request will be sent)
        for (var x = 0; x < parts.length; x++) {
            // Waypoints does not include first stop (origin) and last stop (destination)
            var waypointsMap = [];
            for (var j = 1; j < parts[x].length - 1; j++)
                waypointsMap.push({location: parts[x][j], stopover: false});
            // Service options
            var service_options = {
                origin: parts[x][0],
                destination: parts[x][parts[x].length - 1],
                waypoints: waypointsMap,
                travelMode: 'DRIVING'
            };
            // Send request
            // @ts-ignore
            directionsService.route(service_options, service_callback);
        }
    }, [google, props.waypoints]);

    useEffect(() => {
        clearMarkers(prevMarkersRef.current);
        if (props.markers) {
            props.markers.forEach(routeMapMarker => {
                let marker = new google.maps.Marker();
                marker.setMap(map);
                marker.setIcon(routeMapMarker.icon)
                marker.setPosition(routeMapMarker.location)
                marker.setTitle(routeMapMarker.name)

                prevMarkersRef.current.push(marker);
            })
        }
    }, [props.markers])

    function clearMarkers(markers: google.maps.Marker[]) {
        for (let m of markers) {
            m.setMap(null);
        }
    }

    if (props.waypoints.length < 2) {
        return (
            <div className="my-4 text-sm text-gray-500">Please add 2 or more stops to preview the route map</div>
        );
    }

    return (
        <div className="w-full my-4 h-96" ref={mapEl}>

        </div>
    );
}

export default RouteMap;