import Dialog from "../dialog/Dialog";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {selectDeletePassenger, setDeletePassenger, setEditPassenger} from "./slice";
import {useState} from "react";
import Alert from "../alerts/Alert";
import { setSuccessNotificationText} from "../notification/slice";
import {deletePassenger as requestDeletePassenger} from "./service";


export const DeleteDialog = () => {

    const deletePassenger = useAppSelector(selectDeletePassenger);
    const dispatch = useAppDispatch();
    const [deleting, setDeleting] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')


    if (!deletePassenger) {
        return null;
    }

    const cancelFunction = () => {
        setErrorMessage('')
        dispatch(setDeletePassenger(null))
    }



    const handleDeletePassenger = () => {
        setDeleting(true);
        requestDeletePassenger(deletePassenger).then(() =>{
            dispatch(setDeletePassenger(null))
            dispatch(setSuccessNotificationText('Passenger ' + deletePassenger.firstName + ' ' + deletePassenger.lastName +' deleted successfully'))
            dispatch(setEditPassenger(null));
        }).catch(() => {
            setErrorMessage('Failed to delete Passenger, please try again');
        }).finally(() => {
            setDeleting(false);
        })
    }

    return (
        <Dialog title="Delete Passenger" confirmFunction={handleDeletePassenger} cancelFunction={cancelFunction} disabled={deleting} confirmText={deleting? 'Deleting...' : 'Delete'}>
            <p> Are you sure you want to delete delete passenger<strong> {deletePassenger.firstName + ' ' + deletePassenger.lastName}? </strong></p>
            <Alert message={errorMessage} type="error" />
        </Dialog>
    )
}