import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import AlertComponentProps from "./AlertComponentProps";


export default function Alert (props: AlertComponentProps) {
    let color = '';
    const alertRef = useRef<HTMLDivElement>(null);

    switch (props.type) {
        case 'error':
        case 'danger':
            color = 'red';
            break;
        case 'success':
            color = 'green';
            break;
        case 'info':
            color = 'blue';
            break;
        case 'warning':
            color = 'yellow';
            break;
        default:
            color = 'gray';
            break;
    }

    useEffect(() => {
        if (alertRef && alertRef.current) {
            const top = alertRef.current.offsetTop;
            window.scrollTo({
                top,
                behavior: 'smooth',
            })
        }
    }, []);

    if (props.message) {
        const bgClass = 'bg-'+color+'-500';
        return (
            <div ref={alertRef} className={'p-1 my-1 text-sm rounded-sm text-white transition-all ' + bgClass}>{ props.message }</div>
        );
    }
    return null;
}