import { ReactNode } from 'react';
import Modal from './Modal';

export interface ConfirmModalProps {
    onClose: CallableFunction,
    onConfirm: CallableFunction,
    title: string,
    children: ReactNode,
    disabled?: boolean,
    loading?: boolean,
}

export function ConfirmModal (props: ConfirmModalProps) {
    return (
        <Modal title={props.title} closeAction={props.onClose}>
            <div>{props.children}</div>
            <div className="mt-4">
                <button type="button" className="mr-2 bg-yellow-300 button" onClick={() => {props.onConfirm()}} autoFocus disabled={props.disabled || props.loading}>{props.loading ? '...Loading' :'OK'}</button>
                <button type="button" className="text-gray-600 button" onClick={() => {props.onClose()}} disabled={props.disabled}>Cancel</button>
            </div>
        </Modal>
    );
}

export default ConfirmModal;