import { AxiosRequestConfig } from 'axios';
import {apiAxios as axios} from '../api/axios';
import {Driver} from './types';

export interface FetchDriversQuery {
    search? : string,
}

export interface DriverSessionReportParams {
    startDate: Date
    endDate: Date
    driver?: number
    organisation?: number
    route?: any,
}

interface DriverReportParams {
    organisation: number,
    route?: any,
}

interface DriverOption {
    id: number,
    value: string,
}

interface DriversDropDownParams {
    organisation?: any,
}

export interface DriverSession {
    sessionId: number,
    routeId: number,
    routeName: string,
    driverId: number,
    driverName: string,
    startDate: string,
    endDate?: string,
}

export function fetchDrivers(query?: FetchDriversQuery)
{
    return axios.get('/driver', {params: query || {}} as AxiosRequestConfig);
}

export function fetchAllDrivers()
{
    return axios.get('/driver/all');
}

export function fetchDriver(id: any)
{
    return axios.get('/driver/'+id);
}

export function saveDriver(driver: Driver)
{
    if (driver.id) {
        return axios.put('/driver/'+driver.id, driver);
    }
    return axios.post('/driver', driver);
}

export function deleteDriver(driver: Driver) {
    return axios.delete('/driver/'+driver.id);
}

export function getDriverDropdown(params: DriversDropDownParams) {
    return axios.get<DriverOption[]>('/driver/dropdown', {params} as AxiosRequestConfig)
}

export function getDriverSessionReport(params: DriverSessionReportParams) {
    return axios.get<DriverSession[]>('/driver/session-report', {params} as AxiosRequestConfig)
}

export function exportDriverSessionReportCsv(params: DriverSessionReportParams) {
    return axios.get('/driver/export/session-report', {params} as AxiosRequestConfig)
}

export function getDriversReport(params: DriverReportParams) {
    return axios.get('/driver/report', {params} as AxiosRequestConfig)
}

export function exportDriversReportCsv(params: DriverReportParams) {
    return axios.get('/driver/export/report', {params} as AxiosRequestConfig)
}

export function regenerateDriverPins(params: {driverIds: number[]}) {
    return axios.post('driver/regenerate-pins', {...params} as AxiosRequestConfig)
}