import React, { useEffect, useState, ChangeEvent } from "react";
import { AppPromo, AppPromoUser } from "./types";
import { Carer } from "../carers/types";
import { fetchUsers } from "../users/service";

export interface UsersFormProps {
    appPromo: AppPromo,
    onChange: (appPromo: AppPromo) => any,
}

export const UsersForm = (props: UsersFormProps) => {
    const [users, setUsers] = useState<Carer[]>([]);
    const [loaded, setLoaded] = useState(false);
    const [search, setSearch] = useState('');

    useEffect(() => {
        setLoaded(false);
        fetchUsers({perPage: 10000, allTypes: true}).then(({data}) => {
            setUsers(data.items);
            setLoaded(true);
        }).catch(err => {
            console.error(err);
            setLoaded(true);
        })
    }, []);

    const toggleUser = (user: Carer) => {
        let promoUsers = [...props.appPromo.users];
        const index = promoUsers.findIndex(r => (r.userId === user.id));
        if (index < 0) {
            promoUsers.push({
                userId: user.id,
            } as AppPromoUser);
        }
        if (index > -1) {
            promoUsers.splice(index, 1);
        }
        props.onChange({ ...props.appPromo, users: promoUsers });
        setSearch('');
    }

    const filteredUsers = users.filter((user) => {
        if (search === '') {
            return true;
        }
        if (user.firstName.toLowerCase().includes(search.toLowerCase()) || user.lastName.toLowerCase().includes(search.toLowerCase()) || user.email?.toLowerCase().includes(search.toLowerCase())) {
            return true;
        }

        return false;
    });

    const searchChanged = (event: ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    }

    return (
        <div className="my-4 overflow-y-auto shadow max-h-52">
            {!loaded && (
                <p className="p-4 text-sm text-gray-500">Loading users...</p>
            )}

            {loaded && users.length === 0 && (
                <p className="p-4 text-sm text-gray-500">No users available to select</p>
            )}
            {loaded && users.length > 0 && (
                <div>
                    <input type="search" value={search} className="w-full border-gray-400 input-control" onChange={searchChanged} placeholder="Search users..." />
                </div>
            )}
            {filteredUsers.map((user: Carer) => {
                const readerPromoUser = props.appPromo.users.find(d => d.userId === user.id);
                return (
                    <div className="flex items-center p-2 mb-1 border-2 rounded" key={'user_' + user.id}>
                        <div className="mr-2">
                            <input type="checkbox" checked={readerPromoUser !== undefined} onChange={(e) => { toggleUser(user) }} />
                        </div>
                        <span>{user.firstName} {user.lastName} - {user.email}</span>
                    </div>
                );
            })}
        </div>
    );
};

export default UsersForm;