import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { clearEditRoute, selectEditRoute, setDeleteRoute, setEditRoute } from "./slice";
import Modal from '../modal/Modal';
import { Route } from "./types";
import { useEffect, useState } from "react";
import Alert from "../alerts/Alert";
import FormError from '../form/FormError';
import { saveRoute } from "./service";
import { Formik, Form, Field } from "formik";
import * as Yup from 'yup';
import { useHistory } from "react-router-dom";

export default function EditModal () {
    const editRoute = useAppSelector(selectEditRoute);
    const [route, setRoute] = useState<Route|null>(null);
    const [saving, setSaving] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [apiErrors, setApiErrors] = useState({});
    const dispatch = useAppDispatch();

    const resetMessages = () => {
        setSuccessMessage('');
        setErrorMessage('');
        setApiErrors({});
    }

    const history = useHistory();

    useEffect(() => {
        if (editRoute) {
            setRoute({...editRoute} as Route);
            resetMessages();
        } else {
            setRoute(null);
            resetMessages();
        }
    }, [editRoute]);
    

    if (!route) {
        return null;
    }


    const closeModal = () => {
        dispatch(clearEditRoute());
        resetMessages();
    }

    const validation = Yup.object().shape({
        name: Yup.string().label('Name').required().max(50),
    });

    const submitSave = (values: Route) => {
        const data = {...values, id: route.id};
        setRoute(data);
        setSaving(true);
        resetMessages();
        saveRoute(data).then(({data}) => {
            if (history.location.pathname.includes('routes')) {
                // Only redirect if we are in the routes UI already
                setSuccessMessage('Route saved successfully, redirecting...');
                window.setTimeout(() => {
                    dispatch(setEditRoute(null));
                    history.push('/routes/'+data.id+'/edit');
                }, 3000)
                return;
            }
            setSuccessMessage('Route saved successfully');
            window.setTimeout(() => {
                dispatch(setEditRoute(null));
            }, 3000)

        }).catch(err => {
            setErrorMessage('There was a problem saving the route, check for errors');
            if (err.response.data) {
                setApiErrors(err.response.data);
            }
        }).finally(() => {
            setSaving(false);
        })
    }
    const deleteRoute = () => {
        dispatch(setDeleteRoute(route));
    }

    const errorMessageHtml = errorMessage ? (<Alert type="error" message={errorMessage} />) : null;
    const successMessageHtml = successMessage ? (<Alert type="success" message={successMessage} />) : null;

    return (
        <Modal title={route.id ? 'Edit Route' : 'New Route'} closeAction={closeModal}>
            <Formik
                initialValues={{...route}}
                onSubmit={(values: Route) => {submitSave(values)}}
                validationSchema={validation}
                validateOnBlur
                >
                {({errors, isValid, dirty}) => {
                    const allErrors = {...errors, ...apiErrors}
                    return (
                        <Form>
                            <div className="mb-4">
                                <label className="block mb-1 font-medium" htmlFor="name">Name:</label>
                                <Field type="text" id="name" name="name" className="p-1 border-2 border-gray-100 rounded"/>
                                <FormError name="name" errors={allErrors}/>
                            </div>
                            {errorMessageHtml}
                            {successMessageHtml}
                            <div className="flex py-2 mt-4 border-t-2">
                                <button className="ml-auto mr-2 bg-yellow-300 button" type="submit" disabled={(saving || !isValid || !dirty)}>{saving ? 'Saving...' : 'Save'}</button>
                                <button className="button" type="button" onClick={() => {closeModal()}}>Close</button>
                                {route.id ? (<button className="ml-auto text-red-600 button" type="button" onClick={() => {deleteRoute()}}>Delete</button>) : null}
                            </div>
                        </Form>
                    )}
                }
            </Formik>
        </Modal>
    );
}