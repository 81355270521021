import { Link } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import { getLiveHeadlineStats } from "./service";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LiveTag from '../stats/LiveTag';

interface HeadlineStatProps {
    text: string
}
interface HeadlineStat {
    name: string
    type: string
    statistic: string
}
interface HeadlineStats extends Array<HeadlineStat> { }

const HeadlineStatistic = (props: HeadlineStatProps) => {
    return (<h3 className="m-3">
        {props.text}
    </h3>
    )
}


export const LiveHeadlines = () => {
    const [headlines, setHeadlines] = useState<HeadlineStats>([])
    const [loading, setLoading] = useState(false)
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        handleGetLiveStats()
        const interval = setInterval(() => {
            handleGetLiveStats()
        }, 60000);
        return () => clearInterval(interval);
    }, [])

    const handleGetLiveStats = () => {
        setLoading(true)
        getLiveHeadlineStats().then((res) => {
            const data = res.data as HeadlineStats
            setHeadlines(data)
        }).finally(() => {
            setLoading(false)
            if (!loaded) {
                setLoaded(true)
            }
        })
    }
    return (
        <div className="flex flex-col h-full text-center">
            <div>
                <span className="mb-2 font-bold">Live Routes</span>
            </div>
            {loading && !loaded && <div className="text-center"> <FontAwesomeIcon icon={faSyncAlt} className={'ml-2 text-sm fa-spin'} /> Loading ...</div>}
            <Link to="/sessions" className="mt-4">
                {headlines.map((headline, index) => {
                    return (<div key={index}>
                        <HeadlineStatistic text={headline.name + ':' + (headline.type === 'PERCENTAGE' ? ' %' : ' ') + headline.statistic} />
                    </div>
                    )
                })}
            </Link>
            <div className="mt-auto">
                <LiveTag />
            </div>
        </div>
    )
}