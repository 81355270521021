import {useEffect, useState} from "react";
import {Link, useHistory, useParams} from "react-router-dom";
import {fetchRoute, saveRoute} from "./service";
import {Route} from "./types";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons'
import Alert from '../alerts/Alert';
import FormError from "../form/FormError";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import { setDeleteRoute } from "./slice";
import DeleteDialog from './DeleteDialog';
import {setHtmlTitle} from '../../app/helpers';
import {Select as VehicleSelect} from '../vehicles/Select';
import EditStops from './EditStops';
import EditSchedules from './EditSchedules';
import EditJourneys from './EditJourneys';
import EditDrivers from './EditDrivers';
import RouteMap, {RouteMapWaypoint} from './RouteMap';
import TabButton from '../tabs/TabButton';
import TabsContainer from '../tabs/TabsContainer';
import TabButtonsContainer from '../tabs/TabButtonsContainer';
import TabPanel from '../tabs/TabPanel';
import {selectUserPolicies} from "../auth/slice";
import {getPermissionsLevelForEntity, Permission} from "../policies/helpers";
import {Select as OrganisationSelect} from "../organisations/Select";

interface RouterParams {
    id: string,
}

export function Edit() {
    const policies = useAppSelector(selectUserPolicies)

    const {id} = useParams<RouterParams>();
    const [route, setRoute] = useState({} as Route);
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [saving, setSaving] = useState(false);
    const [activeTab, setActiveTab] = useState('stops');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [organisationId, setOrganisationId] = useState<number | undefined>();
    const [wayPoints, setWayPoints] = useState<RouteMapWaypoint[]>([]);
    const [capacity, setCapacity] = useState<number | undefined>()
    const [apiErrors, setApiErrors] = useState({});
    const dispatch = useAppDispatch();
    const history = useHistory();

    useEffect(() => {
        loadRoute();
    }, []);

    function loadRoute() {
        setLoading(true);
        fetchRoute(parseInt(id)).then(({data}) => {
            setHtmlTitle('Edit Route: ' + data.name);
            if (!data.routeStops) {
                data.routeStops = [];
            }
            if (!data.drivers) {
                data.drivers = [];
            }
            if (!data.schedules) {
                data.schedules = [];
            }
            setRoute(data);
            setCapacity(data.capacity)
            setOrganisationId(data.organisationId)
            setLoaded(true);
            setLoading(false);
        });
    }
    useEffect(() => {
        if(route.routeStops){
            let newWaypoints = (route.routeStops.map(rs => {
                if (!rs.stop.lat || !rs.stop.long) {
                    return null;
                }
                return {
                    name: rs.stop.name,
                    location: new google.maps.LatLng(parseFloat(rs.stop.lat), parseFloat(rs.stop.long))
                } as RouteMapWaypoint;
            }).filter(w => (w !== null)) as RouteMapWaypoint[])

            if (JSON.stringify(newWaypoints) !== JSON.stringify(wayPoints)) {
                setWayPoints(newWaypoints)
            }
        }
    }, [route])

    if (!loaded) {
        return (<span>Loading route, please wait...</span>);
    }

    function setAndSaveRoute(r: Route) {
        setRoute(r);
        handleSaveRoute(r, true);
    }

    function handleSaveRoute(r: Route, quietly: boolean = false) {
        setSaving(true);
        setApiErrors({});
        let saveRouteObj = {...r}
        if (organisationId && organisationId !== r.organisationId) {
            saveRouteObj = {...saveRouteObj, organisationId: organisationId}
        }
        if (capacity !== r.capacity) {
            saveRouteObj = {...saveRouteObj, capacity: capacity}
        }
        setSuccessMessage('');
        setErrorMessage('');
        saveRoute(saveRouteObj).then(({data}) => {
            if (!quietly) {
                setSuccessMessage('Route saved successfully');
            }
            setRoute(data);
            setSaving(false);
        }).catch(error => {
            setSaving(false);
            setErrorMessage('Failed to save route, check for errors');
            if (error.response && error.response.data) {
                setApiErrors(error.response.data);
                return;
            }
            throw error;
        })
    }

    const handleChangeTab = (tab: any) => {
        setActiveTab(tab);
        setSuccessMessage('')
        setErrorMessage('');
    }


    function handleDeleteRoute() {
        dispatch(setDeleteRoute(route));
    }

    function handleDeleted(deletedRoute: Route) {
        if (deletedRoute.id === route.id) {
            history.goBack();
        }
    }

    const permissionsLevel = getPermissionsLevelForEntity('route', route, policies)

    return (
        <div>
            <h1 className="mb-4">Edit Route: {route.name || 'N/A'}</h1>
            <div className="p-4 mb-4 bg-white rounded shadow">
                <div>
                    <label className="form-label">Route Name:</label>
                    <input disabled={permissionsLevel === Permission.Read} type="text" placeholder="Name..." className="w-full input-control" autoFocus value={route.name}
                           onChange={(e) => {
                               setRoute({...route, name: (e.target.value || '')})
                           }}/>
                    <FormError errors={apiErrors} name="name"/>
                </div>
                <div>
                    <label className="form-label">Default Vehicle:</label>
                    <VehicleSelect disabled={permissionsLevel === Permission.Read}  value={route.defaultVehicle ? route.defaultVehicle.id : undefined}
                                   onChange={(vehicle) => {
                                       setRoute({...route, defaultVehicle: vehicle})
                                   }}/>
                    <FormError errors={apiErrors} name="defaultVehicle"/>

                </div>
                <div style={{width: "20%"}}>
                    <label className="form-label">Organisation:</label>
                    <OrganisationSelect disabled={permissionsLevel === Permission.Read}  value={organisationId}
                                   onChange={(organisation) => {
                                       if (organisation === undefined) {
                                           setOrganisationId(undefined)
                                       } else {
                                           setOrganisationId(organisation.id)
                                       }
                                   }} resource={"route"}/>
                    <FormError errors={apiErrors} name="defaultVehicle"/>
                </div>
                <div>
                    <label className="form-label">Capacity:</label>
                    <input  disabled={permissionsLevel === Permission.Read} type="number" step="1" min="0" value={capacity} onChange={(e) => {
                        e.preventDefault();
                        setCapacity((e.target.value ? parseInt(e.target.value) : undefined));
                    }} className="input-control"/>
                    <FormError errors={apiErrors} name="capacity"/>
                </div>
                <div className="mb-2">
                    <label className="form-label">Assignable By Guardians</label>
                    <input disabled={permissionsLevel === Permission.Read} type="checkbox" checked={route.assignableByCarers} onChange={(e) => {
                        setRoute({...route, assignableByCarers:e.target.checked})
                    }} className="mt-2 align-middle input-control"/>
                    <small className="inline-block ml-2 text-sm align-middle text-slate-400">This route will be available for guardians to select from when changing their passenger's reservations.</small>
                    <FormError errors={apiErrors} name="assignableByCarers"/>
                </div>
                <div className="mb-2">
                    <label className="form-label">Automatic End</label>
                    <input disabled={permissionsLevel === Permission.Read} type="checkbox" checked={route.endAutomatically} onChange={(e) => {
                        setRoute({...route, endAutomatically: e.target.checked})
                    }} className="mt-2 align-middle input-control"/>
                    <small className="inline-block ml-2 text-sm align-middle text-slate-400">This route will end automatically after 1 hour from the time we last recieved session data.</small>
                    <FormError errors={apiErrors} name="endAutomatically"/>
                </div>
            </div>
            <TabButtonsContainer>
                <TabButton activeTab={activeTab} tab="stops" onClick={handleChangeTab}>Stops</TabButton>
                <TabButton activeTab={activeTab} tab="schedules" onClick={handleChangeTab}>Schedules</TabButton>
                <TabButton activeTab={activeTab} tab="journeys" onClick={handleChangeTab}>Journeys</TabButton>
                <TabButton activeTab={activeTab} tab="drivers" onClick={handleChangeTab}>Drivers</TabButton>
            </TabButtonsContainer>
            <TabsContainer>
                <TabPanel tab="stops" activeTab={activeTab}>
                    <EditStops permissionsLevel={permissionsLevel} route={route} onChange={setAndSaveRoute} />
                    <RouteMap waypoints={wayPoints}/>
                </TabPanel>
                <TabPanel tab="schedules" activeTab={activeTab}>
                    <EditSchedules  permissionsLevel={permissionsLevel} route={route} onChange={() => loadRoute()} />
                </TabPanel>
                <TabPanel tab="journeys" activeTab={activeTab}>
                    <EditJourneys permissionsLevel={permissionsLevel}  route={route} onChange={() => {loadRoute()}} />
                </TabPanel>
                <TabPanel tab="drivers" activeTab={activeTab}>
                    <EditDrivers permissionsLevel={permissionsLevel}  route={route} onChange={setAndSaveRoute} />
                </TabPanel>
            </TabsContainer>
            <div className="mb-4">
                {successMessage ? (<Alert message={successMessage} type="success" />) : null}
                {errorMessage ? (<Alert message={errorMessage} type="error" />) : null}
            </div>
            <div className="flex items-center mt-4 bg-white rounded shadow">
                <div className="p-4">
                    <Link to="/routes" className="mr-2 button"><FontAwesomeIcon icon={faArrowLeft} className="mr-2" />Routes</Link>
                </div>
                <div className="p-4 ml-auto text-right">
                    <button className="mr-2 button" onClick={() => { loadRoute() }} disabled={saving || loading}>{loading ? 'Loading...' : 'Refresh Route'}</button>
                    {permissionsLevel >= Permission.Edit && <button className="mr-2 bg-yellow-300 button" onClick={() => {
                        handleSaveRoute(route)
                    }} disabled={saving || loading}>{saving ? 'Saving...' : 'Save Route'}</button>}
                    {permissionsLevel >= Permission.Delete && <button className="text-red-400 button" onClick={() => {
                        handleDeleteRoute()
                    }} disabled={saving || loading}>Delete Route</button>}
                </div>
            </div>
            <DeleteDialog onDelete={handleDeleted} />
        </div>
    )
}

export default Edit;