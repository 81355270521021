import React, {useEffect, useRef, useState} from 'react';
import {BulkDeleteReservationProps, Passengers} from "./types";
import {selectEditPassenger} from "./slice";
import {useAppSelector} from "../../app/hooks";
import {fetchPassengers as apiFetchPassengers} from "./service";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSyncAlt} from "@fortawesome/free-solid-svg-icons";
import {ReservationModal} from "../reservations/ReservationModal";
import {Form, Field, Formik} from "formik";
import {fetchJourneyDropDown, fetchReservations} from "../journeys/service";
import {DropDownOption, DropDownOptions} from "../dropdown/types";
import * as Yup from 'yup';
import {getOptionsFromApi} from "../dropdown/helper";
import {ReservationDeleteDialog} from "../reservations/ReservationDeleteDialog";
import {PassengerReservationTable, PassengerTable} from "./PassengerTables";
import {Reservations} from "../reservations/types";
import {getPassengersFromReservations} from "../reservations/helper";
import {selectEditReservation} from "../reservations/slice";
import {useHistory, useLocation} from "react-router";
import {Link} from "react-router-dom";
import {selectUserPolicies} from "../auth/slice";
import {getPermissionsLevel, getPermissionsLevelForEntity, Permission} from "../policies/helpers";
import {Journeys} from "./Journeys";
import {Send} from "../message/Send";
import RouteAutoComplete, {RouteAutoCompleteChangeEvent} from "../routes/RouteAutocomplete";

interface GetPassengersForm {
    journeyId: number | string,
    routeId: number | string
}

export const Index: any = () => {

    const policies = useAppSelector(selectUserPolicies)
    const maxPermissionsLevel = getPermissionsLevel('passenger', policies, undefined, true)
    const permissionsLevel = getPermissionsLevel('passenger', policies)
    const appNotificationPermissionLevel = getPermissionsLevel('appNotification', policies)
    const emailPermissionsLevel = getPermissionsLevel('email', policies)
    // global
    const history = useHistory();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState('');
    const [activeTab, setActiveTab] = useState<null | string>('passengers');
    const [selectAll, setSelectAll] = useState(false)

    const [selectedIds, setSelectedIds] = useState<number[]>([])
    // passengers

    const [allPassengers, setAllPassengers] = useState<Passengers>([])
    // reservations
    const [reservation, setReservation] = useState(false)
    const [reservationPassengers, setReservationPassengers] = useState<Passengers>([])
    const [deleteReservation, setDeleteReservation] = useState<BulkDeleteReservationProps | undefined>(undefined)

    // journey
    const [journeyLoading, setJourneyLoading] = useState(false);
    const [journeyReservations, setJourneyReservations] = useState<Reservations>([])
    const [journeyOptions, setJourneyOptions] = useState<JSX.Element[]>([])
    const [journeyId, setJourneyId] = useState<number>()
    const [journeyDropdownOptions, setJourneyDropdownOptions] = useState<DropDownOptions>([])
    const [journeyName, setJourneyName] = useState('')
    // route
    const [route, setRoute] = useState<DropDownOption>()

    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(1);

    // message
    const [sendMessagePassengers, setSendMessagePassengers] = useState<Passengers>()

    const editPassenger = useAppSelector(selectEditPassenger);
    const editReservation = useAppSelector(selectEditReservation);

    const searchRef = useRef(search);
    searchRef.current = search;


    const getSelectedPassengers = () => {
        const selectedPassengers: Passengers = []
        for (const passenger of getAvailablePassengers()) {
            if (passenger.id) {
                if (selectedIds.indexOf(passenger.id) !== -1) {
                    selectedPassengers.push(passenger)
                }
            }
        }
        return selectedPassengers
    }

    const makeReservation = () => {
        setReservationPassengers(getSelectedPassengers)
        if (reservationPassengers) {
            setReservation(true);
        }
    }

    const sendMessages = () => {
        setSendMessagePassengers(getSelectedPassengers)
    }


    const handleChecked = (target: HTMLInputElement) => {
        const passengerId = parseInt(target.value)
        if (target.checked) {
            setSelectedIds(selectedIds.concat(passengerId))
        } else {
            setSelectAll(false);
            setSelectedIds(selectedIds.filter(id => id !== passengerId));
        }
    }

    const getAvailablePassengers = () => {
        if (activeTab === 'passengers')
            return allPassengers;
        if (activeTab === 'reservations')
            return getPassengersFromReservations(journeyReservations)
        return []
    }


    const handleSelectAllChecked = () => {
        const passengerIds: React.SetStateAction<number[]> = [];
        const availablePassengers = getAvailablePassengers()
        if (!selectAll) {
            for (const passenger of availablePassengers) {
                const passengerPerms = getPermissionsLevelForEntity('passenger', passenger, policies)
                if (passenger.id && passengerPerms > 1) {
                    passengerIds.push(passenger.id)
                }
            }
            setSelectAll(true);
            setSelectedIds(passengerIds);
        } else {
            setSelectedIds([]);
            setSelectAll(false)
        }
    }

    const initialiseGetPassengers = () => {
        setLoading(true);
        setAllPassengers([])
        setReservationPassengers([])
        setSelectedIds([]);
        setSelectAll(false);
    }

    const handleGetJourneyPassengers = (journeyId: number) => {
        initialiseGetPassengers()
        setJourneyId(journeyId)
        setJourneyName(journeyDropdownOptions.filter(option => option.id === journeyId)[0].value)
        fetchReservations(journeyId).then(response => {
            const data = response.data
            setJourneyReservations(data);
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        })
    }

    const handleGetPassengers = () => {
        initialiseGetPassengers()
        apiFetchPassengers({search: searchRef.current, page: page}).then((response) => {
            const data = response.data
            setPages(data.lastPage)
            setAllPassengers(data.items)
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        })
    };

    function tabButtonClasses(tabName: string): string {
        let classes = 'px-3 py-2 pb-3 mr-1 rounded shadow hover:bg-grey-50';
        if (tabName === activeTab) {
            classes += ' bg-gray-50';
        } else {
            classes += ' bg-gray-200'
        }
        return classes;
    }

    const getJourneys = (routeId: any) => {
        setJourneyReservations([])
        setJourneyLoading(true)
        if (activeTab === 'reservations' && routeId > 0) {
            fetchJourneyDropDown({routeId: routeId}).then((response) => {
                const journeyOptionsApi = response.data as DropDownOptions
                setJourneyDropdownOptions(journeyOptionsApi)
                setJourneyOptions(getOptionsFromApi(journeyOptionsApi))
                setJourneyLoading(false)
            })
        }
    }

    useEffect(() => {
        history.replace({pathname: location.pathname, search: ''})
    }, [activeTab])


    useEffect(() => {
        if (!reservation && !editReservation) {
            setSelectedIds([]);
            setSelectAll(false);
            setAllPassengers([]);
            setJourneyReservations([])
            if (activeTab === 'passengers') {
                setJourneyId(undefined);
                setJourneyOptions([])
                setJourneyDropdownOptions([])
            }
        }

    }, [activeTab, reservation, editReservation])

    useEffect(() => {
        if (!reservation && !editReservation) {
            if (activeTab === 'reservations') {
                if (journeyId) {
                    handleGetJourneyPassengers(journeyId)
                }
            }
            if (activeTab === 'passengers') {
                handleGetPassengers()
            }
        }
    }, [reservation, editReservation, page, editPassenger, activeTab])

    const passengerSearchReservationsValidation = Yup.object().shape({
        journeyId: Yup.number().positive().required(),
        routeId: Yup.number().positive().required(),
    })

    return (
        <>
            {deleteReservation && <ReservationDeleteDialog {...deleteReservation}/>}
            <div className="z-0 flex">
                <button className={tabButtonClasses('passengers')} onClick={() => {
                    setActiveTab('passengers')
                }}>Passengers
                </button>
                <button className={tabButtonClasses('reservations')} onClick={() => {
                    setActiveTab('reservations')
                }}>Reservations
                </button>
                <button className={tabButtonClasses('journeys')} onClick={() => {
                    setActiveTab('journeys')
                }}>Journeys
                </button>
            </div>
            {reservation &&
            <ReservationModal closeAction={() => {
                setReservation(false)
            }}
                              passengers={reservationPassengers}
                              originalJourneyId={journeyId}
                              originalJourneyName={journeyName}
            />
            }
            {activeTab === 'journeys' &&
            <div className="relative z-10 p-4 bg-gray-50 rounded shadow -top-1">
                <Journeys/>
            </div>
            }
            {activeTab === 'passengers' &&
            <div className="relative z-10 p-4 bg-gray-50 rounded shadow -top-1">
                <div className="flex px-4 py-2 mb-2 bg-white shadow">
                    <form action="" className="flex mr-auto">
                        <div>
                            <input type="search" placeholder="Name or Card No." value={search}
                                   onChange={(event) => setSearch(event.target.value)}
                                   className="p-2 mr-2 border-2 border-gray-100 rounded"/>
                        </div>
                        <button className="bg-yellow-300 button" disabled={loading} onClick={(event) => {
                            event.preventDefault();
                            handleGetPassengers();
                        }}>Search
                        </button>
                    </form>
                    <div className="ml-auto">
                        <button className="mr-2 text-white bg-gray-600 button" type="button" onClick={(event) => {
                            event.preventDefault();
                            handleGetPassengers();
                        }} disabled={loading}>
                            <FontAwesomeIcon icon={faSyncAlt} className={'mr-1 text-sm ' + (loading ? 'fa-spin' : '')}/>
                            {loading ? 'Loading...' : 'Refresh'}
                        </button>
                        {maxPermissionsLevel >= Permission.Edit &&
                        <button className="mr-2 text-white bg-gray-600 button" disabled={selectedIds.length === 0}
                                type="button" onClick={() => {
                            makeReservation()
                        }}>&#43; New Reservation
                        </button>}
                        {(appNotificationPermissionLevel >= Permission.Create || emailPermissionsLevel >= Permission.Create) &&
                        <button className="mr-2 text-white bg-gray-600 button" disabled={selectedIds.length === 0}
                                type="button" onClick={() => {
                            sendMessages();
                        }}>&#43; Send Message
                        </button>}
                        {permissionsLevel >= Permission.Create &&
                        <Link to="/passengers/new">
                            <button className="text-white bg-gray-600 button" type="button">&#43; New Passenger
                            </button>
                        </Link>}
                    </div>
                </div>
                <PassengerTable pages={pages} setPage={setPage} page={page} passengers={allPassengers}
                                selectAll={selectAll} loading={loading} selectedIds={selectedIds}
                                handleSelectAllChecked={handleSelectAllChecked} handleChecked={handleChecked}/>
            </div>
            }
            {activeTab === 'reservations' &&
            <div className="relative z-10 p-4 bg-gray-50 rounded shadow -top-1">
                <Formik initialValues={{journeyId: 'Select Journey', routeId: 'Select Route'}}
                        onSubmit={(values: GetPassengersForm) => {
                            if (values.journeyId === 'Select Journey')
                                return
                            if (typeof values.journeyId === 'string')
                                values.journeyId = parseInt(values.journeyId)
                            handleGetJourneyPassengers(values.journeyId)
                        }}
                        validationSchema={passengerSearchReservationsValidation}
                >
                    {({values, dirty, isValid, handleChange, setFieldValue, handleSubmit}) => {
                        const handleRouteChanged = (event: RouteAutoCompleteChangeEvent) => {
                            if (event.route) {
                                setRoute(event.route)
                                setFieldValue('routeId', event.route.id);
                                getJourneys(event.route.id)
                            } else {
                                setFieldValue('routeId', null);
                            }
                        }
                        return (
                            <Form>
                                <div className="flex px-4 py-2 mb-2 bg-white shadow">
                                    <div className="flex w-full flex-row items-center">
                                        <label htmlFor="routeId" className="block mr-3 font-medium">Route
                                        </label>
                                        <RouteAutoComplete value={route ? route.value : ''} onChange={handleRouteChanged}/>
                                        <label htmlFor="journeyId" className="block mr-3 font-medium"><span
                                            className="inline-block align-middle">Journey</span>
                                            {journeyLoading && (values.routeId !== 'Select Route') &&
                                            <FontAwesomeIcon icon={faSyncAlt} className={'ml-2 text-sm fa-spin'}/>}
                                        </label>
                                        <Field className="p-1 mr-3 border-2 border-gray-100 rounded" as="select"
                                               name="journeyId"
                                               onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                   setJourneyId(0)
                                                   setJourneyReservations([])
                                                   handleChange(e);
                                                   const journeyId = parseInt(e.target.value)
                                                   if (!isNaN(journeyId)) {
                                                       handleGetJourneyPassengers(journeyId)
                                                   } else {
                                                       setJourneyReservations([]);
                                                       return false
                                                   }
                                               }}
                                               id="journeyId">
                                            <option value='Select Journey'>Select Journey</option>
                                            {journeyOptions}
                                        </Field>


                                        <div className="ml-auto">
                                            {maxPermissionsLevel >= 2 &&
                                            <button className="m-1 text-white bg-red-600 button"
                                                    disabled={selectedIds.length === 0}
                                                    type="button" onClick={() => {
                                                const journeyOption = journeyDropdownOptions.find(option => option.id === parseInt(values.journeyId))
                                                if (journeyOption) {
                                                    setDeleteReservation({
                                                        passengers: getSelectedPassengers(),
                                                        journeyDD: journeyOption,
                                                        closeFunction: (() => {
                                                            setDeleteReservation(undefined);
                                                            setJourneyReservations([]);
                                                            handleSubmit();
                                                        })
                                                    })
                                                }
                                            }}> Delete Reservation
                                            </button>}
                                            <button className="m-1 text-white bg-gray-600 button"
                                                    disabled={!(isValid && dirty)} type="submit">
                                                <FontAwesomeIcon icon={faSyncAlt}
                                                                 className={'mr-1 text-sm ' + (loading ? 'fa-spin' : '')}/>
                                                {loading ? 'Loading...' : 'Refresh'}
                                            </button>
                                            {maxPermissionsLevel >= 2 &&
                                            <button className="m-1 text-white bg-gray-600 button"
                                                    disabled={selectedIds.length === 0}
                                                    type="button" onClick={() => {
                                                makeReservation();
                                            }}
                                            >&#43; Transfer Reservation
                                            </button>}
                                            {(appNotificationPermissionLevel >= Permission.Create || emailPermissionsLevel >= Permission.Create) &&
                                            <button className="mr-2 text-white bg-gray-600 button" disabled={selectedIds.length === 0}
                                                    type="button" onClick={() => {
                                                sendMessages();
                                            }}>&#43; Send Message
                                            </button>}
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
                <PassengerReservationTable journeySelected={!!journeyId} reservations={journeyReservations}
                                           selectAll={selectAll} loading={loading} selectedIds={selectedIds}
                                           handleSelectAllChecked={handleSelectAllChecked}
                                           handleChecked={handleChecked}
                />
            </div>
            }
            {sendMessagePassengers !== undefined && <Send onClose={() => {setSendMessagePassengers(undefined); setSelectedIds([])}} passengers={sendMessagePassengers} carers={[]} />}
        </>
    );
}
