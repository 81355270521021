import { AppRouteProps } from "./AppRoute";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {selectIsLoggedIn, selectUser} from '../auth/slice';
import { Redirect, useLocation } from "react-router";
import AppRoute from "./AppRoute";
import { logoutAction } from "../auth/actions";
interface PrivateRouteProps extends AppRouteProps {
    permission: boolean
}

export function PrivateRoute(props: PrivateRouteProps) {
    const isLoggedIn = useAppSelector(selectIsLoggedIn);
    const user = useAppSelector(selectUser)
    const location = useLocation();
    const dispatch = useAppDispatch();

    if (!isLoggedIn) {
        return (
            <Redirect
                        to={{
                            pathname: "/login",
                            state: {from: location}
                        }}
                    />
        );
    }
    if (!user) {
        return null;
    }

    if (user.type === 'Carer' || user.type === 'Passenger') {
        dispatch(logoutAction());
        return null;
    }

    if(!user && !props.permission) {
        return (
            <Redirect
                to={{
                    pathname: "/",
                    state: {from: location}
                }}
            />)
    }
    if (!props.permission) {
        return (
            <Redirect
                to={{
                    pathname: "/unauthorised",
                    state: {from: location}
                }}
            />
        );
    }
    return (
        <AppRoute {...props}/>
    );
}

export default PrivateRoute;