import React, {useState} from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from 'yup';
import FormError from "../form/FormError";
import Alert from "../alerts/Alert";
import { useEffect } from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {selectUserPolicies} from "../auth/slice";
import {getPermissionsLevel, Permission} from "../policies/helpers";
import {Message} from "./types";
import {sendMessage} from "./service";
import {Passenger} from "../passengers/types";
import Modal from "../modal/Modal";
import {Carer} from "../carers/types";
import {setSuccessNotificationText} from "../notification/slice";


interface SendMessageProps {
    passengers: Array<Passenger>
    carers: Array<Carer>
    onClose: CallableFunction
}

const initialMessage = {
    title: '',
    body: '',
    type: 'email',
} as Message;

export function Send(props: SendMessageProps) {
    const policies = useAppSelector(selectUserPolicies)
    const permissionsLevel = getPermissionsLevel('message', policies)
    const appNotificationPermissionLevel = getPermissionsLevel('appNotification', policies)
    const emailPermissionsLevel = getPermissionsLevel('email', policies)
    const [withoutEmail, setWithoutEmail] = useState<Array<string>>([])
    const [withoutToken, setWithoutToken] = useState<Array<string>>([])
    const [allNames, setAllNames] = useState<Array<string>>([])
    const [apiErrors, setApiErrors] = useState({});
    const [sending, setSending] = useState(false);
    const [emailWarning, setEmailWarning] = useState('')
    const [tokenWarning, setTokenWarning] = useState('')
    const dispatch = useAppDispatch();
    const [error, setError] = useState('');

    useEffect(() => {
        let noEmailNames:Array<string> = []
        let noNotificationTokenNames:Array<string> = []

        props.passengers.forEach((passenger) => {
            if(!passenger.email && passenger.id) {
                noEmailNames.push(passenger.firstName + ' ' + passenger.lastName)
            }
        })
        props.carers.forEach((carer) => {
            if(!carer.email && carer.id) {
                noEmailNames.push(carer.firstName + ' ' + carer.lastName )
            }
        })

        props.passengers.forEach((passenger) => {
            if(!passenger.appNotificationToken && passenger.id) {
                noNotificationTokenNames.push(passenger.firstName + ' ' + passenger.lastName)
            }
        })
        props.carers.forEach((carer) => {
            if(!carer.appNotificationToken && carer.id) {
                noNotificationTokenNames.push(carer.firstName + ' ' + carer.lastName )
            }
        })
        const passengerNames = (props.passengers.map((passenger) => {return passenger.firstName + ' ' + passenger.lastName}))
        const carerNames = (props.carers.map((carer) => {return carer.firstName + ' ' + carer.lastName}))
        setAllNames([...carerNames, ...passengerNames])
        setWithoutEmail(noEmailNames)
        setWithoutToken(noNotificationTokenNames)
    },[])

    useEffect(() => {
        if(withoutEmail.length > 0) {
            setEmailWarning((withoutEmail.length === 1 ? 'This user does not have an email address ' : 'These users do not have email addresses ') + withoutEmail.map((name, index) => {
                return (index !== 0 ? ' ': '') + name
            }))
        }
    }, [withoutEmail])

    useEffect(() => {
        if(withoutToken.length > 0) {
        setTokenWarning((withoutToken.length === 1 ? 'This user doesnt have notifications on ' : 'These users do not have notifications on ') + withoutToken.map((name, index) => {


            return  (index !== 0 ? ' ': '') +  name
            }))
        }
    }, [withoutEmail])



    function handleSubmit(values: Message) {
        setError('');
        setApiErrors({});
        setSending(true)
        values.users = [...props.passengers, ...props.carers]
        sendMessage(values).then((res) => {
            dispatch(setSuccessNotificationText('Messages sent successfully'))
            props.onClose();
        }).catch((err: any) => {
            setError('Error sending message please try again')
            console.error(err);
            setSending(false)
        }).finally(() => {
            setSending(false)
        })

    }

    const validation = Yup.object().shape({
        title: Yup.string().label('Title').required().max(255),
        body: Yup.string().label('Body').required().max(1000),
    });

    const handleCloseModal = () => {
        props.onClose();
    }

    return (
            <Modal title={"Send Messages"} closeAction={handleCloseModal}>

            <Formik
                initialValues={initialMessage}
                onSubmit={handleSubmit}
                validationSchema={validation}
                validateOnBlur
            >
                {({ errors, isValid, dirty, values }) => {
                    const allErrors = { ...errors, ...apiErrors };
                    return (
                        <Form autoComplete="false">
                            <div className="form-label">
                                Recipients:
                            </div>
                                <div className={"mb-2"}>
                                    {allNames.map((name, index) => {
                                        let end = allNames.length !== index+1 ? ', ': ''
                                        return name + end;
                                    })}
                                </div>
                                <div>
                                    <label htmlFor="title" className="form-label">{values.type === 'appNotification'? 'Title:' : 'Subject:'}</label>
                                    <Field disabled={permissionsLevel === Permission.Read}  className="w-full input-control" name="title" id="title" placeholder="Title" />
                                    <FormError name="title" errors={allErrors} />
                                </div>
                                <div>
                                    <label htmlFor="body" className="form-label">Message Body:</label>
                                    <Field disabled={permissionsLevel === Permission.Read}  className="w-full input-control" rows="3" name="body" id="body" placeholder="Type the notification message here..." as="textarea" />
                                    <FormError name="body" errors={allErrors} />
                                </div>
                            {!((withoutEmail.length === (props.passengers.length + props.carers.length)) && (withoutToken.length === (props.passengers.length + props.carers.length))) &&
                            <div>
                                    <label htmlFor="type" className="form-label">Type:</label>
                                    <Field disabled={permissionsLevel === Permission.Read}  className="input-control"  as="select" name="type" id="type">
                                        {appNotificationPermissionLevel >= Permission.Create && (withoutToken.length !== (props.passengers.length + props.carers.length)) && <option value="appNotification">App Notification</option>}
                                        {emailPermissionsLevel >= Permission.Create && (withoutEmail.length !== (props.passengers.length + props.carers.length)) && <option value="email">Email</option>}
                                    </Field>
                                </div>}
                            {withoutEmail && values.type === "appNotification" && <Alert message={tokenWarning} type={"warning"}/>}
                            {withoutToken && values.type === "email" && <Alert message={emailWarning} type={"warning"}/>}
                            <Alert message={error} type="error" />
                            {((withoutEmail.length === (props.passengers.length + props.carers.length)) && (withoutToken.length === (props.passengers.length + props.carers.length))) &&
                            <div className="form-label">
                                No way of sending messages to any of these users please add user emails or have them switch notifications on
                            </div>
                            }
                                <div className="flex">
                                     <>
                                        <button className="mr-2 text-white bg-gray-700 button" type="submit" disabled={sending || !isValid || !dirty}>{sending ? 'Sending...' : 'Send Message'}</button></>
                                </div>
                        </Form>
                    );
                }}
            </Formik>
            </Modal>
    );
}