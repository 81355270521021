import {apiAxios} from '../api/axios';
import { ReaderPromo } from './types';

export function fetchReaderPromos(params: any) {
    return apiAxios.get('/readerPromos', {params});
}

export function saveReaderPromo(readerPromo: ReaderPromo) {
    if (readerPromo.id) {
        return apiAxios.put('/readerPromos/'+readerPromo.id, readerPromo);
    }
    return apiAxios.post('/readerPromos', readerPromo);
}

export function fetchReaderPromo(id: any) {
    return apiAxios.get('/readerPromos/'+id);
}

export function deleteReaderPromo(id: any) {
    return apiAxios.delete('/readerPromos/'+id);
}