import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "../../app/store";
import {Device} from "./types";

interface DeviceState {
    editDevice: null|Device,
    deleteDevice: null|Device
}

const initialState:DeviceState = {
    editDevice:null,
    deleteDevice:null
}

export const slice = createSlice({
    name: "Device",
    initialState,
    reducers: {
        setEditDevice: (state:DeviceState, {payload}) => {
            state.editDevice = payload
        },
        setDeleteDevice: (state:DeviceState, {payload}) => {
            state.deleteDevice = payload
        }
    }
});



export const {setEditDevice, setDeleteDevice} = slice.actions

export const selectEditDevice = (state: RootState) => state.devices.editDevice
export const selectDeleteDevice = (state: RootState) => state.devices.deleteDevice

export default slice.reducer
