import { useEffect, useState } from 'react';
import DateRangePicker, { DateRange } from '../dates/DateRangePicker';
import Chart from 'react-apexcharts';
import { fetchRouteUtilisation, FetchRouteUtilisationParams } from './service';
import {getFormikDateFromString} from '../dates/helper';

interface ChartData {
    value: number,
    label: string,
}

export interface CapacityUtilisationProps {
    order?: string,
}
const lastYear = new Date();
lastYear.setFullYear(lastYear.getFullYear() - 1);

export function CapacityUtilisation(props: CapacityUtilisationProps) {
    const [dateRange, setDateRange] = useState<DateRange>({
        startDate: lastYear,
        endDate: (new Date()),
    });

    const [data, setData] = useState<ChartData[]>([]);
    const [loading, setLoading] = useState(false);

    const order = props.order || 'desc';

    useEffect(() => {
        setLoading(true);
        const params = {
            fromDate: getFormikDateFromString(dateRange.startDate.toISOString()),
            toDate: getFormikDateFromString(dateRange.endDate.toISOString()),
            order,
        } as FetchRouteUtilisationParams;
        fetchRouteUtilisation(params).then(res => {
            setData(res.data.map((r: any) => {
                return {
                    label: r.name,
                    value: r.utilisation,
                } as ChartData;
            }));
            setLoading(false);
        });
    }, [dateRange, order]);

    const chartOptions = {
        xaxis: { categories: data.map(d => d.label) } as ApexXAxis,
        yaxis: { title: { text: 'Capacity Utilised %' } } as ApexYAxis, 
        dataLabels: {
            enabled: true,
            formatter: function (val: any) {
                return val + "%";
            },
        },
        annotations: {
            yaxis: [
              {
                y: 100,
                borderColor: '#ffe28b',
                label: {
                  borderColor: '#ffe28b',
                  style: {
                    color: '#000',
                    background: '#ffe28b'
                  },
                  text: '100% Utilisation'
                }
              },
            ]
          }
    }

    return (
        <div className="flex flex-col h-full">
            <div className="flex items-center pb-2 mb-2 border-b-2">
                <h3 className="font-bold">Top {data.length || ''} {order === 'desc' ? 'Most' : 'Least'} Utilised Routes</h3>
                <div className="ml-auto">
                    <DateRangePicker
                        value={dateRange}
                        onChange={setDateRange}
                    />
                </div>
            </div>
            <div className="flex items-center h-full justify-items-center">
                {(!loading && data.length) ? (
                    <div className="w-full">
                        <Chart type="bar" series={[{ data: data.map(d => d.value) }]} height="370" options={chartOptions} />
                    </div>
                ) : null}
                {(!loading && !data.length) ? (
                    <span className="m-auto">No data available</span>
                ) : null}
                {(loading) ? (
                    <span className="m-auto">Loading data...</span>
                ) : null}
            </div>
        </div>
    );
}

export default CapacityUtilisation;