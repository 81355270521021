import { Link, useHistory } from "react-router-dom";
import { faCheck, faPlus, faSyncAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FormEvent, useState } from "react";
import { Carer } from "./types";
import { useEffect } from "react";
import { fetchCarers } from "./service";
import Pagination from "../pagination/Pagination";
import { Select as OrganisationSelect } from '../organisations/Select';
import {useAppSelector} from "../../app/hooks";
import {selectUserPolicies} from "../auth/slice";
import {getPermissionsLevel, getPermissionsLevelForEntity, Permission} from "../policies/helpers";
import {Send} from "../message/Send";

export interface IndexProps {
    hideHeader?: boolean,
    hideNewButton?: boolean,
    organisationId?: number,
}

export function Index(props: IndexProps) {

    const policies = useAppSelector(selectUserPolicies)
    const permissionsLevel = getPermissionsLevel('carer', policies)
    const organisationPermissions = getPermissionsLevel('organisation', policies, undefined, true)
    const appNotificationPermissionLevel = getPermissionsLevel('appNotification', policies)
    const emailPermissionsLevel = getPermissionsLevel('email', policies)
    const [selectedIds, setSelectedIds] = useState<number[]>([])
    const [sendMessageCarers, setSendMessageCarers] = useState<Array<Carer>>()
    const [selectAll, setSelectAll] = useState(false)
    const [searchText, setSearchText] = useState('');
    const [statusFilter, setStatusFilter] = useState('');
    const [loading, setLoading] = useState(false);
    const [carers, setCarers] = useState<Carer[]>([]);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(1);
    const [organisationId, setOrganisationId] = useState<undefined | number>(props.organisationId);
    const history = useHistory();

    useEffect(() => {
        loadCarers();
    }, [page]);

    function handleSearchSubmit(event: FormEvent) {
        event.preventDefault();
        if (page === 1) {
            loadCarers();
        }
        setPage(1);
    }

    const getSelectedCarers = () => {
        const selectedCarers: Array<Carer> = []
        for (const carer of carers) {
            if (carer.id) {
                if (selectedIds.indexOf(carer.id) !== -1) {
                    selectedCarers.push(carer)
                }
            }
        }
        return selectedCarers
    }

    const sendMessages = () => {
        setSendMessageCarers(getSelectedCarers)
    }

    const handleChecked = (target: HTMLInputElement) => {
        const carerId = parseInt(target.value)
        if (target.checked) {
            setSelectedIds(selectedIds.concat(carerId))
        } else {
            setSelectAll(false);
            setSelectedIds(selectedIds.filter(id => id !== carerId));
        }
    }

    const handleSelectAllChecked = () => {
        const carerIds: React.SetStateAction<number[]> = [];
        if (!selectAll) {
            for (const carer of carers) {
                const passengerPerms = getPermissionsLevelForEntity('carer', carer, policies)
                if (carer.id && passengerPerms > 1) {
                    carerIds.push(carer.id)
                }
            }
            setSelectAll(true);
            setSelectedIds(carerIds);
        } else {
            setSelectedIds([]);
            setSelectAll(false)
        }
    }

    function loadCarers() {
        const params = {
            search: searchText,
            status: statusFilter,
            organisationId,
            page,
        };
        setLoading(true);
        fetchCarers(params).then(({ data }) => {
            setCarers(data.items);
            setPages(data.lastPage);
            setLoading(false);
        }).catch(err => {
            console.error(err);
            setLoading(false);
            setCarers([]);
        })
    }

    return (
        <div>
            {(!props.hideHeader) ? (
                <div>
                    <h1 className="mb-2 text-lg">Guardians</h1>
                </div>
            ) : null}
            <div className="p-2 my-4 bg-white rounded shadow">
                <div className="flex items-center">
                    <form action="" onSubmit={handleSearchSubmit} className="flex">
                        <div className="mr-2">
                            <input type="search" placeholder="Search..." value={searchText} onChange={(e) => { setSearchText(e.target.value) }} className="mb-0 input-control" />
                        </div>
                        <div className="mr-2">
                            <select onChange={(e) => { setStatusFilter(e.target.value) }} className="mb-0 input-control" value={statusFilter}>
                                <option value="">Any Status</option>
                                <option value="enabled">Enabled</option>
                                <option value="disabled">Disabled</option>
                            </select>
                        </div>
                        {organisationPermissions >= Permission.Read && <div className="mr-2">
                            {props.organisationId ? null : (
                                <OrganisationSelect value={organisationId} onChange={(organisation) => {
                                    setOrganisationId(organisation?.id)
                                }}/>
                            )}
                        </div>}
                        <div>
                            <button className="bg-yellow-300 button" disabled={loading}>Search</button>
                        </div>
                    </form>
                    <div className="flex h-auto ml-auto">
                        <button className="mr-2 text-white bg-gray-600 button" type="button" onClick={(event) => { event.preventDefault(); loadCarers(); }} disabled={loading}>
                            <FontAwesomeIcon icon={faSyncAlt} className={'mr-1 text-sm ' + (loading ? 'fa-spin' : '')} />
                            {loading ? 'Loading...' : 'Refresh'}
                        </button>
                        <button className="mr-2 text-white bg-gray-600 button" disabled={selectedIds.length === 0}
                                type="button" onClick={() => {
                            sendMessages();}}
                        >&#43; Send Message</button>
                        {permissionsLevel >=3 && props.hideNewButton ? null : (
                            <Link to="/guardians/new" className="bg-yellow-300 button">
                                <FontAwesomeIcon icon={faPlus} className="mr-1" />
                                <span>New Guardian</span>
                            </Link>
                        )}
                    </div>
                </div>
            </div>
            <div className="p-2 my-4 bg-white rounded shadow">
                {carers.length && !loading ? (
                    <table className="table w-full table-striped">
                        <thead>
                        {(appNotificationPermissionLevel >= Permission.Create || emailPermissionsLevel >= Permission.Create) && <th className="text-left"><input type="checkbox"
                                                          onChange={() => handleSelectAllChecked()}
                                                          checked={selectAll}/></th>}
                            <th className="text-left">Name</th>
                            <th className="text-left">Email</th>
                            {organisationPermissions >= Permission.Read && <th className="text-left">Organisation</th>}
                            <th className="text-center">Enabled</th>
                        </thead>
                        <tbody>
                            {carers.map((carer) => (
                                <tr>
                                    {(appNotificationPermissionLevel >= Permission.Create || emailPermissionsLevel >= Permission.Create) && <td>
                                        {<input onChange={(event) => {
                                            handleChecked(event.target)
                                        }} type="checkbox"
                                                checked={(!!carer.id && selectedIds.indexOf(carer?.id) !== -1)}
                                                value={carer.id}/>}</td>}
                                    <td className="cursor-pointer" onClick={() => { history.push('/guardians/' + carer.id + '/edit') }}>{carer.firstName} {carer.lastName}</td>
                                    <td>{carer.email}</td>
                                    {organisationPermissions >= Permission.Read && <td>{carer.organisationName || '-'}</td>}
                                    <td className="text-center">{carer.enabled ? (
                                        <FontAwesomeIcon icon={faCheck} className="text-green-400" />
                                    ) : (
                                        <FontAwesomeIcon icon={faTimes} className="text-red-400" />
                                    )}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : null}
                {!carers.length && !loading ? (
                    <p>No carers found</p>
                ) : null}
                {loading ? (
                    <p>Loading guardians...</p>
                ) : null}
                <div className="my-2 text-center">
                    <Pagination page={page} pages={pages} onChange={setPage} />
                </div>
                {sendMessageCarers !== undefined && <Send onClose={() => {setSendMessageCarers(undefined); setSelectedIds([])}} carers={sendMessageCarers} passengers={[]} />}
            </div>
        </div>
    );
}

export default Index;