import {apiAxios} from '../api/axios';
import { AppPromo } from './types';

export function fetchAppPromos(params: any) {
    return apiAxios.get('/appPromos', {params});
}

export function saveAppPromo(appPromo: AppPromo) {
    if (appPromo.id) {
        return apiAxios.put('/appPromos/'+appPromo.id, appPromo);
    }
    return apiAxios.post('/appPromos', appPromo);
}

export function fetchAppPromo(id: any) {
    return apiAxios.get('/appPromos/'+id);
}

export function deleteAppPromo(id: any) {
    return apiAxios.delete('/appPromos/'+id);
}