import { useEffect, useState } from "react";
import { UserLog } from "./types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { fetchUserLogs } from './service';
import { formatDateTime } from '../dates/helper'

export function Index() {
    const [search, setSearch] = useState('');
    const [action, setAction] = useState('');
    const [fromTime, setFromTime] = useState('');
    const [toTime, setToTime] = useState('');
    const [userLogs, setUserLogs] = useState<Array<UserLog>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);

    const searchUserLogs = () => {
        if (loading) {
            return;
        }
        setLoading(true);
        const params = {
            search,
            action,
            fromTime,
            toTime
        };
        fetchUserLogs(params).then(({ data }) => {
            setUserLogs(data);
            setLoading(false);
            setLoaded(true);
        }).catch(() => {
            setLoading(false);
        })
    }
    useEffect(() => {
        searchUserLogs();
    }, []);

    return (
        <div>
            <div className="mb-4">
                <h1 className="text-lg">User Logs</h1>
            </div>
            <div className="flex px-4 py-2 mb-2 bg-white shadow">
                <form action="" onSubmit={(event) => { event.preventDefault(); searchUserLogs(); }} className="flex items-center mr-auto">
                    <div className="mr-1">
                        <input type="search" value={search} onChange={(event => setSearch(event.target.value))} placeholder="Search..." className="mb-0 input-control" />
                    </div>
                    <div className="mr-1">
                        <input type="search" value={action} onChange={(event => setAction(event.target.value))} placeholder="Action" className="mb-0 input-control" />
                    </div>
                    <div className="mr-1">
                        <input type="datetime-local" value={fromTime} onChange={(event => setFromTime(event.target.value))} className="mb-0 input-control" />
                        <span>-</span>
                        <input type="datetime-local" value={toTime} onChange={(event => setToTime(event.target.value))} className="mb-0 input-control" />
                    </div>
                    <div>
                        <button className="bg-yellow-300 button" disabled={loading}>Search</button>
                    </div>
                </form>
                <div className="ml-auto">
                    <button className="mr-2 text-white bg-gray-600 button" type="button" onClick={(event) => { event.preventDefault(); searchUserLogs(); }} disabled={loading}>
                        <FontAwesomeIcon icon={faSyncAlt} className={'mr-1 text-sm ' + (loading ? 'fa-spin' : '')} />
                        {loading ? 'Loading...' : 'Refresh'}
                    </button>
                </div>
            </div>
            <div className="p-4 bg-white shadow">
                {(loading) ? (
                    <div>Loading...</div>
                ) : null}
                {(userLogs.length > 0 && loaded) ? (
                    <table className="table w-full table-striped">
                        <thead>
                            <tr>
                                <th className="text-left">User</th>
                                <th>Action</th>
                                <th>Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                userLogs.map((userLog) => {
                                    return (
                                        <tr key={userLog.id} className="cursor-pointer">
                                            <td className="p-1">{userLog.subject.email}</td>
                                            <td className="p-1 text-center">{userLog.action}</td>
                                            <td className="p-1 text-center">{formatDateTime(userLog.createdAt)}</td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                ) : null}
                {(userLogs.length === 0 && !loading) ? (
                    <div>No user logs match the criteria</div>
                ) : null}
            </div>
        </div>
    );
}

export default Index;