import Modal from "../modal/Modal";
import React from "react";
import {Reservations} from "../reservations/types";

interface Props {
    closeModal:CallableFunction
    reservations: Reservations
}
export const ShowReservationModal = (props: Props ) => {

    return (
        <Modal title={"Reservations"} closeAction={props.closeModal}>
            <table className="table w-full table-striped">
                <thead>
                <tr>
                    <th className="text-left">Passenger</th>
                </tr>
                </thead>
                <tbody>
                {props.reservations.map(reservation => {
                    return (
                        <tr key={reservation.passenger.id}>
                            <td>{reservation.passenger.firstName + ' ' + reservation.passenger.lastName}</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </Modal>
    );
}
