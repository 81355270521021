import { useEffect, useState } from 'react';
import { DateRange, DateRangePicker } from '../dates/DateRangePicker';
import Chart from "react-apexcharts";
import { fetchStopArrivals, DateRangeParams } from './service';
import { getFormikDateFromString } from '../dates/helper';

export default function ArrivedOnTime() {
    const [dateRange, setDateRange] = useState<DateRange>({
        startDate: new Date(),
        endDate: new Date(),
    });
    const [data, setData] = useState<number|null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        const params = {
            fromDate: getFormikDateFromString(dateRange.startDate.toISOString()),
            toDate: getFormikDateFromString(dateRange.endDate.toISOString()),
        } as DateRangeParams;
        fetchStopArrivals(params).then((response) => {
            setLoading(false);
            if (response.data == null) {
                setData(null);
                return;
            }
            setData(parseFloat(response.data.statistic));
        }).catch((err: any) => {
            console.error(err);
        })
    }, [dateRange]);

    return (
        <div className="flex flex-col h-full">
            <div className="flex items-center pb-2 mb-2 border-b-2">
                <h3 className="font-bold">Stop Arrivals on Time</h3>
                <div className="ml-auto">
                    <DateRangePicker
                        value={dateRange}
                        onChange={setDateRange}
                    />
                </div>
            </div>
            <div className="flex items-center h-full justify-items-center">
                {(loading) ? (
                     <span className="m-auto">Loading data...</span>
                ) : null}
                {(!loading && data === null) ? (
                     <span className="m-auto">No data available</span>
                ) : null}
                {!loading && data !== null ? (
                    <div className="w-full m-auto">
                        <Chart type="pie" series={[data, (100 - data)]} options={{ labels: ['On Time', 'Late'] }} />
                    </div>
                ) : null}
            </div>
        </div>
    );
}