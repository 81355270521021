import React, {useEffect, useState} from "react";
import {getOrganisationPermissions} from "./service";
import {Organisation} from "../organisations/types";
import {OrganisationPermission, OrganisationPermissions} from "./types";
import {DeleteModal} from "./DeleteModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faSyncAlt} from "@fortawesome/free-solid-svg-icons";
import {useHistory} from "react-router";


interface ListPermissionsProps {
    organisation: Organisation
}


export const List = (props: ListPermissionsProps) => {

    const [organisationPermissions, setOrganisationPermissions] = useState<OrganisationPermissions>();
    const [deletePermission, setDeletePermission] = useState<OrganisationPermission>()
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const getLevelFromAction = (action: string) => {
        switch (action) {
            case "1":
                return "Read"
            case "2":
                return "Edit"
            case "4":
                return "Delete"
        }
    }
    useEffect(() => {
        handleGetPermissions();
    }, [])

    const handleGetPermissions = () => {
        setLoading(true)
        getOrganisationPermissions(props.organisation.id).then((res) => {
            setOrganisationPermissions(res.data)
        }).catch(err => {
            console.error(err)
        }).finally(() => {
            setLoading(false)
        })
    }
    if (loading) {
        return (
            <div>
                ...Loading Permissions
            </div>
        )
    }

    return (
        <div>
            <div className="flex h-auto ml-auto mb-4 justify-end">
                <button className="mr-2 text-white bg-gray-600 button" type="button" onClick={() => {history.push('/permissions/add/'+ props.organisation.id)}} >
                    <FontAwesomeIcon icon={faPlus} className={'mr-1 text-sm ' + (loading ? 'fa-spin' : '')} />
                    Add Permission
                </button>
            {!loading && <button className="mr-2 text-white bg-gray-600 button" type="button" onClick={handleGetPermissions}>
                <FontAwesomeIcon icon={faSyncAlt} className={'mr-1 text-sm ' + (loading ? 'fa-spin' : '')}/>
                Refresh
            </button>}
            </div>
            {organisationPermissions && organisationPermissions.length === 0 && <span className={"text-gray-500"}> No permissions for this organisation please add some.</span>}
            {organisationPermissions && organisationPermissions.length > 0 &&
            <table className="table w-full border-separate table-striped">
                <thead>
                <tr className="justify-center">
                <th className="text-left">Organisation</th>
                <th className="text-center">Type</th>
                <th className="text-center">Level</th>
                    <th> </th>
                </tr>
                </thead>
                <tbody>
                {organisationPermissions?.map((organisationPermission) => {
                    return (
                        <tr>
                            <td className="text-left">{organisationPermission.organisationTarget.name}</td>
                            <td className="text-center">{organisationPermission.resource.map((resource, index) => {return (<span>{resource[0].toUpperCase() + resource.substring(1)}{(index + 1) !== organisationPermission.resource.length && ", "} </span>)})}</td>
                            <td className="text-center">{getLevelFromAction(organisationPermission.action)}</td>
                            <td className="text-center">
                                <button className="text-red-400 button" type="button" onClick={() => {setDeletePermission(organisationPermission)}}>Delete</button>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
            }
            {deletePermission && <DeleteModal confirmAction={handleGetPermissions} deletePermission={deletePermission} closeAction={() => setDeletePermission(undefined)}/>}
        </div>
    )
}