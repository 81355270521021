import React, {useState} from "react";
import {BulkUpload} from "./BulkUpload";
import {useAppSelector} from "../../app/hooks";
import {selectUserPolicies} from "../auth/slice";
import {getPermissionsLevel, Permission} from "../policies/helpers";

export const Index = () => {
    const [activeTab, setActiveTab] = useState<string>('passengers');

    const policies = useAppSelector(selectUserPolicies)
    const carerPermissionsLevel = getPermissionsLevel('carer', policies)
    const passengerPermissionsLevel = getPermissionsLevel('passenger', policies)
    const stopPermissionsLevel = getPermissionsLevel('stop', policies)
    const routePermissionsLevel = getPermissionsLevel('route', policies)


    function tabButtonClasses(tabName: string): string {
        let classes = 'px-3 py-2 pb-3 mr-1 rounded-t shadow hover:bg-grey-50';
        if (tabName === activeTab) {
            classes += ' bg-gray-50';
        } else {
            classes += ' bg-gray-200'
        }
        return classes;
    }

    return (
            <div>
                <div className="mb-4">
                    <h1 className="text-lg">Bulk Upload</h1>
                </div>
                <div className="z-0 flex">
                    {passengerPermissionsLevel >= Permission.Create && <button className={tabButtonClasses('passengers')} onClick={() => {
                        setActiveTab('passengers')
                    }}>Passengers
                    </button>}
                    {passengerPermissionsLevel >= Permission.Edit &&<button className={tabButtonClasses('reservations')} onClick={() => {
                        setActiveTab('reservations')
                    }}>Reservations
                    </button>}
                    {stopPermissionsLevel >=3 && <button className={tabButtonClasses('stops')} onClick={() => {
                        setActiveTab('stops')
                    }}>Stops
                    </button>}
                    {routePermissionsLevel >= Permission.Create && <button className={tabButtonClasses('routes')} onClick={() => {
                        setActiveTab('routes')
                    }}>Routes
                    </button>}
                    {carerPermissionsLevel >=3 && <button className={tabButtonClasses('guardians')} onClick={() => {
                        setActiveTab('guardians')
                    }}>Guardians
                    </button>}
                </div>
                <div className="relative z-10 p-4 bg-gray-50 rounded-b shadow-sm -top-1">
                    <div className="flex align-center px-4 py-2 mb-2 bg-white shadow">
                    <BulkUpload type={activeTab === 'guardians' ? 'carers' : activeTab}/>
                    </div>
                </div>
        </div>
    )
}
