import Modal from '../modal/Modal';
import { Journey } from './types';
import { Schedule } from '../schedules/types';
import { useState, ChangeEvent, FormEvent, MouseEvent } from 'react';
import TimePicker from '../timepicker/Timepicker';
import FormError from '../form/FormError';
import { deleteJourney, saveJourney } from './service';
import Alert from '../alerts/Alert';
import ConfirmModal from '../modal/ConfirmModal';
import {useAppDispatch} from "../../app/hooks";
import {setSuccessNotificationText} from "../notification/slice";
import {Permission} from "../policies/helpers";

export interface JourneyModalProps {
    journey: Journey,
    schedules: Array<Schedule>,
    onCloseAction: CallableFunction,
    permissionsLevel: number
}

export function JourneyModal(props: JourneyModalProps) {
    const [journey, setJourney] = useState({ ...props.journey });
    const [apiErrors, setApiErrors] = useState({});
    const [saving, setSaving] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [deleting, setDeleteing] = useState(false);
    const dispatch = useAppDispatch();

    function handleOnScheduleChange(event: ChangeEvent<HTMLSelectElement>) {
        const newJourney = { ...journey };
        if (event.target.value) {
            newJourney.scheduleId = parseInt(event.target.value);
        } else {
            delete newJourney.scheduleId;
        }
        setJourney(newJourney);
    }

    function handleEveryDayOnClick(event: MouseEvent) {
        event.preventDefault();
        setJourney({
            ...journey,
            monday: true,
            tuesday: true,
            wednesday: true,
            thursday: true,
            friday: true,
            saturday: true,
            sunday: true,
        })
    }

    function handleMonFriOnClick(event: MouseEvent) {
        event.preventDefault();
        setJourney({
            ...journey,
            monday: true,
            tuesday: true,
            wednesday: true,
            thursday: true,
            friday: true,
            saturday: false,
            sunday: false,
        })
    }

    function handleWeekendsOnClick(event: MouseEvent) {
        event.preventDefault();
        setJourney({
            ...journey,
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: true,
            sunday: true,
        })
    }

    function handleClearDaysClick(event: MouseEvent) {
        event.preventDefault();
        setJourney({
            ...journey,
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
            sunday: false,
        })
    }

    function handleDeleteOnClick(event: MouseEvent) {
        event.preventDefault();
        setShowConfirmDelete(true);
    }

    function handleDeleteConfirmed() {
        setSuccessMessage('');
        setErrorMessage('');
        setDeleteing(true);
        deleteJourney(journey).then(() => {
            setSuccessMessage('Journey deleted successfully');
            setShowConfirmDelete(false);
            setDeleteing(false);
            window.setTimeout(() => {
                props.onCloseAction();
            }, 3000);
        }).catch(error => {
            setErrorMessage('Failed to delete journey, try again');
            setDeleteing(false);
            setShowConfirmDelete(false);
            console.error(error);
        })
    }

    function handleOnSubmit(event: FormEvent) {
        event.preventDefault();
        setSuccessMessage('');
        setErrorMessage('')
        setApiErrors({});
        setSaving(true);
        saveJourney(journey).then(({ data }) => {
            setJourney(data);
            setSaving(false);
            dispatch(setSuccessNotificationText('Journey successfully saved to route'));
            props.onCloseAction();
        }).catch(error => {
            setSaving(false);
            setErrorMessage('Failed to save journey, check for errors');
            if (error.response) {
                setApiErrors(error.response.data);
            } else {
                console.error(error);
            }
        })
    }

    return (
        <>
            <Modal title={(journey.id ? 'Edit' : 'Add') + ' Journey'} closeAction={props.onCloseAction}>
                <form action="" onSubmit={handleOnSubmit}>
                    {successMessage ? (<Alert message={successMessage} type="success" />) : null}
                    {errorMessage ? (<Alert message={errorMessage} type="error" />) : null}
                    <div className="mb-3">
                        <label htmlFor="startTime" className="form-label">Start Time</label>
                        <TimePicker disabled={props.permissionsLevel === Permission.Read} value={journey.startTime} onChange={(value: string) => { setJourney({ ...journey, startTime: value }) }} />
                        <FormError errors={apiErrors} name='startTime' />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="schedule" className="form-label">Schedule</label>
                        <select disabled={props.permissionsLevel === Permission.Read} className="w-full input-control" value={journey.scheduleId || ''} onChange={handleOnScheduleChange}>
                            <option value="">Select Schedule...</option>
                            {props.schedules.map(schedule => (
                                <option value={schedule.id} key={'schedule_' + schedule.id}>{schedule.name}</option>
                            ))}
                        </select>
                        <FormError errors={apiErrors} name='scheduleId' />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Days in Operation</label>
                        <div className="flex mb-4">
                            <button type="button" disabled={props.permissionsLevel === Permission.Read} className="mr-2 text-sm text-yellow-500 underline" onClick={handleEveryDayOnClick}>Every Day</button>
                            <button type="button" disabled={props.permissionsLevel === Permission.Read} className="mr-2 text-sm text-yellow-500 underline" onClick={handleMonFriOnClick}>Mon-Fri</button>
                            <button type="button" disabled={props.permissionsLevel === Permission.Read} className="mr-2 text-sm text-yellow-500 underline" onClick={handleWeekendsOnClick}>Weekends</button>
                            <button type="button" disabled={props.permissionsLevel === Permission.Read} className="ml-auto mr-0 text-sm text-red-400 underline" onClick={handleClearDaysClick}>Clear All</button>
                        </div>
                        <div className="flex flex-wrap">
                            <label className="flex items-center mb-2 mr-4">
                                <input type="checkbox" disabled={props.permissionsLevel === Permission.Read} className="mr-1" checked={journey.monday} onChange={event => { setJourney({ ...journey, monday: event.target.checked }) }} />
                                Monday
                            </label>
                            <label className="flex items-center mb-2 mr-4">
                                <input type="checkbox" disabled={props.permissionsLevel === Permission.Read} className="mr-1" checked={journey.tuesday} onChange={event => { setJourney({ ...journey, tuesday: event.target.checked }) }} />
                                Tuesday
                            </label>
                            <label className="flex items-center mb-2 mr-4">
                                <input type="checkbox" disabled={props.permissionsLevel === Permission.Read} className="mr-1" checked={journey.wednesday} onChange={event => { setJourney({ ...journey, wednesday: event.target.checked }) }} />
                                Wednesday
                            </label>
                            <label className="flex items-center mb-2 mr-4">
                                <input type="checkbox" disabled={props.permissionsLevel === Permission.Read} className="mr-1" checked={journey.thursday} onChange={event => { setJourney({ ...journey, thursday: event.target.checked }) }} />
                                Thursday
                            </label>
                            <label className="flex items-center mb-2 mr-4">
                                <input type="checkbox" disabled={props.permissionsLevel === Permission.Read} className="mr-1" checked={journey.friday} onChange={event => { setJourney({ ...journey, friday: event.target.checked }) }} />
                                Friday
                            </label>
                            <label className="flex items-center mb-2 mr-4">
                                <input type="checkbox" disabled={props.permissionsLevel === Permission.Read} className="mr-1" checked={journey.saturday} onChange={event => { setJourney({ ...journey, saturday: event.target.checked }) }} />
                                Saturday
                            </label>
                            <label className="flex items-center mb-2 mr-4">
                                <input type="checkbox" disabled={props.permissionsLevel === Permission.Read} className="mr-1" checked={journey.sunday} onChange={event => { setJourney({ ...journey, sunday: event.target.checked }) }} />
                                Sunday
                            </label>
                        </div>
                    </div>
                    <div className="flex mt-4">
                        {props.permissionsLevel >= Permission.Edit && <button className="ml-auto mr-2 bg-yellow-300 button"
                                 disabled={saving || deleting}>{saving ? 'Saving...' : 'Save'}</button>}
                        {journey.id && props.permissionsLevel >= Permission.Edit ? (<button type="button" onClick={handleDeleteOnClick} className="mr-2 text-red-400 button" disabled={saving || deleting}>Delete</button>) : null}
                        <button type="button" onClick={() => { props.onCloseAction() }} className="button">Cancel</button>
                    </div>
                </form>
            </Modal>
            {showConfirmDelete ? (
                <ConfirmModal title="Delete Journey" onConfirm={handleDeleteConfirmed} onClose={() => { setShowConfirmDelete(false) }} disabled={deleting || saving}>
                    <p>Are you sure you want to delete this journey, this will also delete any associated Sessions?</p>
                </ConfirmModal>
            ) : null}
        </>
    );
}

export default JourneyModal;