import { PassengerReport } from "./PassengerReport";
import { RouteReport } from "./RouteReport";
import { DriverReport } from "./DriverReport";
import { JourneyReport } from "./JourneyReport";
import { useQuery } from "../routing/hooks";
import { Link } from "react-router-dom";
import ReportsContextProvider from "./Context";

export const Index = () => {
    const query = useQuery();
    const report = query.get('report');

    const renderSwitch = () => {
        switch (report) {
            case 'passenger':
                return (<PassengerReport />)
            case 'route':
                return (<RouteReport />)
            case 'driver':
                return (<DriverReport />)
            case 'journey':
                return (<JourneyReport />)
        }
    }

    return (
        <ReportsContextProvider>
            <div className="p-2 mb-3">
                <div className="grid grid-cols-4 gap-3">
                    <div className={"flex rounded justify-center p-2 text-center shadow " + (report === 'passenger' ? 'bg-white' : 'bg-gray-100')}>
                        <Link to="/reports?report=passenger">
                            <h3 className="flex items-center mb-2 text-2xl font-semibold cursor-pointer h-28">Passenger Report</h3>
                        </Link>
                    </div>
                    <div className={"flex rounded justify-center p-2 text-center shadow " + (report === 'route' ? 'bg-white' : 'bg-gray-100')}>
                        <Link to="/reports?report=route">
                            <h3 className="flex items-center mb-2 text-2xl font-semibold cursor-pointer h-28">Route Report</h3>
                        </Link>
                    </div>
                    <div className={"flex rounded justify-center p-2 text-center shadow " + (report === 'driver' ? 'bg-white' : 'bg-gray-100')}>
                        <Link to="/reports?report=driver">
                            <h3 className="flex items-center mb-2 text-2xl font-semibold cursor-pointer h-28">Driver Report</h3>
                        </Link>
                    </div>
                    <div className={"flex rounded justify-center p-2 text-center shadow " + (report === 'journey' ? 'bg-white' : 'bg-gray-100')}>
                        <Link to="/reports?report=journey">
                            <h3 className="flex items-center mb-2 text-2xl font-semibold cursor-pointer h-28">Route Information</h3>
                        </Link>
                    </div>
                </div>
                <div className="my-3">
                    {renderSwitch()}
                </div>
            </div>
        </ReportsContextProvider>
    )
}