export interface FormErrorProps {
    name: string,
    errors: any,
}
export default function FormError (props: FormErrorProps) {
    const errors = props.errors[props.name] || [];

    if (!errors) {
        return null;
    }
    const error = Array.isArray(errors) ? errors[0] : errors;

    if (!error) {
        return null;
    }

    return (
        <div className="p-1 text-xs text-red-500 form-error">{error}</div>
    );
}
