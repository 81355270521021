import {AxiosRequestConfig, AxiosResponse} from "axios";
import {apiAxios as axios} from '../api/axios';
import {
    BulkDeleteReservationRequestBody,
    BulkReservationRequestBody,
    Passenger,
    PassengerImage,
    RegisterProps
} from "./types";

export const fetchPassengers = (params: object) :Promise<AxiosResponse> => {
    return axios.get('/passengers', {params} as AxiosRequestConfig)
}

export const savePassenger = (savePassenger: Passenger) :Promise<AxiosResponse> => {
    if (savePassenger.id) {
        return axios.post('/passengers', savePassenger)
    }
    return axios.put('/passengers', savePassenger);
}

export const deletePassenger = (deletePassenger: Passenger) => {
    return axios.delete('/passengers/'+ deletePassenger.id)
}

export const makeReservations = (props: BulkReservationRequestBody) => {
    return axios.put('/reservation/bulk', props)
}

export const deleteReservations = (props: BulkDeleteReservationRequestBody) => {
    return axios.put('/reservation/bulk/delete', props)
}

export const fetchPassenger = (id: number) :Promise<AxiosResponse> => {
    return axios.get('/passenger/' + id)
}

export const fetchPassengerImage = (id: number) :Promise<AxiosResponse> => {
    return axios.get('/passenger/image/' + id)
}

export const savePassengerImage = (passengerImage: PassengerImage) => {
    return axios.post('/passenger/image', passengerImage as AxiosRequestConfig)
}

export const registerPassenger = (registerProps: RegisterProps) => {
    return axios.post('/register/' + registerProps.id, registerProps.passenger as AxiosRequestConfig)
}
