import {UploadResultTableProps as TableProps} from "./types";


export const UploadResultTable = (props: TableProps) => {



    return (
        <div className="w-full">
            <table className="table w-1/2 m-auto border-separate table-striped">
                <tr>
                    <th>
                        Row
                    </th>
                    <th>
                        Message
                    </th>
                    <th>
                        Status
                    </th>
                </tr>
                {props.responseArray.map((uploadResponse, index )=> {
                    let color = "green"

                    if (uploadResponse.status === "FAILED") {
                        color = "red"
                    }

                    return(
                    <tr className={"text-" + color + "-500"} key={index}>
                        <td>
                            {uploadResponse.row}
                        </td>
                        <td>
                            {uploadResponse.message}
                        </td>
                        <td>
                            {uploadResponse.status}
                        </td>
                    </tr>
                )
                })}
            </table>
        </div>
    )
}
