import Modal from "../modal/Modal";
import {BulkReservationRequestBody, Passengers, ReservationValues} from "../passengers/types";
import {Formik, Field, Form, FieldArray} from 'formik';
import React, {useRef, useState} from "react";
import FormError from "../form/FormError";
import {fetchJourneyDropDown} from "../journeys/service";
import {DropDownOption, DropDownOptions} from "../dropdown/types";
import * as Yup from 'yup';
import {setSuccessNotificationText} from "../notification/slice";
import {makeReservations as ApiMakeReservations} from "../passengers/service";
import Alert from "../alerts/Alert";
import {useAppDispatch} from "../../app/hooks";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSyncAlt} from "@fortawesome/free-solid-svg-icons";
import {getOptionsFromApi} from "../dropdown/helper";
import RouteAutoComplete, {RouteAutoCompleteChangeEvent} from "../routes/RouteAutocomplete";

export interface ReservationModalProps{
    closeAction: CallableFunction,
    passengers: Passengers,
    originalJourneyId?:number|undefined,
    originalJourneyName?:string
}

export const ReservationModal = (props: ReservationModalProps) => {

    const [apiErrors, setApiErrors] = useState({})
    const [errorMessage, setErrorMessage] = useState('');
    const [route, setRoute] = useState<DropDownOption>()
    const [journeyOptions, setJourneyOptions] = useState<JSX.Element[]>([])
    const [journeyLoading, setJourneyLoading] = useState(false)
    const [optionsArray, setOptionsArray] = useState<DropDownOptions>([])
    const [saving, setSaving] = useState(false)
    const dispatch = useAppDispatch();

    const currentOptions = useRef(optionsArray)
    currentOptions.current = optionsArray

    const initialReservationValues = {
        passengers:props.passengers,
        routeId:'Select Route',
        journeyId:'Select Journey',
        originalJourneyId: props.originalJourneyId ?? undefined,
        transfer:false
    }

    const validation = Yup.object().shape({
        journeyId: Yup.number().positive("Please select a Journey").label("Journey").required().typeError("Please select a Journey"),
    })

    const submitSave = (values: ReservationValues) => {

        // formik only uses strings for selects so need to convert here
        const requestBody:BulkReservationRequestBody = {
            passengers:values.passengers.filter(passenger => Object.keys(passenger).length !== 0),
            journeyId:parseInt(values.journeyId),
            originalJourneyId:values.originalJourneyId,
            transfer:values.transfer
        }

        // get journey name
        let journeyName = ''
        currentOptions.current.forEach((option) => {if (option.id === requestBody.journeyId){journeyName = option.value}})

        ApiMakeReservations(requestBody).then(() => {
            let passengerNames = values.passengers.map(p => p.firstName+' '+p.lastName).join(', ');
            dispatch(setSuccessNotificationText('Reservations created for '+ passengerNames + ' on ' + journeyName))
            props.closeAction()
        }).catch((errors) => {
            if (errors.response && errors.response.data)  {
                setApiErrors(errors.response.data)
            }
            setSaving(false)
            setErrorMessage('Error making reservation please try again')
        })
    }

    const getJourneys = (routeId: any) => {
        setJourneyLoading(true)
        if (routeId > 0) {
            fetchJourneyDropDown({routeId:routeId}).then((response) => {
                const journeyOptionsApi = response.data as DropDownOptions
                setOptionsArray(journeyOptionsApi);
                if (props.originalJourneyId)
                    setJourneyOptions(getOptionsFromApi(journeyOptionsApi, [props.originalJourneyId]))
                else
                    setJourneyOptions(getOptionsFromApi(journeyOptionsApi))
                setJourneyLoading(false);
            })
        }
    }



    const errorMessageHTML = errorMessage ? <Alert message={errorMessage} type="error"/> : null

    return (
        <Modal title={props.originalJourneyId ? "Transfer Reservation " :"New Reservation" } closeAction={props.closeAction}>

            <Formik initialValues={initialReservationValues}
                onSubmit={(
                    values: ReservationValues,
                ) => {
                    setTimeout(() => {
                        setSaving(true)
                        setErrorMessage('')
                        submitSave(values)
                    }, 500);}}
                    validationSchema={validation}
                    validateOnBlur
            >
                {({values, setFieldValue, handleChange,errors, isValid, dirty}) => {
                    const allErrors = {...apiErrors, ...errors}
                    const handleRouteChanged = (event: RouteAutoCompleteChangeEvent) => {
                        setJourneyOptions([])
                        setFieldValue('journeyId', 'Select Journey')
                        if (event.route) {
                            setRoute(event.route)
                            setFieldValue('routeId', event.route.id);
                            getJourneys(event.route.id)
                        } else {
                            setFieldValue('routeId', null);
                        }
                    }
                    return(
                        <Form style={{minHeight:"50%"}}>
                            <FieldArray name="passengers">
                                {({replace}) => (
                                    <div>
                                        <div className="text-center mb-4">
                                            <h2 className="text-lg">
                                                {props.originalJourneyId ? "Transfer passengers from " + props.originalJourneyName + " :" :"Create reservation for passengers:"}
                                            </h2>
                                        {values.passengers.map((passenger, index) => (
                                                passenger.id &&
                                            <div key={passenger.id} className="align-items-center">
                                                {passenger.firstName} {passenger.lastName}

                                                 <button
                                                     type="button"
                                                     className="py-0 ml-2 mr-2 text-red-600 transition-all rounded button hover:bg-red-600 hover:text-white"
                                                     onClick={() => {
                                                         if ((values.passengers.filter(passenger => Object.keys(passenger).length !== 0)).length > 1){
                                                             replace(index, {})
                                                         } else {
                                                             props.closeAction()
                                                         }
                                                         }
                                                     }
                                                 >
                                                     X
                                                 </button>
                                                <FormError name={`passengers[${index}].id`} errors={allErrors} />
                                            </div>
                                            )
                                        )}
                                        </div>
                                    </div>
                                    )}
                            </FieldArray>
                            <label htmlFor="routeId" className="block mb-3 font-medium">
                                {props.originalJourneyId ? "New " : ""}Route
                                <RouteAutoComplete value={route ? route.value : ''} onChange={handleRouteChanged} />
                            </label>
                            <label htmlFor="journeyId" className="block mb-3 font-medium"><span
                                className="inline-block align-middle">{props.originalJourneyId ? "New " : ""}Journey</span>
                                {journeyLoading && (values.routeId !== 'Select Route') &&
                                <FontAwesomeIcon icon={faSyncAlt} className={'ml-2 text-sm fa-spin'}/>}

                            <Field className="p-1 ml-3 border-2 border-gray-100 rounded" as="select"
                                   name="journeyId"
                                   id="journeyId">
                                <option>Select Journey</option>
                                {journeyOptions}
                            </Field>
                            </label>
                                {props.originalJourneyId &&
                                <label htmlFor="transfer"  className="block mb-3 font-medium text-red-500">Remove Old Reservation
                                <Field
                                    className="p-1 ml-3 border-2 border-gray-100 rounded"
                                    id="transfer"
                                    name="transfer"
                                    type="checkbox"
                                >

                                </Field>
                            </label>
                                    }
                            <FormError name="journeyId" errors={allErrors}/>
                            <div>
                                {errorMessageHTML}
                            </div>
                            <div className="flex justify-between">
                                <button className="ml-4 bg-yellow-300 button" type="submit" disabled={!(isValid && dirty && !saving)} >{values.transfer ? 'Transfer' : 'Make'} Reservation</button>
                                <button className="mr-4 bg-gray-300 button" onClick={() => props.closeAction()} type="button"> cancel </button>
                            </div>
                        </Form>
                    )}}
            </Formik>

        </Modal>
    )
}