import {Policies} from "./types";

export enum Permission {
    Read = 1,
    Edit,
    Create,
    Delete
}
export const getResourcePolicies = (resource: string, policies: Policies | undefined) => {
    if (policies) {
        return policies.filter(policy => policy.resource === resource)
    }
    return [];
}

// returns the permissions level for a resource or entity at any organisation
export const getPermissionsLevel = (resource: string, policies: Policies | undefined, id = undefined, includeEntities = false) => {
    policies = getResourcePolicies(resource, policies);
    const allowPolicies = policies?.filter(policy => policy.effect === 'allow' &&
        (policy.entityId === '*' || (String(policy.entityId) === String(id)  || includeEntities )
            ||
            ((policy.resource === 'organisation' && !id && (policy.organisationId === '*' || includeEntities)) || ((policy.resource === 'organisation' && ((String(policy.organisationId) === String(id)) || policy.organisationId === '*'))))
        ))
    const denyPolicies = policies?.filter(policy => policy.effect === 'deny' &&
        (policy.entityId === '*' || String(policy.entityId) === String(id)
        ||
            ((policy.resource === 'organisation' && !id) || ((policy.resource === 'organisation' && String(policy.organisationId) === String(id)) || policy.organisationId === '*'))
        ))
    let lowestDeny = 5
    if (denyPolicies.length > 0) {
        denyPolicies.forEach((denyPolicy) => {
            if (denyPolicy.action < lowestDeny) {
                lowestDeny = denyPolicy.action
            }
        })
    }
    let highestAllow = 0
    if (allowPolicies.length > 0) {
        allowPolicies.forEach((allowPolicy) => {
            if (allowPolicy.action > highestAllow) {
                highestAllow = allowPolicy.action
            }
        })
    }
    return (lowestDeny - 1) < highestAllow ? (lowestDeny - 1) : highestAllow
}

// returns the permissions level for an entity using the organisation id and entity id
export const getPermissionsLevelForEntity = (resource: string, entity: object, policies: Policies | undefined) => {
    if (resource === 'organisation') {
        return getPermissionsLevelForOrganisation(resource, entity, policies);
    }
    if (entity.hasOwnProperty('organisationId')) {
        // @ts-ignore
        let organisation = parseInt(entity.organisationId)
        if (entity.hasOwnProperty('id')) {
            // @ts-ignore
            let entityId = entity.id
            return getPermissionsLevel(resource, policies?.filter(policy => parseInt(policy.organisationId) === organisation || policy.organisationId === '*'), entityId)
        }
        return getPermissionsLevel(resource, policies?.filter(policy => parseInt(policy.organisationId) === organisation || policy.organisationId === '*'))
    } else {
        return getPermissionsLevel(resource, policies)
    }
}

export const getPermissionsLevelForResourceAtOrganisation = (resource: string, organisation: number | undefined, policies: Policies | undefined) => {
    if (!organisation || !policies) {
        return 0
    }
    return getPermissionsLevel(resource, policies?.filter(policy => (parseInt(policy.organisationId) === organisation || policy.organisationId === '*')))
}

export const bulkUploadPoliciesExist = (policies: Policies | undefined) => {
    return (
        getPermissionsLevel('route', policies) >= 3 ||
        getPermissionsLevel('stop', policies) >= 3 ||
        getPermissionsLevel('passenger', policies) >= 3 ||
        getPermissionsLevel('carer', policies) >= 3
    )
}

export const getPermissionsLevelForOrganisation = (resource: string, organisation: any, policies: Policies | undefined) => {

    if (organisation.hasOwnProperty('id')) {
        // @ts-ignore
        let organisationId = organisation.id
        return getPermissionsLevel(resource, policies?.filter(policy => (parseInt(policy.organisationId) === organisationId || policy.organisationId === '*')), organisation.id);
    } else {
        return getPermissionsLevel(resource, policies)
    }
}