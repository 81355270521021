import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "../../app/store";
import {Route} from "./types";

export interface RoutesState {
    editRoute: null|Route,
    deleteRoute: null|Route,
}

const initialState: RoutesState = {
    editRoute: null,
    deleteRoute: null,

};

export const slice = createSlice({
    name: 'routes',
    initialState,
    reducers: {
        setEditRoute: (state: RoutesState, {payload}) => {
            state.editRoute = payload;
        },
        clearEditRoute: (state) => {
            state.editRoute = null;
        },
        setDeleteRoute: (state, {payload}) => {
            state.deleteRoute = payload;
        },
    }
});

 export const {
     setEditRoute,
     clearEditRoute,
     setDeleteRoute,
    } = slice.actions;

export const selectEditRoute = (state: RootState) => (state.routes.editRoute);
export const selectDeleteRoute = (state: RootState) => (state.routes.deleteRoute);

export default slice.reducer;