import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { fetchRoute } from "./service";
import { Route } from "./types";
import { setHtmlTitle } from '../../app/helpers';
import { ParamsWithId } from "../routing/types";
import { RouteModal } from "./RouteModal";
import { ScheduleStop } from "../schedules/types";
import { Reservation } from "../reservations/types";
import { ShowStopsModal } from "./ShowStopsModal";
import { ShowReservationModal } from "./ShowReservationModal";
import { getPermissionsLevelForEntity } from "../policies/helpers";
import { useAppSelector } from "../../app/hooks";
import { selectUserPolicies } from "../auth/slice";



export const View = () => {
    const [showMap, setShowMap] = useState(false)
    const { id } = useParams<ParamsWithId>();
    const [route, setRoute] = useState({} as Route);
    const [loading, setLoading] = useState(false);
    const [stops, setStops] = useState<Array<ScheduleStop>>();
    const [reservations, setReservations] = useState<Array<Reservation>>()
    const policies = useAppSelector(selectUserPolicies)
    const routePerms = getPermissionsLevelForEntity('route', route, policies)


    useEffect(() => {
        loadRoute();
    }, []);

    function loadRoute() {
        setLoading(true);
        fetchRoute(parseInt(id)).then(({ data }) => {
            setHtmlTitle('View Route: ' + data.name);
            setRoute(data);
        }).finally(() => {
            setLoading(false)
        });
    }

    if (loading) {
        return (<span>Loading route, please wait...</span>);
    }



    return (
        <div>
            <div className="p-4 mb-4 bg-white rounded shadow">
                <h1 className="mb-4 text-xl font-semibold">Route: {route.name || 'N/A'}</h1>

                <div className={"mb-4"}>
                    <span className="block mb-2 text-base font-medium">Default Vehicle: {route.defaultVehicle?.registrationNumber ? route.defaultVehicle.registrationNumber : 'N/A'}</span>

                    <span className="block mb-3 text-base font-medium">Organisation: {route.organisationName}</span>

                    <span className="block mb-3 text-base font-medium">Capacity: {route.capacity ? route.capacity : 'N/A'}</span>

                    <span className="block mb-3 text-base font-medium">Stops: {route.routeStops ? route.routeStops.length : 'N/A'}</span>
                </div>

                <button onClick={() => { setShowMap(true) }} className="text-white bg-gray-600 button" type="button" >Show Map</button>
                {routePerms >= 2 && <Link
                    to={`/routes/${route.id}/edit`}
                    className="ml-4 text-gray-800 bg-yellow-400 button">Edit Route</Link>}
            </div>
            <div className="grid grid-cols-3 gap-3 ">
                {route.journeys && route.journeys.map(journey => {
                    return (
                        <div className={"p-2 text-center rounded bg-white shadow"} key={journey.id}>
                            <div className="flex flex-col justify-center m-3">
                                <h3 className={"mb-2 text-xl font-semibold"} >{route.name} @ {journey.startTime}</h3>
                                <p><span className="text-sm">Schedule:</span> {journey.schedule?.name ? journey.schedule.name : 'N/A'}</p>
                                <p><span className="text-sm" >Start Time:</span> {journey.startTime ? journey.startTime : 'N/A'}</p>
                                <p><span className="text-sm">Duration:</span> {journey.schedule?.duration ? journey.schedule.duration : 'N/A'}</p>
                                <p><span className="text-sm">No. Stops:</span> {journey.schedule?.scheduleStops ? journey.schedule.scheduleStops.length : 'N/A'}</p>
                            </div>
                            <div className="flex justify-evenly">
                                <button onClick={() => { setReservations(journey.reservations) }} className="flex-1 m-2 text-white bg-gray-600 button" type="button" disabled={!journey.reservations || journey.reservations.length <= 0} >Reservations</button>
                                <button onClick={() => { setStops(journey.schedule?.scheduleStops) }} className="flex-1 m-2 text-white bg-gray-600 button" type="button" disabled={!journey.schedule?.scheduleStops || journey.schedule?.scheduleStops.length === 0}>Stops</button>
                            </div>
                        </div>
                    )
                })}
                {stops && <ShowStopsModal closeModal={() => setStops(undefined)} stops={stops} />}
                {reservations && <ShowReservationModal closeModal={() => setReservations(undefined)} reservations={reservations} />}
                {showMap && <RouteModal closeModal={() => { setShowMap(false) }} routeId={parseInt(id)} />}
            </div>
        </div>
    )
}

