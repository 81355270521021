import {DropDownOptions, ReactSelectOptions} from "./types";
import React from "react";


export const getOptionsFromApi = (ApiOptions: DropDownOptions, removeIds?:number[]) => {
    const options: JSX.Element[] = [];
    for (const {id, value} of ApiOptions) {
        if (!(removeIds && removeIds.includes(id))){
            options.push(
                <option key={id} value={id}>
                    {value}
                </option>
            );
        }
    }
    return options
}

export const ConvertToReactSelectOptions = (ApiOptions: DropDownOptions) => {
    let selectOptions:ReactSelectOptions = []
    for (const {id, value} of ApiOptions) {
        selectOptions.push({label: value, value:id.toString()})
    }
    return selectOptions
}