
import { takeLatest, put, call } from "@redux-saga/core/effects"
import {PayloadAction } from "@reduxjs/toolkit";
import {LoginServerResponse, LoginActionPayload, RefreshActionPayload, LoggedInUser} from './types';
import { loginAction, refreshAction, logoutAction } from "./actions";
import {clearAuthFromStorage, getLoggedInUser, login, logout, refresh, saveAuth} from './service';
import {clearTokens, setErrorMessage, setLoaded, setLoading, setLoggedInUser, setTokens} from "./slice";

function* loginAsync(action: PayloadAction<LoginActionPayload>): any {
    try {
        yield put(setLoading(true));
        const response = yield call(login, action.payload.username, action.payload.password);
        const data = response.data  as LoginServerResponse;
        saveAuth(data.access_token, data.refresh_token, data.expires_in, action.payload.rememberMe);
        yield put(setTokens(data));
        const userResponse = yield call(getLoggedInUser)
        const userData = userResponse.data as LoggedInUser
        // Block certain users from logging in
        if (userData.type === 'Carer' || userData.type === 'Passenger') {
            yield put(setLoading(false));
            yield put(clearTokens());
            yield put(setErrorMessage('You cannot login here, try logging into the app instead'));
            return;
        }
        yield put(setLoggedInUser(userData))
        yield put(setLoading(false));
        yield put(setLoaded(true));
    } catch (err: any) {
        yield put(setLoading(false));
        yield put(clearTokens());
        yield put(setErrorMessage('Invalid login credentials, please try again'));
    }
}

export function* watchLogin() {
    yield takeLatest(loginAction.type, loginAsync)
}

function* refreshAsync(action: PayloadAction<RefreshActionPayload>): any {
    try {
        const response = yield call(refresh, action.payload.refreshToken);
        const data = response.data  as LoginServerResponse;
        saveAuth(data.access_token, data.refresh_token, data.expires_in);
        const userResponse = yield call(getLoggedInUser)
        const userData = userResponse.data as LoggedInUser
        yield put(setLoggedInUser(userData))
        yield put(setTokens(data));
        yield put(setLoaded(true));
    } catch (err: any) {
        yield put(setLoaded(true));
        yield put(logoutAction());
    }
}

export function* watchRefresh() {
    yield takeLatest(refreshAction.type, refreshAsync);
}

function* logoutAsync() {
    yield call(clearAuthFromStorage);
    try {
        yield call(logout);
    }catch(error: any) {
       console.error(error);
    }
    yield put(clearTokens())
}

export function* watchLogout() {
    yield takeLatest(logoutAction.type, logoutAsync);
}