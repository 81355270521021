import {CardsExpandable} from "../cards/CardsExpandable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSyncAlt} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {Passengers} from "./types";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {getFormattedDateFromString} from "../dates/helper";
import {Reservation, Reservations} from "../reservations/types";
import {setEditReservation} from "../reservations/slice";
import Pagination from "../pagination/Pagination";
import {Link} from "react-router-dom";
import {getPermissionsLevelForEntity, Permission} from "../policies/helpers";
import {selectUserPolicies} from "../auth/slice";


export interface PassengerTableProps {
    passengers: Passengers
    selectAll: boolean,
    loading: boolean,
    selectedIds: number[],
    handleSelectAllChecked: CallableFunction,
    handleChecked: CallableFunction,
    page: number
    pages: number
    setPage: CallableFunction
}

export interface PassengerReservationTableProps {
    reservations: Reservations
    selectAll: boolean,
    loading: boolean,
    selectedIds: number[],
    journeySelected: boolean,
    handleSelectAllChecked: CallableFunction,
    handleChecked: CallableFunction,
}

export const PassengerTable = (props: PassengerTableProps) => {
    const policies = useAppSelector(selectUserPolicies)

    return (
        <>
            <form>
                <div className="p-4 bg-white shadow">
                    <div className="mb-4">
                        <h1 className="text-lg">Passengers</h1>
                    </div>
                    <table className="table w-full table-striped">
                        <thead>
                        <tr className="justify-center">
                            <th className="text-left"><input type="checkbox"
                                                             onChange={() => props.handleSelectAllChecked()}
                                                             checked={props.selectAll}/></th>
                            <th className="text-left">Name</th>
                            <th className="text-left">Email</th>
                            <th className="text-left">Phone</th>
                            <th className="text-left">Notes</th>
                            <th className="text-left">Cards</th>
                        </tr>
                        </thead>
                        <tbody>

                        {props.passengers.map((passenger) => {
                                const permissionsLevel = getPermissionsLevelForEntity('passenger', passenger, policies)

                                return (<tr className="justify-center" key={passenger.id}>
                                        <td>
                                            {permissionsLevel >= Permission.Edit && <input onChange={(event) => {
                                                props.handleChecked(event.target)
                                            }} type="checkbox"
                                                                            checked={(!!passenger.id && props.selectedIds.indexOf(passenger?.id) !== -1)}
                                                                            value={passenger.id}/>}</td>
                                        <td className="cursor-pointer"><Link
                                            to={'/passengers/' + passenger.id + '/edit'}>{passenger.firstName} {passenger.lastName}</Link>
                                        </td>
                                        <td>{passenger.email}</td>
                                        <td>{passenger.phone}</td>
                                        <td>{passenger.notes}</td>
                                        <td className="flex-row">{passenger.cards.length > 0 &&
                                        <CardsExpandable cards={passenger.cards}/>}</td>
                                    </tr>
                                )
                            }
                        )}
                        </tbody>
                    </table>
                    <div className="flex justify-center block font-medium text-gray-400">
                        {props.loading &&
                        <>
                            <FontAwesomeIcon icon={faSyncAlt} className={'mr-1 ' + (props.loading ? 'fa-spin' : '')}/>
                            Loading...
                        </>
                        }
                        {props.passengers.length < 1 && !props.loading && 'No passengers'}
                    </div>
                </div>
            </form>
            <div className="text-center m-2">
                <Pagination page={props.page} onChange={props.setPage} pages={props.pages}/>
            </div>
        </>
    )
}

export const PassengerReservationTable = (props: PassengerReservationTableProps) => {
    const policies = useAppSelector(selectUserPolicies)

    const dispatch = useAppDispatch();

    const openEditReservation = (reservation: Reservation) => {
        dispatch(setEditReservation(reservation));
    }
    const text = props.journeySelected ? 'No passengers found' : 'Please select a route and journey'

    return (
        <>
            <form>
                <div className="p-4 bg-white shadow">
                    <div className="mb-4">
                        <h1 className="text-lg">Passengers</h1>
                    </div>
                    <table className="table w-full border-separate table-striped">
                        <thead>
                        <tr className="justify-center">
                            <th className="text-left">
                                <input type="checkbox" onChange={() => props.handleSelectAllChecked()}
                                       checked={props.selectAll}/>
                            </th>
                            <th className="text-left">Name</th>
                            <th className="text-left">Email</th>
                            <th className="text-left">Phone</th>
                            <th className="text-left">Notes</th>
                            <th className="text-left">Cards</th>
                            <th className="text-left">Reservation</th>
                        </tr>
                        </thead>
                        <tbody>

                        {props.reservations.map((reservation) => {
                            const passenger = reservation.passenger
                            const permissionsLevel = getPermissionsLevelForEntity('passenger', passenger , policies)

                            return (<tr className="justify-center" key={passenger.id}>
                                        <td>{permissionsLevel >= Permission.Edit && <input onChange={(event) => {
                                            props.handleChecked(event.target)
                                        }} type="checkbox"
                                                    checked={(!!passenger.id && props.selectedIds.indexOf(passenger.id) !== -1)}
                                                    value={passenger.id}/>}</td>

                                            <td className="cursor-pointer"><Link to={'/passengers/' + passenger.id + '/edit'}>{passenger.firstName} {passenger.lastName}</Link></td>

                                        <td>{passenger.email}</td>
                                        <td>{passenger.phone}</td>
                                        <td>{passenger.notes}</td>
                                        <td className="flex-row">{passenger.cards.length > 0 &&
                                        <CardsExpandable cards={passenger.cards}/>}</td>
                                        <td className="cursor-pointer"
                                            onClick={() => openEditReservation(reservation)}>{reservation.date ? getFormattedDateFromString(reservation.date) : 'perpetual'}</td>
                                    </tr>
                                )
                            }
                        )}

                        </tbody>
                    </table>
                    <div className="flex justify-center block mt-2 font-medium text-gray-400">
                        {props.loading &&
                        <>
                            <FontAwesomeIcon icon={faSyncAlt} className={'mr-1 ' + (props.loading ? 'fa-spin' : '')}/>
                            Loading...
                        </>
                        }
                        {props.reservations.length < 1 && !props.loading && text}
                    </div>
                </div>
            </form>
        </>
    )
}