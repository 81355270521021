import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "../../app/store";
import {Card} from "./cardInterface";

interface CardState {
    deleteCard: null|Card
}

const initialState:CardState = {
    deleteCard: null
}


export const slice = createSlice({
    name: "card",
    initialState,
    reducers: {
        setDeleteCard: (state:CardState, {payload}) => {
            state.deleteCard = payload
        }
    }
});



export const {setDeleteCard} = slice.actions

export const selectDeleteCard = (state: RootState) => state.cards.deleteCard

export default slice.reducer