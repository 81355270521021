import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {selectNotification, setNotification} from "./slice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import {useEffect} from "react";

export const UiNotification = () => {


    const dispatch = useAppDispatch();

    const notification = useAppSelector(selectNotification);

    const removeNotification = () => {
        dispatch(setNotification(null))
    }
    useEffect(() => {
        setTimeout(() => {
            dispatch(setNotification(null))
        }, 10000)
    })
    if(!notification){
        return null
    }
    let color = ""

    switch (notification.type) {
        case "success":
            color = "green"
            break;
        case "error":
            color = "red"
            break;
        case "info":
            color = 'blue';
            break;
        default:
            color = 'gray';
            break;
    }

    const typeClass = 'bg-' + color + '-500';

    return (
        <div className={"text-white px-6 py-4 border-0 rounded relative mb-4 " + typeClass}>
            <span className="inline-block mr-4 text-xl align-middle">
                <FontAwesomeIcon icon={faBell} className="text-white text-md"/>
             </span>
            <span className="inline-block mr-8 align-middle">
                {notification.text}
            </span>
            <button onClick={() => removeNotification()} className="absolute top-0 right-0 mt-4 mr-6 text-2xl font-semibold leading-none bg-transparent outline-none focus:outline-none">
                <span>×</span>
            </button>
        </div>
    )
}