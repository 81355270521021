import {EditRouteTabProps, RouteStop} from './types';
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import StopModal from '../stops/StopModal';
import ConfirmModal from '../modal/ConfirmModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsAltV, faPlus } from '@fortawesome/free-solid-svg-icons'
import { useState } from "react";
import {Stop} from '../stops/types';
import {Permission} from "../policies/helpers";

export function EditStops (props: EditRouteTabProps) {
    const [showStopModal, setShowStopModal] = useState(false);
    const [stopToDelete, setStopToDelete] = useState<Stop | null>(null);

    const canBeRemoved = (stop :RouteStop) => {
        let scheduleStopIds: number[] = [];
        props.route.schedules.forEach(schedule => {
            schedule.scheduleStops.forEach(scheduleStop => {
               scheduleStopIds.push(scheduleStop.routeStop.id);
            })
        })
        return !scheduleStopIds.includes(stop.id);
    }
    function addStop() {
        setShowStopModal(true);
    }

    function handleStopSelected(stop: Stop) {
        setShowStopModal(false);
        const newRoute = { ...props.route };
        const newRouteStop = { stop } as RouteStop;
        newRoute.routeStops.push(newRouteStop);
        props.onChange(newRoute);
    }

    function deleteStop(stop: Stop) {
        const index = props.route.routeStops.findIndex((routeStop) => (routeStop.stop.id === stop.id));
        const newRoute = { ...props.route };
        newRoute.routeStops.splice(index, 1);
        props.onChange(newRoute);
        setStopToDelete(null);
    }

    function reorder(list: Array<RouteStop>, startIndex: number, endIndex: number): Array<RouteStop> {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    }

    function onDragEnd(result: DropResult) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        let routeStops = reorder(
            props.route.routeStops,
            result.source.index,
            result.destination.index
        );

        routeStops = routeStops.map((routeStop: RouteStop, index: number) => {
            routeStop.orderNumber = index;
            return routeStop;
        })

        props.onChange({ ...props.route, routeStops });
    }

    return (
        <>
            <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable isDropDisabled={props.permissionsLevel === Permission.Read} droppableId="stopsDroppable">
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {props.route.routeStops.map((stop, index) => {
                                        const stopUid = stop.id + '_' + stop.stop.id;
                                        return (
                                            <Draggable isDragDisabled={props.permissionsLevel === Permission.Read} key={stopUid} draggableId={stopUid} index={index}>
                                                {(provided, snapshot) => {
                                                    let classNames = 'flex items-center p-2 mb-2 bg-white border-2 rounded transition-colors hover:bg-gray-100';
                                                    if (snapshot.isDragging) {
                                                        classNames += ' border-gray-500 shadow-lg';
                                                    }
                                                    return (
                                                        <div className={classNames} key={stop.id} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                            <div className="px-2 py-1 mr-2 bg-gray-200 rounded">
                                                                <FontAwesomeIcon icon={faArrowsAltV} />
                                                            </div>
                                                            <div>
                                                                <span className="font-semibold">{stop.stop.name}</span>
                                                            </div>
                                                            <div className="ml-auto">
                                                                {props.permissionsLevel >= Permission.Edit && canBeRemoved(stop) && <button
                                                                    className="p-1 text-sm font-semibold text-red-400 rounded"
                                                                    onClick={() => {
                                                                        setStopToDelete(stop.stop)
                                                                    }}>Remove Stop</button>}
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                }
                                            </Draggable>
                                        )
                                    }
                                    )}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    {!props.route.routeStops.length ? (
                        <span className="block px-1 py-4">This route does not have any stops yet.</span>
                    ) : null}
                    <button className="text-white bg-gray-500 button" onClick={() => { addStop() }}><FontAwesomeIcon icon={faPlus} className="mr-2"/>Add Stop</button>
                    {showStopModal ? (<StopModal closeAction={() => { setShowStopModal(false) }} onSelect={handleStopSelected} title="Add Stop" />) : null}
                    {stopToDelete ? (<ConfirmModal title="Remove Stop" onClose={() => { setStopToDelete(null) }} onConfirm={() => { deleteStop(stopToDelete) }}>Are you sure you want to remove the stop <strong>{stopToDelete.name}</strong> from this route?</ConfirmModal>) : null}
                
        </>
    );
}

export default EditStops;