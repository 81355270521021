import { ReactNode } from "react";

export interface DashboardBlockProps {
    children: ReactNode,
}

export default function DashboardBlock (props: DashboardBlockProps) {
    return (
        <div className="p-2 bg-white rounded shadow-sm">{props.children}</div>
    );
}