import { ReactNode } from "react";

export interface TabButtonProps {
    activeTab?: string,
    tab: string,
    onClick: CallableFunction
    children?: ReactNode
}

export function TabButton(props: TabButtonProps) {

    function tabButtonClasses(): string {
        let classes = 'px-3 py-2 pb-3 mr-1 rounded shadow hover:bg-white';
        if (props.tab === props.activeTab) {
            classes += ' bg-white';
        } else {
            classes += ' bg-gray-200'
        }
        return classes;
    }

    return (
        <button className={tabButtonClasses()} onClick={() => { props.onClick(props.tab) }}>{props.children}</button>
    );
}

export default TabButton;