import {AxiosRequestConfig} from 'axios';
import {apiAxios as axios} from '../api/axios';
import {Route} from "./types";


export function fetchRoutes(params?: object) {
    return axios.get('/routes', {params} as AxiosRequestConfig);
}
export function saveRoute(route: Route) {
    if (route.id) {
        return axios.put('/routes/'+route.id, route);
    }
    return axios.post('/routes', route);
}

export function fetchRoute(id: number) {
    return axios.get('/routes/'+id);
}

export function deleteRoute(route: Route) {
    return axios.delete('/routes/'+route.id);
}
