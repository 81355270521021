import logo from '../../logo.png';
import {Link, useLocation} from "react-router-dom";
import {useAppSelector} from "../../app/hooks";
import {selectIsLoggedIn} from "../auth/slice";
import {useEffect} from "react";
import {useHistory} from "react-router";

export const Complete = () => {
    const location = useLocation();
    const history = useHistory()
    const isLoggedIn = useAppSelector(selectIsLoggedIn);

    interface LocationState {
        from?: Location,
    }

    useEffect(() => {
        if (isLoggedIn) {
            let from = '/';
            if (location.state) {
                const locState = location.state as LocationState;
                from = locState.from?.pathname || from;
            }
            history.replace(from);
        }
    }, [isLoggedIn, history]);

    return (
        <div className="flex justify-center w-screen h-screen align-center">
            <div className="w-11/12 max-w-sm p-4 mx-auto my-auto text-center bg-white rounded shadow-sm border-box">
                <img src={logo} alt="Zywepit Logo" className="px-1 mx-auto"/>
                <h2 className="mb-2 font-medium text-md">Registration complete please check your email if you need to set a password or click below to login</h2>
                <div className="py-1 text-sm">
                    <Link to="/login">Login</Link>
                </div>
            </div>
        </div>
    );
}

