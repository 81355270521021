import { useState } from "react";
import {Driver, DriverRoute} from './types';
import {Link, useParams} from "react-router-dom";
import { fetchDriver } from './service';
import { ParamsWithId } from '../routing/types';
import { useEffect } from "react";

export const View = () => {

    const [driver, setDriver] = useState<Driver>();

    const { id } = useParams<ParamsWithId>();
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        if (!id) {
            return;
        }
        setLoading(true);
        fetchDriver(id).then(({ data }) => {
            setDriver(data);
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
            console.error(error);
        });
    }, [id]);

    if (loading || !driver) {
        return (
            <div>Loading driver...</div>
        );
    }

    return (

        <div>
            <div>
                <h1 className="text-lg">View Driver</h1>
            </div>
                            <div className="grid grid-cols-2 grid-flow-row gap-4 mt-2 mb-4">
                                <div className="p-4 bg-white shadow">
                                    <div>
                                        <h3 className="mb-4 text-sm text-gray-400">Contact Info</h3>
                                    </div>
                                    <div>
                                        <label htmlFor="id" className="block mb-1 font-medium text-lg">Driver ID: {driver.id ? driver.id : 'N/A'}</label>
                                    </div>
                                    <div className="flex">
                                            <label htmlFor="firstName" className="block mb-1 font-medium text-lg">Name: {driver.firstName + ' ' + driver.lastName}</label>
                                    </div>
                                    <div>
                                        <label htmlFor="email" className="block mb-1 font-medium text-lg">Email Address: {driver.email ? driver.email : 'N/A'}</label>

                                    </div>
                                    <div>
                                        <label htmlFor="mobile" className="block mb-1 font-medium text-lg">Mobile Number: {driver.mobile ? driver.mobile: 'N/A'}</label>

                                    </div>

                                </div>
                                <div className="p-4 bg-white shadow">
                                    <div>
                                        <h3 className="mb-4 text-sm text-gray-400">Address</h3>
                                    </div>
                                    <div>
                                        <label htmlFor="addressLine1" className="block mb-1 font-medium text-lg">Address
                                            Line 1: {driver.addressLine1}</label>

                                    </div>
                                    <div>
                                        <label htmlFor="addressLine2" className="block mb-1 font-medium text-lg">Address
                                            Line 2: {driver.addressLine2}</label>

                                    </div>
                                    <div>
                                        <label htmlFor="addressLine3" className="block mb-1 font-medium text-lg">Address
                                            Line 3: {driver.addressLine3}</label>

                                    </div>
                                    <div>
                                        <label htmlFor="town"
                                               className="block mb-1 font-medium text-lg">Town: {driver.town}</label>

                                    </div>
                                    <div>
                                        <label htmlFor="county"
                                               className="block mb-1 font-medium text-lg">County: {driver.county}</label>

                                    </div>
                                    <div>
                                        <label htmlFor="postcode"
                                               className="block mb-1 font-medium text-lg">Postcode: {driver.postcode}</label>

                                    </div>
                                </div>
                            </div>
                        <div className="grid grid-cols-2 grid-flow-row gap-4 mt-2 mb-4">
                                <div className="p-4 mb-4 bg-white shadow">
                                    <div>
                                        <h3 className="mb-4 text-sm text-gray-400">Additional Info</h3>
                                    </div>
                                    <div>
                                        <label htmlFor="notes"
                                               className="block mb-1 font-medium text-lg">Notes: {driver.notes}</label>

                                    </div>
                                    <div>
                                        <label htmlFor="drivingLicenceNumber"
                                               className="block mb-1 font-medium text-lg">Driving Licence
                                            Number: {driver.drivingLicenceNumber}</label>

                                    </div>
                                    <div>
                                        <label htmlFor="nationalInsuranceNumber"
                                               className="block mb-1 font-medium text-lg">National Insurance
                                            Number: {driver.nationalInsuranceNumber}</label>

                                    </div>
                                    <div>
                                        <label htmlFor="trainingDetails" className="block mb-1 font-medium text-lg">Training
                                            Details: {driver.trainingDetails}</label>

                                    </div>
                                </div>
                                <div className="p-4 mb-4 bg-white shadow">
                                    <div>
                                        <h3 className="mb-4 text-sm text-gray-400">Routes</h3>
                                        <div>
                                            <div className="grid grid-flow-row grid-cols-3 gap-4 mt-2 mb-4">
                                            {driver.routes.map((route: DriverRoute) => {
                                                return (
                                                    <div className={"p-2 text-center rounded bg-white shadow"} key={route.id}>
                                                        <div className="m-3 flex flex-col justify-center">
                                                            <span>{route.routeName}</span>
                                                            <Link to={'/routes/'+route.routeId +'/view'} className="block my-1 mt-4 m-2 flex-1 text-white bg-gray-600 button">View Route</Link>
                                                        </div>
                                                    </div>
                                                            )
                                            })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
        </div>
    );
}
