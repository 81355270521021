import { useState, MouseEvent } from "react";
import { User } from './types';
import { Formik, Form, Field } from "formik";
import FormError from "../form/FormError";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Link, useHistory, useParams } from "react-router-dom";
import * as Yup from 'yup';
import { deleteUser, fetchUser, saveUser } from './service';
import { useEffect } from "react";
import Alert from "../alerts/Alert";
import ConfirmModal from "../modal/ConfirmModal";
import { Select as OrganisationSelect } from '../organisations/Select';
import { Organisation } from "../organisations/types";
import {useAppSelector} from "../../app/hooks";
import {selectUserPolicies} from "../auth/slice";
import {getPermissionsLevel, getPermissionsLevelForEntity, Permission} from "../policies/helpers";
import PermissionsList from "./PermissionsList";

export function Edit() {
    const [user, setUser] = useState<User>({
        firstName: '',
        lastName: '',
        email: '',
        enabled: true,
    } as User);

    const policies = useAppSelector(selectUserPolicies)
    const organisationPermissionsLevel = getPermissionsLevel('organisation', policies, undefined, true)
    const permissionsLevel = getPermissionsLevelForEntity(user.type ? user.type.toLowerCase() : 'user', user, policies)
    const orgPermissionsLevel = getPermissionsLevelForEntity('organisation', {id: user.organisationId}, policies)
    const [apiErrors, setApiErrors] = useState({});
    const [saving, setSaving] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const {id, organisationId } = useParams<{ id: string, organisationId: string }>();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [sendWelcome, setSendWelcome] = useState(false);

    useEffect(() => {
        if (!id) {
            setSendWelcome(true);
            return;
        }
        setSendWelcome(false);
        setLoading(true);
        fetchUser(id).then(({data}) => {
            setUser(data);
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
            console.error(error);
            history.goBack();
        });
    }, [id]);

    useEffect(() => {
        if (organisationId) {
            setUser({...user, organisationId: parseInt(organisationId)});
        }
    }, [])

    if (loading) {
        return (
            <div>Loading user...</div>
        );
    }

    function handleFormSubmit(values: any) {
        const userData = {
            ...user, ...values,
            id: parseInt(id),
            enabled: (values.enabled === 'true' || values.enabled === true),
            organisationId: user.organisationId,
            sendWelcome
        } as User;
        setApiErrors({});
        setSaving(true);
        setSuccessMessage('');
        setErrorMessage('');
        saveUser(userData).then(({data}) => {
            setUser(data);
            setSaving(false);
            setSuccessMessage('User saved successfully');
            window.setTimeout(() => {
                setSuccessMessage('');
            }, 5000);
            if (!id) {
                history.push('/users/' + data.id + '/edit');
            }
        }).catch(error => {
            setErrorMessage('Failed to save user, check for errors');
            setSaving(false);
            if (error.response) {
                setApiErrors(error.response.data);
            } else {
                console.error(error);
            }
        });
    }

    function handleDeleteClick(event: MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        setShowDelete(true);
    }

    function handleConfirmDelete() {
        setDeleting(true);
        deleteUser(user).then(() => {
            setDeleting(false);
            setSuccessMessage('User deleted successfully, redirecting...');
            setShowDelete(false);
            window.setTimeout(() => {
                history.push('/users');
            }, 3000);
        }).catch(error => {
            setErrorMessage('Failed to delete user, please try again');
            console.error(error);
            setShowDelete(false);
            setDeleting(false);
        });
    }

    function handleOrganisationChanged(organisation: Organisation | undefined) {
        if (organisation === undefined) {
            setUser({...user, organisationId: undefined});
            return;
        }
        setUser({...user, organisationId: organisation.id});
    }

    const validation = Yup.object().shape({
        firstName: Yup.string().label('First name').required().max(50),
        lastName: Yup.string().label('Last name').required().max(50),
        email: Yup.string().label('Email').email().required(),
    });

    return (
        <div>
            <div>
                <h1 className="text-lg">{user.id ? 'Edit' : 'New'} User</h1>
            </div>
            <Formik
                initialValues={{...user, password: ''}}
                onSubmit={handleFormSubmit}
                validationSchema={validation}
                validateOnBlur
            >
                {({errors, isValid, values}) => {
                    const allErrors = {...errors, ...apiErrors};

                    return (
                        <Form autoComplete="false">
                            <div className="grid grid-flow-row grid-cols-2 gap-4 mt-2 mb-4">
                                <div className="p-4 bg-white shadow">
                                    <div>
                                        <h3 className="mb-4 text-sm text-gray-400">Contact Info</h3>
                                    </div>
                                    <div>
                                        <label htmlFor="id" className="form-label">User ID:</label>
                                        <input disabled={permissionsLevel === Permission.Read} type="text" name="id"
                                               id="id"
                                               className="input-control" readOnly placeholder="####" value={values.id}/>
                                        {!values.id ? (
                                            <p className="mb-2 text-sm text-gray-400">This will be automatically
                                                assigned</p>
                                        ) : null}
                                        <FormError name="id" errors={allErrors}/>
                                    </div>
                                    <div className="flex">
                                        <div className="mr-2">
                                            <label htmlFor="firstName" className="form-label">Name:</label>
                                            <Field disabled={permissionsLevel === Permission.Read} type="text"
                                                   name="firstName"
                                                   id="firstName" className="input-control" placeholder="First name"/>
                                            <FormError name="firstName" errors={allErrors}/>
                                        </div>
                                        <div>
                                            <label htmlFor="lastName" className="form-label">&nbsp;</label>
                                            <Field disabled={permissionsLevel === Permission.Read} type="text"
                                                   name="lastName"
                                                   id="lastName" className="input-control" placeholder="Last name"/>
                                            <FormError name="lastName" errors={allErrors}/>
                                        </div>
                                    </div>
                                    <div>
                                        <label htmlFor="email" className="form-label">Email Address:</label>
                                        <Field disabled={permissionsLevel === Permission.Read} type="email" name="email"
                                               id="email"
                                               className="w-full input-control"/>
                                        <FormError name="email" errors={allErrors}/>
                                    </div>
                                    {organisationPermissionsLevel >= Permission.Read && <div>
                                        <label htmlFor="organisation" className="form-label">Organisation:</label>
                                        <OrganisationSelect disabled={permissionsLevel === Permission.Read}
                                                            value={user.organisationId}
                                                            resource={"user"}
                                                            onChange={handleOrganisationChanged}/>
                                    </div>}
                                    <div>
                                        <label className="form-label">Status:</label>
                                        <Field disabled={permissionsLevel === Permission.Read} as="select"
                                               name="enabled"
                                               className="input-control">
                                            <option value="true">Enabled</option>
                                            <option value="false">Disabled</option>
                                        </Field>
                                    </div>
                                    {!user.id ? (
                                        <div>
                                            <label className="form-label">Welcome User</label>
                                            <label>
                                                <input disabled={permissionsLevel === Permission.Read} type="checkbox"
                                                       checked={sendWelcome} onChange={e => {
                                                    setSendWelcome(e.target.checked)
                                                }} className="mr-1"/>
                                                <span>Send welcome email to user?</span>
                                            </label>
                                        </div>
                                    ) : null}
                                </div>
                                {orgPermissionsLevel >= Permission.Edit && <div className="p-4 bg-white shadow">
                                    <h3 className="mb-4 text-sm text-gray-400">Permissions</h3>
                                    <PermissionsList user={user} />
                                </div> }
                            </div>
                            {successMessage ? (<Alert message={successMessage} type="success"/>) : null}
                            {errorMessage ? (<Alert message={errorMessage} type="error"/>) : null}
                            <div className="p-4 mt-4 bg-white shadow">
                                <div className="flex">
                                    <Link to="/users" className="button">
                                        <FontAwesomeIcon icon={faArrowLeft} className="mr-2"/>
                                        Back to Users
                                    </Link>
                                    {permissionsLevel >= Permission.Edit &&
                                    <button className="ml-auto mr-2 bg-yellow-300 button" type="submit"
                                            disabled={saving || !isValid}>{saving ? 'Saving user...' : 'Save User'}</button>
                                    } {permissionsLevel >= Permission.Delete && user.id ? (
                                    <button className="text-red-400 button" disabled={saving}
                                            onClick={handleDeleteClick}>Delete User</button>
                                ) : null}
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
            {showDelete ? (
                <ConfirmModal title="Delete user" onClose={() => {
                    setShowDelete(false)
                }} onConfirm={handleConfirmDelete} disabled={deleting}>
                    Are you sure you want to delete this user?
                </ConfirmModal>
            ) : null}
        </div>
    );
}

export default Edit;