import { MediaFile } from "./types";
import ConfirmModal from '../modal/ConfirmModal';
import { MouseEvent, useState } from "react";
import { isMediaFileAnImage } from "./util";
import { deleteMediaFile } from './service';
import Alert from '../alerts/Alert';

export interface DeleteProps {
    mediaFile: MediaFile,
    onDelete?: CallableFunction,
}

export function Delete(props: DeleteProps) {
    const [showConfirm, setShowConfirm] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [error, setError] = useState('');

    function handleDeleteClick(event: MouseEvent) {
        event.preventDefault();
        setShowConfirm(true);
    }

    function handleDeleteConfirmed() {
        setDeleting(true);
        setError('');
        deleteMediaFile(props.mediaFile).then(() => {
            if (props.onDelete) {
                props.onDelete();
            }
            setShowConfirm(false);
        }).catch(err => {
            setError('Failed to delete, please try again');
        }).finally(() => {
            setDeleting(false);
        })
    }

    const label = isMediaFileAnImage(props.mediaFile) ? 'Image' : 'File';

    return (
        <>
            <button className="text-red-400 button" type="button" onClick={handleDeleteClick}>Delete {label}</button>
            {showConfirm ? (
                <ConfirmModal title={'Delete ' + label} onClose={() => setShowConfirm(false)} onConfirm={() => handleDeleteConfirmed()} disabled={deleting}>
                    {error ? (
                        <Alert message={error} type="error" />
                    ) : null}
                    <p>Are you sure you want to delete this {label}?</p>
                </ConfirmModal>
            ) : null}
        </>
    );
}

export default Delete;