import { Link, useHistory } from "react-router-dom";
import { faPlus, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FormEvent, useState } from "react";
import { AppNotification } from "./types";
import { useEffect } from "react";
import { fetchAppNotifications } from "./service";
import {formatDateTime} from '../dates/helper';
import Intro from './Intro';
import {useAppSelector} from "../../app/hooks";
import {selectUserPolicies} from "../auth/slice";
import {getPermissionsLevel, Permission} from "../policies/helpers";

export function Index() {
    const policies = useAppSelector(selectUserPolicies)
    const permissionsLevel = getPermissionsLevel('appNotification', policies)

    const [searchText, setSearchText] = useState('');
    const [loading, setLoading] = useState(false);
    const [appNotifications, setAppNotifications] = useState<AppNotification[]>([]);
    const history = useHistory();

    useEffect(() => {
        loadAppNotifications();
    }, []);

    function handleSearchSubmit(event: FormEvent) {
        event.preventDefault();
        loadAppNotifications();
    }

    function loadAppNotifications() {
        const params = {
            search: searchText,
        };
        setLoading(true);
        fetchAppNotifications(params).then(({ data }) => {
            setAppNotifications(data);
            setLoading(false);
        });
    }

    return (
        <div>
            <div>
                <h1 className="mb-2 text-lg">App Notifications</h1>
                <Intro />
            </div>
            <div className="p-2 my-4 bg-white rounded shadow">
                <div className="flex items-center">
                    <form action="" onSubmit={handleSearchSubmit} className="flex">
                        <div className="mr-2">
                            <input type="search" placeholder="Search..." value={searchText} onChange={(e) => { setSearchText(e.target.value) }} className="mb-0 input-control" />
                        </div>
                        <div>
                            <button className="bg-yellow-300 button" disabled={loading}>Search</button>
                        </div>
                    </form>
                    <div className="flex h-auto ml-auto">
                        <button className="mr-2 text-white bg-gray-600 button" type="button" onClick={(event) => { event.preventDefault(); loadAppNotifications(); }} disabled={loading}>
                            <FontAwesomeIcon icon={faSyncAlt} className={'mr-1 text-sm ' + (loading ? 'fa-spin' : '')} />
                            {loading ? 'Loading...' : 'Refresh'}
                        </button>
                        {permissionsLevel >= Permission.Create && <Link to="/appNotifications/new" className="bg-yellow-300 button">
                            <FontAwesomeIcon icon={faPlus} className="mr-1"/>
                            <span>New App Notification</span>
                        </Link>}
                    </div>
                </div>
            </div>
            <div className="p-4 mb-3 text-sm text-gray-600 bg-white shadow">
                <p className="mb-2 font-semibold">Sending bulk messages:</p>
                <ul className="ml-4 list-disc">
                    <li>To Passengers use checkbox selection in the passenger section</li>
                    <li>To Guardians use checkbox selection in the guardian section</li>
                    <li>To Passengers on routes use checkbox selection under reservations in the passenger section</li>
                </ul>
            </div>
            <div className="p-2 my-4 bg-white rounded shadow">
                {appNotifications.length && !loading ? (
                    <table className="table w-full table-striped">
                        <thead>
                            <th className="text-left">Title</th>
                            <th className="text-left">Send At</th>
                            <th className="text-left">Sent At</th>
                        </thead>
                        <tbody>
                            {appNotifications.map((appNotification) => (
                                <tr className="cursor-pointer" onClick={() => { history.push('/appNotifications/' + appNotification.id + '/edit') }}>
                                    <td>{appNotification.title}</td>
                                    <td>{formatDateTime(appNotification.sendAt)}</td>
                                    <td>{formatDateTime(appNotification.sentAt)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : null}
                {!appNotifications.length && !loading ? (
                    <p>No app notifications found</p>
                ) : null}
                {loading ? (
                    <p>Loading app notifications...</p>
                ) : null}
            </div>
        </div>
    );
}

export default Index;