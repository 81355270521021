import { Passenger } from "./types";
import { Formik, Field, Form, FormikHelpers } from 'formik';
import Alert from "../alerts/Alert";
import {useEffect, useState} from "react";
import FormError from "../form/FormError";
import * as Yup from 'yup';
import {registerPassenger} from "./service";
import {useHistory} from "react-router";
import {useLocation, useParams} from "react-router-dom";
import {ParamsWithId} from "../routing/types";
import {useAppSelector} from "../../app/hooks";
import {selectIsLoggedIn} from "../auth/slice";

const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    age: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    town: '',
    county: '',
    postcode: '',
    support: false,
    wheelchair: false,
    student: false,
    carers: [],
    cards:[],
    sendWelcome: true,
    password: ''
} as Passenger

export const Register = () => {
    const history = useHistory();
    const [saving, setSaving] = useState(false)
    const [apiErrors, setApiErrors] = useState({})
    const [errorMessage, setErrorMessage] = useState('')
    const {id} = useParams<ParamsWithId>()
    const location = useLocation();
    const isLoggedIn = useAppSelector(selectIsLoggedIn);

    interface LocationState {
        from?: Location,
    }

    useEffect(() => {
        if (isLoggedIn) {
            let from = '/';
            if (location.state) {
                const locState = location.state as LocationState;
                from = locState.from?.pathname || from;
            }
            history.replace(from);
        }
    }, [isLoggedIn, history]);
    const validation =
        Yup.object().shape({
            firstName: Yup.string().label("First name").required(),
            lastName: Yup.string().label("Last name").required(),
            notes: Yup.string().label("Notes").nullable(),
            age: Yup.string().label("Age").required(),
            email: Yup.string().label("Email").email().nullable(),
            postcode: Yup.string().label('Postcode').nullable().min(5).max(9),
        })


    const handleRegisterPassenger = (passenger: Passenger) => {
        setErrorMessage('');
        setApiErrors({});
        registerPassenger({passenger, id:parseInt(id)}).then(() => {
            history.push('/registration-complete')
        }).catch((error): any => {
            if (error.response && error.response.data) {
                setApiErrors(error.response.data)
            }
            setErrorMessage('This form has an error please check the fields')
            setSaving(false)
        });
    }

    const errorMessageHTML = errorMessage ? <Alert message={errorMessage} type="error" /> : null
    return (
        <div className="flex w-screen h-screen align-center">
            <div className="w-6/12  p-4 mx-auto my-auto bg-white rounded shadow-sm border-box">
            <h1 className="text-lg font-semibold text-center">Register Passenger</h1>
            <div>
                <Formik
                    initialValues={initialValues}
                    onSubmit={(
                        passenger: Passenger,
                        { setSubmitting }: FormikHelpers<Passenger>,
                    ) => {
                        setTimeout(() => {
                            setErrorMessage('');
                            setSaving(true);
                            handleRegisterPassenger(passenger);
                            setSubmitting(false);
                        }, 500);
                    }}
                    validationSchema={validation}
                    validateOnBlur
                >
                    {({ errors, isValid, values }) => {
                        const allErrors = { ...apiErrors, ...errors }
                        return (
                            <Form autoComplete="off">
                                <div className="grid grid-flow-row grid-cols-2 gap-4 mt-2 mb-4">
                                    <div className="p-4 bg-white shadow">
                                        <div>
                                            <h3 className="mb-4 text-lg text-gray-400">Passenger Info</h3>
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="firstName" className="form-label">First Name</label>
                                            <Field  className="input-control" id="firstName" name="firstName"
                                                   placeholder="John" />
                                            <FormError name="firstName" errors={allErrors} />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="lastName" className="form-label">Last Name</label>
                                            <Field  className="input-control" id="lastName" name="lastName"
                                                   placeholder="Doe" />
                                            <FormError name="lastName" errors={allErrors} />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="phone" className="form-label">Phone</label>
                                            <Field  className="input-control" id="phone" name="phone"
                                                   placeholder="Phone Number" />
                                            <FormError name="phone" errors={allErrors} />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="email" className="form-label">Email</label>
                                            <Field autocomplete="off" className="input-control" id="email" name="email"
                                                   placeholder="john@zwypeit.com" type="email" />
                                            <FormError name="email" errors={allErrors} />
                                        </div>
                                    </div>

                                    <div className="p-4 bg-white shadow">
                                        <div>
                                            <h3 className="mb-4 text-lg text-gray-400">Address</h3>
                                        </div>
                                        <div>
                                            <label htmlFor="addressLine1" className="form-label">Address Line 1:</label>
                                            <Field type="text" name="addressLine1" id="addressLine1"
                                                    className="input-control" />
                                            <FormError name="addressLine1" errors={allErrors} />
                                        </div>
                                        <div>
                                            <label htmlFor="addressLine2" className="form-label">Address Line 2:</label>
                                            <Field type="text" name="addressLine2" id="addressLine2"
                                                    className="input-control" />
                                            <FormError name="addressLine2" errors={allErrors} />
                                        </div>
                                        <div>
                                            <label htmlFor="addressLine3" className="form-label">Address Line 3:</label>
                                            <Field type="text" name="addressLine3" id="addressLine3"
                                                    className="input-control" />
                                            <FormError name="addressLine3" errors={allErrors} />
                                        </div>
                                        <div>
                                            <label htmlFor="town" className="form-label">Town:</label>
                                            <Field type="text" name="town" id="town"  className="input-control" />
                                            <FormError name="town" errors={allErrors} />
                                        </div>
                                        <div>
                                            <label htmlFor="county" className="form-label">County:</label>
                                            <Field type="text" name="county" id="county"  className="input-control" />
                                            <FormError name="county" errors={allErrors} />
                                        </div>
                                        <div>
                                            <label htmlFor="postcode" className="form-label">Postcode:</label>
                                            <Field type="text" name="postcode" id="postcode"
                                                   className="uppercase input-control" />
                                            <FormError name="postcode" errors={allErrors} />
                                        </div>
                                    </div>
                                    <div className="p-4 bg-white shadow">
                                        <div>
                                            <h3 className="mb-4 text-lg text-gray-400">Additional info</h3>
                                        </div>
                                        <div>
                                            <label htmlFor="age" className="form-label">Age</label>
                                            <Field as="select"
                                                   name="age" id="age"  className="input-control">
                                                <option value="">Select Age</option>
                                                <option value="-16">Under 16</option>
                                                <option value="16-18">16 - 18</option>
                                                <option value="18-24">18-24</option>
                                                <option value="25-34">25-34</option>
                                                <option value="35-44">35-44</option>
                                                <option value="45-54">45-54</option>
                                                <option value="55-64">55-64</option>
                                                <option value="65-70">65-70</option>
                                                <option value="70+">75+</option>

                                            </Field>
                                            <FormError name="age" errors={allErrors} />
                                        </div>
                                        <div>
                                            <label htmlFor="support" className="form-label">Do you require
                                                additional support?</label>
                                            <Field type="checkbox" name="support" id="support"
                                                    className="input-control" />
                                            <FormError name="support" errors={allErrors} />
                                        </div>
                                        <div>
                                            <label htmlFor="wheelchair" className="form-label">Do you require wheelchair
                                                access?</label>
                                            <Field type="checkbox"  name="wheelchair" id="wheelchair" className="input-control" />
                                            <FormError name="wheelchair" errors={allErrors} />
                                        </div>
                                        <div>
                                            <label htmlFor="student" className="form-label">Are you a Student</label>
                                            <Field type="checkbox" name="student" id="student"
                                                    className="input-control" />
                                            <FormError name="student" errors={allErrors} />
                                        </div>
                                    </div>
                                    <div className="p-4 bg-white shadow">
                                        <div>
                                        <label className="form-label">Welcome User</label>
                                            <Field type="checkbox"  name="sendWelcome" id="sendWelcome" className="input-control" />
                                            <span>Send welcome email to user?</span>
                                        </div>
                                        {!values.sendWelcome &&
                                            <div>
                                                <label htmlFor="student" className="form-label">Password</label>
                                                <Field type="password" name="password" id="password" autocomplete="off"
                                                       aria-label="Password" placeholder="Password"
                                                       className="input-control"/>
                                                <FormError name="password" errors={apiErrors}/>
                                            </div>}
                                    </div>
                                </div>
                                <div>
                                    {errorMessageHTML}
                                    <div className="flex flex-row py-2 mt-4 border-t-2">
                                        <div className="ml-auto">
                                            <button className="mr-2 bg-yellow-300 button" type="submit"
                                                    disabled={saving || !isValid}>Register</button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
            </div>
        </div>
    )
}
