import {AxiosRequestConfig, AxiosResponse} from "axios";
import {apiAxios as axios} from '../api/axios';
import {GetJourneyDropdownParams, Journey} from "./types";

export const fetchJourneyDropDown = (params: GetJourneyDropdownParams) :Promise<AxiosResponse> => {
    return axios.get('/journeys/dropdown/'+ params.routeId)
}
export const fetchReservations = (journeyId: number): Promise<AxiosResponse> => {
    return axios.get('/journeys/'+ journeyId +'/passengers')
}

export function saveJourney(journey: Journey): Promise<AxiosResponse>{
    if (journey.id) {
        return axios.put('/journeys/'+journey.id, journey);
    }
    return axios.post('/journeys', journey);
}

export function deleteJourney(journey: Journey): Promise<AxiosResponse> {
    return axios.delete('/journeys/'+journey.id);
}
export function getJourneyDataForReport(params: {journeyId: number})
{
    return axios.get('/journey/report', {params} as AxiosRequestConfig)
}
export function getJourneyDataExport(params: {journeyId: number})
{
    return axios.get('/journey/report/export', {params} as AxiosRequestConfig)
}



