import Modal from "../modal/Modal";
import React from "react";
import {ScheduleStop} from "../schedules/types";

interface Props {
    closeModal:CallableFunction
    stops: Array<ScheduleStop>
}
export const ShowStopsModal = (props: Props ) => {

    return (
        <Modal title={"Stops"} closeAction={props.closeModal}>
            <table className="table w-full table-striped">
                <thead>
                <tr>
                    <th className="text-left">Stop</th>
                    <th className="text-left">Active</th>
                </tr>
                </thead>
                <tbody>
                {props.stops.map(stop => {
                    return (
                        <tr key={stop.routeStop.stop.id}>
                            <td>{stop.routeStop.stop.name}</td>
                            <td>{!stop.disabled? 'Yes': 'No'}</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </Modal>
    );
}