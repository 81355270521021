import logo from '../../logo.png';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { logoutAction } from '../auth/actions';
import { selectIsLoggedIn, selectUserPolicies } from '../auth/slice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard, faBus, faInbox, faMapMarkerAlt, faDownload, faSignOutAlt, faArchive, faTachometerAlt, faUserFriends, faRoute, faMobileAlt, faViruses, faTicketAlt, faUpload, faTabletAlt, faBuilding, faBell, faUserCheck, faUsers } from '@fortawesome/free-solid-svg-icons';
import { bulkUploadPoliciesExist, getPermissionsLevel, Permission } from "../policies/helpers";

export default function Sidebar() {
    const dispatch = useAppDispatch();
    const isLoggedIn = useAppSelector(selectIsLoggedIn);
    const policies = useAppSelector(selectUserPolicies);

    const sessionPermissionLevel = getPermissionsLevel('session', policies, undefined, true);
    const routePermissionLevel = getPermissionsLevel('route', policies, undefined, true);
    const passengerPermissionLevel = getPermissionsLevel('passenger', policies, undefined, true);
    const carersPermissionLevel = getPermissionsLevel('carer', policies, undefined, true);
    const driverPermissionLevel = getPermissionsLevel('driver', policies, undefined, true);
    const vehiclePermissionLevel = getPermissionsLevel('vehicle', policies, undefined, true);
    const boxPermissionLevel = getPermissionsLevel('box', policies, undefined, true);
    const covidPermissionLevel = getPermissionsLevel('covid', policies, undefined, true);
    const appNotificationPermissionLevel = getPermissionsLevel('appNotification', policies, undefined, true);
    const organisationPermissionLevel = getPermissionsLevel('organisation', policies, undefined, true);
    const appPromoPermissionLevel = getPermissionsLevel('appPromo', policies, undefined, true);
    const userPermissionLevel = getPermissionsLevel('user', policies, undefined, true);
    const discountPermissionLevel = getPermissionsLevel('discount', policies, undefined, true);
    const readerPromoPermissionLevel = getPermissionsLevel('readerPromo', policies, undefined, true);
    const emailPermissionLevel = getPermissionsLevel('email', policies, undefined, true);

    const handleLogout = () => {
        dispatch(logoutAction());
    }

    if (!isLoggedIn) {
        return null;
    }

    return (
        <div className="flex flex-col w-2/12 min-h-screen pb-4 bg-gray-600 shadow">
            <div className="p-2 text-center bg-white">
                <Link to="/" >
                    <img src={logo} alt="Zywpeit Logo" className="h-10 m-auto" />
                </Link>
            </div>
            <div>
                <Link to="/" className="block w-full p-3 font-medium text-white transition-all hover:bg-gray-700">
                    <span className="inline-block w-3 mr-2"><FontAwesomeIcon icon={faTachometerAlt} className="text-sm" /></span> Dashboard
                </Link>
            </div>
            {sessionPermissionLevel >= Permission.Read &&
                <div>
                    <Link to="/sessions"
                        className="block w-full p-3 font-medium text-white transition-all hover:bg-gray-700">
                        <span className="inline-block w-3 mr-2"><FontAwesomeIcon icon={faRoute} className="text-sm" /></span>Today's
                        Routes
                    </Link>
                </div>
            }
            {vehiclePermissionLevel >= Permission.Read && <div>
                <Link to="/vehicles"
                    className="block w-full p-3 font-medium text-white transition-all hover:bg-gray-700">
                    <span className="inline-block w-3 mr-2"><FontAwesomeIcon icon={faBus}
                        className="text-sm" /></span> Vehicles
                </Link>
            </div>}
            {passengerPermissionLevel >= Permission.Read && <div>
                <Link to="/passengers"
                    className="block w-full p-3 font-medium text-white transition-all hover:bg-gray-700">
                    <span className="inline-block w-3 mr-2"><FontAwesomeIcon icon={faUserFriends} className="text-sm" /></span> Passengers
                </Link>
            </div>}
            {carersPermissionLevel >= Permission.Read && <div>
                <Link to="/guardians"
                    className="block w-full p-3 font-medium text-white transition-all hover:bg-gray-700">
                    <span className="inline-block w-3 mr-2"><FontAwesomeIcon icon={faUserFriends} className="text-sm" /></span> Guardians
                </Link>
            </div>}
            {routePermissionLevel >= Permission.Read && <div>
                <Link to="/routes" className="block w-full p-3 font-medium text-white transition-all hover:bg-gray-700">
                    <span className="inline-block w-3 mr-2"><FontAwesomeIcon icon={faMapMarkerAlt} className="text-sm" /></span> Routes
                </Link>
            </div>}
            {driverPermissionLevel >= Permission.Read && <div>
                <Link to="/drivers"
                    className="block w-full p-3 font-medium text-white transition-all hover:bg-gray-700">
                    <span className="inline-block w-3 mr-2"><FontAwesomeIcon icon={faAddressCard} className="text-sm" /></span> Drivers
                </Link>
            </div>}
            {boxPermissionLevel >= Permission.Read && <div>
                <Link to="/devices"
                    className="block w-full p-3 font-medium text-white transition-all hover:bg-gray-700">
                    <span className="inline-block w-3 mr-2"><FontAwesomeIcon icon={faMobileAlt}
                        className="text-sm" /></span> Devices
                </Link>
            </div>}

            {covidPermissionLevel >= Permission.Read && <div>
                <Link to="/covid" className="block w-full p-3 font-medium text-white transition-all hover:bg-gray-700">
                    <span className="inline-block w-3 mr-2"><FontAwesomeIcon icon={faViruses}
                        className="text-sm" /></span> Covid Tests Info
                </Link>
            </div>}
            {sessionPermissionLevel >= Permission.Read && <div>
                <Link to="/reports"
                    className="block w-full p-3 font-medium text-white transition-all hover:bg-gray-700">
                    <span className="inline-block w-3 mr-2"><FontAwesomeIcon icon={faArchive}
                        className="text-sm" /></span>Reports
                </Link>
            </div>}
            {bulkUploadPoliciesExist(policies) && <div>
                <Link to="/upload" className="block w-full p-3 font-medium text-white transition-all hover:bg-gray-700">
                    <span className="inline-block w-3 mr-2"><FontAwesomeIcon icon={faUpload}
                        className="text-sm" /></span> Bulk Upload
                </Link>
            </div>}
            {discountPermissionLevel >= Permission.Read && <div>
                <Link to="/discounts"
                    className="block w-full p-3 font-medium text-white transition-all hover:bg-gray-700">
                    <span className="inline-block w-3 mr-2"><FontAwesomeIcon icon={faTicketAlt}
                        className="text-sm" /></span> Discounts
                </Link>
            </div>}
            {readerPromoPermissionLevel >= Permission.Read && <div>
                <Link to="/readerPromos"
                    className="block w-full p-3 font-medium text-white transition-all hover:bg-gray-700">
                    <span className="inline-block w-3 mr-2"><FontAwesomeIcon icon={faTabletAlt}
                        className="text-sm" /></span> Reader
                    Promotions
                </Link>
            </div>}
            {appPromoPermissionLevel >= Permission.Read && <div>
                <Link to="/appPromos"
                    className="block w-full p-3 font-medium text-white transition-all hover:bg-gray-700">
                    <span className="inline-block w-3 mr-2"><FontAwesomeIcon icon={faMobileAlt}
                        className="text-sm" /></span> App Promotions
                </Link>
            </div>}
            {appNotificationPermissionLevel >= Permission.Read && <div>
                <Link to="/appNotifications"
                    className="block w-full p-3 font-medium text-white transition-all hover:bg-gray-700">
                    <span className="inline-block w-3 mr-2"><FontAwesomeIcon icon={faBell}
                        className="text-sm" /></span> App
                    Notifications
                </Link>
            </div>}
            {emailPermissionLevel >= Permission.Read && <div>
                <Link to="/messages"
                      className="block w-full p-3 font-medium text-white transition-all hover:bg-gray-700">
                    <span className="inline-block w-3 mr-2"><FontAwesomeIcon icon={faInbox}
                                                                             className="text-sm" /></span> Help Requests
                </Link>
            </div>}
            {userPermissionLevel >= Permission.Read && <div>
                <Link to="/userLogs"
                    className="block w-full p-3 font-medium text-white transition-all hover:bg-gray-700">
                    <span className="inline-block w-3 mr-2"><FontAwesomeIcon icon={faUserCheck}
                        className="text-sm" /></span> User Logs
                </Link>
            </div>}
            {userPermissionLevel >= Permission.Delete && <div>
                <Link to="/users" className="block w-full p-3 font-medium text-white transition-all hover:bg-gray-700">
                    <span className="inline-block w-3 mr-2"><FontAwesomeIcon icon={faUsers} className="text-sm" /></span> Users
                </Link>
            </div>}
            {organisationPermissionLevel >= Permission.Edit && <div>
                <Link to="/organisations"
                    className="block w-full p-3 font-medium text-white transition-all hover:bg-gray-700">
                    <span className="inline-block w-3 mr-2"><FontAwesomeIcon icon={faBuilding}
                        className="text-sm" /></span> Organisations
                </Link>
            </div>}
            {organisationPermissionLevel >= Permission.Edit && <div>
                <Link to="/export"
                      className="block w-full p-3 font-medium text-white transition-all hover:bg-gray-700">
                    <span className="inline-block w-3 mr-2"><FontAwesomeIcon icon={faDownload}
                                                                             className="text-sm" /></span> Reporting Exports
                </Link>
            </div>}
            <button onClick={() => { handleLogout() }} className="block w-full p-3 mt-auto font-medium text-left text-red-400 transition-all hover:bg-gray-700">
                <span className="inline-block w-3 mr-2"><FontAwesomeIcon icon={faSignOutAlt} className="text-sm" /></span> Logout
            </button>
        </div>
    );
}
