import { FormEvent, useState } from 'react';
import logo from '../../logo.png';
import Alert from '../alerts/Alert';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { requestForgotPassword } from './service';
import FormError from '../form/FormError';

export function ForgotPassword() {
    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [apiErrors, setApiErrors] = useState({});

    function submit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        if (loading || success) {
            return;
        }
        setErrorMessage('');
        setLoading(true);
        requestForgotPassword({ username }).then(() => {
            setSuccess(true);
            setLoading(false);
        }).catch(err => {
            setErrorMessage('There was a problem, please try again');
            if (err.response.data) {
                setApiErrors(err.response.data);
            }
            if (err.response.data && err.response.data.message) {
                setErrorMessage(err.response.data.message);
            }
            setLoading(false);
        })
    }

    return (
        <div className="flex justify-center w-screen h-screen align-center">
            <div className="w-11/12 max-w-sm p-4 mx-auto my-auto text-center bg-white rounded shadow-sm border-box">
                <img src={logo} alt="Zywepit Logo" className="px-1 mx-auto" />
                <h2 className="mb-2 font-medium text-md">Forgot Password</h2>
                <p className="mb-2 text-sm text-gray-500">If you have forgotten your password, we can send you an email with a link to reset it</p>
                <form action="" onSubmit={submit} className="flex flex-col" method="POST">
                    <input type="text" name="username" required id="username" aria-label="Username" placeholder="Username"
                        autoFocus className="input-control" value={username} onChange={(e) => { setUsername(e.target.value) }} disabled={loading || success} />
                    <FormError errors={apiErrors} name="username" />
                    <Alert type="error" message={errorMessage} />
                    {success ? (
                        <div className="py-2">
                            <Alert type="success" message="Check your inbox for a link to reset your password" />
                        </div>
                    ) : (
                        <div className="py-2">
                            {
                                loading ? (
                                    <button className="bg-gray-300 button" disabled>Submitting...</button>
                                ) : (
                                    <button className="bg-yellow-300 button">Reset Password</button>
                                )}
                        </div>
                    )}
                    <div className="py-1 text-sm">
                        <Link to="/login"><FontAwesomeIcon icon={faArrowLeft} /> Back to Login</Link>
                    </div>
                </form>
            </div >
        </div >
    );
}

export default ForgotPassword;