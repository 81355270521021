import React, {useEffect, useState} from "react";
import {DiscountGroup, DiscountGroups} from "./types";
import {deleteDiscountGroup, fetchDiscountGroups} from "./service";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faSyncAlt} from "@fortawesome/free-solid-svg-icons";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {setErrorNotificationText, setSuccessNotificationText} from "../notification/slice";
import ConfirmModal from "../modal/ConfirmModal";
import {AddGroup} from "./AddGroup";
import {getPermissionsLevel, getPermissionsLevelForEntity, Permission} from "../policies/helpers";
import {selectUserPolicies} from "../auth/slice";

export const Groups = () => {
    const policies = useAppSelector(selectUserPolicies)
    const permissionLevel = getPermissionsLevel('discountGroup', policies)
    const [discountGroups, setDiscountGroups] = useState<DiscountGroups>();
    const [addDiscountGroup, setAddDiscountGroup] = useState(false)
    const [deleteGroup, setDeleteGroup] = useState<DiscountGroup>()
    const [loading, setLoading] = useState(false)
    const dispatch = useAppDispatch();

    useEffect(() => {
       handleGetDiscountGroups()
    }, [])

    const handleGetDiscountGroups = () => {
        setLoading(true)
        fetchDiscountGroups().then((res) => {
            setDiscountGroups(res.data)
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleDeleteDiscountGroup = (discountGroup: DiscountGroup) => {
        if (discountGroup.id) {
            deleteDiscountGroup(discountGroup.id).then(() => {
                dispatch(setSuccessNotificationText("Discount group deleted successfully"))
            }).catch(() => {
                dispatch(setErrorNotificationText("Discount group not deleted"))
            }).finally(() => {
                setDeleteGroup(undefined)
                handleGetDiscountGroups()
            })
        }
    }

    return (
        <div>
            <div className="p-2 my-4 bg-white rounded shadow">
                <div className="flex items-center">
                    <div>
                        <h1 className="text-lg">Discount Groups</h1>
                    </div>

                    <div className="flex h-auto ml-auto">
                        <button className="mr-2 text-white bg-gray-600 button" type="button" onClick={(event) => { event.preventDefault(); handleGetDiscountGroups(); }} disabled={loading}>
                            <FontAwesomeIcon icon={faSyncAlt} className={'mr-1 text-sm ' + (loading ? 'fa-spin' : '')} />
                            {loading ? 'Loading...' : 'Refresh'}
                        </button>
                        {permissionLevel >= Permission.Create && <button onClick={() => {
                            setAddDiscountGroup(true)
                        }} className="bg-yellow-300 button">
                            <FontAwesomeIcon icon={faPlus} className="mr-1"/>
                            <span>New Discount Group</span>
                        </button>}
                    </div>
                </div>
            </div>
            {permissionLevel >= Permission.Delete && <div className="p-4 mb-3 bg-white shadow">
                <h3 className={"mb-2 text-center  "}><span
                    className={"font-bold"}>Note: </span>
                    <span className={"font-semibold"}>You can't delete groups which are attached to discounts</span>
                </h3>
            </div>}
            {discountGroups && discountGroups.length === 0 && <span>
                No discount groups please add one.
            </span>}
                {discountGroups && discountGroups.length > 0 && !loading &&
                <div className="p-2 my-4 bg-white rounded shadow">
                <table className="table w-full table-striped">
                        <thead>
                        <th className="text-left">Name</th>
                        <th className="text-left"> </th>
                        </thead>
                        <tbody>
                {discountGroups && discountGroups.map(discountGroup => {
                    const PermLevelEntity = getPermissionsLevelForEntity('discountGroup', discountGroup, policies)
                    return (
                    <tr>
                        <td>{discountGroup.name}</td>
                        <td>{PermLevelEntity >= Permission.Delete && !discountGroup?.hasDiscounts && <button onClick={() => setDeleteGroup(discountGroup)} className={"text-red-400 font-semibold"}>Delete</button>}</td>
                    </tr>
                    )})
                }
                        </tbody>
                </table>
                </div>
                }
            {deleteGroup && <ConfirmModal onClose={() => setDeleteGroup(undefined)} onConfirm={() => {handleDeleteDiscountGroup(deleteGroup)}} title={"Delete Discount Group"}>
                Are you sure you would like to delete group {deleteGroup.name}?
            </ConfirmModal>}
            {addDiscountGroup && <AddGroup onClose={() => {setAddDiscountGroup(false); handleGetDiscountGroups()}}/>}
        </div>
    )
}