import { useEffect, useState } from "react";
import { todaysSessions } from "./service";
import { RouteSession, RouteSessions } from "./types";
import { getFormattedDateTimeFromString } from "../dates/helper";
import { selectLiveRouteSession, setLiveRouteSession } from "./slice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { LiveRouteModal } from "./LiveRouteModal";

export const Index = () => {

    const dispatch = useAppDispatch();
    const [liveRouteMapSession, setLiveRouteMapSession] = useState<RouteSession>();
    const [routeSessions, setRouteSessions] = useState<RouteSessions>([])
    const liveRouteSession = useAppSelector(selectLiveRouteSession)
    const [search, setSearch] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        handleGetRouteSessions();
    }, [liveRouteSession])

    const handleGetRouteSessions = () => {
        setLoading(true)
        const params = {
            search,
        };
        todaysSessions(params).then((res) => {
            const data = res.data as RouteSessions
            setRouteSessions(data)
        }).finally(() => {
            setLoading(false)
        })
    }
    const closeLiveMap = () => {
        setLiveRouteMapSession(undefined);
    }

    return (<div className="p-3 rounded shadow bg-gray-50">
        <div className="mb-4">
            <h1 className="text-lg">Today's Routes</h1>
        </div>

        <div className="flex px-4 py-2 mb-2 bg-white shadow">
            <form action="" onSubmit={(event) => { event.preventDefault(); handleGetRouteSessions(); }} className="flex items-center mr-auto">
                <div>
                    <input type="search" value={search} onChange={(event => setSearch(event.target.value))} placeholder="Search..." className="mb-0 mr-2 input-control" />
                </div>
                <div>
                    <button className="bg-yellow-300 button" disabled={loading}>Search</button>
                </div>
            </form>
            <div className="ml-auto">
                <button className="mr-2 text-white bg-gray-600 button" type="button" onClick={(event) => { event.preventDefault(); handleGetRouteSessions(); }} disabled={loading}>
                    <FontAwesomeIcon icon={faSyncAlt} className={'mr-1 text-sm ' + (loading ? 'fa-spin' : '')} />
                    {loading ? 'Loading...' : 'Refresh'}
                </button>
            </div>
        </div>
        {liveRouteMapSession && <LiveRouteModal closeModal={() => {
            closeLiveMap()
        }} routeSession={liveRouteMapSession} />}
        <div className="p-4 bg-white shadow">
            <table className="table w-full table-striped">
                <thead>
                    <tr>
                        <th>Route Name</th>
                        <th>Status</th>
                        <th>Started</th>
                        <th>Ended</th>
                        <th>Duration</th>
                        <th>Driver</th>
                        <th>No. Stops</th>
                        <th>Capacity</th>
                        <th>On Route</th>
                        <th>Checked In</th>
                        <th>Checked Out</th>
                    </tr>
                </thead>
                <tbody>
                    {routeSessions.map(routeSession => {
                        const live = !routeSession.endDate
                        const liveClassName = routeSession.endDate ? 'text-red-700' : 'text-green-700';
                        const checkedInPassengers = routeSession.sessionPassengers.filter(passenger => !passenger.checkedOut)
                        const checkedOutPassengers = routeSession.sessionPassengers.filter(passenger => !!passenger.checkedOut)
                        return (
                            <tr key={routeSession.id}>
                                <td className="text-left">
                                    <button type="button" title="View route map" className="cursor-pointer" onClick={() => setLiveRouteMapSession(routeSession)}>{routeSession.route.name}</button>
                                </td>
                                <td className="text-center">
                                    <button type="button" title="View route map" className={`${liveClassName} cursor-pointer`} onClick={() => setLiveRouteMapSession(routeSession)}>{live ? 'Active' : 'Inactive'}</button>
                                </td>
                                <td className="text-center">{routeSession.startDate ? getFormattedDateTimeFromString(routeSession.startDate) : '-'}</td>
                                <td className="text-center">{routeSession.endDate ? getFormattedDateTimeFromString(routeSession.endDate) : '-'}</td>
                                <td className="text-center">{routeSession.schedule.duration ? `${routeSession.schedule.duration} mins` : '-'}</td>
                                <td className="text-center">{routeSession.driver?.firstName ? `${routeSession.driver.firstName} ${routeSession.driver.lastName}` : '-'}</td>
                                <td className="text-center">{routeSession.schedule.scheduleStops ? routeSession.schedule.scheduleStops.length : '-'}</td>
                                <td className="text-center">{routeSession.route.capacity ? routeSession.route.capacity : '-'}</td>
                                <td className="text-center">
                                    <button title="View passenger list" className="text-yellow-500 underline cursor-pointer" type="button" onClick={() => { dispatch(setLiveRouteSession(routeSession)) }}>{checkedInPassengers ? checkedInPassengers.length : '-'}</button>
                                </td>
                                <td className="text-center">
                                    <button title="View passenger list" className="text-yellow-500 underline cursor-pointer" type="button" onClick={() => { dispatch(setLiveRouteSession(routeSession)) }}>{routeSession.sessionPassengers ? routeSession.sessionPassengers.length : '-'}</button>
                                </td>
                                <td className="text-center">
                                    <button title="View passenger list" className="text-yellow-500 underline cursor-pointer" type="button" onClick={() => { dispatch(setLiveRouteSession(routeSession)) }}>{checkedOutPassengers ? checkedOutPassengers.length : '-'}</button>
                                </td>
                                <td className="text-center">
                                    <button title="View Map" className="text-yellow-500 underline cursor-pointer" type="button" onClick={() => setLiveRouteMapSession(routeSession)}>View Map</button>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    </div >)
}