import { Link, useHistory } from "react-router-dom";
import { faPlus, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormEvent, useState } from "react";
import { AppPromo } from "./types";
import { useEffect } from "react";
import { fetchAppPromos } from "./service";
import Intro from './Intro';
import {selectUserPolicies} from "../auth/slice";
import {useAppSelector} from "../../app/hooks";
import {getPermissionsLevel, Permission} from "../policies/helpers";
import {formatDateTime} from "../dates/helper";

export function Index() {
    const policies = useAppSelector(selectUserPolicies)
    const permissionsLevel = getPermissionsLevel('appPromo' ,policies)
    const [searchText, setSearchText] = useState('');
    const [loading, setLoading] = useState(false);
    const [appPromos, setAppPromos] = useState<AppPromo[]>([]);
    const history = useHistory();

    useEffect(() => {
        loadAppPromos();
    }, []);

    function handleSearchSubmit(event: FormEvent) {
        event.preventDefault();
        loadAppPromos();
    }

    function loadAppPromos() {
        const params = {
            search: searchText,
        };
        setLoading(true);
        fetchAppPromos(params).then(({ data }) => {
            setAppPromos(data);
            setLoading(false);
        });
    }

    return (
        <div>
            <div>
                <h1 className="mb-2 text-lg">App Promotions</h1>
                <Intro />
            </div>
            <div className="p-2 my-4 bg-white rounded shadow">
                <div className="flex items-center">
                    <form action="" onSubmit={handleSearchSubmit} className="flex">
                        <div className="mr-2">
                            <input type="search" placeholder="Search..." value={searchText} onChange={(e) => { setSearchText(e.target.value) }} className="mb-0 input-control" />
                        </div>
                        <div>
                            <button className="bg-yellow-300 button" disabled={loading}>Search</button>
                        </div>
                    </form>
                    <div className="flex h-auto ml-auto">
                        <button className="mr-2 text-white bg-gray-600 button" type="button" onClick={(event) => { event.preventDefault(); loadAppPromos(); }} disabled={loading}>
                            <FontAwesomeIcon icon={faSyncAlt} className={'mr-1 text-sm ' + (loading ? 'fa-spin' : '')} />
                            {loading ? 'Loading...' : 'Refresh'}
                        </button>
                        {permissionsLevel >= Permission.Create && <Link to="/appPromos/new" className="bg-yellow-300 button">
                            <FontAwesomeIcon icon={faPlus} className="mr-1"/>
                            <span>New App Promotion</span>
                        </Link>}
                    </div>
                </div>
            </div>
            <div className="p-2 my-4 bg-white rounded shadow">
                {appPromos.length && !loading ? (
                    <table className="table w-full table-striped">
                        <thead>
                            <th className="text-left">Title</th>
                            <th>Banner</th>
                            <th className="text-left">Start Date</th>
                            <th className="text-left">End Date</th>
                        </thead>
                        <tbody>
                            {appPromos.map((appPromo) => (
                                <tr className="cursor-pointer" onClick={() => { history.push('/appPromos/' + appPromo.id + '/edit') }}>
                                    <td>{appPromo.title}</td>
                                    <td className="text-center">{appPromo.isBanner ? 'Y' : 'N'}</td>
                                    <td>{formatDateTime(appPromo.startDate)}</td>
                                    <td>{formatDateTime(appPromo.endDate)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : null}
                {!appPromos.length && !loading ? (
                    <p>No app promotions found</p>
                ) : null}
                {loading ? (
                    <p>Loading app promotions...</p>
                ) : null}
            </div>
        </div>
    );
}

export default Index;