import Dialog from "../dialog/Dialog";

import {useState} from "react";
import Alert from "../alerts/Alert";
import {BulkDeleteReservationProps, BulkDeleteReservationRequestBody} from "../passengers/types";
import {deleteReservations} from "../passengers/service";
import {useAppDispatch} from "../../app/hooks";
import {setSuccessNotificationText} from "../notification/slice";



export const ReservationDeleteDialog = (props: BulkDeleteReservationProps) => {

    const [deleting, setDeleting] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const dispatch = useAppDispatch();

    const passengerNames = props.passengers.map(p => p.firstName+' '+p.lastName).join(', ');

    const handleDeleteReservation = () => {
        setDeleting(true)
        const deleteReservationRequestBody:BulkDeleteReservationRequestBody = {
            passengers:props.passengers,
            journeyId:props.journeyDD.id,
        }
        deleteReservations(deleteReservationRequestBody).then(() => {
            dispatch(setSuccessNotificationText('Reservation deleted for passengers: '+ passengerNames))
            setDeleting(false)
            props.closeFunction()
        }).catch(() => {
            setErrorMessage('Error deleting reservation please try again')
            setDeleting(false)
        })
    }

    return (
        <Dialog  title="Delete Reservations" confirmFunction={handleDeleteReservation} cancelFunction={props.closeFunction} disabled={deleting} confirmText={deleting? 'Deleting...' : 'Delete'}>
                <p> Are you sure you want to delete reservations on journey <strong>{props.journeyDD.value}</strong> for passengers: <strong> {props.passengers.map(p => p.firstName+' '+p.lastName).join(', ')}</strong>?</p>
                <Alert message={errorMessage} type="error" />
        </Dialog>
)
}
