import { Passenger } from "./types";
import { Formik, Field, Form, FormikHelpers, FieldArray } from 'formik';
import Alert from "../alerts/Alert";
import { useEffect, useState } from "react";
import FormError from "../form/FormError";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setDeletePassenger } from "./slice";
import { selectDeleteCard, setDeleteCard } from "../cards/slice";
import * as Yup from 'yup';
import { setSuccessNotificationText } from "../notification/slice";
import { fetchPassenger, savePassenger as apiSavePassenger } from "./service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faIdCard } from "@fortawesome/free-solid-svg-icons";
import { Link, useParams } from "react-router-dom";
import { ParamsWithId } from "../routing/types";
import { useHistory } from "react-router";
import Preview from "../media/Preview";
import Delete from "../media/Delete";
import { selectUserPolicies } from "../auth/slice";
import { getPermissionsLevelForEntity, Permission } from "../policies/helpers";
import CarersForm from "./CarersForm";


export const EditAdd = () => {
    const policies = useAppSelector(selectUserPolicies)

    const [passenger, setPassenger] = useState<Passenger>({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        notes: '',
        cards: [],
        age: '',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        town: '',
        county: '',
        postcode: '',
        support: false,
        wheelchair: false,
        student: false,
        image: undefined,
        carers: [],
    } as Passenger);

    const deletingCard = useAppSelector(selectDeleteCard)
    const [loading, setLoading] = useState(false)
    const { id } = useParams<ParamsWithId>();
    const history = useHistory();

    useEffect(() => {
        if (!id || deletingCard) {
            return
        }
        handleGetPassenger()
    }, [id, deletingCard]);

    const handleGetPassenger = () => {
        setLoading(true);
        const idNumber = parseInt(id)
        fetchPassenger(idNumber).then(({ data }) => {
            const retrievedPassenger = data as Passenger
            const keys = Object.keys(retrievedPassenger) as Array<keyof typeof retrievedPassenger>
            keys.forEach(function (key) {
                if (retrievedPassenger[key] == null) {
                    // @ts-ignore
                    retrievedPassenger[key] = undefined;
                }
            })
            setPassenger(retrievedPassenger);
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
            console.error(error);
            history.goBack();
        });
    }
    const [saving, setSaving] = useState(false)
    const [apiErrors, setApiErrors] = useState({})
    const [errorMessage, setErrorMessage] = useState('')
    const [mounted, setMounted] = useState(false);
    const dispatch = useAppDispatch();

    useEffect(() => {
        setMounted(true);
    }, [mounted, dispatch])

    const deleteCard = (cardId: number) => {
        const deleteCard = passenger.cards.filter(card => card.id === cardId)[0];
        dispatch(setDeleteCard(deleteCard))
    }


    const validation =
        Yup.object().shape({
            firstName: Yup.string().label("First name").required(),
            lastName: Yup.string().label("Last name").required(),
            notes: Yup.string().label("Notes").nullable(),
            age: Yup.string().label("Age").required(),
            email: Yup.string().label("Email").email().nullable(),
            postcode: Yup.string().label('Postcode').nullable().min(5).max(9),
            cardNumber: Yup.string().label("Card number").min(8, 'Card number too short')
        })


    const handleSavePassenger = (editedPassenger: Passenger) => {
        setErrorMessage('');
        setApiErrors({});
        const passengerData = { ...editedPassenger, carers: passenger.carers } as Passenger;
        apiSavePassenger(passengerData).then((response) => {
            const savedPassenger = response.data as Passenger
            dispatch(setSuccessNotificationText('Passenger ' + savedPassenger.firstName + ' ' + savedPassenger.lastName + ' saved successfully'))
            setSaving(false);
            if (savedPassenger.id && history.location.pathname.includes('new')) {
                history.push('/passengers/' + savedPassenger.id + '/photo')
            } else {
                history.push('/passengers')
            }
        }).catch((error): any => {
            if (error.response && error.response.data) {
                setApiErrors(error.response.data)
            }
            setErrorMessage('This form has an error please check the fields')
            setSaving(false)
        });
    }

    const handleDeletePassenger = (deletePassenger: Passenger) => {
        dispatch(setDeletePassenger(deletePassenger))
    }

    if (loading || !passenger) {
        return (
            <div>Loading passenger...</div>
        );
    }

    const permissionsLevel = getPermissionsLevelForEntity('passenger', passenger, policies)

    const errorMessageHTML = errorMessage ? <Alert message={errorMessage} type="error" /> : null
    return (
        <>
            <h1 className="text-lg">{passenger.id ? 'Edit' : 'New'} Passenger</h1>
            <div>
                <Formik
                    initialValues={{ ...passenger }}
                    onSubmit={(
                        editedPassenger: Passenger,
                        { setSubmitting }: FormikHelpers<Passenger>,
                    ) => {
                        setTimeout(() => {
                            setErrorMessage('');
                            setSaving(true);
                            handleSavePassenger(editedPassenger);
                            setSubmitting(false);
                        }, 500);
                    }}
                    validationSchema={validation}
                    validateOnBlur
                >
                    {({ values, errors, isValid }) => {
                        const allErrors = { ...apiErrors, ...errors }
                        return (
                            <Form>
                                <div className="grid grid-flow-row grid-cols-2 gap-4 mt-2 mb-4">
                                    <div className="p-4 bg-white shadow">
                                        <div>
                                            <h3 className="mb-4 text-lg text-gray-400">Passenger Info</h3>
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="firstName" className="form-label">First Name</label>
                                            <Field disabled={permissionsLevel === Permission.Read} className="input-control" id="firstName" name="firstName"
                                                placeholder="John" />
                                            <FormError name="firstName" errors={allErrors} />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="lastName" className="form-label">Last Name</label>
                                            <Field disabled={permissionsLevel === Permission.Read} className="input-control" id="lastName" name="lastName"
                                                placeholder="Doe" />
                                            <FormError name="lastName" errors={allErrors} />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="notes" className="form-label">Notes</label>
                                            <Field className="w-full input-control" disabled={permissionsLevel === Permission.Read} id="notes" name="notes"
                                                as="textarea" placeholder="Enter notes here..." />
                                            <FormError name="notes" errors={allErrors} />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="phone" className="form-label">Phone</label>
                                            <Field disabled={permissionsLevel === Permission.Read} className="input-control" id="phone" name="phone"
                                                placeholder="Phone Number" />
                                            <FormError name="phone" errors={allErrors} />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="email" className="form-label">Email</label>
                                            <Field disabled={permissionsLevel === Permission.Read} className="input-control" id="email" name="email"
                                                placeholder="john@zwypeit.com" type="email" />
                                            <FormError name="email" errors={allErrors} />
                                        </div>
                                        {passenger.id && <>
                                            <label htmlFor="photo" className="form-label">Photo</label>
                                            {passenger.image &&
                                                <>
                                                    <div>
                                                        <Preview mediaFile={passenger.image} />
                                                    </div>
                                                    <div>
                                                        <Delete mediaFile={passenger.image} onDelete={() => {
                                                            dispatch(setSuccessNotificationText('Photo Deleted'))
                                                            handleGetPassenger()
                                                        }} />
                                                    </div>
                                                </>
                                            }
                                            {permissionsLevel >= Permission.Edit && <Link to={"/passengers/" + passenger.id + "/photo"}>
                                                <button
                                                    type="button"
                                                    className="text-white bg-gray-600 button">
                                                    {passenger.image ? 'Change Photo' : 'Add Photo'}
                                                </button>
                                            </Link>}
                                        </>}
                                    </div>

                                    <div className="p-4 bg-white shadow">
                                        <div>
                                            <h3 className="mb-4 text-lg text-gray-400">Address</h3>
                                        </div>
                                        <div>
                                            <label htmlFor="addressLine1" className="form-label">Address Line 1:</label>
                                            <Field type="text" name="addressLine1" id="addressLine1"
                                                disabled={permissionsLevel === Permission.Read} className="input-control" />
                                            <FormError name="addressLine1" errors={allErrors} />
                                        </div>
                                        <div>
                                            <label htmlFor="addressLine2" className="form-label">Address Line 2:</label>
                                            <Field type="text" name="addressLine2" id="addressLine2"
                                                disabled={permissionsLevel === Permission.Read} className="input-control" />
                                            <FormError name="addressLine2" errors={allErrors} />
                                        </div>
                                        <div>
                                            <label htmlFor="addressLine3" className="form-label">Address Line 3:</label>
                                            <Field type="text" name="addressLine3" id="addressLine3"
                                                disabled={permissionsLevel === Permission.Read} className="input-control" />
                                            <FormError name="addressLine3" errors={allErrors} />
                                        </div>
                                        <div>
                                            <label htmlFor="town" className="form-label">Town:</label>
                                            <Field type="text" name="town" id="town" disabled={permissionsLevel === Permission.Read} className="input-control" />
                                            <FormError name="town" errors={allErrors} />
                                        </div>
                                        <div>
                                            <label htmlFor="county" className="form-label">County:</label>
                                            <Field type="text" name="county" id="county" disabled={permissionsLevel === Permission.Read} className="input-control" />
                                            <FormError name="county" errors={allErrors} />
                                        </div>
                                        <div>
                                            <label htmlFor="postcode" className="form-label">Postcode:</label>
                                            <Field type="text" name="postcode" id="postcode" disabled={permissionsLevel === Permission.Read}
                                                className="uppercase input-control" />
                                            <FormError name="postcode" errors={allErrors} />
                                        </div>
                                    </div>
                                    <div className="p-4 bg-white shadow">
                                        <div>
                                            <h3 className="mb-4 text-lg text-gray-400">Cards</h3>
                                        </div>
                                        <div>
                                            <FieldArray name="cards"  >
                                                {({ remove, push }) => (
                                                    <div>
                                                        {values.cards && values.cards.length > 0 &&
                                                            values.cards.map((card, index) => (
                                                                <div
                                                                    className="flex flex-wrap items-center p-2 mb-4 border-2 rounded"
                                                                    key={index}>
                                                                    <FontAwesomeIcon icon={faIdCard} className="mr-4" />
                                                                    <div className="col">
                                                                        <label className="form-label"
                                                                            htmlFor={`cards.${index}.cardNumber`}>Card
                                                                            Number
                                                                        </label>
                                                                        <Field disabled={permissionsLevel === Permission.Read} className="p-1 mb-0 input-control"
                                                                            name={`cards.${index}.cardNumber`}
                                                                            type="text"
                                                                        />
                                                                        <FormError name={`cards[${index}].cardNumber`}
                                                                            errors={allErrors} />
                                                                    </div>
                                                                    {permissionsLevel >= Permission.Edit && <button
                                                                        type="button"
                                                                        className="py-1 ml-auto mr-2 text-sm text-red-600 transition-all rounded button hover:bg-red-500 hover:text-white"
                                                                        onClick={() => {
                                                                            if (card.id) {
                                                                                deleteCard(card.id)
                                                                            } else {
                                                                                remove(index)
                                                                            }
                                                                        }}
                                                                    >
                                                                        Delete Card
                                                                    </button>}
                                                                </div>
                                                            ))}
                                                        <div className="mb-2">
                                                            {permissionsLevel >= Permission.Edit && <button
                                                                type="button"
                                                                className="text-white bg-gray-600 button"
                                                                onClick={(event) => {
                                                                    event.preventDefault();
                                                                    push({
                                                                        id: null,
                                                                        cardNumber: '',
                                                                        passengerId: passenger.id ? passenger.id : null
                                                                    });
                                                                }}
                                                            >
                                                                &#43; Add Card
                                                            </button>}
                                                        </div>
                                                    </div>
                                                )}
                                            </FieldArray>
                                        </div>

                                    </div>
                                    <div className="p-4 bg-white shadow">
                                        <div>
                                            <h3 className="mb-4 text-lg text-gray-400">Additional info</h3>
                                        </div>
                                        <div>
                                            <label htmlFor="age" className="form-label">Age</label>
                                            <Field as="select"
                                                name="age" id="age" disabled={permissionsLevel === Permission.Read} className="input-control">
                                                <option value="">Select Age</option>
                                                <option value="-16">Under 16</option>
                                                <option value="16-18">16 - 18</option>
                                                <option value="18-24">18-24</option>
                                                <option value="25-34">25-34</option>
                                                <option value="35-44">35-44</option>
                                                <option value="45-54">45-54</option>
                                                <option value="55-64">55-64</option>
                                                <option value="65-70">65-70</option>
                                                <option value="70+">75+</option>

                                            </Field>
                                            <FormError name="age" errors={allErrors} />
                                        </div>
                                        <div>
                                            <label htmlFor="support" className="form-label">Do you require
                                                additional support?</label>
                                            <Field type="checkbox" name="support" id="support"
                                                disabled={permissionsLevel === Permission.Read} className="input-control" />
                                            <FormError name="support" errors={allErrors} />
                                        </div>
                                        <div>
                                            <label htmlFor="wheelchair" className="form-label">Do you require wheelchair
                                                access?</label>
                                            <Field type="checkbox" disabled={permissionsLevel === Permission.Read} name="wheelchair" id="wheelchair" className="input-control" />
                                            <FormError name="wheelchair" errors={allErrors} />
                                        </div>
                                        <div>
                                            <label htmlFor="student" className="form-label">Are you a Student</label>
                                            <Field type="checkbox" name="student" id="student"
                                                disabled={permissionsLevel === Permission.Read} className="input-control" />
                                            <FormError name="student" errors={allErrors} />
                                        </div>
                                    </div>
                                    <div className="p-4 bg-white shadow">
                                        <h3 className="mb-4 text-lg text-gray-400">Guardians</h3>
                                        <CarersForm passenger={passenger} onChange={(p) => setPassenger({...passenger, carers: p.carers})} />
                                    </div>
                                </div>
                                <div>
                                    {errorMessageHTML}
                                    <div className="flex flex-row py-2 mt-4 border-t-2">
                                        <div className="">
                                            <Link to="/passengers" className="button">
                                                <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                                                Back to Passengers
                                            </Link>
                                        </div>
                                        {permissionsLevel >= Permission.Edit &&
                                            <div className="ml-auto">
                                                <button className="mr-2 bg-yellow-300 button" type="submit"
                                                    disabled={saving || !isValid}>{saving ? 'Saving...' : 'Save Passenger'}</button>
                                                {(passenger.id && permissionsLevel >= Permission.Delete) ? (
                                                    <button className="ml-auto text-red-500 button" type="button"
                                                        onClick={() => {
                                                            handleDeletePassenger(passenger)
                                                        }}
                                                    >Delete Passenger</button>) : null}
                                            </div>}
                                    </div>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        </>
    )
}
