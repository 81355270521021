import { useEffect } from "react";

export interface PaginationProps {
    page: number,
    onChange: CallableFunction,
    pages: number,
}

export function Pagination(props: PaginationProps) {
    let pages = [];
    for (let i = 1; i <= props.pages; ++i) {
        pages.push(i);
    }

    const classes = 'inline-block px-2 py-1 mr-1 bg-white border-2 rounded transition transition-all disabled:bg-white disabled:opacity-50';

    useEffect(() => {
    }, [props.page])

    function setPage(page: number) {
        props.onChange(page);
    }

    const prevPage = props.page - 1;
    const nextPage = props.page + 1;

    return (
        <div>
            <button title="First" className={classes} disabled={props.page === 1} onClick={() => { setPage(1) }}>|&lt;</button>
            <button title="Prev" className={classes} disabled={prevPage < 1} onClick={() => { setPage(prevPage) }}>&lt;</button>
            <select name="page" className={classes} onChange={(e) => {setPage(parseInt(e.target.value))}} value={props.page}>
                {pages.map(p => {
                    return (
                        <option disabled={props.page === p} value={p} key={p}>Page {p}</option>
                    )
                })}
            </select>
            <button title="Next" className={classes} disabled={nextPage > props.pages} onClick={() => { setPage(nextPage) }}>&gt;</button>
            <button title="Last" className={classes} disabled={props.page >= props.pages} onClick={() => { setPage(props.pages) }}>&gt;|</button>
        </div>
    );
}

export default Pagination;