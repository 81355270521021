import {SearchStopsParams, Stop} from './types';
import { AxiosPromise } from 'axios';
import {apiAxios as axios} from '../api/axios';

export function saveStop(stop: Stop): AxiosPromise<Stop> {
    if (stop.id) {
        return axios.put('/stops/'+stop.id, stop);
    }
    return axios.post('/stops', stop);
}

export function fetchStops(params: SearchStopsParams) {
    return axios.get('/stops', {params});
}