import { useContext, useState } from "react";
import { getJourneyDataExport, getJourneyDataForReport } from "../journeys/service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faSyncAlt, faFileDownload } from "@fortawesome/free-solid-svg-icons";
import Alert from "../alerts/Alert";
import { JourneyData } from "../journeys/types";
import { JourneyDataTable } from "./JourneyDataTable";
import RouteAutoComplete from "../routes/RouteAutocomplete";
import { ReportsContext } from "./Context";

export const JourneyReport = () => {

    const [loading, setLoading] = useState(false)

    const [journeyData, setJourneyData] = useState<JourneyData>()
    const [exporting, setExporting] = useState(false)
    const FileDownload = require('js-file-download');
    const { selectedRoute, selectedJourney, selectRouteById, selectJourneyById, journeysLoading, journeyOptions } = useContext(ReportsContext);
    const [errorMessage, setErrorMessage] = useState('');

    const errorMessageHTML = errorMessage ? <Alert message={errorMessage} type="error" /> : null

    const getJourneyReport = (id: number) => {
        setErrorMessage('')
        setJourneyData(undefined)
        setLoading(true)
        const values = { journeyId: id }
        getJourneyDataForReport(values).then((res) => {
            const data = res.data as JourneyData
            setJourneyData(data);
        }).catch(() => {
            setErrorMessage('Error getting report')
        }).finally(() => {
            setLoading(false)
        })
    }

    const downloadCsv = () => {
        if (selectedJourney?.id) {
            setExporting(true)
            setErrorMessage('')
            getJourneyDataExport({ journeyId: selectedJourney.id }).then((response) => {
                FileDownload(response.data, 'journey_report.csv');
            }).catch(() => {
                setErrorMessage('Error downloading csv')
            }).finally(() => {
                setExporting(false)
            })
        }
    }

    return (
        <>
            <form onSubmit={(e) => {
                e.preventDefault();
                if (selectedJourney) {
                    getJourneyReport(selectedJourney?.id)
                }
            }}>
                <div className="flex px-4 py-2 mb-2 bg-white shadow">
                    <div className="mr-1 self-center">
                        <label htmlFor="routeId" className="block mr-1 font-medium">Route</label>
                    </div>
                    <div className={"mr-2"}>
                        <RouteAutoComplete value={selectedRoute?.value || ''} onChange={(e) => selectRouteById(e.route?.id)} placeholder="Route"/>
                    </div>
                    <div className="mr-1">
                        <label htmlFor="journeyId" className="block mr-3 font-medium"><span
                            className="inline-block align-middle">Journey</span>
                            {journeysLoading && selectedRoute &&
                                <FontAwesomeIcon icon={faSyncAlt} className={'ml-2 text-sm fa-spin'} />}
                            <select className="p-1 ml-3 mr-3 border-2 border-gray-100 rounded"
                                onChange={(e) => {
                                    setJourneyData(undefined)
                                    setErrorMessage('')
                                    selectJourneyById(e.target.value);
                                }}
                                name="journeyId"
                                id="journeyId"
                                value={selectedJourney?.id || ''}
                            >
                                <option value="">Select Journey</option>
                                {journeyOptions.map(j => (
                                    <option key={j.id} value={j.id}>{j.value}</option>
                                ))}
                            </select>
                        </label>
                    </div>
                    <div className="ml-auto">
                        <button type="button" onClick={() => { downloadCsv() }}
                            className="mr-2 bg-gray-300 button"
                            disabled={(loading || !journeyData || !selectedJourney || exporting)}
                        >
                            <span><FontAwesomeIcon icon={faFileDownload} className="mr-1" />
                                {exporting ? '...Exporting' : 'Export to Csv'}</span>
                        </button>
                        <button type="submit" className="ml-2 bg-yellow-300 button" disabled={loading || !selectedJourney}>
                            {!loading ? <span><FontAwesomeIcon icon={faSearch} className="mr-1" />Get Report</span> :
                                <span><FontAwesomeIcon icon={faSyncAlt} className={'ml-2 text-sm fa-spin'} />...Loading</span>}
                        </button>
                    </div>
                </div>
            </form>
            <div className="text-center">
                {errorMessageHTML}
            </div>
            {journeyData !== undefined && <JourneyDataTable journeyData={journeyData} loading={loading} />}
        </>
    )
}