import { ChangeEvent, useState } from "react";
import { fetchStops } from "./service";
import {Stop} from './types';

export interface FindComponentProps {
    onSelect: CallableFunction,
    onBack?: CallableFunction
}

export function Find (props: FindComponentProps) {
    const [search, setSearch] = useState('');
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);

    function handleOnSearchChange (event: ChangeEvent<HTMLInputElement>) {
        setSearch(event.target.value);
        performSearch(event.target.value);
    }

    function performSearch(search: string) {
        if (!search || search === '') {
            setResults([]);
            return;
        }
        if (loading) {
            return;
        }
        setLoading(true);
        const params = {
            search,
        };
        fetchStops(params).then(({data}) => {
            setResults(data);
            setLoading(false);
        }).catch(error => {
            setResults([]);
            setLoading(false);
            console.error(error);
        })
    }

    return (
        <div className="w-full">
            <input type="text" className="block w-full input-control" value={search} onChange={handleOnSearchChange} placeholder="Search for stop..." autoFocus/>
            <div className="overflow-y-auto">
                {loading ? (<span className="text-xs text-gray-400">Loading...</span>) : null}
                {results.length && !loading ? (<span className="text-xs text-gray-400">Results:</span>) : null}
                {search && !results.length && !loading ? (<span className="text-xs text-gray-400">No results</span>) : null}
                {results.map((stop: Stop) => {
                    return (
                        <div key={stop.id} className="flex p-2 mb-1 transition-all bg-white border-2 rounded shadow cursor-pointer hover:bg-gray-100" onClick={() => {props.onSelect(stop)}}>
                            <span>{stop.name}</span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default Find;