import TabButtonsContainer from "../tabs/TabButtonsContainer";
import TabButton from "../tabs/TabButton";
import TabsContainer from "../tabs/TabsContainer";
import TabPanel from "../tabs/TabPanel";
import {useState} from "react";
import List from "./List";
import {Groups} from "./Groups";


export const Index = () => {
    const [activeTab, setActiveTab] = useState('discounts');

    const handleChangeTab = (tab: any) => {
        setActiveTab(tab);
    }

    return (
        <div>
       <TabButtonsContainer>
           <TabButton activeTab={activeTab} tab="discounts" onClick={handleChangeTab}>Discounts</TabButton>
           <TabButton activeTab={activeTab} tab="groups" onClick={handleChangeTab}>Groups</TabButton>

       </TabButtonsContainer>
       <TabsContainer>
           <TabPanel tab="discounts" activeTab={activeTab}>
               <List/>
           </TabPanel>
           <TabPanel tab="groups" activeTab={activeTab}>
               <Groups/>
           </TabPanel>
       </TabsContainer>
   </div>)
}