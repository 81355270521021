import React, { ChangeEvent, useEffect, useState } from "react";
import { Carer, CarerPassenger } from "./types";
import { Passenger } from "../passengers/types";
import { fetchPassengers } from "../passengers/service";

export interface PassengersFormProps {
    carer: Carer,
    onChange: (carer: Carer) => any,
}


export const PassengersForm = (props: PassengersFormProps) => {
    const [users, setUsers] = useState<Passenger[]>([]);
    const [loaded, setLoaded] = useState(false);
    const [search, setSearch] = useState('');

    useEffect(() => {
        setLoaded(false);
        fetchPassengers({ perPage: 10000 }).then(({ data }) => {
            setUsers(data.items);
            setLoaded(true);
        }).catch(err => {
            console.error(err);
            setLoaded(true);
        })
    }, []);

    const toggleUser = (user: Passenger) => {
        let passengers = [...props.carer.passengers];
        const index = passengers.findIndex(r => (r.userId === user.id));
        if (index < 0) {
            passengers.push({
                userId: user.id,
            } as CarerPassenger);
        }
        if (index > -1) {
            passengers.splice(index, 1);
        }
        props.onChange({ ...props.carer, passengers });
        setSearch('');
    }

    const searchChanged = (event: ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    }

    const filteredUsers = users.filter((user) => {
        if (search === '') {
            return true;
        }
        if (user.firstName.toLowerCase().includes(search.toLowerCase()) || user.lastName.toLowerCase().includes(search.toLowerCase()) || user.email?.toLowerCase().includes(search.toLowerCase())) {
            return true;
        }

        return false;
    });

    return (
        <div className="my-4 shadow">
            {!loaded && (
                <p className="p-4 text-sm text-gray-500">Loading users...</p>
            )}

            {loaded && !users.length && (
                <p className="p-4 text-sm text-gray-500">No users available to select</p>
            )}

            {loaded && users.length !== 0 &&
                <div>
                    <input type="search" value={search} className="w-full border-gray-400 input-control" onChange={searchChanged} placeholder="Search passengers..." />
                </div>
            }
            <div className="overflow-y-auto max-h-80">
                {filteredUsers.map((user: Passenger) => {
                    const readerPassengerUser = props.carer.passengers.find(d => d.userId === user.id);
                    return (
                        <div className="flex items-center p-2 mb-1 border-2 rounded" key={'user_' + user.id}>
                            <div className="mr-2">
                                <input type="checkbox" checked={readerPassengerUser !== undefined} onChange={(e) => { toggleUser(user) }} />
                            </div>
                            <span>{user.firstName} {user.lastName} - {user.email}</span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default PassengersForm;