import { ReactNode } from "react";

export interface TabPaneProps {
    tab: string,
    activeTab?: string,
    children?: ReactNode,
}
export function TabPanel(props: TabPaneProps) {

    return (
        <div className={props.tab === props.activeTab ? 'block' : 'hidden'}>{props.children}</div>
    );
}

export default TabPanel;