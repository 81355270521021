import {apiAxios as axios} from '../api/axios';
import { Schedule } from './types';

export function saveSchedule(schedule: Schedule) {
    if (schedule.id) {
        return axios.put('/schedules/'+schedule.id, schedule);
    }
    return axios.post('/schedules', schedule);
}

export function deleteSchedule(schedule: Schedule) {
    return axios.delete('/schedules/'+schedule.id);
}