import {Passenger} from "./types";
import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "../../app/store";

interface PassengerState {
    deletePassenger: null|Passenger
    editPassenger: null|Passenger
}

const initialState:PassengerState = {
    deletePassenger: null,
    editPassenger:null
}


export const slice = createSlice({
    name: "passenger",
    initialState,
    reducers: {
        setDeletePassenger: (state: PassengerState, {payload}) => {
            state.deletePassenger = payload
        },
        setEditPassenger: (state:PassengerState, {payload}) => {
            state.editPassenger = payload
        },
    }

});



export const {setDeletePassenger, setEditPassenger} = slice.actions

export const selectDeletePassenger = (state: RootState) => state.passengers.deletePassenger
export const selectEditPassenger = (state: RootState) => state.passengers.editPassenger

export default slice.reducer
