import {useAppSelector} from "../../app/hooks";
import {selectIsLoggedIn, selectUser} from "../auth/slice";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBuilding, faUser} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import {LoggedInUser} from "../auth/types";
import {Loader} from "../loader/Loader";

export const Index = () => {
    const loggedInUser = useAppSelector(selectUser);
    const loggedIn = useAppSelector(selectIsLoggedIn)
    const [user, setUser] = useState<LoggedInUser>()

    useEffect(() => {
        setUser(loggedInUser)
        },[loggedInUser]
    )

    if (!loggedInUser && loggedIn) {

        return <Loader/>;
    }

    if (!loggedInUser) {
        return null
    }

    return (
        <div className="h-14 p-2 m-auto ">
            <div className="ml-auto w-2/12">
                <Link to={"/users/" + loggedInUser.id + "/edit"} className="block pt-3 pb-2 font-medium ">
                    <span className="inline-block w-3 mr-2"><FontAwesomeIcon icon={faUser} className="text-sm" /></span> {(user?.firstName ?? '') + ' ' + (user?.lastName ?? '')}
                </Link>
                <div className="block font-medium ">
                    <span className="inline-block w-3 mr-2"><FontAwesomeIcon icon={faBuilding} className="text-sm" /></span> {(user?.organisationName ?? '')}
                </div>
            </div>
        </div>
    )
}