import {getFormattedTimeFromStringWithSeconds} from "../dates/helper";
import Alert from "../alerts/Alert";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSyncAlt} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useRef, useState} from "react";
import {RouteSession} from "./types";
import {refreshSession} from "./service";

interface LiveSessionTableProps {
    routeSession:RouteSession
    closeModal: CallableFunction
}

interface UpdateMessage {
    type:string
    message:string
}
interface Updates extends Array<UpdateMessage>{}


export const LiveSessionTable = (props: LiveSessionTableProps) => {

    const [routeSession, setRouteSession] = useState<RouteSession>(props.routeSession);
    const [updates, setUpdates] = useState<Updates>([]);
    const [refreshing, setRefreshing] = useState(false)
    const [lastUpdated, setLastUpdated] = useState<undefined| Date>();
    const routeSessionRef = useRef(routeSession);
    const live = !props.routeSession.endDate

    routeSessionRef.current = routeSession;

    const refreshData = () => {
        setRefreshing(true)
        refreshSession(routeSession.id).then((response) => {
            const updatedRouteSession = response.data as RouteSession
            const oldRouteSession: RouteSession = {...routeSessionRef.current}
            compareRouteSessions(updatedRouteSession, oldRouteSession);
            setRouteSession(updatedRouteSession)
            setLastUpdated(new Date())
        }).finally(() => {
            setRefreshing(false)
        })
    }

    const compareRouteSessions = (newSession:RouteSession, oldSession:RouteSession) =>{
        const checkedOut = oldSession.sessionPassengers.filter(sessionPassenger => !!sessionPassenger.checkedOut)
        const oldSessionPassengerIds = oldSession.sessionPassengers.map(sessionPassenger => {return sessionPassenger.passenger.id})
        const checkedOutPassengerIds = checkedOut.map(sessionPassenger => {return sessionPassenger.passenger.id})
        const newUpdates:Updates = []
        const newCheckedIn = !(oldSession.sessionPassengers.length === newSession.sessionPassengers.length);
        if(newCheckedIn) {
            const newSessionPassengers = newSession.sessionPassengers.filter(sessionPassenger => !oldSessionPassengerIds.includes(sessionPassenger.passenger.id))
            newSessionPassengers.forEach(sessionPassenger => {
                let passengerName = sessionPassenger.passenger.firstName ? sessionPassenger.passenger.firstName + ' ' + sessionPassenger.passenger.lastName : 'New passenger'
                newUpdates.push({type :'Check In', message: passengerName + ' checked in.'})
            })
        }
        const updatedCheckedOut = newSession.sessionPassengers.filter(sessionPassenger => !!sessionPassenger.checkedOut)
        if (updatedCheckedOut.length !== checkedOut.length){
            const newCheckedOutPassengers = updatedCheckedOut.filter(sessionPassenger => !checkedOutPassengerIds.includes(sessionPassenger.passenger.id))
            newCheckedOutPassengers.forEach(sessionPassenger => {
                let passengerName = sessionPassenger.passenger.firstName ? sessionPassenger.passenger.firstName + ' ' + sessionPassenger.passenger.lastName : 'New passenger'
                newUpdates.push({type :'Check Out', message: passengerName + ' checked out.'})
            })
        }
        setUpdates(newUpdates)
    }


    useEffect(() => {
        if(live){
        refreshData()
            const interval = setInterval(() => {
                refreshData()
            }, 15000);
            return () => clearInterval(interval);
        }
    }, []);

    const sessionPassengerIds = routeSession.sessionPassengers.map(sessionPassenger => {return sessionPassenger.passenger.id})
    const reservationPassengersIds = routeSession.journey.reservations.map(reservation => {return reservation.passenger.id})
    const liveClassName = routeSession.endDate ? 'text-red-700' : 'text-green-700';
    const reservationsForSessionPassengers = routeSession.journey.reservations.filter(reservation => sessionPassengerIds.includes(reservation.passenger.id))
    const reservationsUsed = reservationsForSessionPassengers.length + '/' + routeSession.journey.reservations.length
    const percentageCapacity = routeSession.route.capacity ? Math.round(reservationsForSessionPassengers.length/routeSession.route.capacity*100) + '%' : 'N/A'
    return (

        <div className={"p-2 text-center "} key={routeSession.id}>
            <div className="flex flex-col justify-center m-3">
                <h3 className={"mb-2 text-xl font-semibold " + liveClassName}>Reservations on {routeSession.route.name} at {routeSession.journey.startTime} ({routeSession.schedule.name})</h3>
                <div className="flex flex-row justify-evenly">
                    <p><span
                        className="text-sm">Reservations Used:</span> {reservationsUsed ? reservationsUsed : 'N/A'}
                    </p>
                    <p><span
                        className="text-sm">Capacity:</span> {routeSession.route.capacity ? routeSession.route.capacity + ' (' +percentageCapacity + ')': 'N/A'}
                    </p>
                </div>

                <table className="table w-full table-striped">
                    <thead>
                    <tr>
                        <th className="text-center">Reservation</th>
                        <th className="text-center">Passenger</th>
                        <th className="text-center">Checked In</th>
                        <th className="text-center">Checked Out</th>
                    </tr>
                    </thead>
                    <tbody>
                    {routeSession.journey.reservations.map(reservation => {
                        const reservationPassenger = routeSession?.sessionPassengers.filter(sessionPassenger => sessionPassenger.passenger.id === reservation.passenger.id)[0]
                        return (
                            <tr key={reservation.passenger.id}>
                                <td>Yes</td>
                                <td>{reservation.passenger.firstName + ' ' + reservation.passenger.lastName}</td>
                                <td>{reservationPassenger && getFormattedTimeFromStringWithSeconds(reservationPassenger.checkedIn) }</td>
                                <td>{reservationPassenger && getFormattedTimeFromStringWithSeconds(reservationPassenger.checkedOut) }</td>
                            </tr>

                        )
                    })}
                    {
                        routeSession.sessionPassengers.map((sessionPassenger => {
                            if(!reservationPassengersIds.includes(sessionPassenger.passenger.id)){
                                return (
                                    <tr key={sessionPassenger.passenger.id}>
                                        <td>No</td>
                                        <td>{sessionPassenger.passenger.firstName + ' ' + sessionPassenger.passenger.lastName}</td>
                                        <td>{sessionPassenger && getFormattedTimeFromStringWithSeconds(sessionPassenger.checkedIn) }</td>
                                        <td>{sessionPassenger && getFormattedTimeFromStringWithSeconds(sessionPassenger.checkedOut) }</td>
                                    </tr>
                                )
                            }
                            return null
                        }))
                    }
                    </tbody>
                </table>
                <div>
                    {updates.map(update => {return (<div className={"m-1"}>
                        <Alert message={update.message} type={'info'}/>
                    </div>)})}
                </div>
                <div className="flex flex-row mt-2 justify-evenly">
                    <p><span
                        className="text-sm">Last Updated: {!refreshing && lastUpdated ? lastUpdated?.toLocaleTimeString() : <FontAwesomeIcon icon={faSyncAlt} className={'ml-2 text-sm fa-spin'}/>}
                        </span>
                    </p>

                </div>
            </div>
        </div>
    )
}