import {ApiMarker, ApiMarkers} from "./types";
import {RouteMapMarker} from "../routes/RouteMap";
import busPin from "../../img/bus-pin.png";
import passengerPin from "../../img/yellow-pin.png";
import startPin from "../../img/yellow-pin.png"
import endPin from "../../img/pink-pin.png"

export enum type {
    BUS_LOCATION = 'BUS_LOCATION',
    FIRST_LOCATION = 'FIRST_LOCATION',
    LAST_LOCATION = 'LAST_LOCATION',
    PASSENGER_LOCATION = 'PASSENGER_LOCATION'
}

export const convertMarker = (marker: ApiMarker) => {

    let icon;
    let name = '';
    switch (marker.type) {
        case type.BUS_LOCATION:
            icon = busPin;
            name = 'Bus Last Location';
            break;
        case type.FIRST_LOCATION:
            icon = startPin;
            name = 'Start of Journey';
            break;
        case type.LAST_LOCATION:
            icon = endPin;
            name = 'End of Journey';
            break;
        case type.PASSENGER_LOCATION:
            icon = passengerPin;
            name = 'Passenger Location'
    }


    return {
        name,
        location: new google.maps.LatLng(parseFloat(marker.lat), parseFloat(marker.long)),
        icon,
    } as RouteMapMarker;
}


export const convertMarkers = (markers: ApiMarkers) => {
    return markers.map((marker) => {
        return convertMarker(marker)
    })
}