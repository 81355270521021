import React, { useState } from "react";
import { Organisation } from "./types";
import { Formik, Form, Field } from "formik";
import * as Yup from 'yup';
import FormError from "../form/FormError";
import Alert from "../alerts/Alert";
import { deleteOrganisation, fetchOrganisation, saveOrganisation } from './service';
import {faArrowLeft, faPlus} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useHistory, useParams } from "react-router-dom";
import { ParamsWithId } from "../routing/types";
import { useEffect } from "react";
import ConfirmModal from "../modal/ConfirmModal";
import { MouseEvent } from 'react';
import { Index as UserIndex } from '../users/Index';
import TabButton from '../tabs/TabButton';
import TabsContainer from '../tabs/TabsContainer';
import TabButtonsContainer from '../tabs/TabButtonsContainer';
import TabPanel from '../tabs/TabPanel';
import {useAppSelector} from "../../app/hooks";
import {selectUserPolicies} from "../auth/slice";
import {
    getPermissionsLevelForEntity, getPermissionsLevelForResourceAtOrganisation, Permission,
} from "../policies/helpers";
import {List} from "../permissions/List";

const initialOrganisation = {
    name: '',
    active: true,
} as Organisation;

export function Edit() {
    const [organisation, setOrganisation] = useState<Organisation>(initialOrganisation);
    const policies = useAppSelector(selectUserPolicies)
    const permissionsLevel = getPermissionsLevelForEntity('organisation', organisation, policies)
    const userPermissionsLevel = getPermissionsLevelForResourceAtOrganisation('user',  organisation.id, policies)
    const [apiErrors, setApiErrors] = useState({});
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const history = useHistory();
    const { id } = useParams<ParamsWithId>();
    const [showDelete, setShowDelete] = useState(false);
    const [deleting, setDeleteing] = useState(false);
    const [activeTab, setActiveTab] = useState('details');

    useEffect(() => {
        if (!id) {
            setOrganisation(initialOrganisation)
            return;
        }
        setLoading(true);
        fetchOrganisation(id).then(({ data }) => {
            setOrganisationFromResponse(data);
        }).catch((err: any) => {
            setOrganisation(initialOrganisation);
            history.goBack();
            console.error(err);
        }).finally(() => {
            setLoading(false);
        })
    }, [id]);

    function handleSubmit(values: Organisation) {
        setError('');
        setApiErrors({});
        setMessage('');
        setSaving(true);
        const organisationData = { ...values }
        saveOrganisation(organisationData).then(({ data }) => {
            if (!organisation.id) {
                history.push('/organisations/' + data.id + '/edit');
            }
            setOrganisationFromResponse(data);
            setSaving(false);
            setMessage('Organisation saved successfully');
        }).catch((err: any) => {
            setSaving(false);
            setError('Failed to save organisation, please check for errors');
            if (err.response && err.response.data) {
                setApiErrors(err.response.data);
                return;
            }
            console.error(err);
        })
    }

    function setOrganisationFromResponse(data: any) {
        const organisation = { ...data };
        setOrganisation(organisation);
    }

    function handleDeleteClick(event: MouseEvent) {
        event.preventDefault();
        setShowDelete(true);
    }

    function handleDeleteConfirmed() {
        setDeleteing(true);
        deleteOrganisation(organisation.id).then(() => {
            setShowDelete(false);
            setDeleteing(false);
            setMessage('Organisation deleted successfully, redirecting...');
            window.setTimeout(() => {
                history.replace('/organisations');
            }, 3000);
        }).catch(() => {
            setShowDelete(false);
            setError('Failed to delete organisation, please try again');
            setDeleteing(false);
        })
    }

    const validation = Yup.object().shape({
        name: Yup.string().label('Name').required().max(255),
    });

    if (loading) {
        return (
            <div>
                <span>Loading organisation...</span>
            </div>
        )
    }

    return (
        <div>
            <div className="mb-2">
                <h1 className="text-lg">{organisation.id ? 'Edit' : 'New'} Organisation {organisation.id && organisation.name}</h1>
            </div>
            <TabButtonsContainer>
                <TabButton activeTab={activeTab} tab="details" onClick={setActiveTab}>Details</TabButton>
                {organisation.id && userPermissionsLevel >= Permission.Read ? (
                    <TabButton activeTab={activeTab} tab="users" onClick={setActiveTab}>Users</TabButton>
                ) : null}
                {permissionsLevel >= Permission.Delete && organisation.id && <TabButton tab={"permissions"} activeTab={activeTab} onClick={setActiveTab}>Permissions</TabButton>}
            </TabButtonsContainer>
            <TabsContainer>
                <TabPanel tab="details" activeTab={activeTab}>
                    <Formik
                        initialValues={{ ...organisation }}
                        onSubmit={handleSubmit}
                        validationSchema={validation}
                        validateOnBlur
                    >
                        {({ errors, isValid }) => {
                            const allErrors = { ...errors, ...apiErrors };
                            return (
                                <Form autoComplete="false">
                                    <div className="bg-white">
                                        <div>
                                            <label htmlFor="name" className="form-label">Name:</label>
                                            <Field disabled={permissionsLevel === Permission.Read} className="w-full input-control" name="name" id="name" placeholder="Organisation Name" />
                                            <FormError name="name" errors={allErrors} />
                                        </div>
                                        <div>
                                            <label htmlFor="active" className="form-label">Status:</label>
                                            <label htmlFor="active">
                                                <Field disabled={permissionsLevel === Permission.Read} type="checkbox" name="active" id="active" className="mr-2" />
                                                Active
                                            </label>
                                            <FormError name="active" errors={allErrors} />
                                        </div>
                                    </div>
                                    <Alert message={message} type="success" />
                                    <Alert message={error} type="error" />
                                    <div className="my-2 bg-white">
                                        <div className="flex">
                                            {permissionsLevel >= Permission.Edit && <button className="ml-auto bg-yellow-300 button"
                                                     disabled={deleting || saving || !isValid}>{saving ? 'Saving...' : 'Save Organisation'}</button>}
                                            {permissionsLevel >= Permission.Delete && organisation.id ? (
                                                <button className="text-red-400 button" type="button" disabled={saving} onClick={handleDeleteClick}>Delete Organisation</button>
                                            ) : null}
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </TabPanel>
                {organisation.id ? (
                    <TabPanel tab="users" activeTab={activeTab}>
                        <div className="flex">
                        <div className="mb-2 ">
                            <h1 className="text-lg">Organisation Users</h1>
                        </div>
                            {userPermissionsLevel >= Permission.Create && <div className="flex h-auto ml-auto">
                                <button className="mr-2 text-white bg-gray-600 button" type="button" onClick={() => {
                                    history.push('/users/new/' + organisation.id)
                                }}>
                                    <FontAwesomeIcon icon={faPlus}
                                                     className={'mr-1 text-sm ' + (loading ? 'fa-spin' : '')}/>
                                    Add User
                                </button>
                            </div>}
                        </div>
                        <UserIndex hideHeader hideNewButton organisationId={organisation.id} />
                    </TabPanel>
                ) : null}
                {permissionsLevel >= Permission.Delete && organisation.id && <TabPanel tab={"permissions"} activeTab={activeTab}>
                    <div className="flex">
                    <div>
                        <h1 className="text-lg">Organisation Permissions</h1>
                        <p className="block mb-4 text-sm text-gray-500">These permissions are given to <strong>all users</strong> that belong to this organisation. For more control consider giving specific users their own permissions.</p>
                    </div>
                    </div>
                    <List organisation={organisation} />
                </TabPanel>}
            </TabsContainer>
            <div className="p-4 my-2 bg-white">
                <div className="flex">
                    <Link to="/organisations" className="button">
                        <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                        Back to Organisations
                    </Link>
                </div>
            </div>
            {showDelete ? (
                <ConfirmModal title="Delete Organisation" onClose={() => setShowDelete(false)} onConfirm={handleDeleteConfirmed} disabled={deleting || saving}>
                    Are you sure you want to delete this organisation?
                </ConfirmModal>
            ) : null}
        </div>
    );
}