import {Formik, Form, Field, FormikValues} from 'formik';
import {useEffect, useRef, useState} from 'react';
import * as Yup from 'yup';
import {Stop} from './types';
import FormError from '../form/FormError';
import {Search as GeoSearch} from '../geo/Search';
import {saveStop} from './service';
import Alert from '../alerts/Alert';

export type StopFormOnSaveFunction = (stop: Stop) => void;

export interface StopFormProps {
    onSaved?: StopFormOnSaveFunction,
}

let map: google.maps.Map;
let mapMarker: google.maps.Marker;

export function StopForm(props: StopFormProps) {
    const [apiErrors, setApiErrors] = useState({});
    const [stop, setStop] = useState<null | Stop>(null);
    const [saving, setSaving] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [lat, setLat] = useState<Number>();
    const [lng, setLng] = useState<Number>();

    const mapEl = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (mapEl.current === null) {
            return;
        }
        const mapOptions = {
            center: {lat: 51.50689, lng: -0.645144},
            zoom: 8,
        } as google.maps.MapOptions;
        map = new google.maps.Map(mapEl.current, mapOptions)
        mapMarker = new google.maps.Marker();
        map.addListener('click', (event: google.maps.MapMouseEvent) => {
            handleSearchResult(event.latLng)
        })
    }, [google]);

    function handleSubmit(values: FormikValues) {
        setSaving(true);
        setErrorMessage('');
        setSuccessMessage('');
        const stopValues = {...values, lat, long: lng}
        saveStop(stopValues as Stop).then(({data}) => {
            setStop(data);
            setSaving(false);
            setSuccessMessage('Stop saved successfully');
            window.setTimeout(() => {
                setSuccessMessage('');
            }, 5000);
            if (props.onSaved && data) {
                props.onSaved(data);
            }
        }).catch(error => {
            setSaving(false);
            setErrorMessage('Failed to save stop, check for errors');
            if (error.response.data.errors) {
                setApiErrors(error.response.data.errors);
                return;
            }
            throw error;
        })
    }

    const createFormValidation = Yup.object().shape({
        name: Yup.string().required().label('Name'),
    });

    function handleSearchResult(result: null | google.maps.LatLng) {
        if (result == null) {
            return;
        }
        map.setCenter(result);
        const currentZoom = map.getZoom() || 1;
        const targetZoom = 18;
        map.setZoom(currentZoom < targetZoom ? targetZoom : currentZoom);
        mapMarker.setPosition(result);
        mapMarker.setMap(map);
        setLat(result.lat);
        setLng(result.lng)
    }


    return (
        <Formik
            initialValues={stop || {
                name: '',
                lat: '',
                long: '',
            }}
            onSubmit={handleSubmit}
            validationSchema={createFormValidation}
            validateOnBlur
        >
            {({errors, isValid, dirty, submitForm}) => {
                const allErrors = {...errors, ...apiErrors}

                return (
                    <div className="w-full">
                        <div>
                            <Alert message={successMessage} type="success"/>
                            <Alert message={errorMessage} type="error"/>
                        </div>
                        <div className="flex">
                            <div className="flex flex-col w-1/3 pr-2">
                                <Form>
                                    <div>
                                        <label htmlFor="name" className="form-label">Name</label>
                                        <Field id="name" name="name" placeholder="Name" className="input-control"
                                               style={{maxWidth: "100%"}} autoFocus/>
                                        <FormError name="name" errors={allErrors}/>
                                    </div>
                                    <div>
                                        <label htmlFor="lat" className="form-label">Latitude</label>
                                        <input type="number" id="lat" name="lat" step="0.0001" placeholder="Latitude"
                                               style={{maxWidth: "100%"}} className="input-control" required
                                               value={String(lat)} onChange={(event) => {
                                            setLat(parseFloat(event.target.value))
                                        }}/>
                                        <FormError name="lat" errors={allErrors}/>
                                    </div>
                                    <div>
                                        <label htmlFor="long" className="form-label">Longitude</label>
                                        <input type="number" id="long" name="long" step="0.0001" placeholder="Longitude"
                                               style={{maxWidth: "100%"}} className="input-control" value={String(lng)}
                                               onChange={(event) => {
                                                   setLng(parseFloat(event.target.value))
                                               }}/>
                                        <FormError name="long" errors={allErrors}/>
                                    </div>
                                </Form>
                            </div>
                            <div className="w-full pl-2">
                                <label className="form-label">Search by Address</label>
                                <GeoSearch onResult={handleSearchResult}/>
                                <div className="block w-full h-96" ref={mapEl}>
                                </div>
                            </div>
                        </div>
                        <div className="flex mt-2">
                            <button type="submit" onClick={()=> submitForm()} className="ml-auto bg-yellow-300 button" disabled={!dirty || !isValid || saving}>{saving ? 'Saving...' : 'Save Stop'}</button>
                        </div>
                    </div>

                );
            }}
        </Formik>
    );
}

export default StopForm;