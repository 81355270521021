import { FormEvent, useEffect, useRef, useState } from 'react';
import logo from '../../logo.png';
import Alert from '../alerts/Alert';
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { requestResetPassword } from './service';
import { useQuery } from '../routing/hooks';
import FormError from '../form/FormError';

export function ResetPassword() {
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [apiErrors, setApiErrors] = useState({});
    const passwordEl = useRef<HTMLInputElement>(null);

    const history = useHistory();

    const query = useQuery();

    const token = query.get('token');

    if (!token) {
        history.replace('/login');
    }

    useEffect(() => {
        passwordEl?.current?.focus();
    }, []);

    function submit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        if (loading || success || !token) {
            return;
        }
        setApiErrors({});
        setErrorMessage('');
        setLoading(true);
        requestResetPassword({ password, confirmPassword, token }).then(() => {
            setSuccess(true);
            setLoading(false);
        }).catch(err => {
            setErrorMessage('There was a problem, please try again');
            if (err.response && err.response.data) {
                setApiErrors(err.response.data);
            }
            if (err.response.data && err.response.data.message) {
                setErrorMessage(err.response.data.message);
            }
            setLoading(false);
            passwordEl?.current?.focus();
        })
    }

    return (
        <div className="flex justify-center w-screen h-screen align-center">
            <div className="w-11/12 max-w-sm p-4 mx-auto my-auto text-center bg-white rounded shadow-sm border-box">
                <img src={logo} alt="Zywepit Logo" className="px-1 mx-auto" />
                <h2 className="mb-2 font-medium text-md">Reset Password</h2>
                <p className="mb-2 text-sm text-gray-500">Enter a new password below</p>
                <form action="" onSubmit={submit} className="flex flex-col" method="POST">
                    <input type="password" name="password" id="password" aria-label="Password" placeholder="Password"
                        autoFocus className="input-control" value={password} onChange={(e) => { setPassword(e.target.value) }} disabled={loading || success} ref={passwordEl} />
                    <FormError name="password" errors={apiErrors} />
                    <input type="password" name="confirmPassword" id="confirmPassword" aria-label="Confirm Password" placeholder="Confirm Password"
                        autoFocus className="input-control" value={confirmPassword} onChange={(e) => { setConfirmPassword(e.target.value) }} disabled={loading || success} />
                    <FormError name="confirmPassword" errors={apiErrors} />
                    <Alert type="error" message={errorMessage} />
                    {success ? (
                        <div className="py-2">
                            <Alert type="success" message="Your password was successfully changed, continue to login" />
                        </div>
                    ) : (
                        <div className="py-2">
                            {
                                loading ? (
                                    <button className="bg-gray-300 button" disabled>Submitting...</button>
                                ) : (
                                    <button className="bg-yellow-300 button">Change Password</button>
                                )}
                        </div>
                    )}
                    <div className="py-1 text-sm">
                        <Link to="/login"><FontAwesomeIcon icon={faArrowLeft} /> Back to Login</Link>
                    </div>
                </form>
            </div >
        </div >
    );
}

export default ResetPassword;