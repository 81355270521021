import {AxiosRequestConfig, AxiosResponse} from "axios";
import {apiAxios as axios} from '../api/axios';
import {Device} from "./types";

export function saveDevice(device:Device): Promise<AxiosResponse> {
    if (device.id){
        return axios.post('/boxes', device )
    }
    return axios.put('/boxes', device)
}

export function deleteDevice(device:Device): Promise<AxiosResponse> {
    return axios.delete('/boxes/' + device.id)
}

export const fetchDevices = (params:object):Promise<AxiosResponse>=> {
    return axios.get('/boxes', {params} as AxiosRequestConfig)
}

export const unprovisionDevice = (device:Device):Promise<AxiosResponse> => {
    return axios.get('/boxes/unprovision/'+ device.id)
}
