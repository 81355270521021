import React, { useContext, useState } from "react";
import DateRangePicker, { DateRange } from "../dates/DateRangePicker";
import { RouteSessions, SessionRouteReportData } from "../sessions/types";
import { DropDownOptions } from "../dropdown/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload, faSearch, faSyncAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import { getSessionRouteCsvReport, getSessionsRouteForReport } from "../sessions/service";
import { RouteSessionTable } from "./RouteSessionTable";
import Alert from "../alerts/Alert";
import RouteAutoComplete from "../routes/RouteAutocomplete";

import { ReportsContext } from "./Context";

export const RouteReport = () => {
    const todayStart = new Date();
    todayStart.setHours(0, 0, 0, 0);

    const todayEnd = new Date();
    todayEnd.setHours(23, 59, 59, 999);
    const [dateRange, setDateRange] = useState<DateRange>({
        startDate: todayStart,
        endDate: todayEnd,
    });
    const [routeSessions, setRouteSessions] = useState<RouteSessions>()
    const [loading, setLoading] = useState(false)
    const [exporting, setExporting] = useState(false)
    const FileDownload = require('js-file-download');
    const [journeys, setJourneys] = useState<DropDownOptions>([]);
    const { selectedRoute, selectRouteById, journeyOptions, journeysLoading, selectedJourney, selectJourneyById } = useContext(ReportsContext);
    const [errorMessage, setErrorMessage] = useState('');

    const errorMessageHTML = errorMessage ? <Alert message={errorMessage} type="error" /> : null

    const getSessionReport = () => {
        setErrorMessage('')
        setRouteSessions([])
        setLoading(true)
        let journeyIdArray = journeyOptions.map(journey => journey.id)
        const values = { journeyIdArray, startDate: dateRange.startDate, endDate: dateRange.endDate }
        getSessionsRouteForReport(values).then((res) => {
            const data = res.data as RouteSessions
            setRouteSessions(data);
        }).catch(() => {
            setErrorMessage('Error getting report')
        }).finally(() => {
            setLoading(false)
        })
    }

    const downloadCsv = () => {
        setExporting(true)
        setErrorMessage('')
        let journeyIdArray = journeyOptions.map(journey => journey.id)
        const values = {
            journeyIdArray,
            startDate: dateRange.startDate,
            endDate: dateRange.endDate
        } as SessionRouteReportData
        getSessionRouteCsvReport(values).then((response) => {
            FileDownload(response.data, 'route_report.csv');
        }).catch(() => {
            setErrorMessage('Error downloading csv')
        }).finally(() => {
            setExporting(false)
        })
    }

    const addJourney = (journeyId: any) => {
        let addJourneys = [...journeys];
        if (journeyOptions) {
            addJourneys.push(...journeyOptions.filter(journeyDDOption => journeyDDOption.id === parseInt(journeyId) && !addJourneys.includes(journeyDDOption)))
        }
        setJourneys(addJourneys)
    }

    return (
        <>
            <form onSubmit={(e) => {
                e.preventDefault();
                addJourney(selectedJourney?.id);
            }}>
                <div className="flex px-4 py-2 mb-2 bg-white shadow">
                    <div className="mr-1 self-center">
                        <label htmlFor="routeId" className="block mr-3 font-medium">Route</label>
                    </div>
                    <div className={"mr-2"}>
                        <RouteAutoComplete value={selectedRoute ? selectedRoute.value : ''} onChange={(e) => selectRouteById(e.route?.id)} placeholder="Route"/>
                    </div>
                    <div className="mr-1">
                        <label htmlFor="journeyId" className="block mr-3 font-medium"><span
                            className="inline-block align-middle">Journey</span>
                            {journeysLoading && selectedRoute &&
                                <FontAwesomeIcon icon={faSyncAlt} className={'ml-2 text-sm fa-spin'} />}
                            <select className="p-1 ml-3 mr-3 border-2 border-gray-100 rounded"
                                value={selectedJourney?.id || ''}
                                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                    setRouteSessions(undefined)
                                    setErrorMessage('')
                                    selectJourneyById(event.target.value)
                                }}
                                id="journeyId">
                                <option>Select Journey</option>
                                {journeyOptions.map(j => (
                                    <option value={j.id}>{j.value}</option>
                                ))}
                            </select>
                        </label>
                    </div>
                    <div className="ml-auto">
                        <button type="submit" className="ml-2 bg-yellow-300 button" disabled={loading}>
                            <span>Add Journey</span>
                        </button>
                    </div>
                </div>
                <div className="flex flex-wrap m-2">
                    {journeys.map(journey => {
                        return (
                            <span className="p-1 m-1 bg-green-200 rounded">
                                {journey.value}<span className="p-2 text-red-500 cursor-pointer"
                                    onClick={() => {
                                        setJourneys([...journeys.filter(journey1 => journey1.id !== journey.id)])
                                    }}><FontAwesomeIcon icon={faTimes} /></span>
                            </span>
                        )
                    })}
                </div>
            </form>
            <div className="flex px-4 py-2 mb-2 bg-white shadow">
                <div className="flex flex-row w-full text-center">
                    <span className="self-center block mr-3 font-medium">Dates</span>
                    <DateRangePicker value={dateRange} onChange={(range: DateRange) => {
                        setDateRange(range)
                        setErrorMessage('')
                    }} />
                    <div className="ml-auto">
                        <button type="button" onClick={() => {
                            downloadCsv()
                        }}
                            className="mr-2 bg-gray-300 button"
                            disabled={(loading || (!routeSessions || routeSessions.length === 0) || exporting)}
                        >
                            <span><FontAwesomeIcon icon={faFileDownload} className="mr-1" />
                                {exporting ? '...Exporting' : 'Export to Csv'}</span>
                        </button>
                        <button type="button" onClick={() => {
                            getSessionReport()
                        }} className="ml-2 bg-yellow-300 button" disabled={loading || journeys.length === 0}>
                            {!loading ? <span><FontAwesomeIcon icon={faSearch} className="mr-1" />Get Report</span> :
                                <span><FontAwesomeIcon icon={faSyncAlt}
                                    className={'ml-2 text-sm fa-spin'} />...Loading</span>}
                        </button>
                    </div>
                </div>
            </div>
            <div className="text-center">
                {errorMessageHTML}
            </div>
            {routeSessions !== undefined && <RouteSessionTable routeSessions={routeSessions} loading={loading} />}
        </>
    )
}