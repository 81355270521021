import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EditRouteTabProps } from "./types";
import ScheduleModal from '../schedules/ScheduleModal';
import { useState } from "react";
import {Schedule, ScheduleStop} from '../schedules/types';
import {Permission} from "../policies/helpers";

export function EditSchedules (props: EditRouteTabProps) {

    const [editSchedule, setEditSchedule] = useState<null | Schedule>(null);

    function handleModalClose() {
        props.onChange()
        setEditSchedule(null);
    }

    function handleAddNew() {
        const newSchedule = {
            name: '',
            route: {...props.route},
            scheduleStops: props.route.routeStops.map((routeStop) => {
                return {
                    routeStop: {...routeStop},
                } as ScheduleStop;
            }),
        };
        setEditSchedule(newSchedule);
    }

    return (
        <>
            {props.route.schedules.map((schedule) => (
                <div
                    className="flex items-center p-2 mb-2 transition-colors bg-white border-2 rounded cursor-pointer hover:bg-gray-100"
                    key={'schedule_' + schedule.id} onClick={() => {
                    setEditSchedule(schedule)
                }}>
                    <span className="mr-auto font-bold">{schedule.name}</span>
                    <button className="text-sm text-white bg-gray-500 button">{props.permissionsLevel === Permission.Read ? 'Show': 'Edit'}</button>
                </div>
            ))}
            {props.permissionsLevel >= Permission.Edit && (props.route.schedules.length === 0) &&
            <span className="block px-1 py-4">
            This route doesn’t currently have any schedules. Once you’ve added the stops for your route, please
                create a schedule here</span>}
            <button className="text-white bg-gray-500 button" onClick={handleAddNew}><FontAwesomeIcon icon={faPlus}
                                                                                                      className="mr-2"/>Add
                Schedule
            </button>
            {editSchedule ? (<ScheduleModal closeAction={handleModalClose} schedule={editSchedule} routeStops={props.route.routeStops} permissionsLevel={props.permissionsLevel} />) : null}
        </>
    );
}

export default EditSchedules;