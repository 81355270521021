import { useEffect, useState } from 'react';
import { DateRange, DateRangePicker } from '../dates/DateRangePicker';
import Chart from "react-apexcharts";
import { fetchMostPopularRoutes, DateRangeParams } from './service';
import { PieData } from './types';
import { getFormikDateFromString } from '../dates/helper';

export function PopularRoutesPie() {
    const [dateRange, setDateRange] = useState<DateRange>({
        startDate: new Date(),
        endDate: new Date(),
    });
    const [data, setData] = useState<PieData[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        const params = {
            fromDate: getFormikDateFromString(dateRange.startDate.toISOString()),
            toDate: getFormikDateFromString(dateRange.endDate.toISOString()),
        } as DateRangeParams;
        fetchMostPopularRoutes(params).then((response) => {
            setLoading(false);
            let popData = response.data.map((r: any) => {
                return {
                    label: r.name,
                    value: r.passengersCount,
                } as PieData;
            });
            setData(popData);
        }).catch((err: any) => {
            console.error(err);
        })
    }, [dateRange]);

    return (
        <div className="flex flex-col h-full">
            <div className="flex items-center pb-2 mb-2 border-b-2">
                <h3 className="font-bold">Top {data.length || ''} Most Popular Routes</h3>
                <div className="ml-auto">
                    <DateRangePicker
                        value={dateRange}
                        onChange={setDateRange}
                    />
                </div>
            </div>
            <div className="flex items-center h-full justify-items-center">
                {(loading) ? (
                     <span className="m-auto">Loading data...</span>
                ) : null}
                {(!loading && data.length === 0) ? (
                     <span className="m-auto">No data available</span>
                ) : null}
                {!loading && data.length > 0 ? (
                    <div className="w-full m-auto">
                        <Chart type="pie" series={data.map(d => d.value)} options={{ labels: data.map(d => d.label) }} />
                    </div>
                ) : null}
            </div>
        </div>
    );
}

export default PopularRoutesPie;