import React, { useRef, useState } from "react";
import { CovidTests } from "./types";
import { getFormattedDateFromString } from "../dates/helper";
import { fetch as fetchCovidTests } from "./service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUndo } from "@fortawesome/free-solid-svg-icons";
import DateRangePicker, { DateRange } from "../dates/DateRangePicker";
import { useHistory } from "react-router";
import { Formik, Form } from "formik";
import { Link } from "react-router-dom";
import Alert from "../alerts/Alert";
import * as yup from "yup";
import UserAutoComplete from "../users/UserAutoComplete";
import { useAppSelector } from "../../app/hooks";
import { selectUserPolicies } from "../auth/slice";
import { getPermissionsLevel, Permission } from "../policies/helpers";
import { User } from "../users/types";

interface CovidTestParams {
    userId: number,
    fromDate: string,
    toDate: string,
}

export const Index = () => {
    const policies = useAppSelector(selectUserPolicies)
    const permissionLevel = getPermissionsLevel('covid', policies)

    const [covidTests, setCovidTests] = useState<CovidTests>()
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [user, setUser] = useState<User>()
    const history = useHistory();
    const lastMonth = new Date();

    lastMonth.setDate(lastMonth.getDate() - 30)
    lastMonth.setHours(0, 0, 0, 0);

    const todayEnd = new Date();
    todayEnd.setHours(23, 59, 59, 999);
    const [dateRange, setDateRange] = useState<DateRange>({
        startDate: lastMonth,
        endDate: todayEnd,
    });

    const startDate = useRef(dateRange.startDate);
    const endDate = useRef(dateRange.endDate);
    startDate.current = dateRange.startDate
    endDate.current = dateRange.endDate


    const refreshDateRange = () => {
        setDateRange({
            startDate: lastMonth,
            endDate: todayEnd
        })
        setCovidTests(undefined)
    }


    const handleGetCovidTests = (user: User) => {
        setCovidTests(undefined)
        setLoading(true)
        setErrorMessage('');
        const params = {
            userId: user.id,
            fromDate: startDate.current.toISOString(),
            toDate: endDate.current.toISOString(),
        } as CovidTestParams;
        fetchCovidTests(params).then((res) => {
            const data = res.data as CovidTests
            setCovidTests(data)
        }).catch((err) => {
            setErrorMessage(err.response.data.email)
        }).finally(() => {
            setLoading(false)
        })
    }

    const rules = yup.object({
        user: yup.object().required(),
    });

    const errorMessageHtml = errorMessage ? (<Alert type="error" message={errorMessage} />) : null;

    return (
        <div>
            <div className="mb-4">
                <h1 className="text-lg">Covid Tests {user ? 'For ' + user.firstName + ' ' + user.lastName : 'Info'}</h1>
            </div>
            <div>
                <Formik
                    initialValues={{ user: undefined }}
                    validationSchema={rules}
                    onSubmit={(values) => {
                        if (values.user) {
                            handleGetCovidTests(values.user)
                        }
                    }}
                >
                    {({ isValid, setFieldValue, submitForm }) => {
                        // @ts-ignore
                        return (
                            <Form className="flex items-center px-4 py-2 bg-white shadow">
                                <div>
                                    <DateRangePicker value={dateRange} onChange={setDateRange} />
                                </div>
                                <div className="ml-2">
                                    <UserAutoComplete value={user ? user?.firstName + ' ' + user?.lastName : ''} placeholder="Search by name" onChange={async ({ user }) => { await setFieldValue('user', user); setUser(user); submitForm(); }} />
                                </div>
                                <div className="ml-2">
                                    <button className="mr-2 bg-yellow-300 button disabled:bg-gray-200" type="submit" disabled={loading || !isValid}>
                                        {loading ? 'Loading...' : 'Search'}
                                    </button>
                                </div>
                                <div className="ml-auto">
                                    <button className="mr-2 text-white bg-gray-600 button" type="button"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            refreshDateRange()
                                        }} disabled={loading}>
                                        <FontAwesomeIcon icon={faUndo}
                                            className={'mr-1 text-sm'} />
                                        Reset
                                    </button>
                                    {permissionLevel >= Permission.Create && <button className="text-white bg-gray-600 button" type="button" onClick={(event) => {
                                        event.preventDefault();
                                        if (user) {
                                            history.push("/covid/new/" + user?.id);
                                        } else {
                                            history.push('/covid/add/new')
                                        }
                                    }}>&#43; Add Test
                                    </button>}
                                </div>
                            </Form>)
                    }}
                </Formik>
            </div>
            {covidTests && covidTests.length > 0 ? (
                <div className="p-4 mt-4 bg-white rounded shadow">
                    <table className="table w-full table-striped">
                        <thead>
                            <tr>
                                <th className="text-left">Person</th>
                                <th>Test ID</th>
                                <th>Test Date</th>
                                <th>Type</th>
                                <th>Result</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {covidTests.map(covidTest => {
                                const style = covidTest.positive ? 'text-red-500 text-center' : 'text-green-500 text-center'
                                return (
                                    <tr key={covidTest.id}>
                                        <td>{covidTest.user?.firstName + ' ' + covidTest.user?.lastName}</td>
                                        <td className="text-center">{covidTest.testId}</td>
                                        <td className="text-center">{getFormattedDateFromString(covidTest.date)}</td>
                                        <td className="text-center">{covidTest.type}</td>
                                        <td className={style}>{covidTest.positive ? 'Positive' : 'Negative'}</td>
                                        <td className="text-right">
                                            {covidTest.positive && <button onClick={() => { history.push('/covid/exposed/' + covidTest.id) }} className="p-1 underline">Exposed People</button>}
                                        </td>
                                    </tr>)
                            })}
                        </tbody>
                    </table>
                </div>
            ) : null}
            {covidTests && covidTests.length === 0 &&
                <div className="px-1 py-4">
                    No covid tests for this user click to <Link className="underline" to={"/covid/new/" + user?.id}>add a test</Link>
                </div>}
            {!covidTests && !user && !errorMessageHtml &&
                <div className="px-1 py-4">
                    Please enter an email and a date range to find tests
                </div>}
            {errorMessageHtml}
        </div>
    )
}