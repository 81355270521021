import { all } from '@redux-saga/core/effects';
import {watchLogin, watchLogout, watchRefresh} from '../features/auth/sagas';

// Export a collection of all our sagas
export default function* rootSaga() {
    yield all([
      watchLogin(),
      watchRefresh(),
      watchLogout(),
    ])
  }