import moment from "moment";

export const getFormattedDateFromString = (dateString:string|null) => {
    if (dateString){
        const date = new Date(dateString)
        let options = {day: 'numeric', month: 'short', year: 'numeric'};
        // @ts-ignore
        return date.toLocaleString('en-GB', options)
    }

    return ''
}

export const getFormattedDateTimeFromString = (dateString:string|null) => {
    if (dateString){
        const date = new Date(dateString)
        let options = {day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute:'numeric', second:'numeric'};
        // @ts-ignore
        return date.toLocaleString('en-GB', options)
    }

    return ''
}


export const getFormikDateFromString = (dateString:string|null) => {
    if (dateString){
        return moment(dateString).format('YYYY-MM-DD')
    }
    return ''
}

export const getFormikDateTimeFromString = (dateString:string|null) => {
    if (dateString){
        return moment(dateString).format("YYYY-MM-DDTHH:mm")
    }
    return ''
}


export const getDateFromString = (dateString:string|null) => {
    if (dateString){
        return new Date(dateString)
    }
    return false
}

export const formatDateTime = (dateTime: string|null, fallback = '-') => {
    if (!dateTime) {
        return fallback;
    }

    return moment.utc(dateTime).local().format('DD/MM/YYYY HH:mm a');
}

export const formatDate = (dateTime: string|null, fallback = '-') => {
    if (!dateTime) {
        return fallback;
    }

    return moment.utc(dateTime).local().format('DD/MM/YYYY');
}


export const getFormattedTimeFromString = (dateString: string|null, includeSeconds = false) => {
    if (dateString){
        const date = new Date(dateString)
        const options = includeSeconds ?  {hour: '2-digit', minute:'2-digit', second:'2-digit'} : {hour: '2-digit', minute:'2-digit'}
        // @ts-ignore
        return date.toLocaleTimeString('en-GB', options)
    }

    return ''
}

export const getFormattedTimeFromStringWithSeconds = (dateString: string|null) => {
    if (dateString){
        const date = new Date(dateString)
        const options = {hour: '2-digit', minute:'2-digit', second:'2-digit'}
        // @ts-ignore
        return date.toLocaleTimeString('en-GB', options)
    }

    return ''
}

export const getDateDifference = ({dateOneString, dateTwoString, units = null}: {dateOneString:string, dateTwoString:string, units:null|string}) => {
    const dateOne = new Date(dateOneString)
    const dateTwo = new Date(dateTwoString)
    // @ts-ignore
    const difference = Math.abs(dateOne - dateTwo)
    switch (units){
        case 'seconds':
            return Math.round(difference / 1000)
        case 'minutes':
            return Math.round(difference / (1000 * 60))
        case 'hours':
            return Math.round(difference / (1000 * 60 * 60))
    }
}
