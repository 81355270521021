import logo from "../../logo.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBan} from "@fortawesome/free-solid-svg-icons";

export const Index = () => {
    return (
        <div className="flex flex-col justify-center align-center" style={{width:"100%", height:"100%"}}>
            <img src={logo} alt="Zywpeit Logo" className="h-10 ml-auto mr-auto" />
            <span className="ml-auto mr-auto text-lg text-gray-400">
                <FontAwesomeIcon icon={faBan} className="mr-1 text-red-500"/>
                You are not authorized to view this resource</span>
        </div>
    )
}