import {useAppDispatch, useAppSelector} from "../../app/hooks";
import { selectDeleteRoute, setDeleteRoute, setEditRoute } from "./slice";
import Dialog from "../dialog/Dialog";
import Alert from "../alerts/Alert";
import { useState } from "react";
import { deleteRoute as apiDeleteRoute } from "./service";

export interface DeleteDialogProps {
    onDelete?: CallableFunction,
}

export function DeleteDialog (props: DeleteDialogProps) {
    const dispatch = useAppDispatch();
    const route = useAppSelector(selectDeleteRoute);
    const [errorMessage, setErrorMessage] = useState('');
    const [deleting, setDeleting] = useState(false);

    if (!route) {
        return null;
    }

    const cancelFunction = () => {
        setErrorMessage('');
        dispatch(setDeleteRoute(null));
    }

    const confirmFunction = () => {
        setDeleting(true);
        apiDeleteRoute(route).then(() => {
            if (props.onDelete) {
                props.onDelete(route);
            }
            dispatch(setDeleteRoute(null));
            dispatch(setEditRoute(null));
        }).catch(() => {
            setErrorMessage('Failed to delete route, please try again');
        }).finally(() => {
            setDeleting(false);
        });
    }
    
    return (
    <Dialog title="Delete Route" confirmFunction={confirmFunction} cancelFunction={cancelFunction} disabled={deleting} confirmText={deleting? 'Deleting...' : 'Delete'}>
        <p>Are you sure you want to delete route <strong>{route.name}</strong>?</p>
        {errorMessage ? (<Alert message={errorMessage} type="error" />) : null}
    </Dialog>
    );
}

export default DeleteDialog;