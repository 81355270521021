import React, { useEffect } from "react";
import { RouteProps, useLocation, Route } from "react-router-dom";
import {setHtmlTitle} from '../../app/helpers';


export interface AppRouteProps extends RouteProps {
    title: string,
}

export function AppRoute(props: AppRouteProps) {
    const location = useLocation();
    useEffect(() => {setHtmlTitle(props.title)}, [location]);
    const restProps = {...props} as RouteProps;
    return (<Route {...restProps}>{props.children}</Route>)
}

export default AppRoute;