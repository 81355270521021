import { AxiosRequestConfig } from 'axios';
import {apiAxios} from '../api/axios';
import { Organisation } from './types';

export function fetchOrganisations (params = {}) {
    return apiAxios.get('/organisations', {params} as AxiosRequestConfig);
}

export function fetchOrganisation (id: any) {
    return apiAxios.get('/organisations/'+id);
}

export function saveOrganisation (organisation: Organisation) {
    if (organisation.id) {
        return apiAxios.put('/organisations/'+organisation.id, organisation);
    }

    return apiAxios.post('/organisations', organisation);
}

export function deleteOrganisation(id: any) {
    return apiAxios.delete('/organisations/'+id);
}