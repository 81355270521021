import { useEffect, useState } from "react";
import { fetchUserPermissions } from "./service";
import { User } from "../users/types";
import { UserPermission } from "./types";
import DeletePermissionModal from "./DeletePermissionModal";
import NewPermissionModal from "./NewPermissionModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSyncAlt } from "@fortawesome/free-solid-svg-icons";


interface PermissionsListProps {
    user: User
}


export const PermissionsList = (props: PermissionsListProps) => {

    const [userPermissions, setUserPermissions] = useState<UserPermission[]>();
    const [deletePermission, setDeletePermission] = useState<UserPermission>()
    const [showNewPermissionModal, setShowNewModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const getLevelFromAction = (action: string) => {
        switch (action) {
            case "1":
                return "Read-only"
            case "2":
                return "Edit"
            case "3":
                return "Create/Edit"
            case "4":
                return "Full"
        }
    }
    useEffect(() => {
        handleGetPermissions();
    }, [])

    const handleGetPermissions = () => {
        if (!props.user.id) {
            setUserPermissions([]);
            return;
        }
        setLoading(true)
        fetchUserPermissions(props.user.id).then((res) => {
            setUserPermissions(res.data)
        }).catch(err => {
            console.error(err)
        }).finally(() => {
            setLoading(false)
        })
    }
    if (loading) {
        return (
            <div>
                Loading Permissions...
            </div>
        )
    }

    if (!props.user.id) {
        return (
            <div>
                <p>Please save the new user first before adding permissions</p>
            </div>
        );
    }

    return (
        <div>
            <div className="flex justify-end h-auto mb-4 ml-auto">
                <button className="mr-2 text-white bg-gray-600 button" type="button" onClick={() => setShowNewModal(true)} >
                    <FontAwesomeIcon icon={faPlus} className={'mr-1 text-sm ' + (loading ? 'fa-spin' : '')} />
                    Add Permission
                </button>
                {!loading && <button className="mr-2 text-white bg-gray-600 button" type="button" onClick={handleGetPermissions}>
                    <FontAwesomeIcon icon={faSyncAlt} className={'mr-1 text-sm ' + (loading ? 'fa-spin' : '')} />
                    Refresh
                </button>}
            </div>
            {userPermissions && userPermissions.length === 0 && <span className={"text-gray-500"}> No permissions for this user please add some.</span>}
            {userPermissions && userPermissions.length > 0 &&
                <table className="table w-full border-separate table-striped">
                    <thead>
                        <tr className="justify-center">
                            <th className="text-left">Organisation</th>
                            <th className="text-center">Type</th>
                            <th className="text-center">Level</th>
                            <th> </th>
                        </tr>
                    </thead>
                    <tbody>
                        {userPermissions?.map((userPermission) => {
                            return (
                                <tr>
                                    <td className="text-left">{userPermission.organisationTarget ? userPermission.organisationTarget.name : 'All'}</td>
                                    <td className="text-center">{userPermission.resource.map((resource, index) => { return (<span>{resource[0].toUpperCase() + resource.substring(1)}{(index + 1) !== userPermission.resource.length && ", "} </span>) })}</td>
                                    <td className="text-center">{getLevelFromAction(userPermission.action)}</td>
                                    <td className="text-center">
                                        <button className="text-red-400 button" type="button" onClick={() => { setDeletePermission(userPermission) }}>Delete</button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            }
            <p className="mt-4 text-sm text-gray-500">* All resources for that organisation</p>
            {deletePermission && <DeletePermissionModal confirmAction={handleGetPermissions} permission={deletePermission} closeAction={() => setDeletePermission(undefined)} />}
            {showNewPermissionModal && <NewPermissionModal user={props.user} confirmAction={handleGetPermissions} closeAction={() => setShowNewModal(false)} />}
        </div>
    )
}

export default PermissionsList;