import { useEffect, useState } from "react";
import ConfirmModal from "../modal/ConfirmModal";
import { User, NewUserPermission } from "./types";
import { saveUserPermission } from "./service";
import { setSuccessNotificationText } from "../notification/slice";
import { useAppDispatch } from "../../app/hooks";
import OrganisationSelect from "../organisations/Select";
import FormError from "../form/FormError";

interface NewPermissionProps {
    user: User,
    closeAction: CallableFunction
    confirmAction: CallableFunction
}

const relationships = [
    { value: "route-read", label: "Read-only Passengers & Routes Permissions" },
    { value: "route-edit", label: "Edit Passengers & Routes Permissions" },
    { value: "route-create", label: "Create/Edit Passengers & Routes Permissions" },
    { value: "route-delete", label: "Full Passengers & Routes Permissions" },
    { value: "carer-read", label: "Read-only Guardians Permissions" },
    { value: "carer-edit", label: "Edit Guardians Permissions" },
    { value: "carer-create", label: "Create/Edit Guardians Permissions" },
    { value: "carer-delete", label: "Full Guardians Permissions" },
    { value: "email-read", label: "Read-only Help Requests Permissions" },
    { value: "email-edit", label: "Edit Help Requests Permissions" },
    { value: "email-delete", label: "Full Help Requests Permissions" },
    { value: "driver-read", label: "Read-only Drivers Permissions" },
    { value: "driver-edit", label: "Edit Drivers Permissions" },
    { value: "driver-create", label: "Create/Edit Drivers Permissions" },
    { value: "driver-delete", label: "Full Drivers Permissions" },
    { value: "full-read", label: "Read-only Everything Permissions" },
    { value: "full-edit", label: "Edit Everything Permissions" },
    { value: "full-create", label: "Create/Edit Everything Permissions" },
    { value: "full-delete", label: "Full Everything Permissions" },
];

export const NewPermissionModal = (props: NewPermissionProps) => {
    const [permission, setPermission] = useState<NewUserPermission>({
        userId: props.user.id,
        relationship: relationships[0].value,
    });
    const [apiErrors, setApiErrors] = useState<any>({});
    const [error, setError] = useState('');
    const [saving, setSaving] = useState(false);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (props.user.organisationId) {
            setPermission({...permission, organisationId: props.user.organisationId});
        }
    }, [props.user]);

    const handleNewPermission = () => {
        if (saving) {
            return;
        }
        setError('');
        setApiErrors({});
        setSaving(true);
        saveUserPermission(permission).then(res => {
            dispatch(setSuccessNotificationText('Permission saved successfully'));
            props.confirmAction();
            props.closeAction();
        }).catch((err) => {
            setError('Failed to save user permission');
            if (err.response) {
                if (err.response.data.error) {
                    setError(err.response.data.error);
                }
                if (err.response.data) {
                    setApiErrors(err.response.data);
                }
                setSaving(false);
            }
        })
    }

    return (
        <ConfirmModal title={"New User Permission"} onClose={props.closeAction} onConfirm={handleNewPermission} disabled={saving}>
            <div>
                <label htmlFor="relationship" className="form-label">Permission Level</label>
                <select className="input-control" id="relationship" value={permission.relationship} onChange={(e) => setPermission({...permission, relationship: e.target.value})}>
                    {relationships.map(o => (
                        <option key={`option-${o.value}`} value={o.value}>{o.label}</option>
                    ))}
                </select>
                <FormError errors={apiErrors} name="relationship" />
            </div>
            <div>
                <label className="form-label">Organisation</label>
                <OrganisationSelect value={permission.organisationId} onChange={(o) => setPermission({...permission, organisationId: o?.id})}/>
                <FormError errors={apiErrors} name="organisationId" />
            </div>
            {error ? (
                <div>
                    <p className="p-1 text-xs text-red-500 form-error">{error}</p>
                </div>
            ) : null}
        </ConfirmModal>
    )
}

export default NewPermissionModal;