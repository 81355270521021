import { useState } from "react";
import { ReaderPromo} from "./types";
import { Formik, Form, Field } from "formik";
import * as Yup from 'yup';
import FormError from "../form/FormError";
import Alert from "../alerts/Alert";
import { deleteReaderPromo, fetchReaderPromo, saveReaderPromo } from './service';
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useHistory, useParams } from "react-router-dom";
import { ParamsWithId } from "../routing/types";
import { useEffect } from "react";
import ConfirmModal from "../modal/ConfirmModal";
import { MouseEvent } from 'react';
import Upload from "../media/Upload";
import Preview from "../media/Preview";
import Delete from "../media/Delete";
import { MediaFile } from "../media/types";
import Intro from "./Intro";
import { getFormikDateTimeFromString } from "../dates/helper";
import {AddRouteModal} from "./AddRouteModal";

const initialReaderPromo = {
    title: '',
    startDate: '',
    endDate: '',
    image: null,
    routes: [],
} as ReaderPromo;

export function Edit() {
    const [readerPromo, setReaderPromo] = useState<ReaderPromo>(initialReaderPromo);
    const [apiErrors, setApiErrors] = useState({});
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const history = useHistory();
    const { id } = useParams<ParamsWithId>();
    const [showDelete, setShowDelete] = useState(false);
    const [deleting, setDeleteing] = useState(false);
    const [addRoute, setAddRoute] = useState(false);

    useEffect(() => {
        if (!id) {
            setReaderPromo(initialReaderPromo)
            return;
        }
        setLoading(true);
        fetchReaderPromo(id).then(({ data }) => {
            const promo = { ...data };
            promo.startDate = promo.startDate ? getFormikDateTimeFromString(promo.startDate) : '';
            promo.endDate = promo.endDate ? getFormikDateTimeFromString(promo.endDate) : '';
            setReaderPromo(promo);
        }).catch((err: any) => {
            setReaderPromo(initialReaderPromo);
            history.goBack();
            console.error(err);
        }).finally(() => {
            setLoading(false);
        })
    }, [id]);


    function handleSubmit(values: ReaderPromo) {
        setError('');
        setApiErrors({});
        setMessage('');
        setSaving(true);
        const readerpromoData = { ...values, image: readerPromo.image, routes: readerPromo.routes }
        saveReaderPromo(readerpromoData).then(({ data }) => {
            if (!readerPromo.id) {
                history.push('/readerPromos/' + data.id + '/edit');
            }
            setReaderPromo(data);
            setSaving(false);
            setMessage('Reader Promotion saved successfully');
        }).catch((err: any) => {
            setSaving(false);
            setError('Failed to save reader promotion, please check for errors');
            if (err.response && err.response.data) {
                setApiErrors(err.response.data);
                return;
            }
            console.error(err);
        })
    }

    function handleDeleteClick(event: MouseEvent) {
        event.preventDefault();
        setShowDelete(true);
    }

    function handleDeleteConfirmed() {
        setDeleteing(true);
        deleteReaderPromo(readerPromo.id).then(() => {
            setShowDelete(false);
            setDeleteing(false);
            setMessage('Reader promotion deleted successfully, redirecting...');
            window.setTimeout(() => {
                history.replace('/readerPromos');
            }, 3000);
        }).catch(() => {
            setShowDelete(false);
            setError('Failed to delete reader promotion, please try again');
            setDeleteing(false);
        })
    }

    function handleUpload(mediaFile: MediaFile) {
        setReaderPromo({ ...readerPromo, image: mediaFile });
    }

    function handleDeleteImage() {
        setReaderPromo({ ...readerPromo, image: null })
    }

    const removeRoute = (id: number) => {
        let newReaderPromo = {...readerPromo}
        newReaderPromo.routes = newReaderPromo.routes.filter(r => r.routeId !== id);
        setReaderPromo(newReaderPromo);
    }

    const validation = Yup.object().shape({
        title: Yup.string().label('Title').required().max(255),
    });

    if (loading) {
        return (
            <div>
                <span>Loading reader promotion...</span>
            </div>
        )
    }

    return (
        <div>
            {addRoute && <AddRouteModal closeAction={() => {setAddRoute(false)}} readerPromo={readerPromo} setReaderPromo={setReaderPromo}/>}
            <div>
                <h1 className="text-lg">{readerPromo.id ? 'Edit' : 'New'} Reader Promotion</h1>
                <Intro />
            </div>
            <Formik
                initialValues={{ ...readerPromo }}
                onSubmit={handleSubmit}
                validationSchema={validation}
                validateOnBlur
            >
                {({ errors, isValid }) => {
                    const allErrors = { ...errors, ...apiErrors };
                    return (
                        <Form autoComplete="false">
                            <div className="p-4 my-2 bg-white rounded shadow">
                                <div>
                                    <label htmlFor="title" className="form-label">Title:</label>
                                    <Field className="input-control" name="title" id="title" placeholder="Title" />
                                    <FormError name="title" errors={allErrors} />
                                </div>
                                <div>
                                    <label htmlFor="startDate" className="form-label">Start Date:</label>
                                    <Field className="input-control" name="startDate" id="startDate" type="datetime-local" />
                                    <FormError name="startDate" errors={allErrors} />
                                </div>
                                <div>
                                    <label htmlFor="endDate" className="form-label">End Date:</label>
                                    <Field className="input-control" name="endDate" id="endDate" type="datetime-local" />
                                    <FormError name="endDate" errors={allErrors} />
                                </div>
                                <div>
                                    {readerPromo.image ? (
                                        <>
                                            <Preview mediaFile={readerPromo.image} />
                                            <div>
                                                <Delete mediaFile={readerPromo.image} onDelete={handleDeleteImage} />
                                            </div>
                                        </>
                                    ) : null}
                                </div>
                                <div>
                                    <Upload onUploaded={handleUpload} imageOnly={true} />
                                </div>
                                <FormError name="image" errors={allErrors} />
                                <div className="mt-4 mb-2">
                                    <label>Routes:</label>
                                </div>
                                <div>
                                    <div>
                                        {
                                            readerPromo.routes.map(route => {
                                                return (
                                                    <div className="flex items-center p-2 mb-1 border-2 rounded" key={'route_' + route.routeId}>
                                                        <span>{route.routeName}</span>
                                                        <div>
                                                            <button className={"text-red-500 m-2"} onClick={() => {
                                                                if (route.routeId) {
                                                                    removeRoute(route.routeId)
                                                                }}}> Remove</button>
                                                        </div>
                                                    </div>)
                                            })
                                        }
                                    </div>
                                    <button className="bg-yellow-300 button" onClick={(event) => {
                                        event.preventDefault();
                                        setAddRoute(true);
                                    }}>Add Route</button>
                                </div>
                            </div>
                            <Alert message={message} type="success" />
                            <Alert message={error} type="error" />
                            <div className="p-4 my-2 bg-white rounded shadow">
                                <div className="flex">
                                    <Link to="/readerPromos" className="button">
                                        <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                                        Back to Reader Promotions
                                    </Link>
                                    <button className="ml-auto bg-yellow-300 button" disabled={deleting || saving || !isValid}>{saving ? 'Saving...' : 'Save Reader Promotion'}</button>
                                    {readerPromo.id ? (
                                        <button className="text-red-400 button" type="button" disabled={saving} onClick={handleDeleteClick}>Delete Reader Promotion</button>
                                    ) : null}
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
            {showDelete ? (
                <ConfirmModal title="Delete Reader Promotion" onClose={() => setShowDelete(false)} onConfirm={handleDeleteConfirmed} disabled={deleting || saving}>
                    Are you sure you want to delete this reader promotion?
                </ConfirmModal>
            ) : null}
        </div>
    );
}