import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt, faUpload } from "@fortawesome/free-solid-svg-icons";
import { Types } from "./types";
import { exportTables } from "./service";
import { getPermissionsLevel, Permission } from "../policies/helpers";
import { useAppSelector } from "../../app/hooks";
import { selectUserPolicies } from "../auth/slice";
import moment from "moment";


export const Index = () => {
    const [selectedTypes, setSelectedTypes] = useState<Array<string>>([])
    const [selectAll, setSelectAll] = useState(false)
    const [exporting, setExporting] = useState(false)
    const policies = useAppSelector(selectUserPolicies)

    const types: Types = [
        { name: 'appNotification', label: 'App Notifications' },
        { name: 'appPromo', label: 'App Promos' },
        { name: 'box', label: 'Devices' },
        { name: 'card', label: 'Cards', parent: 'passenger' },
        { name: 'carer', label: 'Carers' },
        { name: 'covidTest', label: 'Covid Tests', parent: 'covid' },
        { name: 'discount', label: 'Discounts' },
        { name: 'driver', label: 'Drivers' },
        { name: 'driverRoute', label: 'Driver Routes', parent: 'route' },
        { name: 'email', label: 'Emails' },
        { name: 'journey', label: 'Journeys', parent: 'route' },
        { name: 'organisation', label: 'Organisations' },
        { name: 'passengerUser', label: 'Passengers', parent: 'passenger' },
        { name: 'readerPromo', label: 'Reader Promotions' },
        { name: 'route', label: 'Routes' },
        { name: 'routeStop', label: 'Route Stops', parent: 'route' },
        { name: 'schedule', label: 'Schedules', parent: 'route' },
        { name: 'scheduleStop', label: 'Schedule Stops', parent: 'route' },
        { name: 'session', label: 'Sessions' },
        { name: 'sessionPassenger', label: 'Passenger Sessions', parent: 'passenger' },
        { name: 'sessionStop', label: 'Session Stops', parent: 'session' },
        { name: 'stop', label: 'Stops' },
        { name: 'user', label: 'Users' },
        { name: 'vehicle', label: 'Vehicles' },
    ];


    const availableTypes = types.filter(type => getPermissionsLevel(type.parent ?? type.name, policies, undefined, true) >= Permission.Read)

    const handleGetExport = () => {
        setExporting(true)
        exportTables({ types: selectedTypes }).then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Zywpeit_Export_${moment().format('YYYY-MM-DDHHmmss')}.zip`);
            document.body.appendChild(link);
            link.click();
        }).finally(() => {
            setExporting(false)
        })
    }
    const handleChecked = (target: HTMLInputElement) => {
        if (target.checked) {
            setSelectedTypes(selectedTypes.concat(target.value))
        } else {
            setSelectAll(false);
            setSelectedTypes(selectedTypes.filter(type => type !== target.value));
        }
    }


    const handleSelectAllChecked = () => {
        const typeNames: React.SetStateAction<string[]> = [];
        if (!selectAll) {
            for (const type of availableTypes) {
                if (type.name) {
                    typeNames.push(type.name)
                }
            }
            setSelectAll(true);
            setSelectedTypes(typeNames);
        } else {
            setSelectedTypes([]);
            setSelectAll(false)
        }
    }

    return (
        <>
            <div className="flex px-4 py-2 m-4 bg-white shadow">
                <div>
                    <h1 className="text-lg font-semibold">Reporting Exports</h1>
                </div>
                <div className="ml-auto">
                    <button className="mr-2 text-white bg-gray-600 button" type="button" disabled={exporting || selectedTypes.length === 0}
                        onClick={() => handleGetExport()}>   {!exporting && <FontAwesomeIcon className={'mr-1 text-sm'} icon={faUpload} />}
                        {exporting && <FontAwesomeIcon icon={faSyncAlt} className={'mr-1 text-sm fa-spin'} />}
                        {exporting ? 'Exporting...' : 'Export'}</button>
                </div>
            </div>
            <div className={"flex justify-center"}>
                <div className={"w-full"}>
                    <div className="p-4 mx-4 mb-4 bg-white shadow">
                        <div>
                            <div>
                                <table className="table w-full table-striped">
                                    <thead>
                                        <tr className="justify-center">
                                            <th className="w-6 text-left"><input type="checkbox"
                                                onChange={() => handleSelectAllChecked()}
                                                checked={selectAll} /></th>
                                            <th className="text-left">Export Type</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {availableTypes.map((type) => {
                                            return (<tr>
                                                <td className="w-6 text-left">
                                                    {<input onChange={(event) => {
                                                        handleChecked(event.target)
                                                    }} type="checkbox"
                                                        checked={(!!type.name && selectedTypes.indexOf(type.name) !== -1)}
                                                        value={type.name} />}</td>
                                                <td className={"text-left"}>{type.label}</td>
                                            </tr>)
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}