import {Cards} from "./cardInterface";
import React, {useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faIdCard } from '@fortawesome/free-solid-svg-icons'

interface Props {
    cards:Cards
}
export const CardsExpandable = (props: Props) => {
    const [open, setOpen] = useState(false)


    return (<>
        <button onClick={(event) => {event.preventDefault();  setOpen(!open)}} className="hover:underline"><FontAwesomeIcon icon={faIdCard}/> {open ? 'Hide Cards' : 'Show Cards'}</button>
        {open && props.cards.map((card) => {return (
                <div key={card.id} className="mt-1">
                    #{card.cardNumber}
                </div>
            )})}
        </>
    );
}