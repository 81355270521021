import {FormEvent, useState, MouseEvent, useEffect} from 'react';
import Modal from '../modal/Modal';
import { Schedule, ScheduleStop } from './types';
import { deleteSchedule, saveSchedule } from './service';
import Alert from '../alerts/Alert';
import FormError from '../form/FormError';
import ConfirmModal from '../modal/ConfirmModal';
import {setErrorNotificationText, setSuccessNotificationText} from "../notification/slice";
import {useAppDispatch} from "../../app/hooks";
import {Permission} from "../policies/helpers";
import {RouteStop} from "../routes/types";

export interface ScheduleModalProps {
    closeAction: CallableFunction,
    schedule: Schedule,
    permissionsLevel: number,
    routeStops: RouteStop[]
}

export function ScheduleModal(props: ScheduleModalProps) {
    const dispatch = useAppDispatch();
    const [schedule, setSchedule] = useState<Schedule>({ ...props.schedule });
    const [routeStopIds, setRouteStopIds] = useState<Array<number>>([]);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [apiErrors, setApiErrors] = useState({});
    const [saving, setSaving] = useState(false);
    const [isDeleteing, setIsDeleteing] = useState(false);

    function handleDeleteClick(event: MouseEvent<HTMLElement>) {
        event.preventDefault();
        setErrorMessage('');
        setSuccessMessage('');
        setIsDeleteing(true);
    }

    useEffect(() => {
        const initialRouteStopIds = (schedule.scheduleStops.map((stop) => {
            return (stop.routeStop.id)
        }))
        setRouteStopIds(initialRouteStopIds);
    }, [schedule.scheduleStops])

    function handleOnSubmit(event: FormEvent) {
        event.preventDefault();
        if (saving) {
            return;
        }
        setSaving(true);
        setSuccessMessage('');
        setErrorMessage('');
        setApiErrors({});
        saveSchedule(schedule).then(() => {
            dispatch(setSuccessNotificationText('Schedule Saved'));
            props.closeAction();
            setSaving(false);
        }).catch(error => {
            setErrorMessage('Failed to save schedule, check for errors');
            if (error.response && error.response.data) {
                setApiErrors(error.response.data);
            }
            setSaving(false);
        })
    }

    function showMinsFromLastStop(index: number): boolean {
        if (index === 1) {
            return false;
        }
        if (schedule.scheduleStops[index] && schedule.scheduleStops[index].disabled) {
            return false;
        }
        const disabledStops = schedule.scheduleStops.slice(0, index).filter((scheduleStop) => scheduleStop.disabled);
        return disabledStops.length !== index;
    }

    function handleStopDisabledInput(routeStop: RouteStop) {
        let newStops:ScheduleStop[]
        if (routeStopIds.includes(routeStop.id)){
            newStops = schedule.scheduleStops.filter((scheduleStop) => scheduleStop.routeStop.id !== routeStop.id)
        } else {
            newStops = [...schedule.scheduleStops, {disabled: false, routeStop: routeStop}]
        }
        const newSchedule = { ...schedule, scheduleStops: newStops};
        setSchedule(newSchedule);
    }

    function handleDeleteConfirmed() {
        setSaving(true);
        deleteSchedule(schedule).then(() => {
            setSaving(false);
            props.closeAction();
        }).catch(error => {
            dispatch(setErrorNotificationText('Failed to delete schedule, make sure schedule is not used for any current journeys and try again'));
            setSaving(false);
            setIsDeleteing(false);
            props.closeAction();
        })
    }
    let count = 0;

    return (
        <>
            <Modal closeAction={props.closeAction} title="Schedule">
                <form onSubmit={handleOnSubmit}>
                    <Alert type="success" message={successMessage} />
                    <Alert type="error" message={errorMessage} />
                    <div className="mb-2">
                        <label className="form-label">Schedule Name:</label>
                        <input disabled={props.permissionsLevel === Permission.Read} type="text" className="input-control" required value={schedule.name} onChange={(event) => { setSchedule({ ...schedule, name: event.target.value }) }} placeholder="Name..." />
                        <FormError errors={apiErrors} name="name" />
                    </div>
                    <div>
                        <span className="block mb-2 ml-1 text-sm text-gray-500">Stops:</span>
                        {props.routeStops.map((routeStop: RouteStop, index) => {

                            let scheduleStop: ScheduleStop | undefined = undefined;
                            if (schedule.scheduleStops) {
                                scheduleStop = schedule.scheduleStops.find((scheduleStop: ScheduleStop) => {
                                    return (scheduleStop.routeStop.id === routeStop.id)
                                });
                            }
                            if (scheduleStop) {
                                count++;
                            }
                            const colour = (!scheduleStop || scheduleStop.disabled) ? 'text-gray-400': ''
                            return (
                                <div className="flex items-center p-2 my-1 border shadow" key={routeStop.id + '_' + index}>
                                    <span className={'mr-auto font-semibold transition-color ' + colour} >{routeStop.stop?.name}</span>
                                    {scheduleStop && showMinsFromLastStop(count) &&
                                    <div>
                                        <input disabled={props.permissionsLevel === Permission.Read} type="number" step="1" min="0" className="w-20 mb-0 input-control" placeholder="10" defaultValue={scheduleStop.timeFromLastStop} onChange={(event) => {
                                            const newSchedule = { ...schedule };
                                            // @ts-ignore
                                            newSchedule.scheduleStops.find((stop) => {return stop.routeStop.id === routeStop.id}).timeFromLastStop = parseInt(event.target.value);
                                            setSchedule(newSchedule);
                                        }}/>
                                        <span> mins</span>
                                    </div>
                                    }
                                    <div className="ml-6">
                                        <label className="text-red-400">
                                            <input disabled={props.permissionsLevel === Permission.Read} type="checkbox" className="mr-2" checked={!scheduleStop || scheduleStop.disabled} onChange={(event) => { handleStopDisabledInput(routeStop) }} />
                                            <span>Disabled</span>
                                        </label>
                                    </div>
                                </div>)
                        })}
                    </div>
                    <div className="flex mt-4 mb-2">
                        {props.permissionsLevel >= Permission.Edit && <button className="ml-auto bg-yellow-300 button"
                                 disabled={saving}>{saving ? 'Saving...' : 'Save Schedule'}</button>}
                        {schedule.id && props.permissionsLevel >= Permission.Delete ? (<button className="ml-2 text-red-400 button" disabled={saving}
                                                 onClick={handleDeleteClick}>Delete Schedule</button>) : null}
                        <button className="ml-2 button" onClick={() => { props.closeAction() }}>Cancel</button>
                    </div>
                </form>
            </Modal>
            {isDeleteing ? (
                <ConfirmModal title="Delete Schedule" onClose={() => {setIsDeleteing(false)}} onConfirm={handleDeleteConfirmed}>
                    <p>Are you sure you want to delete schedule <strong>{schedule.name}?</strong>?</p>
                    <p>This will also delete any associated Journeys and their Sessions, this cannot be undone.</p>
                </ConfirmModal>
            ) : null}
        </>
    );
}

export default ScheduleModal;