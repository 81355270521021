import {Form, Formik} from "formik";
import FormError from "../form/FormError";
import React, {useEffect, useState} from "react";
import {DropDownOption, DropDownOptions} from "../dropdown/types";
import {bulkUpload, getExample} from "./service";
import {GetExampleParams, UploadResponses} from "./types";
import {fetchJourneyDropDown} from "../journeys/service";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSyncAlt, faUpload} from "@fortawesome/free-solid-svg-icons";
import {UploadResultTable} from "./UploadResultTable";
import Alert from "../alerts/Alert";
import RouteAutoComplete, {RouteAutoCompleteChangeEvent} from "../routes/RouteAutocomplete";

interface BulkUploadProps {
    type:string,

}

export const BulkUpload = (props:BulkUploadProps) => {
    const [errorMessage, setErrorMessage] = useState('')
    const [file, setFile] = useState<File>();
    const [response, setResponse] = useState<UploadResponses>([]);
    const [uploading, setUploading] = useState(false)
    const [route, setRoute] = useState<DropDownOption>()
    const [loadingJourneys, setLoadingJourneys] = useState(false)
    const [downloading , setDownloading] = useState(false)
    const FileDownload = require('js-file-download');
    const [routeId, setRouteId] = useState<number>();
    const [journeyId, setJourneyId] = useState('');
    const [journeys, setJourneys] = useState<DropDownOptions>([])

    const handleChangeRoute = (event: RouteAutoCompleteChangeEvent) => {
        setJourneyId('')
        setJourneys([])
        setRouteId(event.route?.id)
        setRoute(event.route)
        if (event.route) {
            if (props.type === 'reservations') {
                setLoadingJourneys(true)
                fetchJourneyDropDown({routeId:event.route.id}).then((response) => {
                    const journeyDD = response.data as DropDownOptions
                    if (journeyDD.length > 0){
                        setJourneys(response.data as DropDownOptions)
                    } else {
                        setJourneys([{value:'No journeys', id:0}])
                    }
                }).finally(() => {
                    setLoadingJourneys(false)
                })
            }
        }
    }

    const handleSelectJourney = (id:string) => {
        setJourneyId(id)
    }

    const handleUploadFile = (onSuccess:CallableFunction) => {
        if (file) {
            bulkUpload({file, type: props.type, routeId:routeId, journeyId:parseInt(journeyId)}).then(response  =>{
                const data = response.data as UploadResponses
                setResponse(data)
                setFile(undefined)
                onSuccess()
            }).catch((error) => {
                if (error.response && error.response.data.message && error.response.status === 422)  {
                    setErrorMessage(error.response.data.message)
                } else {
                    setErrorMessage('Error with file please make sure columns match example csv')
                }
            }).finally(() => {
                setUploading(false)
            })
        }
    }

    const getType = () => {
        return props.type === 'carers' ? 'guardians' : props.type
    }
    const handleGetExample = (params:GetExampleParams) => {
        setDownloading(true);
        getExample(params).then((response) => {
            FileDownload(response.data, getType() + '_example.csv');
        }).finally(() => {
            setDownloading(false)
        })
    }

    const needsRoutes = ['stops', 'reservations']

    useEffect(() => {
        setResponse([])
        setErrorMessage('')
        setFile(undefined)
        if (needsRoutes.includes(props.type)) {
            setRouteId(undefined)
            setRoute(undefined)
            setJourneyId('')
            setJourneys([])
        }
    },[props.type])

    const errorMessageHTML = errorMessage ? <Alert message={errorMessage} type="error"/> : null
    const cols = needsRoutes.includes(props.type) ? "grid-cols-3" : "grid-cols-2"
    return (
        <div className="flex-col flex-1">
            {needsRoutes.includes(props.type) &&
            <div className="p-4 flex-row mb-3 bg-white shadow">
                <h3 className={"mb-2 text-center  "}><span
                    className={"font-bold"}>Note: </span>
                    <span className={"font-semibold"}>
                        {props.type === 'stops' && "Select a route and add stops to that route."}
                        {props.type === 'reservations' && "Select a journey and add reservations to that journey."}

                    </span>
                </h3>
            </div>}
        <div className="m-2 align-center flex-1">
            {response.length < 1 && <div className={"grid gap-2 " + cols }>
                {needsRoutes.includes(props.type) &&
                <div>
                <div>
                        <h3 className="mb-2 font-medium text-md">Route</h3>
                </div>
                    <div>
                        <RouteAutoComplete value={route ? route.value : ''} onChange={handleChangeRoute} />
                    </div>
                    {props.type === 'reservations' &&
                    <>
                        <div>
                            <h3 className="mb-2 font-medium text-md">Journey{loadingJourneys &&
                            <FontAwesomeIcon icon={faSyncAlt} className={'ml-2 text-sm fa-spin'}/>}</h3>
                        </div>
                        <div className="mb-2 grid grid-cols-2 gap-2">
                            <div className="mb-2">
                                <select className={"input-control"}  value={parseInt(journeyId)} onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                    handleSelectJourney(event.target.value)
                                }}>
                                    <option value=''> </option>
                                    {journeys && journeys.map((journey) => {
                                        return (
                                            <option key={journey.id} value={journey.id}>{journey.value}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>
                    </>
                    }
                </div>
                }
                <div className="m-3 flex justify-center align-center">
                    <Formik
                        onSubmit={(
                            values
                        ) => {
                            setTimeout(() => {
                                setErrorMessage('');
                                setResponse([])
                                setUploading(true);
                                handleUploadFile(() => {
                                    values.file = ''
                                })
                            }, 500);
                        }}
                        validateOnBlur
                        initialValues={{file: ''}}>
                        {({values, errors, handleChange,handleReset, handleSubmit}) => {
                            const allErrors = {...errors}
                            return (
                                <Form className={"text-center"}>
                                    <h2 className="mb-2 font-medium text-lg">Bulk upload {getType()}</h2>
                                    <label htmlFor="file"
                                           className="inline-block w-auto text-sm text-white bg-gray-500 cursor-pointer button">
                                        <input type="file" accept=".csv" className="invisible w-0" id="file" name="file"
                                               placeholder="Choose File"
                                               onChange={(event: React.FormEvent<HTMLInputElement>) => {
                                                   if (!event.currentTarget.files) {
                                                       return;
                                                   }
                                                   setErrorMessage('');
                                                   setFile(event.currentTarget.files[0]);
                                                   handleChange(event);
                                               }}/>
                                        <FontAwesomeIcon icon={faUpload} className="mr-1"/>
                                        <span>Choose a File</span>
                                    </label>
                                    <FormError name="file" errors={allErrors}/>
                                    {errorMessageHTML}
                                    <div className={"mt-2"}>
                                        {uploading ? (
                                            <button className="bg-yellow-400 button" type="button"
                                                    disabled={true}>Uploading...</button>
                                        ) : (
                                            <button className="bg-yellow-400 button" type="submit"
                                                    disabled={!file || (props.type === 'stops' && (!routeId || routeId === 0)) || (props.type === 'reservations' && (!journeyId || parseInt(journeyId) === 0))}
                                                    onChange={(event => {handleReset(event); handleSubmit()})}
                                            >Upload {file && values.file && values.file.replace(/^.*[\\/]/, '')}</button>
                                        )}
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>
                </div>
                <div className="m-3 flex justify-center">
                    <div className="block">
                        <h2 className="mb-2 font-medium text-lg">
                            Help
                        </h2>

                        <div>
                            <h3 className="mb-2 mt-2 font-medium text-md">Example csv</h3>
                        </div>
                        <button className="bg-yellow-400 button" type="button" disabled={downloading}
                                onClick={() => handleGetExample({type: props.type})}>{downloading ? 'Downloading...' : 'Download'}
                        </button>
                    </div>
                </div>
            </div>}

            {response.length > 0 &&
            <div className="flex flex-col items-center">
                <div>
                    <h2 className="mb-2 font-medium text-lg">Bulk upload {getType()} result</h2>
                </div>
                 <UploadResultTable responseArray={response}/>
                <div className="p-2 bg-white">
                    <button className="bg-yellow-400 button" type="button"
                            onClick={() => setResponse([])}> Back
                    </button>
                </div>
            </div>}
    </div>
            </div>)
}