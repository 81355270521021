import Modal from "../modal/Modal";
import RouteMap, { RouteMapWaypoint} from "../routes/RouteMap";
import React, {useEffect, useState} from "react";
import {Route} from "./types";
import {fetchRoute} from "./service";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSyncAlt} from "@fortawesome/free-solid-svg-icons";
import {convertRouteStops} from "./helpers";



interface LiveRouteModalProps {
    closeModal:CallableFunction,
    routeId: number
}

export const RouteModal = (props:LiveRouteModalProps) => {
    const [route, setRoute] = useState<Route>()
    const [loading, setLoading] = useState(false)
    const [waypoints, setWaypoints] = useState<RouteMapWaypoint[]>([])


    const handleGetRoute = () => {
        fetchRoute(props.routeId).then(async function (res){
            const data = res.data as Route
            setRoute(data)
        }).catch((err) => {
            console.error(err)
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() =>{
        if (route) {
            setWaypoints(convertRouteStops(route))
        }
    }, [route])

    useEffect(() => {
        setLoading(true)
        handleGetRoute();
    }, [])


    return (<>
            <Modal title={"Route Map"} closeAction={props.closeModal}>
                {loading && <div className="text-center"><FontAwesomeIcon icon={faSyncAlt} className={'ml-2 text-sm fa-spin'}/></div> }
                {waypoints && route && <RouteMap waypoints={waypoints}/>}
            </Modal>
        </>
    )
}