import { useEffect, useState } from "react";
import {fetchDevices, unprovisionDevice} from "./service";
import {Device, Devices} from "./types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectDeleteDevice, selectEditDevice, setDeleteDevice, setEditDevice } from "./slice";
import { selectUserPolicies } from "../auth/slice";
import { getPermissionsLevel, getPermissionsLevelForEntity, Permission } from "../policies/helpers";
import ConfirmModal from "../modal/ConfirmModal";
import {setErrorNotificationText, setSuccessNotificationText} from "../notification/slice";

export const Index = () => {

    const policies = useAppSelector(selectUserPolicies)
    const permissionsLevel = getPermissionsLevel('box', policies)

    const [unprovision, setUnprovision] = useState<Device>();
    const [unprovisioning, setUnprovisioning] = useState(false);
    const [devices, setDevices] = useState<Devices>([])
    const [search, setSearch] = useState('')
    const [loading, setLoading] = useState(true)
    const dispatch = useAppDispatch();

    const deleteDevice = useAppSelector(selectDeleteDevice)
    const editDevice = useAppSelector(selectEditDevice)

    const getDevices = () => {
        setLoading(true);
        setDevices([])
        const params = {
            search: search,
        }
        fetchDevices(params).then((response) => {
            const data = response.data as Devices
            setDevices(data)
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleUnprovisionDevice = (device: Device) => {
        setUnprovisioning(true)
        unprovisionDevice(device).then(() => {
            dispatch(setSuccessNotificationText('Device unprovisioned'))
            getDevices()
        }).catch((error) => {
            dispatch(setErrorNotificationText('Failed to unprovision device'))
            console.error(error)
        }).finally(() => {
            setUnprovisioning(false)
            setUnprovision(undefined);
        })
    }

    useEffect(() => {
        if (!editDevice && !deleteDevice) {
            getDevices()
        }
    }, [deleteDevice, editDevice])

    return (
        <div>
            {unprovision && <ConfirmModal loading={unprovisioning} onClose={() => setUnprovision(undefined)} onConfirm={() => {handleUnprovisionDevice(unprovision)}} title={"Unprovision Device"}>
                Are you sure you want to unprovision device with serial number {unprovision.serial}
            </ConfirmModal>}
            <div className="mb-4">
                <h1 className="text-lg">Devices</h1>
            </div>
            <div className="flex px-4 py-2 mb-2 bg-white shadow">
                <form action="" onSubmit={(event) => { event.preventDefault(); getDevices(); }} className="flex mr-auto">
                    <div>
                        <input type="search" value={search} onChange={(event => setSearch(event.target.value))} placeholder="Search..." className="p-2 mr-2 border-2 border-gray-100 rounded" />
                    </div>
                    <div>
                        <button className="bg-yellow-300 button" disabled={loading}>Search</button>
                    </div>
                </form>
                <div className="ml-auto">
                    <button className="mr-2 text-white bg-gray-600 button" type="button" onClick={(event) => { event.preventDefault(); getDevices(); }} disabled={loading}>
                        <FontAwesomeIcon icon={faSyncAlt} className={'mr-1 text-sm ' + (loading ? 'fa-spin' : '')} />
                        {loading ? 'Loading...' : 'Refresh'}
                    </button>
                    {permissionsLevel >= Permission.Create && <button className="text-white bg-gray-600 button" type="button" onClick={(event) => {
                        event.preventDefault();
                        dispatch(setEditDevice({}))
                    }}>&#43; New Device</button>}
                </div>
            </div>
            <div className="w-full p-4 bg-white shadow">
                <table className="table w-full table-striped">
                    <thead>
                        <tr>
                            <th className="text-left">Serial No.</th>
                            <th>Provisioned</th>
                            <th>Contact</th>
                            <th>Last Active Route</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {devices.map(device => {
                            const devicePermissions = getPermissionsLevelForEntity('box', device, policies)
                            return (
                                <tr key={'device_' + device.id}>
                                    <td>{device.serial}</td>
                                    <td className="text-center">{device.provisioned ? 'Y' : 'N'}</td>
                                    <td className="text-center">{device.contactUserName || '-'}</td>
                                    <td className="text-center">N/A</td>
                                    <td className="text-right">
                                        {devicePermissions > 3 && <button onClick={() => {dispatch(setDeleteDevice(device))}} className="m-1 text-sm font-semibold text-red-600 bg-gray-100 rounded button">Delete</button>}
                                        {!device.provisioned && devicePermissions > 2 && <button onClick={() => {dispatch(setEditDevice(device))}} className="m-1 text-sm font-semibold text-white bg-gray-600 rounded button">Edit</button>}
                                        {device.provisioned && devicePermissions > 2 && <button onClick={() => {setUnprovision(device)}} className="m-1 text-sm font-semibold text-white bg-gray-600 rounded button">Unprovision</button>}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}