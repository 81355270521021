import { AxiosRequestConfig } from 'axios';
import {apiAxios} from '../api/axios';
import { NewUserPermission, User, UserPermission } from './types';

export interface FetchUsersParams {
    notifiable? : boolean,
    search?: string,
    status?: string,
    organisationId?: number,
    allTypes?: boolean,
    perPage?: number,
};

export function fetchUsers(params: FetchUsersParams = {}) {
    return apiAxios.get('/users', {params} as AxiosRequestConfig);
}

export function fetchUser(id: any)
{
    return apiAxios.get('/users/'+id);
}

export function saveUser(user: User)
{
    if (user.id) {
        return apiAxios.put('/users/'+user.id, user);
    }
    return apiAxios.post('/users', user);
}

export function deleteUser(user: User) {
    return apiAxios.delete('/users/'+user.id);
}

export function fetchUsersDropdown() {
    return apiAxios.get('/users/dropdown')
}

export function fetchUserPermissions(userId: number) {
    return apiAxios.get(`/userPermissions/${userId}`);
}

export function deleteUserPermission(permission: UserPermission) {
    return apiAxios.post('/userPermissions/delete', permission);
}

export function saveUserPermission(permission: NewUserPermission) {
    return apiAxios.post('/userPermissions', permission);
}