import React, {useEffect, useState} from "react";
import {RouteSessionTable} from "../reports/RouteSessionTable";
import {Link, useParams} from "react-router-dom";
import {ParamsWithId} from "../routing/types";
import {fetchById, fetchExposedSessions, getCovidCsvReport} from "./service";
import {CovidTest} from "./types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faFileDownload, faSyncAlt} from "@fortawesome/free-solid-svg-icons";
import Alert from "../alerts/Alert";


export const ExposedPeopleReport = () => {

    const [reportLoading, setReportLoading] = useState(false)
    const [testLoading, setTestLoading] = useState(false)
    const [routeSessions, setRouteSessions] = useState()
    const [exporting, setExporting] = useState(false)
    const [covidTest, setCovidTest] = useState<CovidTest>()
    const [errorMessage, setErrorMessage] = useState('')
    const { id } = useParams<ParamsWithId>();
    const FileDownload = require('js-file-download');


    useEffect(() => {
        getCovidTest()
        getCovidReport()
    }, [])

    const getCovidReport = () => {
        setReportLoading(true)
        fetchExposedSessions(parseInt(id)).then((response) => {
            const data = response.data;
            setRouteSessions(data);
        }).catch(err => {
            console.error(err)
        }).finally(() => {
            setReportLoading(false)
        })
    }

    const getCovidTest = () => {
        setTestLoading(true)
        fetchById(parseInt(id)).then((res) => {
            const data = res.data as CovidTest
            setCovidTest(data)
        }).catch((err) => {
            console.error(err)
        }).finally(() => {
            setTestLoading(false)
        })
    }

    const downloadCsv = () => {
        setExporting(true)
        setErrorMessage('')
        getCovidCsvReport(parseInt(id)).then((response) => {
            let filename = 'passengers_exposed_to_covid.csv'
            if (covidTest) {
                filename = 'exposed_to_' + covidTest.user?.firstName + covidTest.user?.lastName+'.csv'
            }
            FileDownload(response.data, filename);
        }).catch(() => {
            setErrorMessage('Error downloading csv')
        }).finally(() => {
            setExporting(false)
        })
    }

    const errorMessageHTML = errorMessage ? <Alert message={errorMessage} type="error"/> : null


    return (
        <>
            <div>
                <div className="flex px-4 py-2 mb-2 bg-white shadow">
                    <div className="flex w-full flex-row items-center">
                        {covidTest && covidTest.user && <span className="text-lg font-semibold">
                    Bus journeys which could have been exposed to user {covidTest.user?.firstName + ' ' + covidTest.user?.lastName } 4 days before first positive test
                </span>}
                        <div className="ml-auto">
                            <button type="button" onClick={() => {downloadCsv()}} className="bg-gray-300 mr-2 button"
                                    disabled={reportLoading}>
                                <span><FontAwesomeIcon icon={faFileDownload} className="mr-1"/>{exporting ? '...Exporting' : 'Export to Csv'}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {errorMessageHTML}
            {routeSessions !== undefined && <RouteSessionTable routeSessions={routeSessions} loading={reportLoading || testLoading}/>}
            {(reportLoading || testLoading) && <div className="text-center"><FontAwesomeIcon icon={faSyncAlt} className={'ml-2 text-sm fa-spin'}/></div> }
            {!(reportLoading || testLoading) && <div className="flex">
                <Link to="/covid" className="button">
                    <FontAwesomeIcon icon={faArrowLeft} className="mr-2"/>
                    Back to Tests
                </Link>
            </div>}
        </>
    )
}