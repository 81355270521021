import { useEffect } from 'react';
import { ChangeEvent, useState } from 'react';
import { Organisation } from './types';
import { fetchOrganisations, fetchOrganisation } from './service';

export type OnChangeCallback = (organisation?: Organisation) => void;

export interface SelectProps {
    onChange: OnChangeCallback,
    resource?: string
    disabled?: boolean
    value?: number
}

interface OrganisationSelectParams {
    search:string
    perPage: number
    resource?: string
}

export function Select(props: SelectProps) {
    const [search, setSearch] = useState('');
    const [organisations, setOrganisations] = useState([]);
    const [singleOrg, setSingleOrg] = useState(false);
    const [organisation, setOrganisation] = useState<Organisation | null>(null);
    const [moreResult, setMoreResults] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true)
        fetchOrganisations({search:'', perPage: 10, resource:props.resource }).then(({ data }) => {
            if (data.items.length === 1 && props.value) {
                setSingleOrg(true)
            }
        }).finally(() => {
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        if (props.value) {
            fetchOrganisation(props.value).then(({ data }) => {
                setOrganisation(data);
                setSearch(data.name)
            })
        }
    }, [props.value])

    useEffect(() => {
        if (!search) {
            setOrganisation(null);
            setOrganisations([]);
            return;
        }
        let params = {search, perPage: 10} as OrganisationSelectParams
        if (props.resource) {
            params["resource"] = props.resource
        }
        fetchOrganisations({ search, perPage: 10, resource:props.resource }).then(({ data }) => {
            setOrganisations(data.items);
            setMoreResults((data.lastPage > 1));
        })
    }, [search]);

    function handleOnSearchChange(event: ChangeEvent<HTMLInputElement>) {
        if (event.target.value === "") {
            props.onChange(undefined);
        }
        setSearch(event.target.value);
    }

    function handleOnSearchBlur(event: ChangeEvent<HTMLInputElement>) {
        window.setTimeout(() => setOrganisations([]), 500)
        if (!search) {
            setOrganisation(null);
            props.onChange();
            return;
        }
        if (organisation) {
            setSearch(organisation.name);
        }
    }

    function handleSelectOrganisation(organisation: Organisation) {
        setSearch(organisation.name);
        setOrganisation(organisation);
        setOrganisations([]);
        props.onChange(organisation);
    }

    function onlyResultSelected() {
        if (!props.value) {
            return false;
        }
        if (organisations.length !== 1) {
            return false;
        }
        const firstOrganisation = organisations[0] as Organisation;

        return props.value === firstOrganisation.id;
    }

    return (
        <div className="relative inline-block clear-both w-full">
            <input disabled={props.disabled || singleOrg || loading} type="search" className="w-full mb-0 input-control" value={search} placeholder={loading ? "...Loading" : "Search organisation..."} onChange={handleOnSearchChange} onBlur={handleOnSearchBlur} />
            {organisations.length && !props.disabled && !onlyResultSelected() ? (
                <div className="absolute left-0 z-30 w-full bg-white border-2 shadow top-10">
                    {organisations.map((organisation: Organisation) => (
                        <button key={organisation.id} onClick={() => handleSelectOrganisation(organisation)} className="block w-full p-2 text-sm text-left hover:bg-gray-100">{organisation.name}</button>
                    ))}
                    {(moreResult) ? (
                        <div className="block w-full p-2 text-xs text-left text-gray-500">
                            More results available, please be more specific...
                        </div>
                    ) : null}
                </div>
            ) : null}
        </div>
    );
}

export default Select;