import { AxiosRequestConfig } from 'axios';
import {apiAxios as axios} from '../api/axios';
import {Vehicle} from "./types";


export function fetchVehicles(params: object) {
    return axios.get('/vehicles', {params} as AxiosRequestConfig);
}

export function fetchVehicle(id: number) {
    return axios.get('/vehicles/'+id);
}

export function saveVehicle(vehicle: Vehicle) {
    if (vehicle.id) {
        return axios.put('/vehicles/'+vehicle.id, vehicle);
    }
    return axios.post('/vehicles', vehicle);
}

export function deleteVehicle(vehicle: Vehicle) {
    return axios.delete('/vehicles/'+vehicle.id);
}