import { useState, MouseEvent } from "react";
import { Carer } from './types';
import { Formik, Form, Field } from "formik";
import FormError from "../form/FormError";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Link, useHistory, useParams } from "react-router-dom";
import * as Yup from 'yup';
import { deleteCarer, fetchCarer, saveCarer } from './service';
import { useEffect } from "react";
import Alert from "../alerts/Alert";
import ConfirmModal from "../modal/ConfirmModal";
import { Select as OrganisationSelect } from '../organisations/Select';
import { Organisation } from "../organisations/types";
import { useAppSelector } from "../../app/hooks";
import { selectUser, selectUserPolicies } from "../auth/slice";
import { getPermissionsLevel, getPermissionsLevelForEntity, Permission } from "../policies/helpers";
import PassengersForm from "./PassengersForm";

export function Edit() {
    const [carer, setCarer] = useState<Carer>({
        firstName: '',
        lastName: '',
        email: '',
        enabled: true,
        passengers: [],
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        town: '',
        county: '',
        postcode: '',
        phoneNumber: '',
    } as Carer);

    const policies = useAppSelector(selectUserPolicies)
    const organisationPermissionsLevel = getPermissionsLevel('organisation', policies, undefined, true)
    const permissionsLevel = getPermissionsLevelForEntity('carer', carer, policies)
    const [apiErrors, setApiErrors] = useState({});
    const [saving, setSaving] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const { id, organisationId } = useParams<{ id: string, organisationId: string }>();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [sendWelcome, setSendWelcome] = useState(false);
    const user = useAppSelector(selectUser);

    useEffect(() => {
        if (!id) {
            setSendWelcome(true);
            return;
        }
        setSendWelcome(false);
        setLoading(true);
        fetchCarer(id).then(({ data }) => {
            setCarer(data);
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
            console.error(error);
            history.goBack();
        });
    }, [id]);

    useEffect(() => {
        if (organisationId) {
            setCarer({ ...carer, organisationId: parseInt(organisationId) });
        }
    }, [])

    if (loading) {
        return (
            <div>Loading guardian...</div>
        );
    }

    function handleFormSubmit(values: any) {
        const carerData = {
            ...carer, ...values,
            id: parseInt(id),
            enabled: (values.enabled === 'true' || values.enabled === true),
            sendWelcome,
            passengers: carer.passengers,
        } as Carer;
        setApiErrors({});
        setSaving(true);
        setSuccessMessage('');
        setErrorMessage('');
        saveCarer(carerData).then(({ data }) => {
            setCarer(data);
            setSaving(false);
            setSuccessMessage('Carer saved successfully');
            window.setTimeout(() => {
                setSuccessMessage('');
            }, 5000);
            if (!id) {
                history.push('/guardians/' + data.id + '/edit');
            }
        }).catch(error => {
            setErrorMessage('Failed to save carer, check for errors');
            setSaving(false);
            if (error.response) {
                setApiErrors(error.response.data);
            } else {
                console.error(error);
            }
        });
    }

    function handleDeleteClick(event: MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        setShowDelete(true);
    }

    function handleConfirmDelete() {
        setDeleting(true);
        deleteCarer(carer).then(() => {
            setDeleting(false);
            setSuccessMessage('Guardian deleted successfully, redirecting...');
            setShowDelete(false);
            window.setTimeout(() => {
                history.push('/guardians');
            }, 3000);
        }).catch(error => {
            setErrorMessage('Failed to delete guardian, please try again');
            console.error(error);
            setShowDelete(false);
            setDeleting(false);
        });
    }

    const validation = Yup.object().shape({
        firstName: Yup.string().label('First name').required().max(50),
        lastName: Yup.string().label('Last name').required().max(50),
        email: Yup.string().label('Email').email().required(),
        organisationId: Yup.string().required().label("Organisation")
    });

    return (
        <div>
            <div>
                <h1 className="text-lg">{carer.id ? 'Edit' : 'New'} Guardian</h1>
            </div>
            <Formik
                initialValues={{ ...carer, password: '', organisationId: user?.organisationId }}
                onSubmit={handleFormSubmit}
                validationSchema={validation}
                validateOnBlur
            >
                {({ errors, isValid, values, setFieldValue }) => {
                    const allErrors = { ...errors, ...apiErrors };
                    function handleOrganisationChanged(organisation: Organisation | undefined) {
                        if (organisation === undefined) {
                            setFieldValue('organisationId', undefined)
                        } else {
                            setFieldValue('organisationId', organisation.id)
                        }
                    }
                    return (
                        <Form autoComplete="false">
                            <div className="grid grid-flow-row grid-cols-2 gap-4 mt-2 mb-4">
                                <div className="p-4 bg-white shadow">
                                    <div>
                                        <h3 className="mb-4 text-lg text-gray-400">Guardian Info</h3>
                                    </div>
                                    <div>
                                        <label htmlFor="id" className="form-label">Guardian ID:</label>
                                        <input disabled={permissionsLevel === Permission.Read} type="text" name="id"
                                            id="id"
                                            className="input-control" readOnly placeholder="####" value={values.id} />
                                        {!values.id ? (
                                            <p className="mb-2 text-sm text-gray-400">This will be automatically
                                                assigned</p>
                                        ) : null}
                                        <FormError name="id" errors={allErrors} />
                                    </div>
                                    <div className="flex">
                                        <div className="mr-2">
                                            <label htmlFor="firstName" className="form-label">Name:</label>
                                            <Field disabled={permissionsLevel === Permission.Read} type="text"
                                                name="firstName"
                                                id="firstName" className="input-control" placeholder="First name" />
                                            <FormError name="firstName" errors={allErrors} />
                                        </div>
                                        <div>
                                            <label htmlFor="lastName" className="form-label">&nbsp;</label>
                                            <Field disabled={permissionsLevel === Permission.Read} type="text"
                                                name="lastName"
                                                id="lastName" className="input-control" placeholder="Last name" />
                                            <FormError name="lastName" errors={allErrors} />
                                        </div>
                                    </div>
                                    <div>
                                        <label htmlFor="email" className="form-label">Email Address:</label>
                                        <Field disabled={permissionsLevel === Permission.Read} type="email" name="email"
                                            id="email"
                                            className="w-full input-control" />
                                        <FormError name="email" errors={allErrors} />
                                    </div>
                                    <div>
                                        <label htmlFor="phoneNumber" className="form-label">Phone Number:</label>
                                        <Field type="text" name="phoneNumber" id="phoneNumber" disabled={permissionsLevel === Permission.Read}  className="w-full input-control" />
                                        <FormError name="phoneNumber" errors={allErrors} />
                                    </div>
                                    <div>
                                        <label htmlFor="addressLine1" className="form-label">Address Line 1:</label>
                                        <Field type="text" name="addressLine1" id="addressLine1" disabled={permissionsLevel === Permission.Read}  className="w-full input-control" />
                                        <FormError name="addressLine1" errors={allErrors} />
                                    </div>
                                    <div>
                                        <label htmlFor="addressLine2" className="form-label">Address Line 2:</label>
                                        <Field type="text" name="addressLine2" id="addressLine2" disabled={permissionsLevel === Permission.Read}  className="w-full input-control" />
                                        <FormError name="addressLine2" errors={allErrors} />
                                    </div>
                                    <div>
                                        <label htmlFor="addressLine3" className="form-label">Address Line 3:</label>
                                        <Field type="text" name="addressLine3" id="addressLine3" disabled={permissionsLevel === Permission.Read}  className="w-full input-control" />
                                        <FormError name="addressLine3" errors={allErrors} />
                                    </div>
                                    <div>
                                        <label htmlFor="town" className="form-label">Town:</label>
                                        <Field type="text" name="town" id="town" disabled={permissionsLevel === Permission.Read}  className="w-full input-control" />
                                        <FormError name="town" errors={allErrors} />
                                    </div>
                                    <div>
                                        <label htmlFor="county" className="form-label">County:</label>
                                        <Field type="text" name="county" id="county" disabled={permissionsLevel === Permission.Read}  className="w-full input-control" />
                                        <FormError name="county" errors={allErrors} />
                                    </div>
                                    <div>
                                        <label htmlFor="postcode" className="form-label">Postcode:</label>
                                        <Field disabled={permissionsLevel === Permission.Read} type="text" name="postcode" id="postcode" className="w-full uppercase input-control" />
                                        <FormError name="postcode" errors={allErrors} />
                                    </div>
                                    {organisationPermissionsLevel >= Permission.Read && <div>
                                        <label htmlFor="organisation" className="form-label">Organisation:</label>
                                        <OrganisationSelect disabled={permissionsLevel === Permission.Read}
                                            value={values.organisationId}
                                            resource={"carer"}
                                            onChange={handleOrganisationChanged} />
                                    </div>}
                                    <FormError name="organisation" errors={allErrors} />
                                    <div>
                                        <label className="form-label">Status:</label>
                                        <Field disabled={permissionsLevel === Permission.Read} as="select"
                                            name="enabled"
                                            className="input-control">
                                            <option value="true">Enabled</option>
                                            <option value="false">Disabled</option>
                                        </Field>
                                    </div>
                                    {!carer.id ? (
                                        <div>
                                            <label className="form-label">Welcome Guardian</label>
                                            <label>
                                                <input disabled={permissionsLevel === Permission.Read} type="checkbox"
                                                    checked={sendWelcome} onChange={e => {
                                                        setSendWelcome(e.target.checked)
                                                    }} className="mr-1" />
                                                <span>Send welcome email to guardian?</span>
                                            </label>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="p-4 bg-white shadow">
                                    <h3 className="mb-4 text-lg text-gray-400">Passengers</h3>
                                    <PassengersForm carer={carer} onChange={setCarer} />
                                </div>
                            </div>
                            {successMessage ? (<Alert message={successMessage} type="success" />) : null}
                            {errorMessage ? (<Alert message={errorMessage} type="error" />) : null}
                            <div className="p-4 mt-4 bg-white shadow">
                                <div className="flex">
                                    <Link to="/guardians" className="button">
                                        <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                                        Back to Guardians
                                    </Link>
                                    {permissionsLevel >= Permission.Edit &&
                                        <button className="ml-auto mr-2 bg-yellow-300 button" type="submit"
                                            disabled={saving || !isValid}>{saving ? 'Saving guardian...' : 'Save Guardian'}</button>
                                    } {permissionsLevel >= Permission.Delete && carer.id ? (
                                        <button className="text-red-400 button" disabled={saving}
                                            onClick={handleDeleteClick}>Delete Guardian</button>
                                    ) : null}
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
            {showDelete ? (
                <ConfirmModal title="Delete guardian" onClose={() => {
                    setShowDelete(false)
                }} onConfirm={handleConfirmDelete} disabled={deleting}>
                    Are you sure you want to delete this guardian?
                </ConfirmModal>
            ) : null}
        </div>
    );
}

export default Edit;