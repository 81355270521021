import {Discount, DiscountGroup} from "./types";
import {apiAxios as axios} from '../api/axios';

export interface FetchDiscountsParams {
    search?: string,
}

export function fetchDiscounts(params: FetchDiscountsParams) {
    return axios.get('/discounts', {params});
}

export function saveDiscount(discount: Discount) {
    if (discount.id) {
        return axios.put('/discounts/'+discount.id, discount);
    }
    return axios.post('/discounts', discount);
}

export function fetchDiscount(id: any) {
    return axios.get('/discount/'+id);
}

export function deleteDiscount(id: any) {
    return axios.delete('/discount/'+id);
}

export function deleteDiscountGroup(id: any) {
    return axios.delete('/discount-group/'+ id)
}

export function fetchDiscountGroups() {
    return axios.get('/discount-group')
}

export function addDiscountGroup(discountGroup: DiscountGroup) {
    return axios.post('/discount-group', discountGroup)
}