import {createSlice} from "@reduxjs/toolkit";
import {RouteSession} from "./types";
import {RootState} from "../../app/store";

export interface SessionsState {
    liveRouteSession: null|RouteSession,
}

const initialState: SessionsState = {
    liveRouteSession:null

};

export const slice = createSlice({
    name: 'sessions',
    initialState,
    reducers: {
        setLiveRouteSession: (state: SessionsState, {payload}) => {
            state.liveRouteSession = payload;
        },
        clearLiveRouteSession: (state: SessionsState) => {
            state.liveRouteSession = null;
        }
    }
});

export const {
    setLiveRouteSession,
    clearLiveRouteSession
} = slice.actions;

export const selectLiveRouteSession = (state: RootState) => (state.sessions.liveRouteSession);

export default slice.reducer;