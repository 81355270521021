import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, MouseEvent } from 'react';
import {Route} from './types';
import {Journey} from '../journeys/types';
import JourneyModal from '../journeys/JourneyModal';
import {Permission} from "../policies/helpers";

export interface EditJourneysProps {
    route: Route,
    onChange: CallableFunction,
    permissionsLevel: number
}

export function EditJourneys(props: EditJourneysProps) {
    const [editJourney, setEditJourney] = useState<null|Journey>(null);

    function handleAddJourneyClick(event: MouseEvent<HTMLElement>) {
        event.preventDefault();
        const newJourney = {

        } as Journey;
        setEditJourney(newJourney);
    }

    function handleModalClose() {
        setEditJourney(null);
        props.onChange(props.route);
    }

    return (
        <div>
            <div>
            {props.route.journeys.map((journey) => (
                <div className="flex items-center p-2 mb-2 transition-colors bg-white border-2 rounded cursor-pointer hover:bg-gray-100" key={'journey_'+journey.id} onClick={() => {setEditJourney(journey)}}>
                    <span className="mr-auto font-bold">{journey.startTime} - {journey.schedule ? journey.schedule.name : 'Unknown Schedule'}</span>
                        <button className="text-sm text-white bg-gray-500 button">{props.permissionsLevel >= Permission.Edit ? 'Edit' : 'Show'}</button>
                </div>
            ))}
                {(props.route.journeys.length === 0) && <span className="block px-1 py-4">This route doesn’t have any journeys set up. Please complete the stops and schedule for this route then create a journey</span>}
            </div>
            {props.permissionsLevel >= Permission.Edit &&  (props.route.schedules.length > 0) && <div className="flex">
                <button className="text-white bg-gray-500 button" onClick={handleAddJourneyClick}>
                    <FontAwesomeIcon icon={faPlus} className="mr-2"/>
                    Add Journey
                </button>
            </div>}
            {editJourney ? (<JourneyModal permissionsLevel={props.permissionsLevel} journey={editJourney} schedules={props.route.schedules} onCloseAction={handleModalClose}/>) : null }
        </div>
    );
}

export default EditJourneys;