import { Link, useHistory } from "react-router-dom";
import { faCheck, faPlus, faSyncAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormEvent, useState } from "react";
import { User } from "./types";
import { useEffect } from "react";
import { fetchUsers } from "./service";
import Pagination from "../pagination/Pagination";
import { Select as OrganisationSelect } from '../organisations/Select';
import {useAppSelector} from "../../app/hooks";
import {selectUserPolicies} from "../auth/slice";
import {getPermissionsLevel, Permission} from "../policies/helpers";

export interface IndexProps {
    hideHeader?: boolean,
    hideNewButton?: boolean,
    organisationId?: number,
}

export function Index(props: IndexProps) {

    const policies = useAppSelector(selectUserPolicies)
    const permissionsLevel = getPermissionsLevel('user', policies)
    const organisationPermissions = getPermissionsLevel('organisation', policies)
    const [searchText, setSearchText] = useState('');
    const [statusFilter, setStatusFilter] = useState('');
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState<User[]>([]);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(1);
    const [organisationId, setOrganisationId] = useState<undefined | number>(props.organisationId);
    const history = useHistory();

    useEffect(() => {
        loadUsers();
    }, [page]);

    function handleSearchSubmit(event: FormEvent) {
        event.preventDefault();
        if (page === 1) {
            loadUsers();
        }
        setPage(1);
    }

    function loadUsers() {
        const params = {
            search: searchText,
            status: statusFilter,
            organisationId,
            page,
        };
        setLoading(true);
        fetchUsers(params).then(({ data }) => {
            setUsers(data.items);
            setPages(data.lastPage);
            setLoading(false);
        }).catch(err => {
            console.error(err);
            setLoading(false);
            setUsers([]);
        })
    }

    return (
        <div>
            {(!props.hideHeader) ? (
                <div>
                    <h1 className="mb-2 text-lg">Users</h1>
                </div>
            ) : null}
            <div className="p-2 my-4 bg-white rounded shadow">
                <div className="flex items-center">
                    <form action="" onSubmit={handleSearchSubmit} className="flex">
                        <div className="mr-2">
                            <input type="search" placeholder="Search..." value={searchText} onChange={(e) => { setSearchText(e.target.value) }} className="mb-0 input-control" />
                        </div>
                        <div className="mr-2">
                            <select onChange={(e) => { setStatusFilter(e.target.value) }} className="mb-0 input-control" value={statusFilter}>
                                <option value="">Any Status</option>
                                <option value="enabled">Enabled</option>
                                <option value="disabled">Disabled</option>
                            </select>
                        </div>
                        {organisationPermissions >= Permission.Read && <div className="mr-2">
                            {props.organisationId ? null : (
                                <OrganisationSelect value={organisationId} onChange={(organisation) => {
                                    setOrganisationId(organisation?.id)
                                }}/>
                            )}
                        </div>}
                        <div>
                            <button className="bg-yellow-300 button" disabled={loading}>Search</button>
                        </div>
                    </form>
                    <div className="flex h-auto ml-auto">
                        <button className="mr-2 text-white bg-gray-600 button" type="button" onClick={(event) => { event.preventDefault(); loadUsers(); }} disabled={loading}>
                            <FontAwesomeIcon icon={faSyncAlt} className={'mr-1 text-sm ' + (loading ? 'fa-spin' : '')} />
                            {loading ? 'Loading...' : 'Refresh'}
                        </button>
                        {permissionsLevel < Permission.Create || props.hideNewButton ? null : (
                            <Link to="/users/new" className="bg-yellow-300 button">
                                <FontAwesomeIcon icon={faPlus} className="mr-1" />
                                <span>New User</span>
                            </Link>
                        )}
                    </div>
                </div>
            </div>
            <div className="p-2 my-4 bg-white rounded shadow">
                {users.length && !loading ? (
                    <table className="table w-full table-striped">
                        <thead>
                            <th className="text-left">Name</th>
                            <th className="text-left">Email</th>
                            <th className="text-left">Type</th>
                            {organisationPermissions >= Permission.Read && <th className="text-left">Organisation</th>}
                            <th className="text-center">Enabled</th>
                        </thead>
                        <tbody>
                            {users.map((user) => (
                                <tr className="cursor-pointer" onClick={() => { history.push('/users/' + user.id + '/edit') }}>
                                    <td>{user.firstName} {user.lastName}</td>
                                    <td>{user.email}</td>
                                    <td>{user.type || '-'}</td>
                                    {organisationPermissions >= Permission.Read && <td>{user.organisationName || '-'}</td>}
                                    <td className="text-center">{user.enabled ? (
                                        <FontAwesomeIcon icon={faCheck} className="text-green-400" />
                                    ) : (
                                        <FontAwesomeIcon icon={faTimes} className="text-red-400" />
                                    )}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : null}
                {!users.length && !loading ? (
                    <p>No users found</p>
                ) : null}
                {loading ? (
                    <p>Loading users...</p>
                ) : null}
                <div className="my-2 text-center">
                    <Pagination page={page} pages={pages} onChange={setPage} />
                </div>
            </div>
        </div>
    );
}

export default Index;