import {useAppDispatch, useAppSelector} from "../../app/hooks";
import { selectDeleteVehicle, setDeleteVehicle, setEditVehicle } from "./slice";
import Dialog from "../dialog/Dialog";
import Alert from "../alerts/Alert";
import { useState } from "react";
import { deleteVehicle as apiDeleteVehicle } from "./service";

export default function DeleteDialog () {
    const dispatch = useAppDispatch();
    const vehicle = useAppSelector(selectDeleteVehicle);
    const [errorMessage, setErrorMessage] = useState('');
    const [deleting, setDeleting] = useState(false);

    if (!vehicle) {
        return null;
    }

    const cancelFunction = () => {
        setErrorMessage('');
        dispatch(setDeleteVehicle(null));
    }

    const confirmFunction = () => {
        setDeleting(true);
        apiDeleteVehicle(vehicle).then(() => {
            dispatch(setDeleteVehicle(null));
            dispatch(setEditVehicle(null));
        }).catch(() => {
            setErrorMessage('Failed to delete vehicle, please try again');
        }).finally(() => {
            setDeleting(false);
        });
    }
    
    return (
    <Dialog title="Delete Vehicle" confirmFunction={confirmFunction} cancelFunction={cancelFunction} disabled={deleting} confirmText={deleting? 'Deleting...' : 'Delete'}>
        <p>Are you sure you want to delete vehicle <strong>{vehicle.registrationNumber}</strong>?</p>
        {errorMessage ? (<Alert message={errorMessage} type="error" />) : null}
    </Dialog>
    );
}