import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "../../app/store";
import {Vehicle} from "./types";

export interface VehiclesState {
    editVehicle: null|Vehicle,
    deleteVehicle: null|Vehicle,
}

const initialState: VehiclesState = {
    editVehicle: null,
    deleteVehicle: null,

};

export const slice = createSlice({
    name: 'vehicles',
    initialState,
    reducers: {
        setEditVehicle: (state: VehiclesState, {payload}) => {
            state.editVehicle = payload;
        },
        clearEditVehicle: (state) => {
            state.editVehicle = null;
        },
        setDeleteVehicle: (state, {payload}) => {
            state.deleteVehicle = payload;
        },
    }
});

 export const {
     setEditVehicle,
     clearEditVehicle,
     setDeleteVehicle,
    } = slice.actions;

export const selectEditVehicle = (state: RootState) => (state.vehicles.editVehicle);
export const selectDeleteVehicle = (state: RootState) => (state.vehicles.deleteVehicle);

export default slice.reducer;