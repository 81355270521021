import { AxiosRequestConfig } from 'axios';
import {apiAxios} from '../api/axios';
export interface DateRangeParams {
    fromDate: string,
    toDate: string,
}

export interface FetchRouteUtilisationParams {
    fromDate: string,
    toDate: string,
    order: string,
}

export function fetchMostPopularRoutes(params: DateRangeParams) {
    return apiAxios.get('/stats/mostPopularRoutes', {params} as AxiosRequestConfig);
}

export function fetchRouteUtilisation(params: FetchRouteUtilisationParams) {
    return apiAxios.get('/stats/routesUtilisation', {params} as AxiosRequestConfig);
}

export function fetchLiveRoutesUtilisations() {
    return apiAxios.get('/stats/liveRoutesUtilisations');
}

export function fetchTotalRoutesRun(params: DateRangeParams) {
    return apiAxios.get('/stats/totalRoutesRun', {params} as AxiosRequestConfig);
}

export function fetchStopArrivals(params: DateRangeParams) {
    return apiAxios.get('/stats/stopArrivals', {params} as AxiosRequestConfig);
}

export function fetchStopDeparts(params: DateRangeParams) {
    return apiAxios.get('/stats/stopDeparts', {params} as AxiosRequestConfig);
}

export function getLiveHeadlineStats()
{
    return apiAxios.get('/stats/sessionHeadlines')
}