import { useState, useEffect } from "react";
import { fetchLiveRoutesUtilisations } from './service';
import LiveTag from './LiveTag';
import Chart from 'react-apexcharts';

let interval: any;

const chartOptions = {
    plotOptions: {
        radialBar: {
            dataLabels: {
                name: {
                    show: false
                },
                value: {
                    fontSize: '24px',
                    offsetY: 8
                }
            }
        }
    }
}

export function LiveRouteUtilisations() {
    const [result, setResult] = useState(0);
    const [loaded, setLoaded] = useState(false);

    function refreshData() {
        fetchLiveRoutesUtilisations().then(response => {
            if(response.data){
                setResult(parseFloat(response.data.statistic));
            }
            setLoaded(true);
        });
    }

    useEffect(() => {
        refreshData();
        interval = window.setInterval(() => {
            refreshData()
        }, 15000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="text-center">
            <div>
                <span className="mb-2 font-bold">Average Route Utilisation</span>
            </div>
            <div>
                <Chart type="radialBar" height="250" series={[result]} options={chartOptions} />
            </div>
            <div>
                {(loaded) ? (<LiveTag />) : null}
            </div>
        </div>
    );
}

export default LiveRouteUtilisations;