import PopularRoutesPie from '../stats/PopularRoutesPie';
import CapacityUtilisation from '../stats/CapacityUtilisation';
import LiveRouteUtilisations from '../stats/LiveRouteUtilisations';
import TotalRoutesRun from '../stats/TotalRoutesRun';
import ArrivedOnTime from '../stats/ArrivedOnTime';
import DepartedOnTime from '../stats/DepartedOnTime';
import DashboardBlock from './DashboardBlock';
import {LiveHeadlines as LiveSessionHeadlines} from "../stats/LiveHeadlines";

export function Dashboard() {

    return (
    <div>
        <div className="mb-4">
            <h1>Dashboard</h1>
        </div>
        <div className="grid grid-cols-3 gap-4 mb-4 grid-layout-row">
           <DashboardBlock>
                <LiveRouteUtilisations />
            </DashboardBlock>
           <DashboardBlock>
                <TotalRoutesRun />
            </DashboardBlock>
            <DashboardBlock>
                <LiveSessionHeadlines/>
            </DashboardBlock>
        </div>
        <div className="grid grid-cols-3 gap-4 grid-layout-row">
           <DashboardBlock>
                <PopularRoutesPie />
            </DashboardBlock>
           <DashboardBlock>
                <CapacityUtilisation />
            </DashboardBlock>
           <DashboardBlock>
                <CapacityUtilisation order="asc" />
            </DashboardBlock>
           <DashboardBlock>
                <ArrivedOnTime />
            </DashboardBlock>
           <DashboardBlock>
                <DepartedOnTime />
            </DashboardBlock>
        </div>
    </div>)
}

export default Dashboard;