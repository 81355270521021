import { ReactNode } from "react";

export interface ModalProps{
    title: string,
    children: ReactNode
    closeAction: CallableFunction,
}

export default function Modal(props: ModalProps) {
    return (
        <div className="fixed top-0 left-0 z-50 flex w-screen h-screen">
            <div className="absolute top-0 left-0 w-full h-full opacity-50 bg-gray-50" onClick={() => {props.closeAction()}}></div>
            <div className="relative w-1/3 mx-auto mt-10 mb-auto bg-white shadow justify-self-center align-self-center">
               <div className="flex items-center p-2 text-lg text-white bg-gray-600">
                   <h2>{props.title}</h2>
                   <button type="button" className="p-1 ml-auto" onClick={(event) => {event.preventDefault(); props.closeAction()}}>&#215;</button>
               </div>
               <div className="p-2 overflow-visible bg-white" style={{'maxHeight': '85vh', overflowY: 'auto'}}>
                   {props.children}
               </div>
            </div>
        </div>
    );
}