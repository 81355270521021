import { createContext, ReactNode, useEffect, useState } from 'react';
import { DropDownOption } from '../dropdown/types';
import { fetchJourneyDropDown } from '../journeys/service';
import { fetchRoute } from "../routes/service";
import { useQuery } from "../routing/hooks";

interface ReportsContextValue {
    selectedRoute?: DropDownOption,
    setSelectedRoute: (route?: DropDownOption) => any,
    selectRouteById: (id?: any) => any,
    journeyOptions: DropDownOption[],
    selectedJourney?: DropDownOption,
    setSelectedJourney: (journey?: DropDownOption) => any,
    selectJourneyById: (id?: any) => any,
    journeysLoading: boolean,
}

export const ReportsContext = createContext<ReportsContextValue>({
    setSelectedRoute: () => { },
    selectRouteById: () => { },
    journeyOptions: [],
    setSelectedJourney: () => { },
    selectJourneyById: () => { },
    journeysLoading: false,
})

interface ReportsContextProviderProps {
    children: ReactNode,
}

const initalJourneys: DropDownOption[] = [];

const ReportsContextProvider = (props: ReportsContextProviderProps) => {
    const [selectedRoute, setSelectedRoute] = useState<DropDownOption>();
    const [selectedJourney, setSelectedJourney] = useState<DropDownOption>();
    const [journeyOptions, setJourneyOptions] = useState<DropDownOption[]>(initalJourneys);
    const [journeysLoading, setJourneysLoading] = useState(false);
    const queryRouteId = useQuery().get('routeId');

    const selectRouteById = (routeId?: any) => {
        setJourneyOptions([]);
        if (!routeId) {
            setSelectedRoute(undefined);
            setSelectedJourney(undefined);
            return;
        }
        fetchRoute(parseInt(routeId)).then(({ data }) => {
            setSelectedRoute({
                value: data.name,
                id: data.id,
            });
        });
    }

    const selectJourneyById = (id?: any) => {
        if (!id) {
            setSelectedJourney(undefined);
            return;
        }
        const journey = journeyOptions.find(j => j.id === parseInt(`${id}`));
        if (journey) {
            setSelectedJourney(journey);
        } else {
            setSelectedJourney(undefined);
        }
    }

    useEffect(() => {
        if (!queryRouteId) {
            return;
        }
        selectRouteById(queryRouteId);
    }, [queryRouteId]);

    useEffect(() => {
        setSelectedJourney(undefined);
        if (!selectedRoute) {
            setJourneyOptions([]);
            setJourneysLoading(false);
            return;
        }
        
        setJourneysLoading(true);
        fetchJourneyDropDown({routeId: selectedRoute.id}).then(({data}) => {
            setJourneyOptions(data);
            setJourneysLoading(false);
        });
    }, [selectedRoute]);


    return (
        <ReportsContext.Provider value={{
            selectedRoute,
            setSelectedRoute,
            selectRouteById,
            journeyOptions,
            selectedJourney,
            setSelectedJourney,
            selectJourneyById,
            journeysLoading,
        }}>
            {props.children}
        </ReportsContext.Provider>
    );
}

export default ReportsContextProvider;