import {apiAxios as axios} from '../api/axios';
import {AxiosRequestConfig} from "axios";
import {PassengerExportReportData, PassengerReportData, SessionRouteReportData} from "./types";



export function todaysSessions(params?:object)
{
    return axios.get('/session/recent', {params} as AxiosRequestConfig);
}
export function refreshSession(id:number)
{
    return axios.get('/session/live/' + id )
}
export function fetchMarkers(id:number)
{
    return axios.get('/session/markers/' + id)
}

export function fetchVehicleLocation(id:number)
{
    return axios.get('/session/' + id+ '/vehicle')
}

export function getSessionPassengers(params: PassengerReportData)
{
    return axios.get('/sessionPassengers', {params} as AxiosRequestConfig)
}
export function getPassengerCsvReport(params:PassengerExportReportData)
{
    return axios.get('/sessionPassengers/export', {params} as AxiosRequestConfig)
}

export function getSessionsRouteForReport(params: SessionRouteReportData)
{
    return axios.get('/session/route/report', {params} as AxiosRequestConfig)
}
export function getSessionRouteCsvReport(params: SessionRouteReportData )
{
    return axios.get('/session/route/report/export', {params} as AxiosRequestConfig)
}
