import logo from "../../logo.png";

export const Loader = () => {
    return (
        <div className="flex items-center justify-center h-screen">
            <div className="text-center">
            <img src={logo} alt="Zywpeit Logo" className="h-10 m-auto mb-4"/>
            <span className="text-gray-400">Loading...</span>
        </div>
    </div>)
}