import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {selectDeleteDevice, setDeleteDevice} from "./slice";
import {useState} from "react";
import {deleteDevice as requestDeleteDevice} from "../devices/service";
import {setSuccessNotificationText} from "../notification/slice";
import Dialog from "../dialog/Dialog";
import Alert from "../alerts/Alert";


export const DeleteDialog = () => {

    const deleteDevice = useAppSelector(selectDeleteDevice);
    const dispatch = useAppDispatch();
    const [deleting, setDeleting] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')


    if (!deleteDevice) {
        return null;
    }

    const cancelFunction = () => {
        setErrorMessage('')
        dispatch(setDeleteDevice(null))
    }



    const handleDeleteDevice = () => {
        setDeleting(true);
        requestDeleteDevice(deleteDevice).then(() =>{
            dispatch(setDeleteDevice(null))
            dispatch(setSuccessNotificationText('Device deleted successfully'))
        }).catch(() => {
            setErrorMessage('Failed to delete device, please try again');
        }).finally(() => {
            setDeleting(false);
        })
    }

    return (
        <Dialog title="Delete device" confirmFunction={handleDeleteDevice} cancelFunction={cancelFunction} disabled={deleting} confirmText={deleting? 'Deleting...' : 'Delete'}>
            <p> Are you sure you want to delete card reader<strong> {deleteDevice.serial} </strong>?</p>
            <Alert message={errorMessage} type="error" />
        </Dialog>
    )    
}