import { useState } from "react";
import { AppPromo, AppPromoUser } from "./types";
import { Formik, Form, Field } from "formik";
import * as Yup from 'yup';
import FormError from "../form/FormError";
import Alert from "../alerts/Alert";
import { deleteAppPromo, fetchAppPromo, saveAppPromo } from './service';
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useHistory, useParams } from "react-router-dom";
import { ParamsWithId } from "../routing/types";
import { useEffect } from "react";
import ConfirmModal from "../modal/ConfirmModal";
import { MouseEvent } from 'react';
import Upload from "../media/Upload";
import Preview from "../media/Preview";
import Delete from "../media/Delete";
import { MediaFile } from "../media/types";
import { getFormikDateTimeFromString } from "../dates/helper";
import Intro from "./Intro";
import { useAppSelector } from "../../app/hooks";
import { selectUserPolicies } from "../auth/slice";
import { getPermissionsLevelForEntity, Permission } from "../policies/helpers";
import UsersForm from "./UsersForm";

const initialUsers: AppPromoUser[] = [];

const initialAppPromo = {
    title: '',
    url: '',
    startDate: '',
    endDate: '',
    users: initialUsers,
    content: '',
    isBanner: false,
    isForAllCarers: false,
    isForAllPassengers: false,
} as AppPromo;

export function Edit() {
    const [appPromo, setAppPromo] = useState<AppPromo>(initialAppPromo);
    const policies = useAppSelector(selectUserPolicies)
    const permissionsLevel = getPermissionsLevelForEntity('appPromo', appPromo, policies)
    const [apiErrors, setApiErrors] = useState({});
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const history = useHistory();
    const { id } = useParams<ParamsWithId>();
    const [showDelete, setShowDelete] = useState(false);
    const [deleting, setDeleteing] = useState(false);

    useEffect(() => {
        if (!id) {
            setAppPromo(initialAppPromo)
            return;
        }
        setLoading(true);
        fetchAppPromo(id).then(({ data }) => {
            setAppPromoFromResponse(data);
        }).catch((err: any) => {
            setAppPromo(initialAppPromo);
            history.goBack();
            console.error(err);
        }).finally(() => {
            setLoading(false);
        })
    }, [id]);

    function handleSubmit(values: AppPromo) {
        setError('');
        setApiErrors({});
        setMessage('');
        setSaving(true);
        const apppromoData = { ...values, image: appPromo.image, users: appPromo.users }
        saveAppPromo(apppromoData).then(({ data }) => {
            if (!appPromo.id) {
                history.push('/appPromos/' + data.id + '/edit');
            }
            setAppPromoFromResponse(data);
            setSaving(false);
            setMessage('App Promotion saved successfully');
        }).catch((err: any) => {
            setSaving(false);
            setError('Failed to save app promotion, please check for errors');
            if (err.response && err.response.data) {
                setApiErrors(err.response.data);
                return;
            }
            console.error(err);
        })
    }

    function setAppPromoFromResponse(data: any) {
        const appPromo = { ...data };
        appPromo.startDate = appPromo.startDate ? getFormikDateTimeFromString(appPromo.startDate) : '';
        appPromo.endDate = appPromo.endDate ? getFormikDateTimeFromString(appPromo.endDate) : '';
        setAppPromo(appPromo);
    }

    function handleDeleteClick(event: MouseEvent) {
        event.preventDefault();
        setShowDelete(true);
    }

    function handleDeleteConfirmed() {
        setDeleteing(true);
        deleteAppPromo(appPromo.id).then(() => {
            setShowDelete(false);
            setDeleteing(false);
            setMessage('App promotion deleted successfully, redirecting...');
            window.setTimeout(() => {
                history.replace('/appPromos');
            }, 3000);
        }).catch(() => {
            setShowDelete(false);
            setError('Failed to delete app promotion, please try again');
            setDeleteing(false);
        })
    }

    function handleUpload(mediaFile: MediaFile) {
        setAppPromo({ ...appPromo, image: mediaFile });
    }

    function handleDeleteImage() {
        setAppPromo({ ...appPromo, image: null })
    }

    const validation = Yup.object().shape({
        title: Yup.string().label('Title').required().max(255),
    });

    if (loading) {
        return (
            <div>
                <span>Loading app promotion...</span>
            </div>
        )
    }

    return (
        <div>
            <div>
                <h1 className="text-lg">{appPromo.id ? 'Edit' : 'New'} App Promotion</h1>
                <Intro />
            </div>
            <Formik
                initialValues={{ ...appPromo }}
                onSubmit={handleSubmit}
                validationSchema={validation}
                validateOnBlur
            >
                {({ values, errors, isValid }) => {
                    const allErrors = { ...errors, ...apiErrors };
                    return (
                        <Form autoComplete="false">
                            <div className="p-4 my-2 bg-white rounded shadow">
                                <div>
                                    <label htmlFor="title" className="form-label">Title:</label>
                                    <Field disabled={permissionsLevel === Permission.Read} className="w-full input-control" name="title" id="title" placeholder="Title" />
                                    <FormError name="title" errors={allErrors} />
                                </div>
                                <div className="w-full">
                                    <label htmlFor="content" className="form-label">Content:</label>
                                    <Field disabled={permissionsLevel === Permission.Read} className="w-full input-control" name="content" id="content" placeholder="Write body content here..." as="textarea" />
                                    <FormError name="content" errors={allErrors} />
                                </div>
                                <div>
                                    <label htmlFor="url" className="form-label">Link URL:</label>
                                    <Field disabled={permissionsLevel === Permission.Read} className="w-full input-control" name="url" id="url" placeholder="https://www.example.com/" />
                                    <small className="p-2 text-gray-500">To link to a screen in the app prefix the url with <pre className="inline-block p-1 text-xs text-blue-600 bg-gray-100 rounded">app://</pre>, for example <pre className="inline-block p-1 text-xs text-blue-600 bg-gray-100 rounded">app://Settings</pre> will navigate the user to the Settings screen in the app.</small>
                                    <FormError name="url" errors={allErrors} />
                                </div>
                                <div>
                                    <label htmlFor="isBanner" className="form-label">Display Type:</label>
                                    <Field id="isBanner" name="isBanner" disabled={permissionsLevel === Permission.Read} className="input-control" as="select">
                                        <option value={0}>Carousel</option>
                                        <option value={1}>Banner</option>
                                    </Field>
                                    <FormError name="isBanner" errors={allErrors} />
                                </div>
                                <div>
                                    <label htmlFor="startDate" className="form-label">Start Date:</label>
                                    <Field disabled={permissionsLevel === Permission.Read} className="input-control" name="startDate" id="startDate" type="datetime-local" />
                                    <FormError name="startDate" errors={allErrors} />
                                </div>
                                <div>
                                    <label htmlFor="endDate" className="form-label">End Date:</label>
                                    <Field disabled={permissionsLevel === Permission.Read} className="input-control" name="endDate" id="endDate" type="datetime-local" />
                                    <FormError name="endDate" errors={allErrors} />
                                </div>

                                <div>
                                    {appPromo.image ? (
                                        <>
                                            <Preview mediaFile={appPromo.image} />
                                            {permissionsLevel >= 2 && <div>
                                                <Delete mediaFile={appPromo.image} onDelete={handleDeleteImage} />
                                            </div>}
                                        </>
                                    ) : null}
                                </div>
                                <div className="mb-4">
                                    {permissionsLevel >= Permission.Edit && <div>
                                        <Upload onUploaded={handleUpload} imageOnly={true} />
                                    </div>}
                                    <FormError name="image" errors={allErrors} />
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="isForAllCarers" className="form-label">Users:</label>
                                    <label htmlFor="isForAllCarers" className="mr-6 align-middle">
                                        <Field type="checkbox" name="isForAllCarers" id="isForAllCarers" />
                                        <span className="ml-2">Show to all Guardians</span>
                                    </label>
                                    <label htmlFor="isForAllPassengers" className="align-middle">
                                        <Field type="checkbox" name="isForAllPassengers" id="isForAllPassengers" />
                                        <span className="ml-2">Show to all Passengers</span>
                                    </label>
                                    <FormError name="isForAllCarers" errors={allErrors} />
                                    <FormError name="isForAllPassengers" errors={allErrors} />
                                </div>

                                {!values.isForAllCarers && !values.isForAllPassengers ? (
                                    <div>
                                        <label className="form-label">Select Users:</label>
                                        <UsersForm appPromo={appPromo} onChange={setAppPromo} />
                                    </div>
                                ) : null}
                            </div>
                            <Alert message={message} type="success" />
                            <Alert message={error} type="error" />
                            <div className="p-4 my-2 bg-white rounded shadow">
                                <div className="flex">
                                    <Link to="/appPromos" className="button">
                                        <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                                        Back to App Promotions
                                    </Link>
                                    {permissionsLevel >= Permission.Edit && <><button className="ml-auto mr-2 bg-yellow-300 button"
                                        disabled={deleting || saving || !isValid}>{saving ? 'Saving...' : 'Save App Promotion'}</button>
                                        {appPromo.id && permissionsLevel >= Permission.Delete ? (
                                            <button className="text-red-400 button" type="button" disabled={saving} onClick={handleDeleteClick}>Delete App Promotion</button>
                                        ) : null}</>}
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
            {showDelete ? (
                <ConfirmModal title="Delete App Promotion" onClose={() => setShowDelete(false)} onConfirm={handleDeleteConfirmed} disabled={deleting || saving}>
                    Are you sure you want to delete this app promotion?
                </ConfirmModal>
            ) : null}
        </div>
    );
}
