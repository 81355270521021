import Modal from "../modal/Modal";
import { Formik, Form, Field } from 'formik';
import React, { useState } from "react";
import Alert from "../alerts/Alert";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectEditDevice, setEditDevice } from "./slice";
import { saveDevice } from "./service";
import { setSuccessNotificationText } from "../notification/slice";
import * as Yup from 'yup'
import FormError from "../form/FormError";
import UserAutoComplete, { UserAutoCompleteChangeEvent } from "../users/UserAutoComplete";


interface EditDeviceValues {
    serial: string,
    contactUserId: number | null,
    contactUserName: string | null,
}

export const EditModal = () => {

    const [errorMessage, setErrorMessage] = useState('');
    const [saving, setSaving] = useState(false)
    const [apiErrors, setApiErrors] = useState({})
    const dispatch = useAppDispatch();

    const device = useAppSelector(selectEditDevice)

    const errorMessageHTML = errorMessage ? <Alert message={errorMessage} type="error" /> : null

    const closeAction = () => {
        setErrorMessage('');
        dispatch(setEditDevice(null))
    }

    if (null === device) {
        return null
    }

    const handleSave = (values: EditDeviceValues) => {
        const updatedDevice = { ...device }
        updatedDevice.serial = values.serial;
        updatedDevice.contactUserId = values.contactUserId;

        saveDevice(updatedDevice).then(() => {
            dispatch(setSuccessNotificationText('Device Updated successfully'))
            setSaving(false)
            dispatch(setEditDevice(null))
            closeAction()
        }).catch((err) => {
            if (err.response.data) {
                setApiErrors(err.response.data);
            }
            setErrorMessage('Error saving please try again')
            setSaving(false)
        });
    }

    const initialValues: EditDeviceValues = {
        serial: device.serial,
        contactUserId: device.contactUserId,
        contactUserName: device.contactUserName,
    }

    const validation = Yup.object().shape({
        serial: Yup.string().required("Must input a serial number")
    })


    return (
        <Modal title="Edit Device" closeAction={closeAction}>
            <Formik initialValues={initialValues}
                validationSchema={validation}
                onSubmit={(
                    values: EditDeviceValues
                ) => {
                    setTimeout(() => {
                        setSaving(true)
                        setErrorMessage('')
                        handleSave(values)
                    }, 500);
                }}
            >
                {({ errors, isValid, dirty, setFieldValue, values }) => {
                    const allErrors = { ...errors, ...apiErrors }
                    const handleContactChanged = (event: UserAutoCompleteChangeEvent) => {
                        if (event.user) {
                            setFieldValue('contactUserId', event.user.id);
                            setFieldValue('contactUserName', (event.user.firstName + ' ' + event.user.lastName));
                        } else {
                            setFieldValue('contactUserId', null);
                            setFieldValue('contactUserName', null);
                        }
                    }
                    return (
                        <Form>
                            <div className="mb-4">
                                <label className="form-label" htmlFor="serial">
                                    Serial Number:
                                </label>
                                <Field
                                    className="m-2 input-control "
                                    type="text"
                                    name="serial" />
                            </div>
                            <div>
                                <label htmlFor="contactUserId" className="form-label">Contact:</label>
                                <UserAutoComplete value={values.contactUserName || ''} onChange={handleContactChanged} />
                            </div>
                            <FormError name="serial" errors={allErrors} />
                            {errorMessageHTML}
                            <div className="flex">
                                <button className="ml-auto bg-yellow-300 button" type="submit" disabled={!(isValid && dirty && !saving)}>{saving ? 'Saving...' : 'Save'}</button>
                                <button className="ml-4 bg-gray-300 button" onClick={closeAction} type="button"> Cancel </button>
                            </div>
                        </Form>
                    )
                }}
            </Formik>

        </Modal>
    )
}