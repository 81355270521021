import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import React, {useState} from "react";
import {JourneyData} from "../journeys/types";
import {RouteModal} from "../routes/RouteModal";

interface RouteSessionTableProps {
    journeyData:JourneyData
    loading:boolean
}
export const JourneyDataTable = (props: RouteSessionTableProps) => {
    const [showPassengers, setShowPassengers] = useState<Array<number>>([]);
    const [routeId, setRouteId] = useState<number>()

    const handleShowPassengers = (id: number) => {
        let tempArray = [...showPassengers]
        if (tempArray.includes(id)) {
            tempArray = tempArray.filter(tempId => tempId !== id)
        } else {
            tempArray.push(id)
        }
        setShowPassengers(tempArray)
    }

    return (<div>
                        <div key={props.journeyData.id} className="p-4 my-3 bg-white shadow">
                            <h3 className={"mb-2 font-semibold "}>
                                Route {props.journeyData.routeName} {props.journeyData.schedule?.name} @ {props.journeyData.startTime}
                            </h3>
                            {routeId && <RouteModal closeModal={() => {setRouteId(undefined)}} routeId={routeId}/>}
                            <table className="table w-full table-striped">
                                <thead>
                                <tr>
                                    <th className="text-left">Route</th>
                                    <th className="text-left">Stops</th>
                                    <th className="text-left" style={{maxWidth:200}} >Drivers</th>
                                    <th className="text-left">Est Duration</th>
                                    <th className="text-left">Capacity</th>
                                    <th className="text-left">Scheduled</th>
                                    <th className="text-left">Schedule</th>
                                    <th> </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>{props.journeyData.routeName}</td>
                                    <td>{props.journeyData.numberOfStops}</td>
                                    <td style={{maxWidth:200}} >{props.journeyData.drivers?.map((driver, index) => { const end = (props.journeyData.drivers?.length !== index + 1) ? ', ': ''; return(driver.firstName + ' ' + driver.lastName + end )})}</td>
                                    <td>{props.journeyData.estimatedDuration}{props.journeyData.estimatedDuration && ' mins'}</td>
                                    <td>{props.journeyData.capacity}</td>
                                    <td>{props.journeyData.startTime}</td>
                                    <td>{props.journeyData.schedule?.name}</td>
                                    <td><button className="font-semibold" onClick={() => {setRouteId(props.journeyData.routeId)}}>Show Map</button></td>
                                </tr>
                                </tbody>
                            </table>
                            {showPassengers.includes(props.journeyData.id) &&  <div className="p-4 m-3 bg-white shadow">

                                <table className="table w-full table-striped">
                                    <thead>
                                    <tr>
                                        <th className="text-left">Passenger Name</th>
                                        <th className="text-left">Guardians</th>
                                        <th className="text-left">Reservation Type</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {props.journeyData.reservations.map(reservation => {
                                        return (
                                            <tr key={reservation.id}>
                                                <td>
                                                    {reservation.passenger.firstName + ' ' + reservation.passenger.lastName}
                                                </td>
                                                <td>{reservation.passenger.carers.map((carer, index) => {
                                                    let end = (index + 1) !== reservation.passenger.carers.length ? ', ': ''
                                                    return carer.firstName + ' ' + carer.lastName + end
                                                })}
                                                </td>
                                                <td>
                                                    {reservation.date ? 'One off' : 'Perpetual'}
                                                </td>
                                            </tr>
                                        )

                                    })}
                                    </tbody>
                                </table>
                            </div>
                            }
                            {props.journeyData.reservations.length !== 0 && <button type="button" className="mt-2 bg-gray-300 button"
                                     onClick={() => handleShowPassengers(props.journeyData.id)}>
                                            <span><FontAwesomeIcon
                                                icon={showPassengers.includes(props.journeyData.id) ? faMinus : faPlus}
                                                className="mr-1"/>
                                                {showPassengers.includes(props.journeyData.id) ? 'Hide' : 'Show'} Reservations</span>
                            </button>}
                            {props.journeyData.reservations.length === 0 &&
                            <div className="w-full font-semibold text-center text-gray-400"> No reservations on route </div>
                            }
                        </div>
    </div>
    )
}
