import {AxiosRequestConfig, AxiosResponse} from "axios";
import {apiAxios as axios} from "../api/axios";
import {DateRangeParams} from "../stats/service";
import {CovidTest} from "./types";

export function save(covidTest: CovidTest): Promise<AxiosResponse> {
    return axios.post('/covid', covidTest as AxiosRequestConfig)
}

export function fetch(params: DateRangeParams) {
    return axios.get('/covid', {params} as AxiosRequestConfig)
}

export function fetchExposedSessions (id: number) {
    return axios.get('/covid/report/' + id)
}

export function fetchById ( id: number) {
    return axios.get('/covid/'+ id)
}

export function getCovidCsvReport (id: number) {
    return axios.get('/covid/report/export/'+id)
}