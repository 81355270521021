import { useEffect, useState } from "react";
import { fetchTotalRoutesRun } from "./service";
import LiveTag from './LiveTag';
import moment from "moment";

let interval: any;

export default function TotalRoutesRun () {
    const [total, setTotal] = useState(0);

    const [loaded, setLoaded] = useState(false);

    function refreshData() {
        const fromDate = moment().startOf('day').format('YYYY-MM-DD');
        const toDate = moment().add(1, 'day').startOf('day').format('YYYY-MM-DD');
        const params = {
            fromDate,
            toDate,
        };
        fetchTotalRoutesRun(params).then(res => {
            setTotal(res.data.statistic);
            setLoaded(true);
        });
    }

    useEffect(() => {
        refreshData();
        interval = window.setInterval(() => {
            refreshData()
        }, 15000);
        return () => clearInterval(interval);
    }, []);


    return (
        <div className="flex flex-col h-full text-center">
            <div>
                <span className="mb-2 font-bold">Routes Run Today</span>
            </div>
            <div className="m-auto">
                <span className="text-xl">{total || '--'}</span>
            </div>
            <div>
                {(loaded) ? (<LiveTag />) : null}
            </div>
        </div>
    );
}
