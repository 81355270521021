import {ChangeEvent, createRef, FormEvent, RefObject, useEffect, useState} from "react";
import {
    clearTokens,
    selectErrorMessage,
    selectIsLoggedIn,
    selectLoading,
    setErrorMessage,
} from "../auth/slice";
import logo from '../../logo.png';
import Alert from "../alerts/Alert";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {useHistory, useLocation, Link} from 'react-router-dom';
import { loginAction } from "../auth/actions";

function Login() {
    const passwordInput: RefObject<HTMLInputElement> = createRef();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const dispatch =  useAppDispatch();
    const history = useHistory();
    const location = useLocation();
    const isLoggedIn = useAppSelector(selectIsLoggedIn);
    const errorMessage = useAppSelector(selectErrorMessage);
    const loading = useAppSelector(selectLoading);

    interface LocationState {
        from?: Location,
    }

    useEffect(() => {
        if (isLoggedIn) {
            let from = '/';
            if (location.state) {
                const locState = location.state as LocationState;
                from = locState.from?.pathname || from;
            }
            history.replace(from);
        }
    }, [isLoggedIn, history]);

    const attemptLogin = (event: FormEvent) => {
        event.preventDefault();
        if (loading) {
            return;
        }

        dispatch(clearTokens());
        dispatch(setErrorMessage(''));
        dispatch(loginAction({username, password, rememberMe}));
    }

    const updateUsername = (event: ChangeEvent) => {
       setUsername((event.target as HTMLInputElement).value);
    }

    const updatePassword = (event: ChangeEvent) => {
        setPassword((event.target as HTMLInputElement).value)
    }

    const updateRememberMe = (event: ChangeEvent) => {
       setRememberMe((event.target as HTMLInputElement).checked);
    }

    let button = (<button className="w-full bg-yellow-300 button" type="submit">Login</button>);
    if (!username || !password) {
        button = (<button className="w-full button" disabled={true}>Login</button>)
    }
    if (loading) {
        button = (<button className="w-full button" disabled={true}>Logging in...</button>)
    }
    return (
        <div className="flex justify-center w-screen h-screen align-center">
            <div className="w-11/12 max-w-sm p-4 mx-auto my-auto text-center bg-white rounded shadow-sm border-box">
                <img src={logo} alt="Zywepit Logo" className="px-1 mx-auto"/>
                <h2 className="mb-2 font-medium text-md">Log in to Zywpeit</h2>
                <form action="" onSubmit={attemptLogin} className="flex flex-col" method="POST">
                    <input type="text" name="username" required id="username" aria-label="Username" placeholder="Username"
                           autoFocus className="input-control" value={username} onChange={updateUsername} disabled={loading}/>
                    <input type="password" name="password" required id="password" aria-label="Password"
                           ref={passwordInput} placeholder="Password" className="input-control"
                           value={password} onChange={updatePassword} disabled={loading}/>
                    <label className="p-1 mb-2 text-left">
                        <input type="checkbox" checked={rememberMe} onChange={updateRememberMe} disabled={loading}/> Stay
                        logged in
                    </label>
                    <Alert type="error" message={errorMessage}/>
                    <div className="py-2">
                        {button}
                    </div>
                    <div className="py-1 text-sm">
                        <Link to="/forgotPassword">Forgot Password</Link>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Login;