import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "../../app/store";
import * as authService from "./service";
import {apiAxios} from '../api/axios';
import { AuthState} from "./types";

const initialState: AuthState = {
    accessToken: null,
    refreshToken: null,
    expiresAt: null,
    errorMessage: '',
    refreshing: false,
    loading: false,
    loaded: false,
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setRefreshing: (state, {payload}) => {
            state.refreshing = payload;
        },
        setTokens: (state, {payload}) => {
            setupAxios(payload.access_token);
            state.refreshToken = payload.refresh_token;
            state.accessToken = payload.access_token;
            state.expiresAt = (Date.now() +(payload.expires_in));
        },
        setLoggedInUser: (state, {payload}) => {
            state.user = payload
        },
        clearTokens: (state) => {
            state.refreshToken = null;
            state.accessToken = null;
            state.expiresAt = null;
            authService.clearAuthFromStorage();
            state.user = undefined;
        },
        setErrorMessage: ((state, {payload}) => {
            state.errorMessage = payload;
        }),
        setLoading: ((state, {payload}) => {
            state.loading = payload;
        }),
        setLoaded: ((state, {payload}) => {
            state.loaded = payload;
        }),
    },
})

function setupAxios(accessToken : string) {
    if (!accessToken) {
        delete apiAxios.defaults.headers.common['Authorization'];

        return;
    }
    // Set the authorization header for axios requests
    apiAxios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
}

export const {setRefreshing, setTokens, clearTokens, setErrorMessage, setLoading, setLoaded, setLoggedInUser} = authSlice.actions;

export const selectAccessToken = (state: RootState) => state.auth.accessToken;

export const selectUser = (state: RootState) => state.auth.user;

export const selectUserPolicies = (state: RootState) => (state.auth.user?.policies)

export const selectRefreshToken = (state: RootState) => state.auth.refreshToken;

export const selectIsLoggedIn = (state: RootState) => (state.auth.accessToken !== '' && state.auth.accessToken !== null && state.auth.user);

export const selectIsTokenExpired = (state: RootState) => (state.auth.expiresAt && Date.now() >= state.auth.expiresAt);

export const selectTokenExpiresAt = (state: RootState) => state.auth.expiresAt;

export const selectErrorMessage = (state: RootState) => (state.auth.errorMessage);

export const selectRefreshing = (state: RootState) => (state.auth.refreshing);

export const selectLoading = (state: RootState) => (state.auth.loading);

export const selectLoaded = (state: RootState) => (state.auth.loaded);

export default authSlice.reducer;

