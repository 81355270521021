import {RouteMapWaypoint} from "./RouteMap";
import {Route} from "./types";

export const convertRouteStops = (route: Route) => {
    return route.routeStops.map(rs => {
        if (!rs.stop.lat || !rs.stop.long) {
            return null;
        }
        return {
            name: rs.stop.name,
            location: new google.maps.LatLng(parseFloat(rs.stop.lat), parseFloat(rs.stop.long))
        } as RouteMapWaypoint;
    }).filter(w => (w !== null)) as RouteMapWaypoint[]
}