import {MediaFile} from './types';
import {getMediaFileViewUrl} from './service';
import { useEffect, useState } from 'react';
import {isMediaFileAnImage} from './util';

export interface PreviewProps {
    mediaFile: MediaFile,
}

export function Preview (props: PreviewProps) {
    const [url, setUrl] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
       load();
    }, []);

    useEffect(() => {
       load();
    }, [props.mediaFile]);

    function load() {
        setLoading(true);
        getMediaFileViewUrl(props.mediaFile).then(({data}) => {
            setUrl(data.signedUrl);
            setLoading(false);
        })
    }

    if(loading) {
        return (<p>Loading preview</p>);
    }

    if(!url) {
        return (<p className="my-2">No preview available</p>);
    }

    if (isMediaFileAnImage(props.mediaFile)) {
        return (
            <div className="inline-block w-auto my-2 border-2 rounded">
                <img src={url} alt={props.mediaFile.fileName} />
            </div>
        );
    }

    return (
        <a href={url} target="_blank" rel="noreferrer" className="my-2 button">View File</a>
    );
}

export default Preview;