import React, { useState } from "react";
import {Discount, DiscountGroup, DiscountGroups} from "./types";
import { Formik, Form, Field } from "formik";
import * as Yup from 'yup';
import FormError from "../form/FormError";
import Alert from "../alerts/Alert";
import {deleteDiscount, fetchDiscount, fetchDiscountGroups, saveDiscount} from './service';
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useHistory, useParams } from "react-router-dom";
import { ParamsWithId } from "../routing/types";
import { useEffect } from "react";
import ConfirmModal from "../modal/ConfirmModal";
import { MouseEvent } from 'react';
import Upload from "../media/Upload";
import Preview from "../media/Preview";
import Delete from "../media/Delete";
import { MediaFile } from "../media/types";
import Select from "react-select";
import {ReactSelectOption, ReactSelectOptions} from "../dropdown/types";

const initialDiscount = {
    name: '',
    title: '',
    description: '',
} as Discount;

export function Edit() {
    const [discount, setDiscount] = useState<Discount>(initialDiscount);
    const [apiErrors, setApiErrors] = useState({});
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [discountGroupOptions, setDiscountGroupOptions] = useState<any[]>([]);
    const [group, setGroup] = useState<DiscountGroup>()
    const [error, setError] = useState('');
    const history = useHistory();
    const { id } = useParams<ParamsWithId>();
    const [showDelete, setShowDelete] = useState(false);
    const [deleting, setDeleteing] = useState(false);

    useEffect(() => {
        if (!id) {
            setDiscount(initialDiscount)
            return;
        }
        setLoading(true);
        fetchDiscount(id).then(({ data }) => {
            setDiscount(data);
            setGroup(data.group);
        }).catch(err => {
            setDiscount(initialDiscount);
            history.goBack();
            console.error(err);
        }).finally(() => {
            setLoading(false);
        })
    }, [id]);

    useEffect(() => {
        fetchDiscountGroups().then((response) => {
            const data = response.data as DiscountGroups
            let selectOptions:ReactSelectOptions = [{label:'', value: ''}]
            for (const {id, name} of data) {
                if(id){
                    selectOptions.push({label: name, value:id.toString()})
                }
            }
            setDiscountGroupOptions(selectOptions)
        })
    }, [])

    function handleSubmit(values: any) {
        setError('');
        setMessage('');
        setSaving(true);
        let discountData = { ...values, image: discount.image, group: null}
        if(group?.id) {
            discountData = { ...discountData, group: group }
        }
        saveDiscount(discountData).then(({ data }) => {
            if (!discount.id) {
                history.push('/discounts/' + data.id + '/edit');
            }
            setDiscount(data);
            setSaving(false);
            setMessage('Discount saved successfully');
        }).catch(err => {
            setSaving(false);
            setError('Failed to save discount, please check for errors');
            if (err.response && err.response.data) {
                setApiErrors(err.response.data);
                return;
            }
            console.error(err);
        })
    }

    function handleDeleteClick(event: MouseEvent) {
        event.preventDefault();
        setShowDelete(true);
    }

    function handleDeleteConfirmed() {
        setDeleteing(true);
        deleteDiscount(discount.id).then(() => {
            setShowDelete(false);
            setDeleteing(false);
            setMessage('Discount deleted successfully, redirecting...');
            window.setTimeout(() => {
                history.replace('/discounts');
            }, 3000);
        }).catch(err => {
            setShowDelete(false);
            setError('Failed to delete discount, please try again');
            setDeleteing(false);
        })
    }

    function handleUpload(mediaFile: MediaFile) {
        setDiscount({ ...discount, image: mediaFile });
    }

    function handleDeleteImage () {
        setDiscount({...discount, image: null})
    }

    const handleChangeGroup = (opt: ReactSelectOption) => {
        let group = {name: opt.label, id: parseInt(opt.value)} as DiscountGroup
        setGroup(group)
    }
    const validation = Yup.object().shape({
        name: Yup.string().label('Name').required().max(255),
        title: Yup.string().label('Title').required().max(255),
        description: Yup.string().label('Description').max(2000),
    });

    if (loading) {
        return (
            <div>
                <span>Loading discount...</span>
            </div>
        )
    }
    const customStyles = {
        container: (styles: any) => ({ ...styles, maxWidth:200}),
    }

    return (
        <div>
            <div>
                <h1 className="text-lg">{discount.id ? 'Edit' : 'New'} Discount</h1>
            </div>
            <Formik
                initialValues={{ ...discount, group: {value: group?.id, label: group?.name}}}
                onSubmit={(values) => {
                    handleSubmit(values)
                }}
                validationSchema={validation}
                validateOnBlur
            >
                {({ errors, isValid, values, setFieldTouched, setFieldValue }) => {
                    const allErrors = { ...errors, ...apiErrors };
                    return (
                        <Form autoComplete="false">
                            <div className="p-4 my-2 bg-white rounded shadow">
                                <div>
                                    <label htmlFor="name" className="form-label">Name:</label>
                                    <Field className="input-control" name="name" id="name" placeholder="Name" />
                                    <FormError name="name" errors={allErrors} />
                                </div>
                                <div>
                                    <label htmlFor="title" className="form-label">Title:</label>
                                    <Field className="input-control" name="title" id="title" placeholder="Title" />
                                    <FormError name="title" errors={allErrors} />
                                </div>
                                <div>
                                    {discount.image ? (
                                        <>
                                            <Preview mediaFile={discount.image} />
                                            <div>
                                                <Delete mediaFile={discount.image} onDelete={handleDeleteImage} />
                                            </div>
                                        </>
                                    ) : null}
                                </div>
                                <div>
                                    <Upload onUploaded={handleUpload} imageOnly={true}/>
                                </div>
                                <div>
                                    <label htmlFor="description" className="form-label">Description:</label>
                                    <Field className="w-full input-control" name="description" id="description" as="textarea" placeholder="Type description here..." />
                                    <FormError name="description" errors={allErrors} />
                                </div>
                                <div>
                                    <label htmlFor="group" className="form-label">Group:</label>
                                    <Select
                                        styles={customStyles}
                                        name="group"
                                        id="group"
                                        onBlur={() => setFieldTouched("group", true)}
                                        value={values.group}
                                        onChange={(opt) => {
                                            // @ts-ignore
                                            handleChangeGroup(opt)
                                            setFieldValue('group', opt)
                                        }}
                                        options={discountGroupOptions}
                                    />
                                    <FormError name="group" errors={allErrors} />
                                </div>
                            </div>
                            <Alert message={message} type="success" />
                            <Alert message={error} type="error" />
                            <div className="p-4 my-2 bg-white rounded shadow">
                                <div className="flex">
                                    <Link to="/discounts" className="button">
                                        <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                                        Back to Discounts
                                    </Link>
                                    <button className="ml-auto bg-yellow-300 button" disabled={deleting || saving || !isValid}>{saving ? 'Saving...' : 'Save Discount'}</button>
                                    {discount.id ? (
                                        <button className="text-red-400 button" type="button" disabled={saving} onClick={handleDeleteClick}>Delete Discount</button>
                                    ) : null}
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
            {showDelete ? (
                <ConfirmModal title="Delete Discount" onClose={() => setShowDelete(false)} onConfirm={handleDeleteConfirmed} disabled={deleting || saving}>
                    Are you sure you want to delete this discount?
                </ConfirmModal>
            ) : null}
        </div>
    );
}