import { faPlus, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import {fetchDrivers, regenerateDriverPins} from "./service";
import { Driver } from "./types";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {selectUserPolicies} from "../auth/slice";
import {getPermissionsLevel, getPermissionsLevelForEntity, Permission} from "../policies/helpers";
import Pagination from "../pagination/Pagination";
import {setErrorNotificationText} from "../notification/slice";
import moment from "moment/moment";

export function Index () {
    const policies = useAppSelector(selectUserPolicies)
    const permissionsLevel = getPermissionsLevel('driver', policies)
    const FileDownload = require('js-file-download');
    const [selectAll, setSelectAll] = useState(false);
    const [selectedIds, setSelectedIds] = useState<number[]>([]);
    const [searchText, setSearchText] = useState('');
    const [loading, setLoading] = useState(false);
    const [drivers, setDrivers] = useState<Driver[]>([]);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(1);
    const [regenerating, setRegenerating] = useState(false);
    const dispatch = useAppDispatch();


    const handleChecked = (target: HTMLInputElement) => {
        const driverId = parseInt(target.value)
        if (target.checked) {
            setSelectedIds(selectedIds.concat(driverId))
        } else {
            setSelectAll(false);
            setSelectedIds(selectedIds.filter(id => id !== driverId));
        }
    }

    const handleSelectAllChecked = () => {
        const driverIds: React.SetStateAction<number[]> = [];
        if (!selectAll) {
            for (const driver of drivers) {
                const driverPerms = getPermissionsLevelForEntity('driver', driver, policies)
                if (driver?.id && driverPerms > 1) {
                    driverIds.push(driver.id)
                }
            }
            setSelectAll(true);
            setSelectedIds(driverIds);
        } else {
            setSelectedIds([]);
            setSelectAll(false)
        }
    }

    useEffect(() => {
        setSelectedIds([]);
        setSelectAll(false)
        loadDrivers();
    }, [page]);

    function loadDrivers() {
        setLoading(true);
        const query = {
            search: searchText,
            page
        };
        fetchDrivers(query).then(({data}) => {
            setPages(data.lastPage);
            setDrivers(data.items);
            setLoading(false);
        }).catch(e => {
            console.error(e);
            setLoading(false);
            setDrivers([]);
        })
    }

    const handleRegenerateDriverPins = () => {
        setRegenerating(true);
        regenerateDriverPins({driverIds: selectedIds}).then((response) => {
            FileDownload(response.data, 'updated_pins_' + moment().format('YYYY-MM-DD') +'.csv');
        }).catch(() => {
            dispatch(setErrorNotificationText('Error regenerating pins'));
        }).finally(() => {
            setRegenerating(false)
        })
    }

    return (
        <div>
            <div className="mb-4">
                <h1 className="text-lg">Drivers</h1>
            </div>
            <form className="flex items-center px-4 py-2 mb-4 bg-white shadow" onSubmit={(e) => {e.preventDefault(); loadDrivers();}}>
                <div className="mr-2">
                    <input type="search" placeholder="Search..." value={searchText} onChange={(e) => {setSearchText(e.target.value)}} className="mb-0 input-control"/>
                </div>
                <div>
                    <button className="bg-yellow-300 button" disabled={loading}>Search</button>
                </div>
                <div className="ml-auto">
                    <button className="mr-2 text-white bg-gray-600 button" type="button" onClick={(event) => {event.preventDefault();  loadDrivers();}} disabled={loading}>
                        <FontAwesomeIcon icon={faSyncAlt} className={'mr-1 text-sm ' + (loading ? 'fa-spin' : '')}/>
                        {loading? 'Loading...' : 'Refresh'}
                    </button>
                    <button className="mr-2 text-white bg-gray-600 button" disabled={selectedIds.length === 0 || regenerating}
                            type="button" onClick={() => {
                        handleRegenerateDriverPins()
                    }}> <FontAwesomeIcon icon={faSyncAlt} className={'mr-1 text-sm ' + (regenerating ? 'fa-spin' : '')}/>
                        {regenerating ? 'Regenerating' : 'Regenerate Pins'}
                    </button>
                    {permissionsLevel >= Permission.Create && <Link to="/drivers/new" className="ml-auto text-white bg-gray-500 button">
                        <FontAwesomeIcon icon={faPlus} className="mr-2"/>
                        New Driver
                    </Link>}
                </div>
            </form>
            {drivers.length === 0 && !loading ? (
                <span className="text-gray-500">No drivers found, <Link to="/drivers/new" className="text-gray-800 underline">start by adding one</Link></span>
            ) : null}

            {!loading ?
                <div className="p-4 bg-white shadow">
                    <div className="mb-4">
                        <h1 className="text-lg">Drivers</h1>
                    </div>
                    <table className="table w-full table-striped p-4">
                        <thead>
                        <tr>
                            <th className="text-left"><input type="checkbox"
                                                             onChange={handleSelectAllChecked}
                                                             checked={selectAll}/></th>
                            <th className="text-left">Name</th>
                            <th className="text-left">Email</th>
                            <th className="text-left">Routes</th>
                            <th className="text-left"></th>
                            <th className="text-left"></th>
                        </tr>
                        </thead>
                        <tbody>
                        {drivers.map((driver: Driver) => {
                            const driverPerms = getPermissionsLevelForEntity('driver', driver, policies)
                            return (
                                <tr>
                                    <td>
                                        {permissionsLevel >= Permission.Edit && <input onChange={(event) => {handleChecked(event.target)}} type="checkbox"
                                                                                       checked={(!!driver.id && selectedIds.indexOf(driver?.id) !== -1)}
                                                                                       value={driver.id}/>}</td>
                                    <td>{driver.firstName} {driver.lastName}</td>
                                    <td>{driver.email}</td>
                                    <td>{driver.routes.length} Route{driver.routes.length !== 1 ? 's' : ''}</td>
                                    <td>{driverPerms >= 2 && <Link to={'/drivers/' + driver.id + '/edit'}
                                                                   className="flex-1 text-center font-semibold">Edit</Link>}</td>
                                    <td><Link to={'/drivers/' + driver.id + '/view'}
                                              className="flex-1 text-center font-semibold">View</Link>
                                    </td>

                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                    <div className="text-center m-2">
                        <Pagination page={page} onChange={setPage} pages={pages}/>
                    </div>
                </div> : <div>...Loading drivers</div>}
        </div>
    );
}

export default Index;