import {Field, Form, Formik} from "formik";
import {getPermissionsLevel, Permission} from "../policies/helpers";

import { Organisation } from "../organisations/types";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {selectUserPolicies} from "../auth/slice";
import React, {useState} from "react";
import { Select as OrganisationSelect } from '../organisations/Select';
import * as Yup from "yup";
import {AddPermission} from "./types";
import {addPermissions} from "./service";
import {setErrorNotificationText, setSuccessNotificationText} from "../notification/slice";
import {useParams} from "react-router-dom";
import {ParamsWithId} from "../routing/types";
import {useHistory} from "react-router";


export const Add = () => {

    const policies = useAppSelector(selectUserPolicies)
    const dispatch = useAppDispatch();
    const permissionsLevel = getPermissionsLevel('organisation', policies)
    const history = useHistory();
    const { id } = useParams<ParamsWithId>()
    const [organisationOne, setOrganisationOne]= useState<Organisation>()
    const [organisationTwo, setOrganisationTwo]= useState<Organisation>()
    const [saving, setSaving] = useState(false);


    const handleSubmit = (values: AddPermission) => {
        addPermissions(values).then((() => {
            dispatch(setSuccessNotificationText('Permission Saved Successfully'))
            history.push('/organisations/'+ values.organisation1+ '/edit')
        })).catch(err => {
            dispatch(setErrorNotificationText('Permission Not Saved'))
            console.error(err)
        }).finally(() => {
            setSaving(false)
        })
    }
    const validation = Yup.object().shape({
        organisation1: Yup.number().required().label('Organisation One'),
        organisation2: Yup.number().required().label('Organisation Two'),
        relationship: Yup.string().required().label('Relationship'),
    });

    return (
        <div className="flex">
            <div className="p-4 bg-white shadow" style={{width:"50%"}}>
                <div className="mb-4">
                <h1 className="text-lg">Add Permission</h1>
                </div>
                <Formik
                    initialValues={{relationship: "", organisation1: parseInt(id), organisation2: undefined}}
                    onSubmit={handleSubmit}
                    validateOnBlur
                    validationSchema={validation}
                >
                    {({setFieldValue, isValid, dirty,values, handleChange}) => {
                        const onOrg1Change = (organisation: Organisation | undefined) => {
                                if (organisation) {
                                    setOrganisationOne(organisation)
                                    setFieldValue('organisation1', organisation.id)
                                } else {
                                    setOrganisationOne(undefined)
                                    setFieldValue('organisation1', undefined)
                                }}
                        return (
                            <Form autoComplete="false">
                                <div className="bg-white">
                                    <div>
                                        <label htmlFor="organisation1" className="form-label">Organisation One</label>
                                        {id !== undefined ? <OrganisationSelect onChange={onOrg1Change} value={parseInt(id)}/> :
                                            <OrganisationSelect onChange={onOrg1Change}/>
                                        }
                                    </div>
                                    <div>
                                        <label htmlFor="active" className="form-label">Relationship</label>
                                        <Field as="select" onChange={(event: React.FormEvent<HTMLInputElement>) => {
                                            handleChange(event)
                                        }} name="relationship" id="relationship" className="input-control">
                                            <option value="">Select Permissions</option>
                                            <option value="full-read">Full Read Permissions</option>
                                            <option value="full-edit">Full Edit Permissions</option>
                                            <option value="full-delete">Full Delete Permissions</option>
                                            <option value="route-read">Passenger And Route Read Permissions</option>
                                            <option value="driver-read">Driver Read Permissions</option>
                                            <option value="route-edit">Passenger And Route Edit Permissions</option>
                                            <option value="driver-edit">Driver Edit Permissions</option>
                                            <option value="route-delete">Passenger And Route Delete Permissions</option>
                                            <option value="driver-delete">Driver Delete Permissions</option>
                                        </Field>
                                    </div>
                                    <div>
                                        <label htmlFor="organisation2" className="form-label">Organisation Two </label>
                                        <OrganisationSelect onChange={(organisation: Organisation|undefined) => {
                                            if(organisation) {
                                                setFieldValue('organisation2', organisation.id)
                                                setOrganisationTwo(organisation)
                                            } else {
                                                setOrganisationTwo(undefined)
                                                setFieldValue('organisation2',undefined)
                                            }}} />
                                    </div>
                                </div>
                                <div className="my-2 bg-white">
                                    {isValid && dirty && <span className="text-gray-400"> An user in organisation {organisationOne?.name} will have {values.relationship} access to organisation {organisationTwo?.name}</span>}
                                </div>
                                <div className="my-2 bg-white">
                                    <div className="flex">
                                        {permissionsLevel >= Permission.Delete && <button className="ml-auto bg-yellow-300 button" disabled={saving || !isValid || !dirty}>{saving ? 'Saving...' : 'Save Permission'}</button>}
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
        </div>
        </div>

    )
}