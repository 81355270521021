import {apiAxios} from "../api/axios";
import {AddPermission, OrganisationPermission} from "./types";

export function addPermissions(addPermission: AddPermission) {
    return apiAxios.post('/permission/add', addPermission)
}

export function getOrganisationPermissions(id: any) {
    return apiAxios.get('/permission/'+ id)
}

export function deletePermission(deletePermission: OrganisationPermission) {
    return apiAxios.post('/permission/delete', deletePermission)
}