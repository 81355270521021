import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { DateRangePicker as Picker } from 'react-date-range';
import { formatDate } from './helper';
import { defaultStaticRanges } from "react-date-range"; // this will give you already defined list
import { createStaticRanges } from "react-date-range"; //pass your object to this method

const lastYear = new Date();
lastYear.setFullYear((new Date()).getFullYear() - 1);
lastYear.setMonth(0);
lastYear.setDate(1);

const thisYear = new Date();
thisYear.setMonth(0);
thisYear.setDate(1);

const now = new Date();


const lastYearRange = {
    label: 'Last Year',
    range: () => ({
        startDate: lastYear,
        endDate: thisYear
    }),
    isSelected: (range: any) => {
        return range.startDate === lastYear && range.endDate === thisYear;
    },
};

const thisYearRange = {
    label: 'This Year',
    range: () => ({
        startDate: thisYear,
        endDate: now
    }),
    isSelected: (range: any) => {
        return range.startDate === thisYear && range.endDate === now;
    },
};

let customRanges = createStaticRanges([thisYearRange, lastYearRange]);

export interface DateRange {
    startDate: Date,
    endDate: Date,
}

export interface DateRangePickerProps {
    value: DateRange,
    onChange?: CallableFunction,
}

export function DateRangePicker(props: DateRangePickerProps) {
    const [showPicker, setShowPicker] = useState(false);

    function toggleShowPicker() {
        setShowPicker(!showPicker);
    }

    function handleOnChange(event: any) {
        if (props.onChange) {
            let startDate = new Date(event.range1.startDate.getTime())
            let endDate = new Date(event.range1.endDate.getTime())
            endDate.setHours(23,59,59,999)
            startDate.setHours(0,0,0,0)
            props.onChange({startDate, endDate});
        }
    }

    return (
        <div className="relative">
            <button type="button" className="m-0 mb-0 text-sm bg-white input-control" onClick={toggleShowPicker}>
                <FontAwesomeIcon icon={faCalendar} className="mr-2" />
                <span>{formatDate(props.value.startDate.toISOString())} - {formatDate(props.value.endDate.toISOString())}</span>
            </button>
            {(showPicker) ? (
                <>
                    <div className="fixed top-0 left-0 w-screen h-screen" onClick={toggleShowPicker}></div>
                    <div className="absolute left-0 z-50 shadow top-11">
                        <Picker ranges={[props.value]} onChange={handleOnChange} staticRanges={[...defaultStaticRanges, ...customRanges]}/>
                    </div>
                </>
            ) : null}
        </div>
    );
}

export default DateRangePicker;
