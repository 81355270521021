import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus, faSyncAlt} from "@fortawesome/free-solid-svg-icons";
import {getDateDifference, getFormattedDateFromString, getFormattedTimeFromString} from "../dates/helper";
import React, {useState} from "react";
import {RouteSessions} from "../sessions/types";

interface RouteSessionTableProps {
    routeSessions:RouteSessions
    loading:boolean
}
export const RouteSessionTable = (props: RouteSessionTableProps) => {
    const [showPassengers, setShowPassengers] = useState<Array<number>>([]);


    const handleShowPassengers = (id: number) => {
        let tempArray = [...showPassengers]
        if (tempArray.includes(id)) {
            tempArray = tempArray.filter(tempId => tempId !== id)
        } else {
            tempArray.push(id)
        }
        setShowPassengers(tempArray)
    }

    return (<div>
    {props.routeSessions !== undefined &&
    <div>
        {props.routeSessions.length === 0 &&
        <div className="w-full font-semibold text-center text-gray-400"> {props.loading ?
            <span><FontAwesomeIcon icon={faSyncAlt} className={'ml-2 text-sm fa-spin'}/>...Loading</span> :
            <div className="p-4 my-3 shadow bg-grey-50">No journeys found</div>} </div>}
        {props.routeSessions.map((routeSession) => {
            return (
                <div key={routeSession.id} className="p-4 my-3 bg-white shadow">
                    <h3 className={"mb-2 font-semibold "}>
                        Route {routeSession.route.name} {routeSession.schedule.name} @ {routeSession.journey.startTime} on {getFormattedDateFromString(routeSession.startDate)}
                        {routeSession.driver ? ' driven by ' + routeSession.driver.firstName + ' ' + routeSession.driver.lastName : ''}
                    </h3>
                    <table className="table w-full table-striped">
                        <thead>
                        <tr>
                            <th className="text-left">Route</th>
                            <th className="text-left">Driver</th>
                            <th className="text-left">Actual Duration</th>
                            <th className="text-left">Estimated Duration</th>
                            <th className="text-left">Capacity</th>
                            <th className="text-left">Total Passengers</th>
                            <th className="text-left">Scheduled Start</th>
                            <th className="text-left">Start</th>
                            <th className="text-left">End</th>
                            <th className="text-left">Schedule</th>
                            <th className="text-left">Notes</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{routeSession.journey.routeName}</td>
                            <td>{routeSession.driver?.firstName ? routeSession.driver.firstName + ' ' + routeSession.driver.lastName : ''}</td>
                            <td>{(routeSession.endDate && routeSession.startDate) ? getDateDifference({
                                dateOneString: routeSession.startDate,
                                dateTwoString: routeSession.endDate,
                                units: 'minutes'
                            }) +  ' mins' : 'N/A'}
                            </td>
                            <td>{routeSession.schedule.duration ? routeSession.schedule?.duration + ' mins' : ''}</td>
                            <td>{routeSession.route.capacity}</td>
                            <td>{routeSession.totalPassengers}</td>
                            <td>{routeSession.journey?.startTime}</td>
                            <td>{routeSession.startDate ? getFormattedTimeFromString(routeSession.startDate, true) : ''}</td>
                            <td>{routeSession.endDate ? getFormattedTimeFromString(routeSession.endDate, true) : ''}</td>
                            <td>{routeSession.schedule?.name}</td>
                            <td>{routeSession.notes ?? ''}</td>
                        </tr>
                        </tbody>
                    </table>
                    {showPassengers.includes(routeSession.id) && <div className="p-4 my-3 bg-white shadow">

                        <table className="table w-full table-striped">
                            <thead>
                            <tr>
                                <th className="text-left">Passenger Name</th>
                                <th className="text-left">Guardians</th>
                                <th className="text-left">Checked In Time</th>
                                <th className="text-left">Checked In Stop</th>
                                <th className="text-left">Checked Out Time</th>
                                <th className="text-left">Checked Out Stop</th>
                            </tr>
                            </thead>
                            <tbody>
                            {routeSession.sessionPassengers.map(sessionPassenger => {
                                return (
                                    <tr key={sessionPassenger.id}>
                                        <td>
                                            {sessionPassenger.passenger.firstName + ' ' + sessionPassenger.passenger.lastName}
                                        </td>

                                        <td>{sessionPassenger.passenger.carers.map((carer, index) => {
                                                let end = (index + 1) !== sessionPassenger.passenger.carers.length ? ', ': ''
                                                return carer.firstName + ' ' + carer.lastName + end
                                            })}
                                        </td>
                                        <td>
                                            {getFormattedTimeFromString(sessionPassenger.checkedIn, true)}
                                        </td>
                                        <td>
                                            {sessionPassenger.checkedInStop?.name}
                                        </td>
                                        <td>
                                            {getFormattedTimeFromString(sessionPassenger.checkedOut, true)}
                                        </td>
                                        <td>
                                            {sessionPassenger.checkedOutStop?.name}
                                        </td>
                                    </tr>
                                )

                            })}
                            </tbody>
                        </table>
                    </div>
                    }
                    <button type="button" className="mt-2 bg-gray-300 button"
                            onClick={() => handleShowPassengers(routeSession.id)}>
                                            <span><FontAwesomeIcon
                                                icon={showPassengers.includes(routeSession.id) ? faMinus : faPlus}
                                                className="mr-1"/>
                                                {showPassengers.includes(routeSession.id) ? 'Hide' : 'Show'} Passengers</span>
                    </button>
                    {routeSession.sessionPassengers.length === 0 &&
                    <div className="w-full font-semibold text-center text-gray-400"> No passengers on route </div>
                    }
                </div>
            )
        })}
    </div>
    }</div>
)}
