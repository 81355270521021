import { useState } from 'react';
import Modal from '../modal/Modal';
import {Stop} from './types';
import StopForm from './StopForm';
import Find from './Find';

export interface StopModalProps {
    title: string,
    closeAction: CallableFunction,
    onSelect: CallableFunction,
}

export function  StopModal(props: StopModalProps) {
    const [mode, setMode] = useState<null|string>(null)

    const handleOnSelect = (stop: Stop) => {
        if (props.onSelect) {
            props.onSelect(stop);
        }
    }

    return (
        <Modal {...props}>
            {(mode == null) ? (
                <div className="flex flex-col my-2">
                    <button className="block mb-2 bg-yellow-300 button" onClick={() => {setMode('create')}}>Create New Stop</button>
                    <button className="block bg-yellow-300 button" onClick={() => {setMode('existing')}}>Select Existing Stop</button>
                </div>
            ) : null}
            {mode === 'create' ? (
                <div>
                    <StopForm onSaved={handleOnSelect}/>
                </div>
            ) : null}
             {mode === 'existing' ? (
                <div>
                    <Find onSelect={handleOnSelect}/>
                </div>
            ) : null}
        </Modal>
    );
}

export default StopModal;