import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import authReducer from '../features/auth/slice';
import vehiclesReducer from "../features/vehicles/slice";
import routesReducer from '../features/routes/slice';
import createSagaMiddleware from '@redux-saga/core';
import passengerReducer from "../features/passengers/slice"
import cardReducer from "../features/cards/slice"
import devicesReducer from "../features/devices/slice"
import notificationReducer from "../features/notification/slice"
import reservationReducer from "../features/reservations/slice"
import sessionsReducer from "../features/sessions/slice"

import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();


export const store = configureStore({
    reducer: {
        auth: authReducer,
        vehicles: vehiclesReducer,
        routes: routesReducer,
        passengers: passengerReducer,
        cards:cardReducer,
        devices: devicesReducer,
        reservations:reservationReducer,
        sessions:sessionsReducer,
        notifications:notificationReducer
    },
    middleware: [
        sagaMiddleware,
    ],
    devTools: process.env.NODE_ENV !== 'production',
});

sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
