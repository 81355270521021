import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "../../app/store";

export interface Notification {
    text:string,
    type:string
}

interface NotificationState {
    notification: Notification|null
}

const initialState:NotificationState = {
    notification:null
}

export const slice = createSlice({
        name: "notification",
        initialState,
        reducers: {
            setNotification: (state: NotificationState, {payload}) => {
                state.notification = payload
            },
            setSuccessNotificationText: (state:NotificationState, {payload}) => {
                state.notification = {text: payload, type:"success"}
            },
            setErrorNotificationText: (state:NotificationState, {payload}) => {
                state.notification = {text: payload, type:"error"}
            },
            setInfoNotificationText: (state:NotificationState, {payload}) => {
                state.notification = {text: payload, type:"info"}
            }
        }
    }
)

export const {setNotification, setSuccessNotificationText, setErrorNotificationText, setInfoNotificationText} = slice.actions

export const selectNotification = (state :RootState) => state.notifications.notification;

export default slice.reducer
