import { useState, MouseEvent } from "react";
import { Driver, DriverRoute } from './types';
import { Formik, Form, Field } from "formik";
import FormError from "../form/FormError";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Link, useHistory, useParams } from "react-router-dom";
import random from 'lodash/random';
import * as Yup from 'yup';
import { deleteDriver, fetchDriver, saveDriver } from './service';
import { ParamsWithId } from '../routing/types';
import { useEffect } from "react";
import Alert from "../alerts/Alert";
import ConfirmModal from "../modal/ConfirmModal";
import {useAppSelector} from "../../app/hooks";
import {selectUserPolicies} from "../auth/slice";
import {getPermissionsLevelForEntity, Permission} from "../policies/helpers";
import {AddRouteModal} from "./AddRouteModal";
import { getFormikDateTimeFromString} from "../dates/helper";

export function Edit() {
    const policies = useAppSelector(selectUserPolicies)

    const [driver, setDriver] = useState<Driver>({
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        pin: String(random(0, 9999)).padStart(4, '0'),
        notes: '',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        town: '',
        county: '',
        postcode: '',
        routes: [],
        drivingLicenceNumber: '',
        nationalInsuranceNumber: '',
        trainingDetails: '',
    } as Driver);
    const [apiErrors, setApiErrors] = useState({});
    const [saving, setSaving] = useState(false);
    const [showPin, setShowPin] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const { id } = useParams<ParamsWithId>();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [addRoute, setAddRoute] = useState(false);

    useEffect(() => {
        if (!id) {
            setShowPin(true);
            return;
        }
        setLoading(true);
        fetchDriver(id).then(({ data }) => {
            setDriver(data);
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
            console.error(error);
            //history.goBack();
        });
    }, [id]);

    if (loading || !driver) {
        return (
            <div>Loading driver...</div>
        );
    }

    const permissionsLevel = getPermissionsLevelForEntity('driver', driver, policies)

    function handleFormSubmit(values: Driver) {
        const driverData = { id, ...values, routes: driver.routes } as Driver;
        setApiErrors({});
        setSaving(true);
        setSuccessMessage('');
        setErrorMessage('');
        saveDriver(driverData).then(({ data }) => {
            setDriver(data);
            setSaving(false);
            setSuccessMessage('Driver saved successfully');
            window.setTimeout(() => {
                setSuccessMessage('');
            }, 5000);
            if (!id) {
                history.push('/drivers/' + data.id + '/edit');
            }
        }).catch(error => {
            setErrorMessage('Failed to save driver, check for errors');
            setSaving(false);
            if (error.response) {
                setApiErrors(error.response.data);
            } else {
                console.error(error);
            }
        });
    }

    function handleDeleteClick(event: MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        setShowDelete(true);
    }

    function handleConfirmDelete() {
        setDeleting(true);
        deleteDriver(driver).then(() => {
            setDeleting(false);
            setSuccessMessage('Driver deleted successfully, redirecting...');
            setShowDelete(false);
            window.setTimeout(() => {
                history.push('/drivers');
            }, 3000);
        }).catch(error => {
            setErrorMessage('Failed to delete driver, please try again');
            console.error(error);
            setShowDelete(false);
            setDeleting(false);
        });
    }

    function updateDriverRouteExpiresAt(route: DriverRoute, expiresAt: string) {
        const driverRoutes = [...driver.routes];
        const driverRouteIndex = driverRoutes.findIndex(d => {
            return (d.id === route.id)
        });
        if (driverRouteIndex < 0) {
            return;
        }
        driverRoutes[driverRouteIndex].expiresAt = expiresAt;
        setDriver({...driver, routes: driverRoutes});

    }

    const removeRoute = (id: number) => {
        let newDriver = {...driver}
        newDriver.routes = newDriver.routes.filter(r => r.id !== id);
        setDriver(newDriver);
    }

    const validation = Yup.object().shape({
        firstName: Yup.string().label('First name').required().max(50),
        lastName: Yup.string().label('Last name').required().max(50),
        mobile: Yup.string().label('Mobile').nullable().min(11).max(15),
        email: Yup.string().label('Email').email().required(),
        pin: Yup.string().label('Pin Number').nullable().length(4).matches(/^[0-9]{4}$/),
        postcode: Yup.string().label('Postcode').nullable().min(5).max(9),
        drivingLicenceNumber: Yup.string().label('Driving Licence Number').nullable().max(18),
        nationalInsuranceNumber: Yup.string().label('National Insurance Number').nullable().max(9),
        trainingDetails: Yup.string().label('Training Details').nullable().max(3000),
    });

    return (
        <div>
            {addRoute && <AddRouteModal driver={driver} setDriver={setDriver} closeAction={() => {
                setAddRoute(false)
            }}/>}
            <div>
                <h1 className="text-lg">{driver.id ? 'Edit' : 'New'} Driver</h1>
            </div>
            <Formik
                initialValues={{ ...driver }}
                onSubmit={handleFormSubmit}
                validationSchema={validation}
                validateOnBlur
            >
                {({ errors, isValid, setFieldValue, values, touched }) => {
                    const allErrors = { ...errors, ...apiErrors };
                    function handleNewPinClick() {
                        const newPin = String(random(0, 9999)).padStart(4, '0');
                        setFieldValue('pin', newPin);
                        setShowPin(true);
                    }



                    return (
                        <Form autoComplete="false">
                            <div className="grid grid-flow-row grid-cols-2 gap-4 mt-2 mb-4">
                                <div className="p-4 bg-white shadow">
                                    <div>
                                        <h3 className="mb-4 text-sm text-gray-400">Contact Info</h3>
                                    </div>
                                    <div>
                                        <label htmlFor="id" className="form-label">Driver ID:</label>
                                        <input type="text" name="id" id="id" disabled={permissionsLevel === Permission.Read}  className="input-control" readOnly placeholder="####" value={values.id} />
                                        {!values.id ? (
                                            <p className="mb-2 text-sm text-gray-400">This will be automatically assigned</p>
                                        ) : null}
                                        <FormError name="id" errors={allErrors} />
                                    </div>
                                    <div className="flex">
                                        <div className="mr-2">
                                            <label htmlFor="firstName" className="form-label">Name:</label>
                                            <Field type="text" name="firstName" id="firstName" disabled={permissionsLevel === Permission.Read}  className="input-control" placeholder="First name" />
                                            <FormError name="firstName" errors={allErrors} />
                                        </div>
                                        <div>
                                            <label htmlFor="lastName" className="form-label">&nbsp;</label>
                                            <Field type="text" name="lastName" id="lastName" disabled={permissionsLevel === Permission.Read}  className="input-control" placeholder="Last name" />
                                            <FormError name="lastName" errors={allErrors} />
                                        </div>
                                    </div>
                                    <div>
                                        <label htmlFor="email" className="form-label">Email Address:</label>
                                        <Field type="email" name="email" id="email" disabled={permissionsLevel === Permission.Read}  className="input-control" />
                                        <FormError name="email" errors={allErrors} />
                                    </div>
                                    <div>
                                        <label htmlFor="mobile" className="form-label">Mobile Number:</label>
                                        <Field type="text" name="mobile" id="mobile" disabled={permissionsLevel === Permission.Read}  className="input-control" />
                                        <FormError name="mobile" errors={allErrors} />
                                    </div>
                                    <div>
                                        <h3 className="mb-4 text-sm text-gray-400">Security</h3>
                                    </div>
                                    <div>
                                        <label htmlFor="pin" className="form-label">Pin Number:</label>
                                        <div className="flex items-center">
                                            {showPin ? (
                                                <Field type="text" name="pin" id="pin" className="mb-0 input-control" disabled={permissionsLevel === Permission.Read}/>
                                            ) : (
                                                <Field type="password" name="pin" id="pin" className="mb-0 input-control" disabled={permissionsLevel === Permission.Read}/>
                                            )}
                                            <button className="h-8 p-1 ml-2 text-sm text-white bg-gray-500 button" type="button" onClick={handleNewPinClick}>Generate New Pin</button>
                                        </div>
                                        <FormError name="pin" errors={allErrors} />
                                        {values.pin ? (
                                            <label className="block ml-2 align-middle">
                                                <input disabled={permissionsLevel === Permission.Read} type="checkbox" checked={showPin} onChange={(e) => { setShowPin(e.target.checked) }} className="mr-2" />
                                                Show Pin
                                            </label>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="p-4 bg-white shadow">
                                    <div>
                                        <h3 className="mb-4 text-sm text-gray-400">Address</h3>
                                    </div>
                                    <div>
                                        <label htmlFor="addressLine1" className="form-label">Address Line 1:</label>
                                        <Field type="text" name="addressLine1" id="addressLine1" disabled={permissionsLevel === Permission.Read}  className="input-control" />
                                        <FormError name="addressLine1" errors={allErrors} />
                                    </div>
                                    <div>
                                        <label htmlFor="addressLine2" className="form-label">Address Line 2:</label>
                                        <Field type="text" name="addressLine2" id="addressLine2" disabled={permissionsLevel === Permission.Read}  className="input-control" />
                                        <FormError name="addressLine2" errors={allErrors} />
                                    </div>
                                    <div>
                                        <label htmlFor="addressLine3" className="form-label">Address Line 3:</label>
                                        <Field type="text" name="addressLine3" id="addressLine3" disabled={permissionsLevel === Permission.Read}  className="input-control" />
                                        <FormError name="addressLine3" errors={allErrors} />
                                    </div>
                                    <div>
                                        <label htmlFor="town" className="form-label">Town:</label>
                                        <Field type="text" name="town" id="town" disabled={permissionsLevel === Permission.Read}  className="input-control" />
                                        <FormError name="town" errors={allErrors} />
                                    </div>
                                    <div>
                                        <label htmlFor="county" className="form-label">County:</label>
                                        <Field type="text" name="county" id="county" disabled={permissionsLevel === Permission.Read}  className="input-control" />
                                        <FormError name="county" errors={allErrors} />
                                    </div>
                                    <div>
                                        <label htmlFor="postcode" className="form-label">Postcode:</label>
                                        <Field disabled={permissionsLevel === Permission.Read} type="text" name="postcode" id="postcode" className="uppercase input-control" />
                                        <FormError name="postcode" errors={allErrors} />
                                    </div>
                                </div>
                            </div>
                            <div className="grid grid-cols-2 gap-4 grid-layout-cols">
                                <div className="p-4 mb-4 bg-white shadow">
                                    <div>
                                        <h3 className="mb-4 text-sm text-gray-400">Additional Info</h3>
                                    </div>
                                    <div>
                                        <label htmlFor="notes" className="form-label">Notes:</label>
                                        <Field disabled={permissionsLevel === Permission.Read} as="textarea" name="notes" id="notes" className="block w-full align-top input-control h-28" placeholder="Write notes here..." />
                                        <FormError name="notes" errors={allErrors} />
                                    </div>
                                    <div>
                                        <label htmlFor="drivingLicenceNumber" className="form-label">Driving Licence Number:</label>
                                        <Field disabled={permissionsLevel === Permission.Read} name="drivingLicenceNumber" id="drivingLicenceNumber" className="w-full input-control" />
                                        <FormError name="drivingLicenceNumber" errors={allErrors} />
                                    </div>
                                    <div>
                                        <label htmlFor="nationalInsuranceNumber" className="form-label">National Insurance Number:</label>
                                        <Field disabled={permissionsLevel === Permission.Read} name="nationalInsuranceNumber" id="nationalInsuranceNumber" className="w-full input-control" />
                                        <FormError name="nationalInsuranceNumber" errors={allErrors} />
                                    </div>
                                    <div>
                                        <label htmlFor="trainingDetails" className="form-label">Training Details:</label>
                                        <Field disabled={permissionsLevel === Permission.Read} as="textarea" name="trainingDetails" id="trainingDetails" className="block w-full align-top input-control h-28" placeholder="Write training details here..." />
                                        <FormError name="trainingDetails" errors={allErrors} />
                                    </div>
                                </div>
                                <div className="p-4 mb-4 bg-white shadow">
                                    <div>
                                        <h3 className="mb-4 text-sm text-gray-400">Routes</h3>
                                        <div>
                                            {
                                                driver.routes.map(route => {
                                                    return (
                                                        <div className="flex items-center p-2 mb-1 border-2 rounded" key={'route_' + route.id}>
                                                        <span>{route.routeName}</span>
                                                        <div className="ml-auto">
                                                            <label className="mr-2 text-sm text-gray-500">Expires at:</label>
                                                            <input disabled={permissionsLevel === Permission.Read} type="datetime-local" value={getFormikDateTimeFromString(route.expiresAt ?? null)} className="mb-0 input-control" onChange={(e) => { updateDriverRouteExpiresAt(route, e.target.value) }} />
                                                        </div>
                                                            {permissionsLevel >= Permission.Edit && <div>
                                                                <button className={"text-red-500 m-2"} onClick={() => {
                                                                    if (route.id) {
                                                                        removeRoute(route.id)
                                                                    }}}> Remove</button>
                                                            </div>}
                                                    </div>)
                                                })
                                            }
                                        </div>
                                        <div>
                                            <button className="bg-yellow-300 button" onClick={(event) => {
                                                event.preventDefault();
                                                setAddRoute(true);
                                            }}>Add Route</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {successMessage ? (<Alert message={successMessage} type="success" />) : null}
                            {errorMessage ? (<Alert message={errorMessage} type="error" />) : null}
                            <div className="p-4 mt-4 bg-white shadow">
                                <div className="flex">
                                    <Link to="/drivers" className="button">
                                        <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                                        Back to Drivers
                                    </Link>
                                    {((permissionsLevel >= Permission.Edit && driver.id) || permissionsLevel >= Permission.Create) && <button className="ml-auto mr-2 bg-yellow-300 button" type="submit"
                                             disabled={saving || !isValid || !touched}>{saving ? 'Saving driver...' : 'Save Driver'}</button>}
                                    {(driver.id && permissionsLevel >= Permission.Delete) ? (
                                        <button className="text-red-400 button" disabled={saving} onClick={handleDeleteClick}>Delete Driver</button>
                                    ) : null}
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
            {showDelete ? (
                <ConfirmModal title="Delete driver" onClose={() => { setShowDelete(false) }} onConfirm={handleConfirmDelete} disabled={deleting}>
                    Are you sure you want to delete this driver?
                </ConfirmModal>
            ) : null}
        </div>
    );
}

export default Edit;