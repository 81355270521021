import Modal from "../modal/Modal";
import RouteMap, {RouteMapMarker, RouteMapWaypoint} from "../routes/RouteMap";
import React, {useEffect, useState} from "react";
import {Route} from "../routes/types";
import {fetchRoute} from "../routes/service";
import {convertRouteStops} from "../routes/helpers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSyncAlt} from "@fortawesome/free-solid-svg-icons";
import {ApiMarkers, RouteSession} from "./types";
import {fetchMarkers} from "./service";
import {convertMarkers} from "./helpers";


interface LiveRouteModalProps {
    closeModal:CallableFunction,
    routeSession: RouteSession
}

export const LiveRouteModal = (props:LiveRouteModalProps) => {
    const [route, setRoute] = useState<Route>()
    const [loading, setLoading] = useState(false)
    const [waypoints, setWaypoints] = useState<RouteMapWaypoint[]>([])
    const [markers, setMarkers] = useState<RouteMapMarker[]>()
    const [apiMarkers, setApiMarkers] = useState<ApiMarkers>([])
    const live = !props.routeSession.endDate


    const refreshMarker = () => {
        fetchMarkers(props.routeSession.id).then((res) => {
            const data = res.data as ApiMarkers
            let newMarkers = convertMarkers(data)
            setMarkers(newMarkers)
        }).catch((err) => {
            console.error(err)
        })
    }
    const handleGetRoute = () => {
        fetchRoute(props.routeSession.route.id).then(async function (res){
            await fetchMarkers(props.routeSession.id).then((res) => {
                const data = res.data as ApiMarkers
                setApiMarkers(data)
            }).catch((err) => {
                throw err
            })
            const data = res.data as Route
            setRoute(data)
        }).catch((err) => {
            console.error(err)
        }).finally(() => {
            setLoading(false)
        })
    }
    useEffect(() => {
        setLoading(true)
        handleGetRoute();
    }, [])

    useEffect(() =>{
        if (route) {
            setWaypoints(convertRouteStops(route))
            if(apiMarkers){
                setMarkers(convertMarkers(apiMarkers))
            }
        }
    }, [route])

    useEffect(() => {
        if(live) {
            const interval = setInterval(() => {
                refreshMarker()
            }, 10000);
            return () => clearInterval(interval);
        }
    }, []);

    const liveTitle = live ? 'Live Route' : 'Bus Route'

    return (<>
            <Modal title={liveTitle} closeAction={props.closeModal}>
                {loading && <div className="text-center"><FontAwesomeIcon icon={faSyncAlt} className={'ml-2 text-sm fa-spin'}/></div> }
                {waypoints && route && <RouteMap waypoints={waypoints} markers={markers}/>}
            </Modal>
        </>
    )
}