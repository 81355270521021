import Modal from "../modal/Modal";
import {addDiscountGroup} from "./service";
import {useAppDispatch} from "../../app/hooks";
import {setErrorNotificationText, setSuccessNotificationText} from "../notification/slice";
import { Formik, Field, Form } from 'formik';
import FormError from "../form/FormError";
import {DiscountGroup} from "./types";
import {useState} from "react";
import * as Yup from "yup";


interface addGroupProps {
    onClose: CallableFunction
}
export const AddGroup = (props: addGroupProps) => {
    const [saving, setSaving] = useState(false)
    const dispatch = useAppDispatch();

    const handleAddDiscountGroup = (discountGroup:DiscountGroup) => {
        setSaving(true)
        addDiscountGroup(discountGroup).then(() => {
            dispatch(setSuccessNotificationText('Discount group added successfully'))
        }).catch(() => {
            dispatch(setErrorNotificationText('Something went wrong: Discount group not added'))
        }).finally(() => {
            setSaving(false)
            props.onClose();
        })
    }
    const validation = Yup.object().shape({
        name: Yup.string().label('Name').required().max(255)
    })

    return (
        <Modal title={"Add Discount Group"} closeAction={() => {props.onClose()} }>
            <Formik
                initialValues={{name:''}}
                validationSchema={validation}
                onSubmit={(values) =>
                    handleAddDiscountGroup(values)
                }
            >
                {({errors}) => {
                    return (
                    <Form>
                        <div className="m-4">
                                <div className="mb-2">
                                    <label htmlFor="name" className="form-label">Group Name:</label>
                                    <Field className="input-control" id="name" name="name"
                                           placeholder="Featured"/>
                                    <FormError name="name" errors={errors}/>
                                </div>
                                <button type="submit" disabled={saving} className="bg-yellow-300 button">{saving? '...saving' : 'Add Group'}</button>
                            </div>
                    </Form>
                    )
                }}
            </Formik>
        </Modal>
    )
}