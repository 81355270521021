import Modal from "../modal/Modal";
import {Driver} from "./types";
import {Form, Formik} from "formik";
import React, {useEffect, useState} from "react";
import RouteAutoComplete, {RouteAutoCompleteChangeEvent} from "../routes/RouteAutocomplete";
import {DropDownOption} from "../dropdown/types";
import Alert from "../alerts/Alert";

interface AddRouteCloseProps {
    driver: Driver
    setDriver: CallableFunction
    closeAction: CallableFunction
}

export const AddRouteModal = (props: AddRouteCloseProps) => {
    const [route, setRoute] = useState<DropDownOption>()
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(() => {
        setTimeout(() => {
            setErrorMessage('')
        }, 5000)
    }, [errorMessage])

    const handleAddRoute = () => {
        let newDriver = {...props.driver}
        if (route && props.driver.id) {
            const driverRouteIds = props.driver.routes.map(route => {
                return route.routeId
            });
            if (driverRouteIds.includes(route.id)) {
                setErrorMessage('Driver Already on Route')
                return;
            }
            newDriver.routes = [...newDriver.routes, {routeId: route.id, driverId: props.driver.id, firstName: props.driver.firstName, lastName: props.driver.lastName, routeName: route.value}]
        }
        props.setDriver(newDriver)
        props.closeAction();
    }

    const errorMessageHTML = errorMessage ? <Alert message={errorMessage} type="error"/> : null

    return (
        <Modal title={"Add Driver Route"} closeAction={props.closeAction}>
            <Formik initialValues={{route: undefined}}
                    onSubmit={() => {
                        handleAddRoute()
                    }}
            >
                {() => {
                    const handleRouteChanged = (event: RouteAutoCompleteChangeEvent) => {
                        if (event.route) {
                            setRoute(event.route)
                        } else {
                            setRoute(undefined);
                        }
                    }

                    return (
                        <Form >
                            <div className={"h-48"}>
                                <label htmlFor="contactUserId" className="form-label">Route:</label>
                                <RouteAutoComplete value={route ? route.value : ''} onChange={handleRouteChanged} />
                            </div>
                            {errorMessageHTML}
                            <div className="flex">
                                <button className="ml-auto bg-yellow-300 button" disabled={!route}>Add Route</button>
                                <button className="ml-4 bg-gray-300 button" onClick={() => props.closeAction()} type="button"> Cancel </button>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </Modal>
    )
}