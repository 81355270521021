import React, { useContext, useEffect, useState } from "react";
import { SessionPassengers } from "../sessions/types";
import { getPassengerCsvReport, getSessionPassengers } from "../sessions/service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { DateRange } from "../dates/DateRangePicker";
import { getFormattedDateTimeFromString } from "../dates/helper";
import Pagination from "../pagination/Pagination";
import { JourneyDateRangeFilter } from "./JourneyDateRangeFilter";
import Alert from "../alerts/Alert";
import { ReportsContext } from "./Context";


export const PassengerReport = () => {

    const todayStart = new Date();
    todayStart.setHours(0, 0, 0, 0);

    const todayEnd = new Date();
    todayEnd.setHours(23, 59, 59, 999);
    const [dateRange, setDateRange] = useState<DateRange>({
        startDate: todayStart,
        endDate: todayEnd,
    });

    const [loading, setLoading] = useState(false)
    const [exporting, setExporting] = useState(false)
    const [sessionPassengers, setSessionPassengers] = useState<SessionPassengers>()
    const FileDownload = require('js-file-download');
    const [errorMessage, setErrorMessage] = useState('');

    const errorMessageHTML = errorMessage ? <Alert message={errorMessage} type="error" /> : null

    // journey
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(1);
    const { selectedRoute, selectedJourney } = useContext(ReportsContext);
    const journeyId = `${selectedJourney?.id}`;
    const routeId = `${selectedRoute?.id}`;

    const handleGetSessionPassengerReport = () => {
        setErrorMessage('')
        setLoading(true)
        setSessionPassengers([]);
        getSessionPassengers({
            page: page,
            journeyId,
            routeId,
            startDate: dateRange.startDate.toISOString(),
            endDate: dateRange.endDate.toISOString()
        }).then((res) => {
            const data = res.data
            setSessionPassengers(data.items)
            setPages(data.lastPage);
        }).catch(() => {
            setErrorMessage('Error getting report')
        }).finally(() => {
            setLoading(false)
        })
    }


    const downloadCsv = () => {
        setExporting(true)
        setErrorMessage('')
        getPassengerCsvReport({
            journeyId,
            routeId,
            startDate: dateRange.startDate.toISOString(),
            endDate: dateRange.endDate.toISOString()
        }).then((response) => {
            FileDownload(response.data, 'route_report.csv');
        }).catch(() => {
            setErrorMessage('Error downloading csv')
        }).finally(() => {
            setExporting(false)
        })
    }

    useEffect(() => {
        if (sessionPassengers !== undefined) {
            handleGetSessionPassengerReport()
        }
    }, [page])

    return (
        <div>
            <div className="p-4 mb-3 bg-white shadow">
                <h3 className={"mb-2 text-center  "}><span
                    className={"font-bold"}>Note: </span>
                    <span className={"font-semibold"}>Report data available only for passengers that have previously travelled on a route.</span>
                </h3>
            </div>
            <JourneyDateRangeFilter onChange={() => {
                setErrorMessage('')
                setSessionPassengers(undefined)
            }}
                dateRange={dateRange}
                setDateRange={setDateRange}
                loading={loading}
                onSubmit={handleGetSessionPassengerReport}
            >
                <button type="button" onClick={() => { downloadCsv() }} className="bg-gray-300 mr-2 button"
                    disabled={(loading || (!sessionPassengers || sessionPassengers.length === 0) || exporting)}>
                    <span><FontAwesomeIcon icon={faFileDownload} className="mr-1" />{exporting ? '...Exporting' : 'Export to Csv'}</span>
                </button>
            </JourneyDateRangeFilter>
            <div className="text-center">
                {errorMessageHTML}
            </div>
            {sessionPassengers !== undefined && sessionPassengers.length === 0 &&
                <div className="font-semibold text-center w-full text-gray-400"> {loading ?
                    <span><FontAwesomeIcon icon={faSyncAlt} className={'ml-2 text-sm fa-spin'} />...Loading</span> :
                    <div className="p-4 m-3 bg-grey-50 shadow">No passengers</div>}
                </div>
            }

            {sessionPassengers !== undefined && sessionPassengers.length !== 0 &&
                <div className="p-4 m-3 bg-white shadow">
                    <h3 className={"mb-2 font-semibold"}>
                        Passenger Report
                    </h3>
                    <table className="table w-full table-striped">
                        <thead>
                            <tr>
                                <th className="text-left">Route</th>
                                <th className="text-left">Passenger Name</th>
                                <th className="text-left">Guardians</th>
                                <th className="text-left">Checked In Time</th>
                                <th className="text-left">Checked In Stop</th>
                                <th className="text-left">Checked Out Time</th>
                                <th className="text-left">Checked Out Stop</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sessionPassengers.map((sessionPassenger) => {
                                return (
                                    <tr key={sessionPassenger.id}>
                                        <td>{sessionPassenger.session?.journey?.routeName}</td>
                                        <td>{sessionPassenger.passenger.firstName + ' ' + sessionPassenger.passenger.lastName}</td>
                                        <td>{sessionPassenger.passenger.carers.map((carer, index) => {
                                            let end = (index + 1) !== sessionPassenger.passenger.carers.length ? ', ' : ''
                                            return carer.firstName + ' ' + carer.lastName + end
                                        })}</td>
                                        <td>{sessionPassenger.checkedIn ? getFormattedDateTimeFromString(sessionPassenger.checkedIn) : ''}</td>
                                        <td>{sessionPassenger.checkedInStop?.name}</td>
                                        <td>{sessionPassenger.checkedOut ? getFormattedDateTimeFromString(sessionPassenger.checkedOut) : ''}</td>
                                        <td>{sessionPassenger.checkedOutStop?.name}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <div className="text-center m-2">
                        <Pagination page={page} onChange={setPage} pages={pages} />
                    </div>
                </div>}
        </div>)
}