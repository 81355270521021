import { ChangeEvent, useEffect, useState } from "react";
import API from "../api/axios";
import { debounce } from "lodash";
import { useCallback } from "react";

export interface RouteAutoCompleteProps {
    value: string,
    onChange: (event: RouteAutoCompleteChangeEvent) => any,
    placeholder?: string,
}

export type RouteAutoCompleteChangeEvent = {
    route?: RouteResult,
}

export interface RouteResult {
    id: number,
    value: string,
}

export const RouteAutoComplete = (props: RouteAutoCompleteProps) => {
    const [search, setSearch] = useState('');
    const [results, setResults] = useState<RouteResult[]>([]);
    const [loading, setLoading] = useState(false);
    const [showResults, setShowResults] = useState(false);
    const [moreResults, setMoreResults] = useState(false);

    useEffect(() => {
        setSearch(props.value);
        fetchResults(props.value);
    }, [props.value]);

    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.value) {
            setSearch('');
            setResults([]);
            setShowResults(false);
            props.onChange({});
            return;
        }
        setSearch(event.target.value);
        debouncedFetchResults(event.target.value);
        setShowResults(true);

        props.onChange({});
    }

    const fetchResults = (searchText: string) => {
        setResults([]);
        setMoreResults(false);
        if (!searchText || searchText.length < 3) {
            return
        }
        setLoading(true);
        const params = { search: searchText };
        API.get('/routes/dropdown', { params }).then(({ data }) => {
            setResults(data.items);
            setLoading(false);
            if (data.lastPage > 1) {
                setMoreResults(true);
            }
        }).catch(err => {
            console.error(err);
            setLoading(false);
            setShowResults(false);
        })
    }

    const debouncedFetchResults = useCallback(debounce((searchText) => fetchResults(searchText), 300), []);

    const handleOnFocus = () => {
        setShowResults(true);
    }

    const selectRoute = (route: RouteResult) => {
        props.onChange({ route });
        setShowResults(false);
    }

    return (
        <div className="relative">
            <input type="text" value={search} onChange={handleOnChange} onFocus={handleOnFocus} className="mb-0 input-control" placeholder={props.placeholder}/>
            {showResults ? (
                <>
                    <div className="fixed top-0 left-0 w-screen h-screen" onClick={() => { setShowResults(false) }}> </div>
                    <div className="absolute max-w-full overflow-y-auto bg-white border shadow min-w-fit w-80 top-10 z-50" style={{ maxHeight: '20rem' }}>
                        {results.map((route, index) => (
                            <button key={'autoselect_route_' + index} className="block w-full p-2 text-sm text-left cursor-pointer hover:bg-blue-200" onClick={() => { selectRoute(route) }}>
                                {route.value}
                            </button>
                        ))}
                        {!loading && search.length < 3 &&  <p className="p-2 text-sm italic text-gray-400">Please enter more than 2 characters</p>}
                        {!loading && results.length === 0 && search.length > 2 ? (
                            <p className="p-2 text-sm italic text-gray-400">No results</p>
                        ) : null}
                        {moreResults ? (
                            <p className="p-2 text-sm italic text-gray-400">Enter search for more results...</p>
                        ) : null}
                        {loading ? (
                            <p className="p-2 text-sm italic text-gray-400">Loading...</p>
                        ) : null}
                    </div>
                </>
            ) : null}
        </div>
    );
}

export default RouteAutoComplete;