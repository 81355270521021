import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {useEffect, useState} from "react";
import {selectEditVehicle, setEditVehicle} from "./slice";
import { Vehicle } from "./types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import {fetchVehicles} from './service';
import {selectUserPolicies} from "../auth/slice";
import {getPermissionsLevel, Permission} from "../policies/helpers";

export function Index () {
    const policies = useAppSelector(selectUserPolicies)
    const permissionsLevel = getPermissionsLevel('vehicle', policies)

    const [search, setSearch] = useState('');
    const [type, setType] = useState('');
    const dispatch = useAppDispatch();
    const editVehicle = useAppSelector(selectEditVehicle);
    const [vehicles, setVehicles] = useState<Array<Vehicle>>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const searchVehicles = () => {
        if (loading) {
            return;
        }
        setLoading(true);
        const params = {
            search,
            type,
        };
        fetchVehicles(params).then(({data}) => {
            setVehicles(data);
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        })
    }
    const onVehicleClick = (vehicle: Vehicle) => {
        dispatch(setEditVehicle(vehicle))
    }
    const newVehicle = () => {
        dispatch(setEditVehicle({
            type:'',
            registrationNumber:'',
            ownedBy:'Organisation',
            isActive: true
        }));
    }
    useEffect(() => {
        if(!editVehicle) {
            searchVehicles();
        }
    }, [editVehicle]);

    return (

        <div>
           <div className="mb-4">
               <h1 className="text-lg">Vehicles</h1>
           </div>
            <div className="flex px-4 py-2 mb-2 bg-white shadow">
                <form action="" onSubmit={(event) => {event.preventDefault(); searchVehicles();}} className="flex mr-auto">
                    <div>
                        <input type="search" value={search} onChange={(event => setSearch(event.target.value))} placeholder="Search..." className="p-2 mr-2 border-2 border-gray-100 rounded"/>
                    </div>
                    <div>
                        <input type="search" value={type} onChange={(event => setType(event.target.value))} placeholder="Type" className="p-2 mr-2 border-2 border-gray-100 rounded"/>
                    </div>
                    <div>
                        <button className="bg-yellow-300 button" disabled={loading}>Search</button>
                    </div>
                </form>
                <div className="ml-auto">
                    <button className="mr-2 text-white bg-gray-600 button" type="button" onClick={(event) => {event.preventDefault();  searchVehicles();}} disabled={loading}>
                        <FontAwesomeIcon icon={faSyncAlt} className={'mr-1 text-sm ' + (loading ? 'fa-spin' : '')}/>
                        {loading? 'Loading...' : 'Refresh'}
                    </button>
                    {permissionsLevel >= Permission.Create &&
                    <button className="text-white bg-gray-600 button" type="button" onClick={(event) => {
                        event.preventDefault();
                        newVehicle()
                    }}>&#43; New Vehicle</button>
                    }
                </div>
            </div>
            <div className="p-4 bg-white shadow">
                <table className="table w-full table-striped">
                    <thead>
                    <tr>
                        <th className="text-left">Reg.</th>
                        <th>Type</th>
                        <th>Capacity</th>
                        <th>Active</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        vehicles.map((vehicle) => {
                            return (
                            <tr key={vehicle.id} onClick={(event) => {event.preventDefault(); onVehicleClick(vehicle)}} className="cursor-pointer">
                                    <td className="p-1">{vehicle.registrationNumber}</td>
                                    <td className="p-1 text-center">{vehicle.type}</td>
                                    <td className="p-1 text-center">{vehicle.capacity}</td>
                                    <td className="p-1 text-center">{vehicle.isActive ? 'Y' : 'N'}</td>
                                </tr>
                            );
                        })
                    }
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Index;