import React, { useState } from "react";
import { AppNotification, AppNotificationUser } from "./types";
import { Formik, Form, Field } from "formik";
import * as Yup from 'yup';
import FormError from "../form/FormError";
import Alert from "../alerts/Alert";
import { deleteAppNotification, fetchAppNotification, saveAppNotification, sendAppNotification } from './service';
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useHistory, useParams } from "react-router-dom";
import { ParamsWithId } from "../routing/types";
import { useEffect } from "react";
import ConfirmModal from "../modal/ConfirmModal";
import { MouseEvent } from 'react';
import { getFormikDateTimeFromString, formatDateTime } from "../dates/helper";
import Intro from "./Intro";
import { User } from '../users/types';
import { fetchUsers } from '../users/service';
import {useAppSelector} from "../../app/hooks";
import {selectUserPolicies} from "../auth/slice";
import {getPermissionsLevelForEntity, Permission} from "../policies/helpers";

const initialAppNotification = {
    title: '',
    url: '',
    body: '',
    sendAt: '',
    sentAt: '',
    appNotificationUsers: []
} as AppNotification;

export function Edit() {
    const [appNotification, setAppNotification] = useState<AppNotification>(initialAppNotification);
    const policies = useAppSelector(selectUserPolicies)
    const permissionsLevel = getPermissionsLevelForEntity('appNotification', appNotification, policies)
    const [apiErrors, setApiErrors] = useState({});
    const [saving, setSaving] = useState(false);
    const [sending, setSending] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const history = useHistory();
    const { id } = useParams<ParamsWithId>();
    const [showDelete, setShowDelete] = useState(false);
    const [deleting, setDeleteing] = useState(false);
    const [users, setUsers] = useState<User[]>([])
    const [usersLoading, setUsersLoading] = useState(false);
    const [notificationDirty, setNotificationDirty] = useState(false);

    useEffect(() => {
        setUsersLoading(true);
        fetchUsers({ notifiable: true }).then(({ data }) => {
            setUsers(data);
        }).catch((err) => {
            console.error(err);
            setError('Failed to load users list');
        }).finally(() => {
            setUsersLoading(false);
        })
    }, []);

    useEffect(() => {
        if (!id) {
            setAppNotification(initialAppNotification)
            return;
        }
        setLoading(true);
        fetchAppNotification(id).then(({ data }) => {
            setAppNotificationFromResponse(data);
        }).catch((err: any) => {
            setAppNotification(initialAppNotification);
            history.goBack();
            console.error(err);
        }).finally(() => {
            setLoading(false);
        })
    }, [id]);

    function handleSubmit(values: AppNotification) {
        setError('');
        setApiErrors({});
        setMessage('');
        setSaving(true);
        if (!appNotification.appNotificationUsers) {
            setSaving(false)
            setError('No recipients for notification')
            return
        }
        const appNotificationUsers = appNotification.appNotificationUsers.map((appNotificationUser: AppNotificationUser) => {
            appNotificationUser.sentAt = appNotificationUser.sentAt || null;
            return appNotificationUser;
        });
        const requestData = { ...values, appNotificationUsers } as AppNotification;
        saveAppNotification(requestData).then(({ data }) => {
            if (!appNotification.id) {
                history.push('/appNotifications/' + data.id + '/edit');
            }
            setAppNotificationFromResponse(data);
            setSaving(false);
            setMessage('App Notification saved successfully');
            setNotificationDirty(false);
        }).catch((err: any) => {
            setSaving(false);
            setError('Failed to save app notification, please check for errors');
            if (err.response && err.response.data) {
                setApiErrors(err.response.data);
                return;
            }
            console.error(err);
        })
    }

    function setAppNotificationFromResponse(data: any) {
        const appNotification = { ...data };
        appNotification.sendAt = appNotification.sendAt ? getFormikDateTimeFromString(appNotification.sendAt) : '';
        appNotification.sentAt = appNotification.sentAt ? getFormikDateTimeFromString(appNotification.sentAt) : '';
        setAppNotification(appNotification);
    }

    function handleDeleteClick(event: MouseEvent) {
        event.preventDefault();
        setShowDelete(true);
    }

    function handleDeleteConfirmed() {
        setDeleteing(true);
        deleteAppNotification(appNotification.id).then(() => {
            setShowDelete(false);
            setDeleteing(false);
            setMessage('App notification deleted successfully, redirecting...');
            window.setTimeout(() => {
                history.replace('/appNotifications');
            }, 3000);
        }).catch(() => {
            setShowDelete(false);
            setError('Failed to delete app notification, please try again');
            setDeleteing(false);
        })
    }

    function handleSendClick() {
        setSending(true);
        setMessage('');
        setError('');
        sendAppNotification(appNotification.id).then(({ data }) => {
            setAppNotificationFromResponse(data);
            setMessage('Notification sent successfully');
            setSending(false);
            window.setTimeout(() => {
                setMessage('');
            }, 5000);
        }).catch(err => {
            console.error(err);
            setError('Failed to send notification, please try again');
            if (err.response && err.response.data.message) {
                setError(err.response.data.message);
            }
            window.setTimeout(() => {
                setError('');
            }, 8000);
        }).finally(() => {
            setSending(false);
        });
    }
    function handleUserChecked(user: User, checked: boolean) {

        const appNotificationUsers = updateAppNotificationUsersList(user, checked, [...appNotification.appNotificationUsers]);

        setAppNotification({ ...appNotification, appNotificationUsers });
    }

    function updateAppNotificationUsersList(user: User, checked: boolean, appNotificationUsers: AppNotificationUser[]): AppNotificationUser[] {
        const matchedIndex = appNotificationUsers.findIndex((notificationUser: AppNotificationUser) => {
            return notificationUser.recipient && notificationUser.recipient.id === user.id;
        });
        if (matchedIndex !== -1 && !checked) {
            appNotificationUsers.splice(matchedIndex, 1);
        }
        if (matchedIndex === -1 && checked) {
            appNotificationUsers.push({
                recipient: user,
                sentAt: '',
            } as AppNotificationUser);
        }
        setNotificationDirty(true);

        return appNotificationUsers;
    }

    function handleSelectAllUsers(checked: boolean) {
        let appNotificationUsers = [...appNotification.appNotificationUsers];
        users.forEach((user: User) => {
            appNotificationUsers = updateAppNotificationUsersList(user, checked, appNotificationUsers);
        });

        setAppNotification({ ...appNotification, appNotificationUsers });
    }

    const validation = Yup.object().shape({
        title: Yup.string().label('Title').required().max(255),
        body: Yup.string().label('Body').required().max(1000),
    });

    if (loading) {
        return (
            <div>
                <span>Loading app notification...</span>
            </div>
        )
    }

    return (
        <div>
            <div>
                <h1 className="text-lg">{appNotification.id ? 'Edit' : 'New'} App Notification</h1>
                <Intro />
            </div>
            <Formik
                initialValues={{ ...appNotification }}
                onSubmit={handleSubmit}
                validationSchema={validation}
                validateOnBlur
            >
                {({ errors, isValid, dirty }) => {
                    const allErrors = { ...errors, ...apiErrors };
                    return (
                        <Form autoComplete="false">
                            <div className="p-4 my-2 bg-white rounded shadow">
                                <div>
                                    <label htmlFor="title" className="form-label">Title:</label>
                                    <Field disabled={permissionsLevel === Permission.Read}  className="w-full input-control" name="title" id="title" placeholder="Title" />
                                    <FormError name="title" errors={allErrors} />
                                </div>
                                <div>
                                    <label htmlFor="body" className="form-label">Message Body:</label>
                                    <Field disabled={permissionsLevel === Permission.Read}  className="w-full input-control" rows="3" name="body" id="body" placeholder="Type the notification message here..." as="textarea" />
                                    <FormError name="body" errors={allErrors} />
                                </div>
                                <div>
                                    <label htmlFor="startDate" className="form-label">Send At:</label>
                                    <p className="mb-2 text-sm text-gray-500">Schedule this notification to automatically send at a pre-determined time</p>
                                    <Field disabled={permissionsLevel === Permission.Read}  className="input-control" name="sendAt" id="sendAt" type="datetime-local" />
                                    <FormError name="sendAt" errors={allErrors} />
                                </div>
                            </div>

                            <div className="p-4 my-2 bg-white rounded shadow">
                                <h2 className="mb-4 font-semibold text-md">Recipients</h2>
                                {usersLoading ? (
                                    <p className="text-sm text-gray-500">Loading users...</p>
                                ) : null}

                                {users.length ? (
                                    <>
                                        <div className="flex p-2">
                                            <label>
                                                <input disabled={permissionsLevel === Permission.Read} type="checkbox" className="mr-2" onChange={(e) => { handleSelectAllUsers(e.target.checked) }} />
                                                <span>Select All</span>
                                            </label>
                                        </div>
                                        <div>
                                            {users.map((user: User) => {
                                                const matchedUser = appNotification.appNotificationUsers.find((notificationUser: AppNotificationUser) => {
                                                        return notificationUser.recipient && notificationUser.recipient.id === user.id;
                                                });
                                                const checked = matchedUser !== undefined;
                                                return (
                                                    <div className="flex p-2 mb-1 border-2 rounded">
                                                        <div className="mr-2">
                                                            <input disabled={permissionsLevel === Permission.Read} type="checkbox" checked={checked} onChange={(e) => { handleUserChecked(user, e.target.checked) }} />
                                                        </div>
                                                        <span>{user.firstName} {user.lastName} - {user.email} ({user.type})</span>
                                                        <span className="ml-auto mr-2 text-gray-500 text-semibold">Sent at:</span>
                                                        <span>{matchedUser?.sentAt ? formatDateTime(matchedUser.sentAt) : '-'}</span>
                                                    </div>
                                                )
                                            }
                                            )}
                                        </div>
                                    </>
                                ) : null}
                                {!users.length && !loading ? (
                                    <p className="text-sm text-gray-500">Sorry, there are currently no notifiable users</p>
                                ) : null}
                            </div>

                            <Alert message={message} type="success" />
                            <Alert message={error} type="error" />
                            <div className="p-4 my-2 bg-white rounded shadow">
                                <div className="flex">
                                    <Link to="/appNotifications" className="button">
                                        <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                                        Back to App Notifications
                                    </Link>
                                    {permissionsLevel >= Permission.Edit && <><button className="ml-auto mr-2 bg-yellow-300 button" type="submit"
                                             disabled={deleting || saving || sending || !isValid}>{saving ? 'Saving...' : 'Save App Notification'}</button>
                                        <button className="mr-2 text-white bg-gray-700 button" type="button" onClick={handleSendClick} disabled={deleting || saving || sending || !isValid || notificationDirty || dirty || !appNotification.appNotificationUsers || !appNotification.appNotificationUsers.length}>{sending ? 'Sending...' : 'Send App Notification'}</button></>}
                                    {appNotification.id && permissionsLevel === Permission.Delete ? (
                                        <button className="text-red-400 button" type="button" disabled={(saving || appNotification.sentAt !== null)} onClick={handleDeleteClick}>Delete App Notification</button>
                                    ) : null}
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
            {showDelete ? (
                <ConfirmModal title="Delete App Notification" onClose={() => setShowDelete(false)} onConfirm={handleDeleteConfirmed} disabled={deleting || saving}>
                    Are you sure you want to delete this app notification?
                </ConfirmModal>
            ) : null}
        </div>
    );
}