import { useEffect } from 'react';
import { ChangeEvent, useState } from 'react';
import {Vehicle} from './types';
import { fetchVehicles, fetchVehicle } from './service';
import { Link } from 'react-router-dom';

export type OnChangeCallback = (vehicle?: Vehicle) => void;

export interface SelectProps {
    onChange: OnChangeCallback,
    value?: number
    disabled?: boolean
}

export function Select(props: SelectProps) {
    const [search, setSearch] = useState('');
    const [vehicles, setVehicles] = useState([]);
    const [vehicle, setVehicle] = useState<Vehicle|null>(null);
    const [loading, setLoading] = useState(false);
    const [fetching, setFetching] = useState(false);

    useEffect(() => {
        if (props.value) {
            setLoading(true)
            fetchVehicle(props.value).then(({data}) => {
                setVehicle(data);
                setSearch(data.registrationNumber)
            }).finally(() => {
                setLoading(false)
            })
        }
    }, [])

    useEffect(() => {
        if (!search) {
            setVehicle(null);
            setVehicles([]);
            return;
        }
        setFetching(true);
        fetchVehicles({search}).then(({data}) => {
            setVehicles(data);
            setFetching(false);
        })
    }, [search]);

    function handleOnSearchChange(event: ChangeEvent<HTMLInputElement>) {
        setSearch(event.target.value);
    }

    function handleOnSearchBlur(event: ChangeEvent<HTMLInputElement>) {
        window.setTimeout(() => setVehicles([]), 500)
        if (!search) {
            setVehicle(null);
            props.onChange(undefined);
            return;
        }
        if(vehicle) {
            setSearch(vehicle.registrationNumber);
        }
    }

    function handleSelectVehicle(vehicle: Vehicle) {
        setSearch(vehicle.registrationNumber);
        setVehicle(vehicle);
        setVehicles([]);
        props.onChange(vehicle);
    }

    function onlyResultSelected() {
        if (!props.value) {
            return false;
        }
        if (vehicles.length !== 1) {
            return false;
        }
        const firstVehicle = vehicles[0] as Vehicle;
        
        return props.value === firstVehicle.id;
    }

    return (
        <div className="relative inline-block w-auto">
            <input disabled={(props.disabled !== undefined && props.disabled) || loading} type="search" className="input-control" value={search} placeholder={loading ? "...Loading" : "Search vehicle..."} onChange={handleOnSearchChange} onBlur={handleOnSearchBlur}/>
            {vehicles.length && !onlyResultSelected() ? (
                <div className="absolute left-0 z-50 w-full text-sm bg-white border-2 shadow top-10 hover:bg-gray-100">
                    {vehicles.map((vehicle: Vehicle) => (
                        <button key={vehicle.id} onClick={() => handleSelectVehicle(vehicle)} className="block w-full p-2 text-left">{vehicle.registrationNumber}</button>
                    ))}
                </div>
            ) : null}
            {search !== '' && vehicles.length === 0 && !fetching && !props.value && 
                <span className='block text-sm text-red-400'>No vehicles match your search, please add this vehicle via the <Link to="/vehicles" className='underline'>Vehicles screen</Link> first</span>
            }
        </div>
    );
}

export default Select;