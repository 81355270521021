import { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { Route } from "./types";
import { fetchRoutes } from './service';
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setEditRoute } from "./slice";
import { Link } from "react-router-dom";
import Pagination from '../pagination/Pagination';
import { selectUserPolicies } from "../auth/slice";
import { getPermissionsLevel, getPermissionsLevelForEntity, Permission } from "../policies/helpers";

export function Index() {
    const policies = useAppSelector(selectUserPolicies)
    const permissionsLevel = getPermissionsLevel('route', policies)
    const [routes, setRoutes] = useState<Route[]>([]);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(1);

    function loadRoutes() {
        setLoading(true);
        setRoutes([])
        const params = {
            search,
            page,
        };
        fetchRoutes(params).then(({ data }) => {
            setRoutes(data.items);
            setPages(data.lastPage);
            setLoaded(true);
            setLoading(false);
        });
    }

    function searchRoutes() {
        if (page === 1) {
            loadRoutes();
        }
        setPage(1);
        setPages(1);
    }

    function newRoute() {
        dispatch(setEditRoute({}));
    }

    useEffect(() => {
        loadRoutes();
    }, [page]);

    return (
        <div>
            <div className="mb-4">
                <h1 className="text-lg">Routes</h1>
            </div>
            <div className="flex p-2 mb-2 bg-white shadow">
                <form action="" onSubmit={(event) => { event.preventDefault(); searchRoutes(); }} className="flex items-center mr-auto">
                    <div>
                        <input type="search" value={search} onChange={(event => setSearch(event.target.value))} placeholder="Search..." className="mb-0 mr-2 input-control" />
                    </div>
                    <div>
                        <button className="bg-yellow-300 button" disabled={loading}>Search</button>
                    </div>
                </form>
                <div className="ml-auto">
                    <button className="mr-2 text-white bg-gray-600 button" type="button" onClick={(event) => { event.preventDefault(); loadRoutes(); }} disabled={loading}>
                        <FontAwesomeIcon icon={faSyncAlt} className={'mr-1 text-sm ' + (loading ? 'fa-spin' : '')} />
                        {loading ? 'Loading...' : 'Refresh'}
                    </button>
                    {permissionsLevel >= Permission.Edit && <button className="text-white bg-gray-600 button" type="button" onClick={(event) => {
                        event.preventDefault();
                        newRoute()
                    }}>&#43; New Route</button>}
                </div>
            </div>
            <div className="p-4 bg-white shadow">
            <table className="table w-full table-striped">
                <thead>
                    <th className="text-left">Name</th>
                    <th className="text-left">Organisation</th>
                    <th>No. Stops</th>
                    <th>Capacity</th>
                    <th>&nbsp;</th>
                </thead>
                <tbody>
                    {routes.map(route => {
                        const routePerms = getPermissionsLevelForEntity('route', route, policies)
                        return (
                            <tr>
                                <td>
                                    <Link to={'/routes/' + route.id + '/view'}>{route.name}</Link>
                                </td>
                                <td>{route.organisationName}</td>
                                <td className="text-center">{route.routeStops.length}</td>
                                <td className="text-center">{route.capacity}</td>
                                <td className="text-right">
                                    <Link
                                        to={`/reports?report=route&routeId=${route.id}`}
                                        className="underline"
                                    > View Report</Link>
                                    {routePerms >= 2 && <Link
                                        to={'/routes/' + route.id + '/edit'}
                                        className="ml-4 underline">Edit</Link>}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <div className="my-2 text-center">
                <Pagination page={page} onChange={setPage} pages={pages} />
            </div>
            </div>
            {routes.length === 0 && !loading && loaded ? (
                <div className="px-2 py-4">
                    <span className="text-gray-500">No routes found, <button onClick={(event) => { event.preventDefault(); newRoute() }} className="text-gray-800 underline">start by adding one</button></span>
                </div>
            ) : null}
        </div>
    );
}