import { FormEvent, useContext, useEffect, useState } from "react";
import { DriverSession, exportDriverSessionReportCsv, exportDriversReportCsv, getDriverDropdown, getDriverSessionReport, getDriversReport } from "../drivers/service";
import { faFileDownload, faSearch, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DateRangePicker, { DateRange } from "../dates/DateRangePicker";
import Alert from "../alerts/Alert";
import { formatDateTime } from "../dates/helper";
import { Driver } from "../drivers/types";
import OrganisationSelect from "../organisations/Select";
import { Link } from "react-router-dom";
import RouteAutoComplete from "../routes/RouteAutocomplete";
import { ReportsContext } from "./Context";

export const DriverReport = () => {
    const todayStart = new Date();
    todayStart.setHours(0, 0, 0, 0);

    const todayEnd = new Date();
    todayEnd.setHours(23, 59, 59, 999);
    const [dateRange, setDateRange] = useState<DateRange>({
        startDate: todayStart,
        endDate: todayEnd,
    });
    const [exporting, setExporting] = useState(false)
    const [driverSessions, setDriverSessions] = useState<DriverSession[]>()
    const [drivers, setDrivers] = useState<Driver[]>()
    const [driverLoading, setDriverLoading] = useState(false)
    const [driverOptions, setDriverOptions] = useState<any[]>([]);
    const [loading, setLoading] = useState(false)
    const [driverId, setDriverId] = useState<any>()
    const [organisationId, setOrganisationId] = useState<any>()
    const [sessionReport, setSessionReport] = useState(false);
    const FileDownload = require('js-file-download');
    const [errorMessage, setErrorMessage] = useState('');
    const { selectedRoute, selectRouteById } = useContext(ReportsContext);

    const errorMessageHTML = errorMessage ? <Alert message={errorMessage} type="error" /> : null

    const handleChangeDate = (changeDateProps: DateRange) => {
        setDateRange(changeDateProps)
        setDriverSessions(undefined)
    }

    useEffect(() => {
        setDriverLoading(true)
        getDriverDropdown({ organisation: organisationId }).then(({ data }) => {
            setDriverOptions(data)
        }).finally(() => {
            setDriverLoading(false)
        })
    }, [organisationId]);

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        setErrorMessage('')
        setLoading(true)
        setDriverSessions([])
        if (sessionReport) {
            getDriverSessionReport({ startDate: dateRange.startDate, endDate: dateRange.endDate, driver: driverId, organisation: organisationId, route: selectedRoute?.id }).then((res) => {
                const data = res.data as DriverSession[]
                setDriverSessions(data)
            }).catch(() => {
                setErrorMessage('Error getting report')
            }).finally(() => {
                setLoading(false)
            })
        } else {
            getDriversReport({ organisation: organisationId, route: selectedRoute?.id }).then((res) => {
                const data = res.data as Driver[];
                setDrivers(data)
            }).catch(() => {
                setErrorMessage('Error getting report')
            }).finally(() => {
                setLoading(false)
            })
        }
    }

    const downloadCsv = () => {
        setErrorMessage('')
        setExporting(true)
        if (sessionReport) {
            exportDriverSessionReportCsv({ startDate: dateRange.startDate, endDate: dateRange.endDate, organisation: organisationId, driver: driverId, route: selectedRoute?.id }).then((res) => {
                FileDownload(res.data, 'driver_session_report.csv');
            }).catch(() => {
                setErrorMessage('Error downloading csv')
            }).finally(() => {
                setExporting(false)
            })
        } else {
            exportDriversReportCsv({ organisation: organisationId, route: selectedRoute?.id }).then((res) => {
                FileDownload(res.data, 'drivers_report.csv');
            }).catch(() => {
                setErrorMessage('Error downloading csv')
            }).finally(() => {
                setExporting(false)
            })
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="flex px-4 py-2 mb-2 bg-white shadow items-center">
                    <div className="mr-4">
                        <OrganisationSelect onChange={o => { setOrganisationId(o?.id) }} value={organisationId} />
                    </div>
                    <div className="mr-4 flex items-center">
                        <label className="form-label inline-block mr-1">Route</label>
                        <RouteAutoComplete value={selectedRoute?.value || ''} onChange={(e) => selectRouteById(e.route?.id)} placeholder="Route"/>
                    </div>
                    <label className="self-center mr-1 form-label cursor-pointer" htmlFor="sessionReport">Show History</label>
                    <input className="mr-4 self-center" type="checkbox" id="sessionReport" value={1} checked={sessionReport} onChange={e => { setSessionReport(e.target.checked) }} />
                    {sessionReport && <>
                        <label htmlFor="driver" className="self-center mr-1 form-label ">
                            Driver
                            {driverLoading &&
                                <FontAwesomeIcon icon={faSyncAlt} className={'ml-3 text-sm fa-spin'} />}
                        </label>
                        <select name="driver" onChange={(e) => setDriverId(e.target.value)} className="input-control mr-4">
                            <option value="">Select Driver</option>
                            {driverOptions.map((d) => (
                                <option key={d.id} value={d.id}>{d.value}</option>
                            ))}
                        </select>
                        <span className="mr-2 self-center form-label">Dates</span>
                        <DateRangePicker value={dateRange} onChange={handleChangeDate} />
                    </>}
                    <div className="ml-auto">
                        <button type="button" onClick={() => { downloadCsv() }} className="bg-gray-300 mr-2 button"
                            disabled={(loading || exporting || (!driverId && !organisationId))}>
                            <span><FontAwesomeIcon icon={faFileDownload} className="mr-1" />{exporting ? '...Exporting' : 'Export to CSV'}</span>
                        </button>
                        <button type="submit" className="bg-yellow-300 ml-2 button" disabled={loading || (!driverId && !organisationId)}>
                            {!loading ? <span><FontAwesomeIcon icon={faSearch} className="mr-1" />Get Report</span> :
                                <span><FontAwesomeIcon icon={faSyncAlt} className={'ml-2 text-sm fa-spin'} />...Loading</span>}
                        </button>
                    </div>
                </div>
                <div className="text-center">
                    {errorMessageHTML}
                </div>
            </form>
            <div className="bg-white p-4 shadow">
                {sessionReport &&
                    <table className="table w-full">
                        <thead>
                            <th className="text-left">Driver</th>
                            <th className="text-left">Route</th>
                            <th className="text-left">Start</th>
                            <th className="text-left">End</th>
                        </thead>
                        <tbody>
                            {driverSessions?.map((s) => (
                                <tr key={s.sessionId}>
                                    <td>
                                        <Link to={`/drivers/${s.driverId}/view`} className="text-primary underline">{s.driverName}</Link>
                                    </td>
                                    <td>{s.routeName}</td>
                                    <td>{formatDateTime(s.startDate)}</td>
                                    <td>{formatDateTime(s.endDate || null)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                }
                {!sessionReport &&
                    <table className="table w-full">
                        <thead>
                            <tr>
                                <th className="text-left">Driver</th>
                                <th className="text-left">Email</th>
                                <th className="text-left">Allocated Routes</th>
                            </tr>
                        </thead>
                        <tbody>
                            {drivers?.map((d) => (
                                <tr key={d.id}>
                                    <td>
                                        <Link to={`/drivers/${d.id}/view`} className="text-primary underline">{d.firstName} {d.lastName}</Link>
                                    </td>
                                    <td>{d.email}</td>
                                    <td>
                                        <ul>
                                            {d.routes.map(r => (
                                                <li key={`${d.id}_${r.id}`}>{r.routeName}</li>
                                            ))}
                                        </ul>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                }
            </div>
        </>
    )
}