import React, {useEffect, useState} from "react";
import {PassengerReportData, SessionPassengers} from "../sessions/types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSyncAlt, faUndo} from "@fortawesome/free-solid-svg-icons";
import DateRangePicker, {DateRange} from "../dates/DateRangePicker";
import {getSessionPassengers} from "../sessions/service";
import Pagination from "../pagination/Pagination";
import {getFormattedDateTimeFromString} from "../dates/helper";

export const Journeys = () => {
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(1);
    const [sessionPassengers, setSessionPassengers] = useState<SessionPassengers>()
    const [live, setLive] = useState(true)
    const weekAgo = new Date();
    weekAgo.setDate(weekAgo.getDate()-7)
    weekAgo.setHours(0, 0, 0, 0);

    const todayEnd = new Date();
    todayEnd.setHours(23, 59, 59, 999);
    const [dateRange, setDateRange] = useState<DateRange>({
        startDate: weekAgo,
        endDate: todayEnd,
    });

    const refreshDateRange = () => {
        setDateRange({
            startDate: weekAgo,
            endDate: todayEnd
        })
        setLive(true)
        setPage(1)
        setSessionPassengers(undefined)
    }
    const handleGetSessionPassengers = () => {
        setLoading(true)
        setSessionPassengers(undefined)
        const params: PassengerReportData = {
            startDate: dateRange.startDate.toISOString(),
            endDate: dateRange.endDate.toISOString(),
            page: page,
            live: live
        }
        getSessionPassengers(params).then((response) => {
            const data = response.data
            setSessionPassengers(data.items)
            setPages(data.lastPage);
        }).catch(error => {
            console.error(error)
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        handleGetSessionPassengers()
    }, [page])

    const liveCss = live ? "bg-yellow-300 text-white" : "text-yellow-300";
    const allCss = live ?  "text-yellow-300" : "bg-yellow-300 text-white";
    return (
        <div>
            <div className="mb-4">
                <h1 className="text-lg">Journeys</h1>
            </div>
            <div className="flex px-4 py-2 mb-2 bg-white shadow">
                <DateRangePicker value={dateRange} onChange={setDateRange}/>
                <div className="flex items-center justify-center ml-4">
                    <button
                        type="button"
                        onClick={() => {
                            setLive(false)
                            setSessionPassengers(undefined)
                        }}
                        className={allCss + " bg-transparent border-l border-t border-b border-yellow-300  font-bold uppercase text-xs  px-4 py-2 rounded-l outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"}
                    >
                        All
                    </button>
                    <button
                        className={liveCss + " bg-transparent border-t border-b border-r border-yellow-300  font-bold uppercase text-xs px-4 py-2 rounded-r outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"}
                        type="button"
                        onClick={() => {
                            setLive(true)
                            setSessionPassengers(undefined)
                        }}
                    >

                        Live Only
                    </button>

                </div>
                <div className="ml-auto">
                    <button className="mr-2 text-white bg-gray-600 button" type="button"
                            onClick={(event) => {
                                event.preventDefault();
                                refreshDateRange()
                            }} disabled={loading}>
                        <FontAwesomeIcon icon={faUndo}
                                         className={'mr-1 text-sm '}/>
                        Reset
                    </button>
                    <button className="bg-yellow-300 button" disabled={loading} onClick={(event) => {
                        event.preventDefault();
                        handleGetSessionPassengers();
                    }}>{loading ? <div className="text-center">Loading <FontAwesomeIcon icon={faSyncAlt} className={'ml-2 text-sm fa-spin'}/></div> : 'Search'}
                    </button>
                </div>
            </div>
            {sessionPassengers === undefined && !loading &&
            <div className={"font-semibold text-center w-full text-gray-400"}>
                Click search to find passengers
            </div>
            }
            {sessionPassengers?.length === 0 && !loading &&
            <div className="w-full font-semibold text-center text-gray-400">
                No passengers found for filters
            </div>
            }
            <div className="grid grid-cols-3 gap-3">
                {sessionPassengers && sessionPassengers.map(sessionPassenger => {
                    return (
                        <div className="flex flex-col p-2 text-center bg-white shadow" key={sessionPassenger.id}>
                            <h1 className="mb-2 text-xl font-semibold">Passenger</h1>
                            <h3 className="mb-2 text-m">Name: {sessionPassenger.passenger.firstName + ' ' + sessionPassenger.passenger.lastName}</h3>
                            <h3 className="mb-2 text-m ">Route: {sessionPassenger.session?.journey?.routeName}</h3>
                            <h3 className="mb-2 text-m ">Journey: {sessionPassenger.session?.journey?.schedule?.name}</h3>
                            <h3 className="mb-2 text-m ">Checked In: {sessionPassenger.checkedInStop?.name}</h3>
                            <h3 className="mb-2 text-m ">Checked In
                                Time: {getFormattedDateTimeFromString(sessionPassenger.checkedIn)}</h3>
                            {(sessionPassenger.checkedOut || sessionPassenger.session?.endDate) && <>
                                <h3 className="mb-2 text-m ">Checked Out: {sessionPassenger.checkedOutStop?.name ? sessionPassenger.checkedOutStop?.name : 'N/A'}</h3>
                                <h3 className="mb-2 text-m ">Checked Out Time: {sessionPassenger.checkedOut ? getFormattedDateTimeFromString(sessionPassenger.checkedOut): 'N/A'}</h3></>}
                        </div>
                    )
                })}
            </div>
            {loading && <div className="text-center">...Loading <FontAwesomeIcon icon={faSyncAlt} className={'ml-2 text-sm fa-spin'}/></div>}
            <div className="my-2 text-center">
                <Pagination page={page} onChange={setPage} pages={pages}/>
            </div>
        </div>
    )
}