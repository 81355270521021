import { useState } from "react";
export interface GeoSearchProps {
    onResult?: CallableFunction,
    onError?: CallableFunction,
}

export function Search(props: GeoSearchProps) {

    const [address, setAddress] = useState('');
    const region = 'uk';

    function performSearch() {
        if (!google.maps) {
            console.warn('Google Maps is not ready');
            return;
        }
        const geocoder: any = new google.maps.Geocoder();
        if (!address || !props.onResult) {
            return;
        }
        geocoder.geocode({ address, region }).then((response: any) => {
            if (!response.results[0]) {
                if (props.onResult) props.onResult(null);
                return;
            }
            if (props.onResult) props.onResult(response.results[0].geometry.location);
        }).catch((error: any) => {
            if (props.onError) {
                props.onError(error);
            }
            console.error(error);
        })
    }

    return (
        <div className="flex">
            <input value={address} onChange={(event) => { setAddress(event.target.value) }} placeholder="Search address..." className="w-full input-control" onKeyUp={(event) => {if(event.key === 'Enter') performSearch()}}/>
            <button type="button" onClick={performSearch} className="mb-2 ml-1 text-white bg-gray-500 button">Search</button>
        </div>
    );
}