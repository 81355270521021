import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { clearEditVehicle, selectEditVehicle, setDeleteVehicle, setEditVehicle } from "./slice";
import Modal from '../modal/Modal';
import { Vehicle } from "./types";
import { useEffect, useState } from "react";
import Alert from "../alerts/Alert";
import FormError from '../form/FormError';
import { saveVehicle } from "./service";
import { Formik, Form, Field } from "formik";
import * as Yup from 'yup';
import {selectUserPolicies} from "../auth/slice";
import {getPermissionsLevelForEntity, Permission} from "../policies/helpers";
import {setSuccessNotificationText} from "../notification/slice";

export default function EditModal () {
    const editVehicle = useAppSelector(selectEditVehicle);
    const policies = useAppSelector(selectUserPolicies)

    const [vehicle, setVehicle] = useState<Vehicle|null>(null);
    const [saving, setSaving] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [apiErrors, setApiErrors] = useState({});
    const dispatch = useAppDispatch();

    const resetMessages = () => {
        setErrorMessage('');
        setApiErrors({});
    }

    useEffect(() => {
        if (editVehicle) {
            setVehicle({...editVehicle} as Vehicle);
            resetMessages();
        } else {
            setVehicle(null);
            resetMessages();
        }
    }, [editVehicle]);
    

    if (!vehicle) {
        return null;
    }

    const permissionsLevel = getPermissionsLevelForEntity('vehicle', vehicle, policies)


    const closeModal = () => {
        dispatch(clearEditVehicle());
        resetMessages();
    }

    const validation = Yup.object().shape({
        registrationNumber: Yup.string().label('Registration Number').required().min(3).max(8),
        type: Yup.string().label('Type').required().max(50),
        capacity: Yup.number().label('Capacity').required().min(1),
        isActive: Yup.boolean(),
        ownedBy: Yup.string().label('Ownership').required(),
    });

    const submitSave = (values: Vehicle) => {
        const data = {...values, id: vehicle.id};
        setVehicle(data);
        setSaving(true);
        resetMessages();
        saveVehicle(data).then(({data}) => {
            setEditVehicle(data);
            dispatch(setSuccessNotificationText('Vehicle saved successfully'))
            closeModal();
        }).catch(err => {
            setErrorMessage('There was a problem saving the vehicle, check for errors');
            if (err.response.data) {
                setApiErrors(err.response.data);
            }
        }).finally(() => {
            setSaving(false);
        })
    }

    const deleteVehicle = () => {
        dispatch(setDeleteVehicle(vehicle));
    }

    const errorMessageHtml = errorMessage ? (<Alert type="error" message={errorMessage} />) : null;

    return (
        <Modal title={vehicle.id ? 'Edit Vehicle' : 'New Vehicle'} closeAction={closeModal}>
            <Formik
                initialValues={{...vehicle}}
                onSubmit={(values: Vehicle) => {
                    submitSave(values)
                }
                }
                validationSchema={validation}
                validateOnBlur
                >
                {({errors, isValid, dirty}) => {
                    const allErrors = {...errors, ...apiErrors}
                    return (
                        <Form>
                            <div className="mb-4">
                                <label className="form-label" htmlFor="registrationNumber">Registration Number:</label>
                                <Field type="text" id="registrationNumber" name="registrationNumber" disabled={permissionsLevel === Permission.Read} className="input-control"/>
                                <FormError name="registrationNumber" errors={allErrors}/>
                            </div>
                            <div className="mb-4">
                                <label className="form-label">Type:</label>
                                <Field type="text" id="type" name="type" disabled={permissionsLevel === Permission.Read} className="input-control"/>
                                <FormError name="type" errors={allErrors}/>
                            </div>
                            <div className="mb-4">
                                <label className="form-label">Capacity:</label>
                                <Field type="number" step="1" min="1" id="capacity" name="capacity" disabled={permissionsLevel === Permission.Read} className="input-control"/>
                                <FormError name="capacity" errors={allErrors}/>
                            </div>
                            <div className="mb-4">
                                <label className="form-label">Status:</label>
                                <label>
                                <span className="mr-2">Is Active</span>
                                <Field type="checkbox" id="isActive" name="isActive" disabled={permissionsLevel === Permission.Read} className="input-control"/>
                                <FormError name="isActive" errors={allErrors}/>
                                </label>
                            </div>
                            <div className="mb-4">
                                <label className="form-label">Ownership:</label>
                                <Field as="select" id="ownedBy" name="ownedBy" disabled={permissionsLevel === Permission.Read} className="input-control">
                                    <option>Organisation</option>
                                    <option>Driver</option>
                                </Field>
                                <FormError name="ownedBy" errors={allErrors}/>
                            </div>
                            {errorMessageHtml}
                            <div className="flex py-2 mt-4 border-t-2">
                                {permissionsLevel >= Permission.Edit && <button className="mr-2 bg-yellow-300 button" type="submit"
                                         disabled={(saving || !isValid || !dirty)}>{saving ? 'Saving...' : 'Save'}</button>}
                                <button className="button" type="button" onClick={() => {closeModal()}}>Close</button>
                                {(vehicle.id && permissionsLevel >= Permission.Delete) ? (<button className="ml-auto text-red-600 button" type="button" onClick={() => {deleteVehicle()}}>Delete</button>) : null}
                            </div>
                        </Form>
                    )}
                }
            </Formik>
        </Modal>
    );
}
