import React, { ReactNode, useContext } from "react";
import DateRangePicker, { DateRange } from "../dates/DateRangePicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import RouteAutoComplete from "../routes/RouteAutocomplete";
import { ReportsContext } from "./Context";

interface FilterProps {
    loading: boolean
    children: ReactNode
    dateRange: DateRange
    setDateRange: CallableFunction
    onChange: CallableFunction
    onSubmit: CallableFunction
}

export const JourneyDateRangeFilter = (props: FilterProps) => {
    const { selectedRoute, selectJourneyById, setSelectedRoute, selectedJourney, journeyOptions, journeysLoading } = useContext(ReportsContext);
    const handleChangeDate = (changeDateProps: DateRange) => {
        props.setDateRange(changeDateProps)
        props.onChange()
    }

    return (
        <div>
            <form onSubmit={(e) => {
                e.preventDefault();
                props.onSubmit();
            }}>
                <div className="flex px-4 py-2 mb-2 bg-white shadow">
                    <div className="flex w-full flex-row items-center">
                        <div className="mr-1">
                            <label htmlFor="routeId" className="block mr-3 font-medium">Route
                            </label>
                        </div>
                        <div className="mr-2">
                            <RouteAutoComplete value={selectedRoute?.value || ''} onChange={(e) => setSelectedRoute(e.route)} placeholder="Route"/>
                        </div>
                        <div className="mr-1">
                            <label htmlFor="journeyId" className="block mr-3 font-medium"><span
                                className="inline-block align-middle">Journey</span>
                                {journeysLoading && selectedRoute &&
                                    <FontAwesomeIcon icon={faSyncAlt} className={'ml-2 text-sm fa-spin'} />}

                                <select
                                    className="p-1 mr-3 ml-3 border-2 rounded border-gray-100"
                                    value={selectedJourney?.id || ''}
                                    onChange={(e) => {
                                        selectJourneyById(e.target.value);
                                        props.onChange()
                                    }}
                                    name="journeyId"
                                    id="journeyId">
                                    <option value="">All Journeys</option>
                                    {journeyOptions.map(j => (
                                        <option value={j.id}>{j.value}</option>
                                    ))}
                                </select>
                            </label>
                        </div>
                        <DateRangePicker value={props.dateRange} onChange={handleChangeDate} />

                        <div className="ml-auto">
                            {props.children}
                            <button
                                type="submit"
                                className="bg-yellow-300 ml-2 button"
                                disabled={props.loading}
                            >
                                {!props.loading ? <span><FontAwesomeIcon icon={faSearch} className="mr-1" />
                                    Get Report</span> : <span><FontAwesomeIcon icon={faSyncAlt} className={'ml-2 text-sm fa-spin'} />
                                    ...Loading</span>}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}