import {createSlice} from "@reduxjs/toolkit";
import {Reservation} from "./types";
import {RootState} from "../../app/store";


interface ReservationState {
    editReservation: null|Reservation
}

const initialState:ReservationState = {
    editReservation:null
}

export const slice = createSlice({
    name: "Reservation",
    initialState,
    reducers: {
        setEditReservation: (state:ReservationState, {payload}) => {
            state.editReservation = payload
        },
    }
});



export const {setEditReservation} = slice.actions

export const selectEditReservation = (state: RootState) => state.reservations.editReservation

export default slice.reducer
