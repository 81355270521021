import {useAppDispatch, useAppSelector} from "../../app/hooks";
import React, {useEffect, useState} from "react";
import {RouteSession} from "./types";
import Modal from "../modal/Modal";
import {clearLiveRouteSession, selectLiveRouteSession} from "./slice";
import {LiveSessionTable} from "./LiveSessionTable";




export const LiveModal = () => {
    const liveRouteSession = useAppSelector(selectLiveRouteSession);
    const [routeSession, setRouteSession] = useState<RouteSession | null>(null);
    const dispatch = useAppDispatch()
    useEffect(() => {
        if (liveRouteSession) {
            setRouteSession({...liveRouteSession} as RouteSession);
        } else {
            setRouteSession(null);
        }
    }, [liveRouteSession]);

    if (!routeSession) {
        return null;
    }

    const live = !routeSession.endDate

    const closeModal = () => {
        dispatch(clearLiveRouteSession())
    }

    const modalTitle = live ? 'Live Passenger Info': 'Passenger Info'

    return (
        <Modal title={modalTitle} closeAction={closeModal}>
            <LiveSessionTable routeSession={routeSession} closeModal={closeModal}/>
        </Modal>
    );
}