import {Message} from "./types";
import apiAxios from "../api/axios";
import {AxiosRequestConfig} from "axios";


export const sendMessage = (message: Message) => {
    return apiAxios.post('/message/send', message)
}
export const fetchAppMessages = (params: any) => {
    return apiAxios.get('/message/sos', {params} as AxiosRequestConfig)
}