import { useEffect, useState } from "react";

export interface TimePickerProps {
    value?: string,
    onChange?: CallableFunction,
    disabled? :boolean,
}

export function TimePicker(props: TimePickerProps) {
    const [hour, setHour] = useState<string>('00');
    const [mins, setMins] = useState<string>('00');

    const minsOptions = []
    for(let i = 0; i < 60; i++) {
        minsOptions.push(String(i).padStart(2, '0'));
    }
    const hoursOptions = []
    for(let i = 0; i < 24; i++) {
        hoursOptions.push(String(i).padStart(2, '0'));
    }

    useEffect(() => {
        if (props.value && props.value !== getFormatedValue()) {
            const valueSplit = props.value.split(':');
            setHour(valueSplit[0] || '00');
            setMins(valueSplit[1] || '00');
        }
    }, []);

    useEffect(() => {
        const value = hour + ':'+mins;
        if(props.onChange && value !== props.value) {
            props.onChange(value);
        }
    }, [hour, mins]);

    function getFormatedValue() {
        return hour + ':'+mins;
    }

    return (
        <div>
            <select disabled={props.disabled !== undefined && props.disabled} className="py-2 pl-1 text-center border-2 rounded-md w-14" value={hour} onChange={event => {setHour(event.target.value)}}>
                {hoursOptions.map(h => (
                    <option key={'hour_'+h}>{h}</option>
                ))}
            </select>
            <span>:</span>
            <select disabled={props.disabled !== undefined && props.disabled} className="py-2 pl-1 text-center border-2 rounded-md w-14" value={mins} onChange={event => {setMins(event.target.value)}}>
                {minsOptions.map(m => (
                    <option key={'mins_'+m}>{m}</option>
                ))}
            </select>
        </div>
    );
}

export default TimePicker;