import { Link, useHistory } from "react-router-dom";
import { faCheck, faPlus, faSyncAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormEvent, useState } from "react";
import { Organisation } from "./types";
import { useEffect } from "react";
import { fetchOrganisations } from "./service";
import Pagination from "../pagination/Pagination";
import {useAppSelector} from "../../app/hooks";
import {selectUserPolicies} from "../auth/slice";
import {getPermissionsLevel, Permission} from "../policies/helpers";

export function Index() {

    const policies = useAppSelector(selectUserPolicies)
    const permissionsLevel = getPermissionsLevel('organisation', policies)
    const [searchText, setSearchText] = useState('');
    const [statusFilter, setStatusFilter] = useState('');
    const [loading, setLoading] = useState(false);
    const [organisations, setOrganisations] = useState<Organisation[]>([]);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(1);
    const history = useHistory();

    useEffect(() => {
        loadOrganisations();
    }, [page]);

    function handleSearchSubmit(event: FormEvent) {
        event.preventDefault();
        if (page === 1) {
            loadOrganisations();
        }
        setPage(1);
        setPages(1);
    }

    function loadOrganisations() {
        const params = {
            search: searchText,
            status: statusFilter,
            page,
        };
        setLoading(true);
        fetchOrganisations(params).then(({ data }) => {
            setOrganisations(data.items);
            setPages(data.lastPage);
            setLoading(false);
        });
    }

    return (
        <div>
            <div>
                <h1 className="mb-2 text-lg">Organisations</h1>
            </div>
            <div className="p-2 my-4 bg-white rounded shadow">
                <div className="flex items-center">
                    <form action="" onSubmit={handleSearchSubmit} className="flex">
                        <div className="mr-2">
                            <input type="search" placeholder="Search..." value={searchText} onChange={(e) => { setSearchText(e.target.value) }} className="mb-0 input-control" />
                        </div>
                        <div className="mr-2">
                            <select onChange={(e) => { setStatusFilter(e.target.value) }} className="mb-0 input-control" value={statusFilter}>
                                <option value="">Any Status</option>
                                <option value="active">Active</option>
                                <option value="inactive">Inactive</option>
                            </select>
                        </div>
                        <div>
                            <button className="bg-yellow-300 button" disabled={loading}>Search</button>
                        </div>
                    </form>
                    <div className="flex h-auto ml-auto">
                        <button className="mr-2 text-white bg-gray-600 button" type="button" onClick={loadOrganisations} disabled={loading}>
                            <FontAwesomeIcon icon={faSyncAlt} className={'mr-1 text-sm ' + (loading ? 'fa-spin' : '')} />
                            {loading ? 'Loading...' : 'Refresh'}
                        </button>
                        {permissionsLevel >= Permission.Create && <Link to="/organisations/new" className="bg-yellow-300 button">
                            <FontAwesomeIcon icon={faPlus} className="mr-1"/>
                            <span>New Organisation</span>
                        </Link>}
                    </div>
                </div>
            </div>
            <div className="p-2 my-4 bg-white rounded shadow">
                {organisations.length && !loading ? (
                    <>
                        <table className="table w-full table-striped">
                            <thead>
                                <th className="text-left">Name</th>
                                <th className="text-center">Active</th>
                            </thead>
                            <tbody>
                                {organisations.map((organisation) => (
                                    <tr className="cursor-pointer" onClick={() => { history.push('/organisations/' + organisation.id + '/edit') }}>
                                        <td>{organisation.name}</td>
                                        <td className="text-center">{organisation.active ? (
                                            <FontAwesomeIcon icon={faCheck} className="text-green-400" />
                                        ) : (
                                            <FontAwesomeIcon icon={faTimes} className="text-red-400" />
                                        )}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="my-2 text-center">
                            <Pagination page={page} pages={pages} onChange={(p: number) => {setPage(p)}} />
                        </div>
                    </>
                ) : null}
                {!organisations.length && !loading ? (
                    <p>No organisations found</p>
                ) : null}
                {loading ? (
                    <p>Loading organisations...</p>
                ) : null}
            </div>
        </div>
    );
}

export default Index;