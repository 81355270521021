import ConfirmModal from "../modal/ConfirmModal";
import {OrganisationPermission} from "./types";
import {deletePermission} from "./service";
import {setErrorNotificationText, setSuccessNotificationText} from "../notification/slice";
import {useAppDispatch} from "../../app/hooks";

interface DeletePermissionProps {
    closeAction: CallableFunction
    confirmAction: CallableFunction
    deletePermission: OrganisationPermission
}

export const DeleteModal = (props :DeletePermissionProps) => {

    const dispatch = useAppDispatch();

    const handleDeletePermission = () => {
        deletePermission(props.deletePermission).then(res => {
            dispatch(setSuccessNotificationText('Permission deleted successfully'));
            props.confirmAction();
            props.closeAction();
        }).catch(() => {
            dispatch(setErrorNotificationText('Permission not deleted'));
            props.closeAction();
        })
    }

    return (
        <ConfirmModal title={"Delete Organisation Permission"} onClose={props.closeAction} onConfirm={handleDeletePermission}>
            Are you sure you want to delete {
            props.deletePermission.resource.map((resource, index) => {
                const comma = ((index + 1) !== props.deletePermission.resource.length) ? ', ': '';
                return (resource[0].toUpperCase() + resource.substring(1) + comma)
            })} permissions
        </ConfirmModal>
    )
}