import {AxiosRequestConfig, AxiosResponse} from 'axios';
import {apiAxios, baseApiAxios} from '../api/axios';

const CLIENT_ID = process.env.REACT_APP_OAUTH_CLIENT_ID || (window as any).env?.client_id;
const CLIENT_SECRET =  process.env.REACT_APP_OAUTH_CLIENT_SECRET || (window as any).env?.client_secret;

export async function login(email: string, password: string): Promise<AxiosResponse> {
    let data = new FormData();
    data.set('username', email);
    data.set('password', password);
    data.set('grant_type', 'password');
    data.set('client_id', CLIENT_ID);
    data.set('client_secret', CLIENT_SECRET);
    return baseApiAxios.post('token', data);
}

export async function  refresh(refreshToken: string): Promise<AxiosResponse> {
    let data = new FormData();
    data.set('refresh_token', refreshToken);
    data.set('grant_type', 'refresh_token');
    data.set('client_id', CLIENT_ID);
    data.set('client_secret', CLIENT_SECRET);

    return apiAxios.post('/token', data, { __isRetryRequest:true } as AxiosRequestConfig);
}

export async function logout(): Promise<AxiosResponse> {
    return apiAxios.post('/logout', {},{  __isRetryRequest:true } as AxiosRequestConfig);
}

export function saveAuth (accessToken: string, refreshToken: string, expiresIn: number, rememberMe: boolean|null = null): void {
    if (rememberMe === null) {
        rememberMe = (window.localStorage.getItem('refreshToken') !== null) as boolean;
    }
    let storage = rememberMe ? window.localStorage : window.sessionStorage;
    storage.setItem('refreshToken', refreshToken);
}

export function loadRefreshToken (): string {
    const token =  window.localStorage.getItem('refreshToken') || window.sessionStorage.getItem('refreshToken');
    return token || '';
}

export function clearAuthFromStorage (): void {
    window.localStorage.removeItem('refreshToken');
    window.sessionStorage.removeItem('refreshToken');
}

export async function getLoggedInUser () {
    return apiAxios.get('/users/loggedIn')
}