import { ReactNode } from "react";

export interface DialogProps {
    title: string,
    cancelFunction: CallableFunction,
    confirmFunction: CallableFunction,
    children: ReactNode,
    disabled?: boolean,
    confirmText?: string,
}
export default function Dialog(props: DialogProps) {
    return (
        <div className="fixed top-0 left-0 z-50 flex w-screen h-screen">
            <div className="absolute top-0 left-0 w-full h-full opacity-50 bg-gray-50"></div>
            <div className="relative mx-auto mt-12 mb-auto bg-white shadow justify-self-center align-self-center">
               <div className="flex items-center p-2 text-lg text-white bg-gray-600">
                   <h2>{props.title}</h2>
                   
               </div>
               <div className="p-2 bg-white" style={{'maxWidth': '65vh'}}>
                   {props.children}
               </div>
               <div className="p-2 bg-white">
               <button className="mr-2 bg-yellow-300 button" disabled={props.disabled} onClick={(event) => {event.preventDefault(); props.confirmFunction()}}>{props.confirmText || 'OK'}</button>
               <button className="p-1 ml-auto" disabled={props.disabled} onClick={(event) => {event.preventDefault(); props.cancelFunction()}}>Cancel</button>
               </div>
            </div>
        </div>
    );
}