import apiAxios from "../api/axios";
import {AxiosRequestConfig} from "axios";
import {BulkUploadParams, GetExampleParams} from "./types";


export const getExample = (params:GetExampleParams) => {
    return apiAxios.get('/bulk-upload/example', {params} as AxiosRequestConfig)
}

export const bulkUpload = (params:BulkUploadParams) => {
    const formData = new FormData();
    formData.append('file', params.file)
    return apiAxios.post('/bulk-upload/'+ params.type, formData, {headers: {
        'Content-Type': 'multipart/form-data'}, params: {routeId: params.routeId, journeyId: params.journeyId}}
    )
}