import {apiAxios} from '../api/axios';

export interface ForgotPasswordData {
    username: string,
}

export interface ResetPasswordData {
    password: string,
    confirmPassword: string,
    token: string,
}

export function requestForgotPassword(data: ForgotPasswordData) {
    return apiAxios.post('/forgotPassword', data);
}

export function requestResetPassword(data: ResetPasswordData) {
    return apiAxios.post('/resetPassword', data);
}