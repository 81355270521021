import Modal from "../modal/Modal";
import {RouteMapMarker} from "../routes/RouteMap";
import React, {useEffect, useRef} from "react";



interface LivePositionProps {
    closeModal:CallableFunction,
    marker: RouteMapMarker
}


let map: google.maps.Map;

export const PositionMap = (props:LivePositionProps) => {

    const mapEl = useRef<HTMLDivElement | null>(null);
    const marker = new google.maps.Marker();

    useEffect(() => {
        if (!props.marker || !mapEl.current) {
            return;
        }
        const mapOptions = {
            zoom: 12,
            center: props.marker.location,
        } as google.maps.MapOptions;

        map = new google.maps.Map(mapEl.current, mapOptions)

        if (props.marker) {
            marker.setMap(map);
            marker.setIcon(props.marker.icon)
            marker.setPosition(props.marker.location)
            marker.setTitle(props.marker.name)
        }
    }, [props.marker])

    return (<>
            <Modal title={"Position"} closeAction={props.closeModal}>
                <div className="w-full my-4 h-96" ref={mapEl}>

                </div>
            </Modal>
        </>
    )
}