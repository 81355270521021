import {apiAxios} from '../api/axios';
import { AppNotification } from './types';

export function fetchAppNotifications(params: any) {
    return apiAxios.get('/appNotifications', {params});
}

export function saveAppNotification(appNotification: AppNotification) {
    if (appNotification.id) {
        return apiAxios.put('/appNotifications/'+appNotification.id, appNotification);
    }
    return apiAxios.post('/appNotifications', appNotification);
}

export function fetchAppNotification(id: any) {
    return apiAxios.get('/appNotifications/'+id);
}

export function deleteAppNotification(id: any) {
    return apiAxios.delete('/appNotifications/'+id);
}

export function sendAppNotification(id: any) {
    return apiAxios.post('/appNotifications/'+id+'/send');
}