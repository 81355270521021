import React, { ChangeEvent, useEffect, useState } from "react";
import { Passenger, PassengerCarer } from "./types";
import { Carer } from "../carers/types";
import { fetchCarers } from "../carers/service";

export interface CarersFormProps {
    passenger: Passenger,
    onChange: (passenger: Passenger) => any,
}


export const CarersForm = (props: CarersFormProps) => {
    const [users, setUsers] = useState<Carer[]>([]);
    const [loaded, setLoaded] = useState(false);
    const [search, setSearch] = useState('');

    useEffect(() => {
        setLoaded(false);
        fetchCarers({ perPage: 10000 }).then(({ data }) => {
            setUsers(data.items);
            setLoaded(true);
        }).catch(err => {
            console.error(err);
            setLoaded(true);
        })
    }, []);

    const toggleUser = (user: Carer) => {
        let passengerUsers = [...props.passenger.carers];
        const index = passengerUsers.findIndex(r => (r.userId === user.id));
        if (index < 0) {
            passengerUsers.push({
                userId: user.id,
            } as PassengerCarer);
        }
        if (index > -1) {
            passengerUsers.splice(index, 1);
        }
        props.onChange({ ...props.passenger, carers: passengerUsers });
        setSearch('');
    }

    const searchChanged = (event: ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    }

    const filteredUsers = users.filter((user) => {
        if (search === '') {
            return true;
        }
        if (user.firstName.toLowerCase().includes(search.toLowerCase()) || user.lastName.toLowerCase().includes(search.toLowerCase()) || user.email?.toLowerCase().includes(search.toLowerCase())) {
            return true;
        }

        return false;
    });

    return (
        <div className="my-4 shadow">
            {!loaded && (
                <p className="p-4 text-sm text-gray-500">Loading users...</p>
            )}

            {loaded && users.length === 0 && (
                <p className="p-4 text-sm text-gray-500">No users available to select</p>
            )}

            {loaded && users.length > 0 && (
                <div>
                    <input type="search" value={search} className="w-full border-gray-400 input-control" onChange={searchChanged} placeholder="Search guardians..." />
                </div>
            )}
            <div className="my-4 overflow-y-auto shadow max-h-52">
                {filteredUsers.map((user: Carer) => {
                    const readerPassengerUser = props.passenger.carers.find(d => d.userId === user.id);
                    return (
                        <div className="flex items-center p-2 mb-1 border-2 rounded" key={'user_' + user.id}>
                            <div className="mr-2">
                                <input type="checkbox" checked={readerPassengerUser !== undefined} onChange={(e) => { toggleUser(user) }} />
                            </div>
                            <span>{user.firstName} {user.lastName} - {user.email}</span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default CarersForm;