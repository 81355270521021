import Preview from "../media/Preview";
import Delete from "../media/Delete";
import Upload from "../media/Upload";
import React, {useEffect, useState} from "react";
import {PassengerImage} from "./types";
import {Link, useParams} from "react-router-dom";
import {ParamsWithId} from "../routing/types";
import {MediaFile} from "../media/types";
import {fetchPassengerImage,savePassengerImage} from "./service";
import Alert from "../alerts/Alert";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faSyncAlt} from "@fortawesome/free-solid-svg-icons";
import {setErrorNotificationText, setSuccessNotificationText} from "../notification/slice";
import {useHistory} from "react-router";
import {useAppDispatch} from "../../app/hooks";

export const AddPhoto = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const {id} = useParams<ParamsWithId>();
    const [passengerImage, setPassengerImage] = useState<PassengerImage>({image:undefined, passengerId:id});
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(() => {
        setLoading(true)
        const idNumber = parseInt(id)
        if (id) {
            fetchPassengerImage(idNumber).then((response) => {
                const data = response.data as PassengerImage
                setPassengerImage(data)
                setLoading(false)
            }).catch(error => {
                console.error(error)
                setErrorMessage('Could not find Passenger')
                dispatch(setErrorNotificationText('Could not find passenger'))
                history.push('/passengers')
            })
        }
    }, [])

    function handleUpload(mediaFile: MediaFile) {
        let uploadedImage:PassengerImage = { passengerId:id, image: mediaFile }
        setPassengerImage(uploadedImage);
        savePassengerImage(uploadedImage).then(() => {
            dispatch(setSuccessNotificationText('Passenger photo updated'))
            history.push('/passengers/'+ id + '/edit')
        }).catch((err) => {
            console.error(err)
        })
    }

    function handleDeleteImage () {
        setPassengerImage({ passengerId:id, image: undefined})
    }

    const errorMessageHTML = errorMessage ? <Alert message={errorMessage} type="error"/> : null

    return (<>
        <div className="p-4 bg-white shadow">
            <div className="font-semibold m-1">
                Add Photo
            </div>
            <div>
                {loading ? <><FontAwesomeIcon icon={faSyncAlt} className={'mr-1 text-sm ' + (loading ? 'fa-spin' : '')}/></> : ''}
                {passengerImage && passengerImage.image ? (
                    <>
                        <Preview mediaFile={passengerImage.image}/>
                        <div>
                            <Delete mediaFile={passengerImage.image}
                                    onDelete={handleDeleteImage}
                            />
                        </div>
                    </>
                ) : null}
            </div>
            <div>
                {!passengerImage.image && <Upload onUploaded={handleUpload} imageOnly={true}/>}
            </div>
        </div>
        {errorMessageHTML}
        <div className="flex flex-row py-2 mt-4 border-t-2">
            <div className="">
                <Link to={"/passengers/" + passengerImage.passengerId + "/edit"} className="button">
                    <FontAwesomeIcon icon={faArrowLeft} className="mr-2"/>
                    Back to Edit Passenger
                </Link>
            </div>
        </div>
    </>)
}